import { APITypesV1, APITypesV2 } from "@cur8/api-client";
import { Answers, Question } from "@cur8/questionnaire";
import { APIClient } from "lib/api/client";
import { saveOnboarding } from "lib/api/onboarding";
import { AdditionalWorries } from "lib/questionnaire/question-flows/onboarding/questions/additionalWorries";
import {
  Diet,
  DietOptions,
  OtherDietOption,
} from "lib/questionnaire/question-flows/onboarding/questions/diet";
import {
  AlcoholTypes,
  DoYouDrinkAlcohol,
  DrinkCount,
} from "lib/questionnaire/question-flows/onboarding/questions/drinking";
import { ExerciseDuration } from "lib/questionnaire/question-flows/onboarding/questions/exercise";
import { FamilyConditions } from "lib/questionnaire/question-flows/onboarding/questions/familyConditions";
import { MedicationTake } from "lib/questionnaire/question-flows/onboarding/questions/medication";
import { PersonalConditions } from "lib/questionnaire/question-flows/onboarding/questions/personalConditions";
import {
  DailyCigaretteCount,
  IsSmoker,
  QuitSmokingRecency,
} from "lib/questionnaire/question-flows/onboarding/questions/smoking";
import {
  IsSnuser,
  QuitSnusingRecency,
  WeeklySnusCanCount,
} from "lib/questionnaire/question-flows/onboarding/questions/snus";
import { v4 } from "uuid";
import { Allergies, Allergy, OtherAllergy } from "./questions/allergy";
import { FamilyHeritage } from "./questions/heritage";

const ONBOARDING_V1_QUESTIONNAIRE_TYPE = "onboarding/1";

export function createResponsePersister(api: APIClient, patientId: string) {
  function storeDetails(payload: APITypesV1.UpdatePatientDetailsRequest) {
    return api.patient.updatePatient(patientId, payload).result;
  }

  function storeOnboardingNotes(
    answersForQuestion: Map<
      Question<unknown, unknown>,
      APITypesV2.QuestionAnswer
    >
  ) {
    const questionsAndAnswers: APITypesV2.QuestionnaireResponse["questionsAndAnswers"] =
      [];

    const diet = answersForQuestion.get(DietOptions);
    if (diet?.answer) {
      if (diet.answer.value === Diet.Other) {
        const customDiet = answersForQuestion.get(OtherDietOption);
        if (customDiet?.answer) {
          questionsAndAnswers.push(customDiet);
        }
      } else {
        questionsAndAnswers.push(diet);
      }
    }

    const allergies = answersForQuestion.get(Allergies);
    if (allergies?.answer) {
      const answer = answersForQuestion.get(Allergies)?.answer
        ?.value as string[];
      if (answer.includes(Allergy.Other)) {
        const customAllergy = answersForQuestion.get(OtherAllergy);
        if (customAllergy?.answer) {
          const customValue = [
            ...(customAllergy.answer.value as string),
            ...answer,
          ];
          questionsAndAnswers.push({
            ...allergies,
            answer: {
              ...allergies.answer,
              value: customValue,
            },
          });
        }
      } else {
        questionsAndAnswers.push(allergies);
      }
    }

    const weeklyExerciseHours = answersForQuestion.get(ExerciseDuration);
    if (weeklyExerciseHours?.answer) {
      questionsAndAnswers.push(weeklyExerciseHours);
    }

    const medication = answersForQuestion.get(MedicationTake);
    if (medication?.answer) {
      questionsAndAnswers.push(medication);
    }

    const smoking = answersForQuestion.get(IsSmoker);
    if (smoking?.answer) {
      questionsAndAnswers.push(smoking);
    }

    const quitSmokingRecency = answersForQuestion.get(QuitSmokingRecency);
    if (quitSmokingRecency?.answer) {
      questionsAndAnswers.push(quitSmokingRecency);
    }

    const dailyCigarettesCount = answersForQuestion.get(DailyCigaretteCount);
    if (dailyCigarettesCount?.answer) {
      questionsAndAnswers.push(dailyCigarettesCount);
    }

    const snus = answersForQuestion.get(IsSnuser);
    if (snus?.answer) {
      questionsAndAnswers.push(snus);
    }

    const snusQuitRecency = answersForQuestion.get(QuitSnusingRecency);
    if (snusQuitRecency?.answer) {
      questionsAndAnswers.push(snusQuitRecency);
    }

    const dailySnusCans = answersForQuestion.get(WeeklySnusCanCount);
    if (dailySnusCans?.answer) {
      questionsAndAnswers.push(dailySnusCans);
    }

    const alcoholAnswer = answersForQuestion.get(DoYouDrinkAlcohol);
    if (alcoholAnswer?.answer) {
      const consumedBeverages = alcoholAnswer?.answer?.value
        ? (alcoholAnswer?.answer?.value as string[])
        : [];

      questionsAndAnswers.push({
        questionId: "alcohol.beer",
        englishLabel: alcoholAnswer.englishLabel,
        label: alcoholAnswer.label,
        languageCode: alcoholAnswer.languageCode,
        answer: {
          $type: "boolean",
          value: consumedBeverages.includes(AlcoholTypes.Beer),
        },
      });

      questionsAndAnswers.push({
        questionId: "alcohol.wine",
        englishLabel: alcoholAnswer.englishLabel,
        label: alcoholAnswer.label,
        languageCode: alcoholAnswer.languageCode,
        answer: {
          $type: "boolean",
          value: consumedBeverages.includes(AlcoholTypes.Wine),
        },
      });

      questionsAndAnswers.push({
        questionId: "alcohol.liquor",
        englishLabel: alcoholAnswer.englishLabel,
        label: alcoholAnswer.label,
        languageCode: alcoholAnswer.languageCode,
        answer: {
          $type: "boolean",
          value: consumedBeverages.includes(AlcoholTypes.Liquor),
        },
      });

      const weeklyDrinks = answersForQuestion.get(DrinkCount);
      if (weeklyDrinks) {
        questionsAndAnswers.push(weeklyDrinks);
      }
    }

    const healthConditionsFamily = answersForQuestion.get(FamilyConditions);
    if (healthConditionsFamily?.answer) {
      questionsAndAnswers.push(healthConditionsFamily);
    }

    const ethnicities = answersForQuestion.get(FamilyHeritage);
    if (ethnicities?.answer) {
      questionsAndAnswers.push(ethnicities);
    }

    const healthConditions = answersForQuestion.get(PersonalConditions);
    if (healthConditions?.answer) {
      questionsAndAnswers.push(healthConditions);
    }

    const additionalWorries = answersForQuestion.get(AdditionalWorries);
    if (additionalWorries?.answer) {
      questionsAndAnswers.push(additionalWorries);
    }

    const questionnaireResponse: APITypesV2.QuestionnaireResponse = {
      questionnaireType: ONBOARDING_V1_QUESTIONNAIRE_TYPE,
      patientId,
      state: APITypesV2.QuestionnaireResponseState.Complete,
      responseId: v4(),
      questionsAndAnswers,
    };

    return saveOnboarding({
      apiClient: api,
      patientId,
      questionnaireResponse,
    });
  }

  return async function handleResponse(
    answers: Answers,
    answersAndQuestions: Map<
      Question<unknown, unknown>,
      APITypesV2.QuestionAnswer
    >
  ) {
    const promises: Array<Promise<object> | undefined> = [];

    promises.push(storeOnboardingNotes(answersAndQuestions).result);
    {
      const heritage = answers.lookup(FamilyHeritage);
      const getAllergies = () => {
        const result = new Set<string>();
        const allergies = answers.lookup(Allergies);
        if (allergies) {
          allergies.forEach((allergy) => result.add(allergy));
        }
        const custom = answers.lookup(OtherAllergy);
        if (custom) {
          result.add(custom);
        }
        result.delete(Allergy.Other);

        return [...result];
      };
      promises.push(
        storeDetails({
          ethnicities: heritage != null ? heritage : undefined,
          allergies: getAllergies(),
        })
      );
    }

    return Promise.all(promises);
  };
}
