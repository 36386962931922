import { APITypesV1 } from "@cur8/api-client";
import { OnboardingData } from "lib/api/onboarding/type";

interface IsOnboardingCompleteProps {
  onboardingData?: OnboardingData | null;
  patientConsents: APITypesV1.ConsentSummary[];
  requiredStudies: { [x: string]: string };
}

export function isAtriumOnboardingComplete({
  patientConsents,
  requiredStudies,
  onboardingData,
}: IsOnboardingCompleteProps): boolean {
  if (onboardingData == null) {
    return false;
  }

  return patientConsents.some((consent) =>
    Object.values(requiredStudies).includes(consent.documentUrl)
  );
}
