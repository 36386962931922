import { Patient } from "@cur8/rich-entity";
import { BurgerLayout } from "render/ui/layout/BurgerLayout";
import { FullScreenPageLayout } from "render/ui/layout/FullScreenPageLayout";
import { LogoHeader } from "render/ui/layout/LogoHeader";
import { Typography } from "render/ui/presentation/Typography";
import { AccountValidateForm } from "render/views/_shared/AccountValidateForm";
import { VideoBackground } from "render/views/_shared/VideoBackground";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

interface AccountVerificationStageProps {
  externalPatientData: {
    phoneNumber: string | null | undefined;
  };
  patient: Patient;
  onSuccess: () => void;
}

export function AccountVerificationStage({
  patient,
  externalPatientData,
  onSuccess,
}: AccountVerificationStageProps) {
  return (
    <FullScreenPageLayout disableBackground>
      <BurgerLayout>
        <LogoHeader />
        <div data-hj-suppress className={styles.body}>
          <div className={styles.content}>
            <div className={styles.text}>
              <Typography variant="title-large">
                <Trans.Title />
              </Typography>
              <Typography variant="title-small" color="subtle">
                <Trans.SubTitle />
              </Typography>
            </div>
            <AccountValidateForm
              externalPatientData={externalPatientData}
              patient={patient}
              onSuccess={onSuccess}
            />
          </div>
        </div>
        {null}
      </BurgerLayout>
      <VideoBackground />
    </FullScreenPageLayout>
  );
}
