import { useNav } from "@pomle/react-router-paths";
import { ReactComponent as Chevron } from "assets/chevron.svg";
import { ReactComponent as ExIcon } from "assets/icons/ex/ex-28x28.svg";
import { ReactComponent as ChangeIcon } from "assets/increase.svg";
import { paths } from "render/routes/paths";
import { Badge } from "render/ui/presentation/Badge";
import { Typography } from "render/ui/presentation/Typography";
import { StudySymbol } from "render/ui/symbol/StudySymbol";
import { IconButton } from "render/ui/trigger/IconButton";
import {
  MetricViewProps,
  Values,
} from "render/views/Report/components/MetricsSection/components/layouts/Details/types";
import { useBadgeState } from "render/views/Report/components/MetricsSection/components/layouts/hooks/useBadgeState";
import { Trans as ScanDataTrans } from "render/views/Report/components/ScanDataTab/trans";
import { Trans } from "../trans";
import styles from "./styles.module.sass";

function calculateChangeIconDirection(change: number) {
  if (change === 0) {
    return "right";
  }
  if (change < 0) {
    return "down";
  }
}

type MetricSectionProps = {
  values: Omit<Values, "ranges">;
  children?: React.ReactNode;
  tabs: React.ReactNode | undefined;
} & Omit<MetricViewProps, "values">;

export function MetricSection({
  label,
  values,
  isClinicalStudy,
  scanNum,
  setScanNum,
  children,
  numOfScans,
  tabs,
  hideChange,
}: MetricSectionProps) {
  const { value, metricRating, previousValue, scanDate, subLabel } = values;
  const badgeState = useBadgeState({ value, metricRating });
  const change =
    !hideChange && value != null && previousValue != null
      ? Number(value) - Number(previousValue)
      : undefined;
  const changeNumber =
    change != null ? +Math.abs(change).toFixed(2) : undefined;
  const changeText =
    change != null ? (
      change >= 0 ? (
        <Trans.Increased value={changeNumber} />
      ) : (
        <Trans.Decreased value={changeNumber} />
      )
    ) : undefined;
  const canGoToPreviousScan = scanNum > 1;
  const canGoToNextScan = scanNum < numOfScans;

  return (
    <div className={styles.container} data-variant={badgeState?.variant}>
      <div className={styles.header}>
        <header className={styles.nav}>
          <div>
            <IconButton
              onClick={useNav(paths.appointmentSummaryData).on({})}
              icon={<ExIcon className={styles.close} />}
              ariaLabel={Trans.Close()}
            />
          </div>
          {scanDate != null ? (
            <nav className={styles.date}>
              {numOfScans > 1 ? (
                <IconButton
                  onClick={() => canGoToPreviousScan && setScanNum(scanNum - 1)}
                  icon={
                    <Chevron
                      className={styles.chevronLeft}
                      data-disabled={!canGoToPreviousScan}
                    />
                  }
                  ariaLabel={Trans.PreviousScan()}
                  disabled={!canGoToPreviousScan}
                />
              ) : null}
              <Typography variant="subtitle-medium" color="subtle">
                {scanDate ? (
                  scanDate.toLocaleString({
                    month: "short",
                    year: "numeric",
                  })
                ) : (
                  <Trans.UnknownDate />
                )}
              </Typography>
              {numOfScans > 1 ? (
                <IconButton
                  onClick={() => canGoToNextScan && setScanNum(scanNum + 1)}
                  icon={
                    <Chevron
                      className={styles.chevronRight}
                      data-disabled={!canGoToNextScan}
                    />
                  }
                  ariaLabel={Trans.PreviousScan()}
                  disabled={!canGoToNextScan}
                />
              ) : null}
            </nav>
          ) : null}
        </header>

        <div className={styles.slideIn}>
          <section className={styles.content}>
            {!!badgeState && (
              <div className={styles.labelAndToggle}>
                <Badge variant={badgeState.variant}>{badgeState.text}</Badge>
                {tabs}
              </div>
            )}
            {value != null ? (
              <>
                <div className={styles.row}>
                  <Typography variant="title-medium" color="subtle">
                    {label}
                  </Typography>
                  {isClinicalStudy ? <StudySymbol /> : null}
                </div>
                <div className={styles.values}>
                  <p className={styles.value}>{value}</p>
                  <Typography variant="title-small" color="subtle">
                    {subLabel}
                  </Typography>
                </div>
                <div className={styles.row}>
                  {change != null && changeText ? (
                    <>
                      <ChangeIcon
                        className={styles.changeIcon}
                        data-direction={calculateChangeIconDirection(change)}
                      />
                      <Typography variant="paragraph-small">
                        {changeText}
                      </Typography>
                      <div className={styles.divider} />
                    </>
                  ) : null}
                  {previousValue ? (
                    <div className={styles.row} data-secondary>
                      <Typography variant="paragraph-small">
                        <Trans.Previous
                          value={previousValue}
                          subLabel={subLabel}
                        />
                      </Typography>
                    </div>
                  ) : (
                    <div className={styles.row} data-secondary>
                      <Typography variant="paragraph-small">
                        <Trans.Baseline />
                      </Typography>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <div className={styles.noDataRow}>
                <div className={styles.row}>
                  <Typography variant="title-medium" color="subtle">
                    {label}
                  </Typography>
                  {isClinicalStudy ? <StudySymbol /> : null}
                </div>
                <Typography variant="paragraph-small">
                  <Trans.NoDataText />
                </Typography>
              </div>
            )}
            {value === undefined ? (
              <div className={styles.backgroundImage} />
            ) : null}
            {children}
            <div className={styles.studies}>
              <Typography variant="paragraph-sub">
                {isClinicalStudy ? (
                  <ScanDataTrans.StudyInfoSweden />
                ) : (
                  <ScanDataTrans.StudyInfo />
                )}
              </Typography>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
