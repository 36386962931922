import { Patient } from "@cur8/rich-entity";
import { Typography } from "render/ui/presentation/Typography";
import { CheckInBadge } from "../../../__shared/CheckInBadge";
import { CheckInStepExplanationLayout } from "../../../__shared/CheckInStepExplanationLayout";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

interface CheckInCompleteStepProps {
  patient: Patient;
}

export function CheckInCompleteStep({ patient }: CheckInCompleteStepProps) {
  return (
    <CheckInStepExplanationLayout
      SubHeader={<CheckInBadge />}
      Content={
        <div className={styles.text}>
          <div className={styles.header}>
            <Typography variant="title-large">
              <Trans.Title />
            </Typography>
            {patient.name?.displayName ? (
              <div className={styles.name}>
                <Typography variant="title-large">
                  <Trans.PatientName patientName={patient.name.displayName} />
                </Typography>
              </div>
            ) : undefined}
          </div>
          <Typography variant="title-medium">
            <Trans.Description />
          </Typography>
        </div>
      }
    />
  );
}
