import { Answers } from "@cur8/questionnaire";
import { useQuestionnaireLanguageCode } from "lib/questionnaire/locale";
import { ExerciseDuration } from "lib/questionnaire/question-flows/onboarding/questions/exercise";
import { Trans as TransQuestions } from "lib/questionnaire/question-flows/onboarding/questions/trans";
import { ChoiceInput } from "render/ui/presentation/Questionnaire/components/ChoiceInput";
import { QuestionView } from "../components/QuestionView";
import { Trans } from "../trans";
import { Step, StepProps } from "../types";

function ExerciseDurationStep({
  answers,
  onAnswer,
  onNext,
  onPrev,
  currentQuestion,
  totalQuestions,
  onResetAnswer,
}: StepProps) {
  const answer = answers.get(ExerciseDuration);
  const label = TransQuestions.Exercise.Question();
  const { englishLabel } = TransQuestions.Exercise.Question;
  const languageCode = useQuestionnaireLanguageCode();

  const answerLabelMap = {
    0: Trans.General.NoNextQuestion(),
    4: TransQuestions.Exercise.HoursPerWeek({ hours: "3-4" }),
    6: TransQuestions.Exercise.HoursPerWeek({ hours: "5-6" }),
    8: TransQuestions.Exercise.HoursPerWeek({ hours: "7-8" }),
    10: TransQuestions.Exercise.HoursPerWeek({ hours: "9-10" }),
    11: TransQuestions.Exercise.HoursPerWeek({ hours: "10+" }),
  };

  const getLabelForChoiceIndex = (choiceIndex: number) => {
    const value = ExerciseDuration.choices[choiceIndex].value;
    return answerLabelMap[value as keyof typeof answerLabelMap];
  };

  return (
    <QuestionView
      currentQuestion={currentQuestion}
      totalQuestionCount={totalQuestions}
      question={ExerciseDuration}
      answer={answer}
      onNext={() => {
        onResetAnswer(ExerciseDuration);
        onNext();
      }}
      onPrev={() => {
        onPrev();
        onResetAnswer(ExerciseDuration);
      }}
      cta={<Trans.General.NoNextQuestion />}
      explanation={{
        openText: <Trans.General.WhyAreWeAsking />,
        closeText: <Trans.General.OkIUnderstand />,
        explanationText: <Trans.Steps.ExerciseExplanation />,
      }}
    >
      <ChoiceInput
        question={ExerciseDuration}
        answer={answer}
        onAnswer={(answer) => {
          onAnswer(ExerciseDuration, {
            answer,
            questionAnswer: {
              questionId: "weeklyExerciseHours",
              englishLabel,
              label,
              languageCode,
              answer: {
                $type: "number",
                value:
                  ExerciseDuration.choices[answer.response.choiceIndex].value,
                label: getLabelForChoiceIndex(answer.response.choiceIndex),
              },
            },
          });
          onNext();
        }}
      />
    </QuestionView>
  );
}

export function filter(steps: Step[], answers: Answers) {
  steps.push(ExerciseDurationStep);
}
