import { Trans } from "./transV2";

export enum RecencyChoicesEnum {
  LessThanSixMonths = "Less than six months ago",
  MoreThanSixMonths = "More than six months ago",
}

export const RecencyChoices: [string | JSX.Element, RecencyChoicesEnum][] = [
  [<Trans.Choice.LessThanSixMonths />, RecencyChoicesEnum.LessThanSixMonths],
  [<Trans.Choice.SixMonths />, RecencyChoicesEnum.MoreThanSixMonths],
];
