import { APITypesV2 } from "@cur8/api-client";
import { useQuery } from "@tanstack/react-query";
import { APIClient } from "lib/api/client";
import { useAPIClient } from "render/context/APIContext";
import { useSession } from "render/context/MSALContext";
import { ExtractBody, ExtractKey, QueryOptions } from "typings/query";

export const queryKey = (patientId: string) => ["patient-consents", patientId];
const queryFn = (apiClient: APIClient, patientId: string) => async () => {
  return apiClient.consentV2.getListOfConsents({
    patientId,
  }).result;
};

type Body = ExtractBody<typeof queryFn>;
type Key = ExtractKey<typeof queryKey>;

export function usePatientConsentsQuery<T = Body>(
  options: QueryOptions<Body, Key, T> = {}
) {
  const { patientId } = useSession();
  const apiClient = useAPIClient();

  return useQuery(queryKey(patientId), queryFn(apiClient, patientId), options);
}

usePatientConsentsQuery.queryKey = queryKey;
usePatientConsentsQuery.queryFn = queryFn;

const studyIds = ["cardio-alpha", "dermaflow-alpha", "spectrum-1"] as const;

const productIds = ["journal-access", "doctor-scribe"] as const;

const consentsIds = [...studyIds, ...productIds] as const;

export type ConsentIds = (typeof consentsIds)[number];

export function useLatestConsentsQuery(options?: { enabled?: boolean }) {
  return usePatientConsentsQuery({
    enabled: options?.enabled,
    select: (data) => {
      return data.items;
    },
  });
}

export function useLatestConsentsByType() {
  return usePatientConsentsQuery({
    select: (data) => {
      return {
        studies: data.items.filter((consent) =>
          consent.consentMetadata?.consentType?.includes(
            APITypesV2.ConsentType.Study
          )
        ),
        other: data.items.filter((consent) =>
          consent.consentMetadata?.consentType?.includes(
            APITypesV2.ConsentType.Other
          )
        ),
        gdpr: data.items.filter((consent) =>
          consent.consentMetadata?.consentType?.includes(
            APITypesV2.ConsentType.Gdpr
          )
        ),
      };
    },
  });
}

export function useLatestOptionalConsentsByType() {
  return usePatientConsentsQuery({
    select: (data) => {
      const optionalConsents = data.items.filter(
        (consent) => consent.consentMetadata.optional === true
      );
      return {
        studies: optionalConsents.filter((consent) =>
          consent.consentMetadata?.consentType?.includes(
            APITypesV2.ConsentType.Study
          )
        ),
        other: optionalConsents.filter((consent) =>
          consent.consentMetadata?.consentType?.includes(
            APITypesV2.ConsentType.Other
          )
        ),
        gdpr: optionalConsents.filter((consent) =>
          consent.consentMetadata?.consentType?.includes(
            APITypesV2.ConsentType.Gdpr
          )
        ),
      };
    },
  });
}
