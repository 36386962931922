import { ChoiceQuestion } from "@cur8/questionnaire";
import { RecencyChoices } from "./choice";
import { Trans } from "./trans";
export enum SmokingFrequency {
  Currently = "Currently",
  Occasionally = "Occasionally",
  Quit = "Quit",
}

export const IsSmoker = new ChoiceQuestion(<Trans.Smoking.Question />, [
  [<Trans.Smoking.FrequencyCurrently />, SmokingFrequency.Currently],
  [<Trans.Smoking.FrequencyOccasionally />, SmokingFrequency.Occasionally],
  [<Trans.Smoking.FrequencyQuit />, SmokingFrequency.Quit],
]);

const CigaretteCountChoices: [string | JSX.Element, number][] = [
  [<Trans.Smoking.CigaretteCount2 />, 2],
  [<Trans.Smoking.CigaretteCount6 />, 6],
  [<Trans.Smoking.CigaretteCount12 />, 12],
  [<Trans.Smoking.CigaretteCount18 />, 18],
  [<Trans.Smoking.CigaretteCount24 />, 24],
];

export const DailyCigaretteCount = new ChoiceQuestion(
  <Trans.Smoking.DailyCigaretteCount />,
  CigaretteCountChoices
);

export const QuitSmokingRecency = new ChoiceQuestion(
  <Trans.Smoking.QuitSmokingRecency />,
  RecencyChoices
);
