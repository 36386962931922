import { APITypesV2 } from "@cur8/api-client";
import { Answer, Answers, Question } from "@cur8/questionnaire";
import { useSnackbar } from "notistack";
import { useCallback, useState } from "react";
import { useOnboardingQuery } from "render/hooks/api/queries/useOnboardingQueryV2";
import { useContactUsPopup } from "render/hooks/popups/useContactUsPopup";
import { useAsyncHandle } from "render/hooks/useAsyncHandle";
import { useTracking } from "render/hooks/useTracking";
import { FooterLayout } from "render/ui/layout/FooterLayout/FooterLayout";
import { FullScreenPageLayout } from "render/ui/layout/FullScreenPageLayout";
import { onboardingEvent } from "../../tracking";
import { Trans } from "../../trans";
import { QuestionsView } from "./components/Questions";
import { useOnboardingPersist } from "./hooks/useOnboardingPersist";

interface OnBoardingViewProps {
  onNavBack: () => void;
  onSuccess: () => void;
}

export function OnboardingFlowView({
  onNavBack,
  onSuccess,
}: OnBoardingViewProps) {
  const { trackEvent } = useTracking();
  const contactUsPopup = useContactUsPopup();
  const { enqueueSnackbar } = useSnackbar();
  const [answers, setAnswers] = useState<Answers>(() => new Answers());
  const [questionnaireAnswers, setQuestionnaireAnswers] = useState<
    Map<Question<unknown, unknown>, APITypesV2.QuestionAnswer>
  >(new Map());

  const onboardingQuery = useOnboardingQuery({ enabled: false });
  const { storeResponse } = useOnboardingPersist();

  const handleComplete = useAsyncHandle(() =>
    storeResponse(questionnaireAnswers)
      .then(() => onboardingQuery.refetch())
      .then(() => onSuccess())
      .catch(() => {
        enqueueSnackbar(<Trans.Error.FailedToSaveMetric />, {
          variant: "error",
        });
      })
  );

  const handleAnswer = useCallback((question: Question, answer: Answer) => {
    setAnswers((answers) => answers.set(question, answer));
  }, []);

  const resetAnswer = useCallback((question: Question) => {
    setAnswers((answers) => answers.remove(question));
  }, []);

  const onContactUsClick = useCallback(() => {
    trackEvent(onboardingEvent.openContactsSidebarClick("open"));
    contactUsPopup.emit();
  }, [contactUsPopup, trackEvent]);

  return (
    <FullScreenPageLayout>
      <FooterLayout>
        <QuestionsView
          onContactUsClick={onContactUsClick}
          onNavBack={onNavBack}
          answers={answers}
          onAnswer={(question, questionnaireAnswer) => {
            handleAnswer(question, questionnaireAnswer.answer);
            setQuestionnaireAnswers((questionnaireAnswers) => {
              questionnaireAnswers?.set(
                question,
                questionnaireAnswer.questionAnswer
              );

              return questionnaireAnswers;
            });
          }}
          isSubmitting={handleComplete.busy}
          onComplete={handleComplete.callback}
          onResetAnswer={resetAnswer}
        />
        <div />
      </FooterLayout>
    </FullScreenPageLayout>
  );
}
