import { classNames } from "@pomle/classnames";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import {
  PaymentRequest,
  PaymentRequestPaymentMethodEvent,
} from "@stripe/stripe-js";
import { ReactComponent as CompasIcon } from "assets/compass.svg";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useConfig } from "render/context/ConfigContext";
import { useTrackingContext } from "render/context/TrackingContext";
import { usePatientQuery } from "render/hooks/api/queries/usePatientQuery";
import { Cart } from "render/hooks/mutations/useCreateCartMutation";
import { Checkout } from "render/hooks/mutations/useInitializeCheckoutMutation";
import PriceNumber from "render/ui/format/PriceNumber";
import { Expandable } from "render/ui/presentation/Expandable";
import { Typography } from "render/ui/presentation/Typography";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { InviteCodeInput } from "./components/InviteCodeInput";
import { PaymentForm } from "./components/payments/PaymentForm";
import styles from "./styles.module.sass";
import { Trans } from "./trans";
import { useAsyncHandle } from "render/hooks/useAsyncHandle";

interface CheckoutFormProps {
  cart: Cart;
  checkout: Checkout;
  onDiscountSubmit: (discount?: string) => void;
  validationErrors: { discountCodeInvalid: boolean };
  clearErrors: (props: Partial<{ discountCodeInvalid: boolean }>) => void;
  isCodeEditable: boolean;
  isFreeBooking: boolean;
  isPlaceboPayment: boolean;
}

function Country({ countryCode }: { countryCode: string }) {
  return (
    <>
      {countryCode === "SE" && <Trans.City.Stockholm />}
      {countryCode === "GB" && <Trans.City.London />}
    </>
  );
}

export function CheckoutForm({
  checkout,
  onDiscountSubmit,
  cart,
  clearErrors,
  validationErrors,
  isCodeEditable,
  isFreeBooking,
  isPlaceboPayment,
}: CheckoutFormProps) {
  const [inviteCode, setInviteCode] = useState(cart.discountCodes[0] ?? "");
  const [isReady, setIsReady] = useState(false);
  const [isCCReady, setIsCCReady] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isHandlingCC, setIsHandlingCC] = useState(false);
  const [wallet, setWallet] = useState("");
  const { conversionsTracking } = useTrackingContext();
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest>();
  const config = useConfig();
  const stripe = useStripe();
  const elements = useElements();
  const patientQuery = usePatientQuery();
  const { enqueueSnackbar } = useSnackbar();
  const currency = cart.currency;
  const price = cart.cartTotal;

  const fullPrice = useMemo(
    () =>
      cart.entries.reduce((acc, entry) => {
        const price: number = entry.price ?? 0;
        const count = entry.count ?? 1;
        return acc + price * count;
      }, 0),
    [cart]
  );
  const hasDiscountCode = cart.discountCodes.length > 0;

  const handleCC = useCallback(async () => {
    setIsHandlingCC(!isHandlingCC);
    setIsCCReady(false);
  }, [setIsHandlingCC, isHandlingCC, setIsCCReady]);

  const handleWalletSubmit = useAsyncHandle(
    useCallback(async () => {
      conversionsTracking.TrackAddPaymentInfo(cart, wallet);
      conversionsTracking.TrackBeginCheckout(cart);
      setIsSubmitting(true);
      paymentRequest?.show();
    }, [cart, wallet, paymentRequest, setIsSubmitting, conversionsTracking])
  );

  const trackPurchase = useCallback(() => {
    if (!patientQuery.data) {
      return;
    }
    conversionsTracking.TrackPurchase(cart);
  }, [patientQuery.data, cart, conversionsTracking]);

  const callCompleteCheckout = useCallback(async () => {
    trackPurchase();
    let completeCheckoutLocation = `${config.apiBaseUrl}/api/v2/patient/${
      patientQuery.data!.patientId
    }/stripe/complete-checkout?redirect_status=succeeded`;
    completeCheckoutLocation += `&payment_intent=${checkout.checkoutData.intentId}&payment_intent_client_secret=${checkout.checkoutData.clientSecret}`;
    window.location.href = completeCheckoutLocation;
  }, [checkout, config, patientQuery.data, trackPurchase]);

  const handleCCFormReady = useCallback(async () => {
    conversionsTracking.TrackAddPaymentInfo(cart, "card");
    setIsCCReady(true);
  }, [cart, conversionsTracking]);

  const handleWalletCallback = useCallback(
    async (ev: PaymentRequestPaymentMethodEvent) => {
      if (stripe === null) {
        return;
      }
      const { paymentIntent, error: confirmError } =
        await stripe.confirmCardPayment(
          checkout.checkoutData.clientSecret,
          { payment_method: ev.paymentMethod.id },
          { handleActions: true }
        );
      if (confirmError) {
        enqueueSnackbar(confirmError.message ?? <Trans.Error.Unexpected />, {
          variant: "error",
        });
        ev.complete("fail");
      } else {
        ev.complete("success");
        if (paymentIntent.status === "requires_action") {
          const { error } = await stripe.confirmCardPayment(
            checkout.checkoutData.clientSecret
          );
          if (error) {
            enqueueSnackbar(<Trans.Error.Unexpected />, {
              variant: "error",
            });
          } else {
            await callCompleteCheckout();
          }
        } else {
          await callCompleteCheckout();
        }
      }
      setIsSubmitting(false);
    },
    [stripe, callCompleteCheckout, enqueueSnackbar, checkout]
  );

  const handleSubmit = useAsyncHandle(
    useCallback(async () => {
      if (stripe == null || elements == null) {
        return;
      }
      try {
        setIsSubmitting(true);
        trackPurchase(); // TODO: track purchase only if payment is successful

        const result = await stripe.confirmPayment({
          elements,
          redirect: "always",
          confirmParams: {
            return_url: checkout.checkoutData.completeCheckoutUrl,
          },
        });

        if (result.error) {
          if (result.error.type !== "validation_error") {
            enqueueSnackbar(
              result.error.message ?? <Trans.Error.Unexpected />,
              {
                variant: "error",
              }
            );
          }
        }
        setIsSubmitting(false);
      } catch {
        enqueueSnackbar(<Trans.Error.Unexpected />, { variant: "error" });
        setIsSubmitting(false);
      }
    }, [stripe, elements, enqueueSnackbar, checkout, trackPurchase])
  );

  useEffect(() => {
    if (stripe == null) {
      return;
    }
    const paymentRequest = stripe.paymentRequest({
      country: cart.country,
      currency: cart.currency.toLowerCase(),
      total: {
        label: "Total",
        amount: cart.cartTotal * 100,
      },
      requestPayerName: true,
      requestPayerEmail: true,
      requestPayerPhone: true,
      disableWallets: ["link"],
      displayItems: [
        {
          label: "Neko Scan",
          amount: cart.cartTotal * 100,
        },
      ],
    });
    setPaymentRequest(paymentRequest);
    paymentRequest
      .canMakePayment()
      .then(async (result) => {
        if (result?.applePay || result?.googlePay) {
          paymentRequest.on("paymentmethod", handleWalletCallback);
          paymentRequest.on("cancel", () => {
            setIsSubmitting(false);
          });
          if (result?.applePay) {
            conversionsTracking.TrackWalletSupported("apple");
            setWallet("apple");
          } else if (result?.googlePay) {
            conversionsTracking.TrackWalletSupported("google");
            setWallet("google");
          }
        }
      })
      .finally(() => {
        setIsReady(true);
      });
  }, [stripe, cart, handleWalletCallback, conversionsTracking]);

  return (
    <div
      data-hj-suppress
      className={styles.checkoutForm}
      style={{
        display: isReady ? "flex" : "none",
        opacity: isReady ? 1 : 0,
      }}
    >
      <div>
        <Typography
          variant="title-medium"
          style={{
            marginBottom: "12px",
          }}
        >
          {isHandlingCC ? (
            <Trans.PaymentInformation />
          ) : isPlaceboPayment ? (
            <Trans.OneStepLeftCapture />
          ) : (
            <Trans.OneStepLeft />
          )}
        </Typography>
        <Typography variant="paragraph-large" color="subtle">
          {isHandlingCC ? (
            <Trans.NekoTakesAllCreditCards />
          ) : isPlaceboPayment ? (
            <Trans.CaptureOnly />
          ) : isFreeBooking ? (
            <Trans.AfterPaymentDetails />
          ) : (
            <Trans.AfterPayment />
          )}
        </Typography>

        {!isHandlingCC && (
          <div>
            {!isPlaceboPayment && (
              <div>
                <div className={styles.details}>
                  <div className={styles.location}>
                    <CompasIcon />
                    <Typography variant="paragraph-small">
                      <Country countryCode={cart.country} />
                    </Typography>
                  </div>
                </div>
                <div className={styles.form}>
                  <InviteCodeInput
                    error={
                      validationErrors.discountCodeInvalid ? (
                        <Trans.Error.InvalidInvitationCode />
                      ) : undefined
                    }
                    applied={hasDiscountCode}
                    code={inviteCode}
                    onChange={(val) => {
                      setInviteCode(val);
                      clearErrors({ discountCodeInvalid: false });
                    }}
                    onClear={() => {
                      conversionsTracking.TrackRemoveDiscountCode(inviteCode);
                      clearErrors({ discountCodeInvalid: false });
                      setInviteCode("");
                      onDiscountSubmit();
                    }}
                    onSubmit={() => {
                      onDiscountSubmit(inviteCode);
                    }}
                    hideClearButton={!isCodeEditable}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      {isHandlingCC && (
        <div
          className={styles.paymentForm}
          style={{
            display: isHandlingCC && isCCReady ? "block" : "none",
            transition: "0.6s all",
            opacity: isHandlingCC ? 1 : 0,
          }}
        >
          <PaymentForm onReady={handleCCFormReady} />
        </div>
      )}
      {!isPlaceboPayment && (
        <div className={styles.orderOverview}>
          <div className={styles.stacked}>
            <Typography variant="cta">
              <Trans.OrderOverview />
            </Typography>
            <Expandable isOpen={hasDiscountCode}>
              {fullPrice > price && (
                <Typography variant="paragraph-small" color="subtle">
                  <Trans.ActiveCode code={inviteCode} />
                </Typography>
              )}
            </Expandable>
          </div>
          <div className={styles.price}>
            <Typography variant="cta" color="subtle">
              <Trans.Total />:
            </Typography>
            <div className={styles.stacked}>
              <Expandable isOpen={!hasDiscountCode}>
                <Typography variant="cta" style={{ color: "#262C2E" }}>
                  <PriceNumber price={price} currency={currency} />
                </Typography>
              </Expandable>
              <Expandable isOpen={hasDiscountCode}>
                <div className={styles.discountedPrice}>
                  <Typography variant="cta" style={{ color: "#262C2E" }}>
                    <PriceNumber price={price} currency={currency} />
                  </Typography>
                  {fullPrice > price && (
                    <Typography
                      variant="paragraph-small"
                      style={{
                        textDecoration: "line-through",
                      }}
                    >
                      <PriceNumber price={fullPrice} currency={currency} />
                    </Typography>
                  )}
                </div>
              </Expandable>
            </div>
          </div>
        </div>
      )}
      <div className={classNames(styles.payments)}>
        {!isHandlingCC && paymentRequest && wallet !== "" && (
          <ActionButton
            onClick={handleWalletSubmit.callback}
            variant="wallet"
            walletVariant={wallet}
            style={{
              marginBottom: "16px",
            }}
          >
            {isSubmitting ? (
              <Trans.Submitting />
            ) : wallet === "apple" ? (
              "Apple Pay"
            ) : (
              "Google Pay"
            )}
          </ActionButton>
        )}
        {isHandlingCC && (
          <ActionButton
            onClick={handleSubmit.callback}
            style={{
              marginBottom: "16px",
            }}
          >
            {isSubmitting ? (
              <Trans.Submitting />
            ) : isFreeBooking ? (
              <Trans.ConfirmDetails />
            ) : (
              <Trans.PayNow />
            )}
          </ActionButton>
        )}
        {!isSubmitting && (
          <ActionButton
            onClick={handleCC}
            hideIcon={isHandlingCC}
            variant={isHandlingCC ? "secondary" : "primary"}
          >
            {!isHandlingCC ? (
              wallet !== "" ? (
                <Trans.CreditCard />
              ) : (
                <Trans.PaymentDetails />
              )
            ) : (
              <Trans.Back />
            )}
          </ActionButton>
        )}
      </div>
    </div>
  );
}
