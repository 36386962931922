import { useQuery } from "@tanstack/react-query";
import { APIClient } from "lib/api/client";
import { useAPIClient } from "render/context/APIContext";
import { useSession } from "render/context/MSALContext";
import { QueryOptions } from "typings/query";

const queryKey = (patientId: string) => [
  "identity-verification-status",
  patientId,
];
const queryFn = (apiClient: APIClient, patientId: string) => async () => {
  const result = await apiClient.patient.fetchIdentityVerificationStatus(
    patientId
  ).result;

  return result;
};

type Body = Awaited<ReturnType<ReturnType<typeof queryFn>>>;
type Key = ReturnType<typeof queryKey>;

export function useIdentityVerificationStatus<T = Body>(
  options: QueryOptions<Body, Key, T> = {}
) {
  const { patientId } = useSession();
  const apiClient = useAPIClient();

  return useQuery({
    queryKey: queryKey(patientId),
    queryFn: queryFn(apiClient, patientId),
    ...options,
  });
}
