import { ReactComponent as ArrowDownload } from "assets/arrow-download.svg";
import { ReactComponent as ArrowRight } from "assets/arrow-point-right.svg";
import { ReactComponent as Squircle } from "assets/squircle.svg";
import { useMemo } from "react";
import { Typography } from "render/ui/presentation/Typography";
import styles from "./styles.module.sass";

type ListItemProps = {
  name: JSX.Element;
  onClick: () => void;
  iconVariant?: "go-to" | "download";
};

export function ListItem({
  name,
  onClick,
  iconVariant = "go-to",
}: ListItemProps) {
  const Icon = useMemo(() => {
    if (iconVariant === "go-to") {
      return (
        <div className={styles.svgContainer}>
          <Squircle className={styles.squircle} />
          <ArrowRight className={styles.arrowRight} />
        </div>
      );
    }

    if (iconVariant === "download") {
      return (
        <div className={styles.svgContainer}>
          <Squircle className={styles.squircle} />
          <ArrowDownload className={styles.arrowDownload} />
        </div>
      );
    }
  }, [iconVariant]);

  return (
    <button onClick={onClick} className={styles.container}>
      <Typography variant="paragraph" style={{ color: "#019DC9" }}>
        {name}
      </Typography>
      <div className={styles.iconContainer}>{Icon}</div>
    </button>
  );
}
