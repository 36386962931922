import { Patient } from "@cur8/rich-entity";
import { localize } from "render/context/InternationalizationContext";

const Greeting = localize<{ patient: Patient }>({
  en_GB: ({ patient }) => (
    <>
      Hi {patient.name?.firstName}, <br /> you're all set for your visit. We
      look forward to seeing you soon.
    </>
  ),
  sv_SE: ({ patient }) => (
    <>
      Hej {patient.name?.firstName}, <br /> allt är klart för ditt första besök.
      Vi ser fram emot att ta emot dig.
    </>
  ),
});

const Invite = {
  Title: localize({
    en_GB: <>Invite friends</>,
    sv_SE: <>Bjud in vänner</>,
  }),
  Description: localize<{ tokenCount: number }>({
    en_GB: ({ tokenCount }) => {
      if (tokenCount === 1) {
        return <>1 invite to share</>;
      }
      return <>{tokenCount} invites to share</>;
    },
    sv_SE: ({ tokenCount }) => {
      if (tokenCount === 1) {
        return <>1 inbjudan att dela</>;
      }
      return <>{tokenCount} inbjudningar att dela</>;
    },
  }),
};
export const Trans = { Greeting, Invite };
