import { VisitSummary } from "@cur8/rich-entity";
import { lastAuditChangeDate } from "lib/lastAuditChangeDate";
import { useCallback, useState } from "react";
import { useTracking } from "render/hooks/useTracking";
import { ReactComponent as ArrowDown } from "render/ui/icons/arrowDown.svg";
import { Expandable } from "render/ui/presentation/Expandable";
import { Separator } from "render/ui/presentation/Separator";
import { Typography } from "render/ui/presentation/Typography/Typography";
import { Summary } from "../../../../../../ui/presentation/doctor/Summary";
import { reportEvent } from "../../../../tracking";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

interface PreviousSummariesProps {
  visitsSummaries: VisitSummary[];
}

export function PreviousSummaries({ visitsSummaries }: PreviousSummariesProps) {
  const { trackEvent } = useTracking();
  const [openedSummaries, setOpenedSummaries] = useState<string[]>([]);
  const expandSummary = useCallback(
    (id: string, expand: boolean) => {
      trackEvent(reportEvent.clickPrevSummary(expand ? "open" : "close"));
      if (expand) {
        setOpenedSummaries((prev) => [...prev, id]);
      } else {
        setOpenedSummaries((prev) => prev.filter((v) => v !== id));
      }
    },
    [setOpenedSummaries, trackEvent]
  );
  const isOpen = useCallback(
    (id: string) => {
      return openedSummaries.some((v) => v === id);
    },
    [openedSummaries]
  );
  return (
    <div>
      <div className={styles.previousSummaries}>
        <Typography variant="subtitle-medium" style={{ textAlign: "left" }}>
          <Trans.ReportHistory />
        </Typography>
        <div className={styles.separator}>
          <Separator width="24px" />
        </div>

        <ul>
          {visitsSummaries.map((summary, index, arr) => {
            const header =
              index === arr.length - 1 ? (
                <Trans.Baseline />
              ) : (
                <Trans.FollowUp />
              );
            const open = isOpen(summary.id);

            return (
              <li
                key={summary.id}
                onClick={() => expandSummary(summary.id, !open)}
              >
                <div className={styles.prevSummaryHeader}>
                  <Typography
                    variant="description"
                    style={{ color: "#263032" }}
                  >
                    {header}
                  </Typography>

                  <ArrowDown
                    style={{
                      transform: `rotate(${!open ? 0 : 180}deg)`,
                      transition: "transform 0.6s",
                    }}
                    stroke="black"
                  />
                </div>

                <Typography variant="small">
                  <Trans.WrittenOn
                    date={
                      <span className={styles.bolded}>
                        {lastAuditChangeDate(summary.audit)?.toFormat(
                          "dd MMMM  yyyy"
                        )}
                      </span>
                    }
                  />
                </Typography>

                <Expandable isOpen={open}>
                  <div className={styles.summary}>
                    <Summary
                      text={summary.summaryText}
                      doctorURI={summary.audit.created?.userUri?.toString()}
                    />
                  </div>
                </Expandable>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
