import { useNav } from "@pomle/react-router-paths";
import { ReactComponent as CrossIcon } from "assets/cross.svg";
import { paths } from "render/routes/paths";
import { BurgerLayout } from "render/ui/layout/BurgerLayout";
import { FullScreenPageLayout } from "render/ui/layout/FullScreenPageLayout";
import { LogoHeader } from "render/ui/layout/LogoHeader";
import { Typography } from "render/ui/presentation/Typography";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { IconButton } from "render/ui/trigger/IconButton";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

export function CheckoutFailView() {
  const nav = {
    home: useNav(paths.root),
    payment: useNav(paths.checkout),
  };

  return (
    <FullScreenPageLayout>
      <BurgerLayout>
        <LogoHeader
          leftElement={
            <IconButton
              icon={<CrossIcon />}
              onClick={() => {
                nav.home.go({});
              }}
            />
          }
        />
        <div className={styles.body}>
          <div className={styles.content}>
            <Typography
              style={{ fontWeight: 300, marginTop: "12px" }}
              variant="title-large"
            >
              <Trans.PaymentFailed />
            </Typography>
            <Typography
              variant="title-small"
              style={{ marginTop: "12px" }}
              color="subtle"
            >
              <Trans.CantProcessPayment />
            </Typography>
            <div className={styles.actions}>
              <ActionButton onClick={nav.payment.on({})}>
                <Trans.TryAgain />
              </ActionButton>
            </div>
          </div>
        </div>
        {null}
      </BurgerLayout>
    </FullScreenPageLayout>
  );
}
