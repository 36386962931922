import { localize } from "render/context/InternationalizationContext";

export const Title = localize({
  en_GB: "Export your data",
  sv_SE: "Exportera din data",
});

export const Description = localize({
  en_GB: (
    <>
      The files contain data such as your patient information, journal,
      bookings, visits, consents, images and transcriptions from your scan.
    </>
  ),
  sv_SE: (
    <>
      Filerna innehåller data som t.ex. din patientinformation, journal,
      bokningar, besök, samtycken, bilder och transkriptioner från dina scans.
    </>
  ),
});

export const Disclaimer = localize({
  en_GB: (
    <>
      The storage space needed for the data depends on how many Neko Body Scans
      a person has done. For someone who has not yet done a scan, less than a MB
      might be needed, while a couple of GB might be needed for someone who has
      done multiple scans.
    </>
  ),
  sv_SE: (
    <>
      Det utrymme som behövs för att lagra datan beror på hur många Neko Body
      Scans en person har gjort. För någon som ännu inte har gjort en scan kan
      mindre än en MB behövas, medan ett par GB kan behövas för någon som har
      gjort flera scans.
    </>
  ),
});

export const CreateFiles = localize({
  en_GB: "Create files to download",
  sv_SE: "Skapa filer för nedladdning",
});

export const CreatingFiles = localize({
  en_GB: "Creating files...",
  sv_SE: "Skapar filer...",
});

export const EmailNotification = localize({
  en_GB: ({ email }: { email?: string }) => (
    <>
      We will send a notification to {email ?? "your email"} once the files are
      ready to download.
    </>
  ),
  sv_SE: ({ email }: { email?: string }) => (
    <>
      Vi kommer skicka en notis till {email ?? "din e-post"} när filerna är
      klara för nedladdning.
    </>
  ),
});

export const RequestedOn = localize({
  en_GB: ({ date }: { date: string }) => <>Requested on {date}</>,
  sv_SE: ({ date }: { date: string }) => <>Efterfrågat den {date}</>,
});

export const DataRequest = localize({
  en_GB: "Data request",
  sv_SE: "Dataförfrågan",
});

export const DecryptionPassword = localize({
  en_GB: "Decryption password",
  sv_SE: "Dekrypteringslösenord",
});

export const Decrypt = localize({
  en_GB:
    "Once downloaded, you will need this password to decrypt the ZIP file.",
  sv_SE:
    "Använd lösenordet för att dekryptera ZIP-filen när du har laddat ner den.",
});

export const Download = localize({
  en_GB: "Download",
  sv_SE: "Ladda ner",
});

export const StartingDownload = localize({
  en_GB: "Your download is starting...",
  sv_SE: "Din nedladdning startar...",
});

export const PleaseWait = localize({
  en_GB: "This may take a few moments, please don't close this page.",
  sv_SE: "Detta kan ta några ögonblick, vänligen stäng inte den här sidan.",
});

export const DownloadFailed = localize({
  en_GB: "Download failed",
  sv_SE: "Nedladdning misslyckades",
});

export const SomethingWrong = localize({
  en_GB: "Something went wrong, please try again.",
  sv_SE: "Något gick fel, vänligen försök igen.",
});

export const TryAgain = localize({
  en_GB: "Try again",
  sv_SE: "Försök igen",
});

export const CreateNewFiles = localize({
  en_GB: "Create new files to download",
  sv_SE: "Skapa nya filer för nedladdning",
});
