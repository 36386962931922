import { type ReactNode } from "react";
import styles from "./styles.module.sass";

type CardProps = {
  children?: ReactNode;
  onClick?: () => void;
};

export function Card({ children, onClick }: CardProps) {
  const Component = onClick ? "button" : "div";

  return (
    <Component className={styles.card} onClick={onClick}>
      {children}
    </Component>
  );
}
