import { localize } from "render/context/InternationalizationContext";

const Status = localize({
  en_GB: <>Confirmed</>,
  sv_SE: <>Bekräftad</>,
});

const Title = localize({
  en_GB: <>Neko Body Scan</>,
  sv_SE: <>Neko Body Scan</>,
});

const EndTime = localize<{ endTime: string; duration: string | number }>({
  en_GB: ({ duration, endTime }) => (
    <>
      Until {endTime} ({duration} min)
    </>
  ),
  sv_SE: ({ duration, endTime }) => (
    <>
      Till {endTime} ({duration} min)
    </>
  ),
});

const FAQ = {
  Title: localize({
    en_GB: <>What's included?</>,
    sv_SE: <>Vad ingår?</>,
  }),
  Skin: {
    Header: localize({
      en_GB: <>Skin</>,
      sv_SE: <>Hud</>,
    }),
    Body: localize({
      sv_SE: (
        <>
          Alla vet att det är viktigt att ta hand om sin hud och regelbundet
          kontrollera födelsemärken, men det kan ta månader att träffa en
          specialist. Neko Body Scan avbildar varje centimeter av din hud på
          mindre än 20 sekunder och följer varje födelsemärke - så att du inte
          behöver oroa dig.
          <ul>
            <li>Helkroppsscan i underkläder</li>
            <li>Upptäcker födelsemärken, utslag och åldersfläckar</li>
            <li>Följer förändringar från 0,2 mm och uppåt</li>
          </ul>
        </>
      ),
      en_GB: (
        <>
          Everyone knows that it is important to take care of your skin and
          regularly check birthmarks, but it can take months to find a
          specialist. Neko Body Scan images every visible centimeter of your
          exposed skin in less than 20 seconds and follows each birthmark - so
          you don't have to worry.
          <ul>
            <li>Full body scan in underwear</li>
            <li>Detects birthmarks, rashes and age spots</li>
            <li>Tracks changes from 0.2mm and up</li>
          </ul>
        </>
      ),
    }),
  },
  Heart: {
    Header: localize({
      en_GB: <>Heart & Circulation</>,
      sv_SE: <>Hjärta och cirkulation</>,
    }),
    Body: localize({
      en_GB: (
        <>
          Cardiovascular diseases are the most common causes of death globally,
          and half of all those affected are estimated to be undiagnosed. Our
          sensors measure and detect deviations in heart function, blood
          pressure and pulse throughout your body.
          <ul>
            <li>Whole body blood pressure with 8 non-invasive sensors</li>
            <li>Detects arterial stiffness, irregular heartbeat and more</li>
            <li>Takes less than 10 minutes</li>
          </ul>
        </>
      ),
      sv_SE: (
        <>
          Hjärtkärlsjukdomar är den vanligaste dödsorsaken globalt, och hälften
          av alla drabbade beräknas vara odiagnostiserade. Våra sensorer mäter
          och upptäcker avvikelser i hjärtfunktion, blodtryck och puls i hela
          din kropp.
          <ul>
            <li>Helkroppsblodtryck med 8 icke-invasiva sensorer</li>
            <li>Upptäcker arteriell stelhet, oregelbundet hjärtslag och mer</li>
            <li>Tar mindre än 10 minuter</li>
          </ul>
        </>
      ),
    }),
  },
  Blood: {
    Header: localize({
      en_GB: <>Blood & Body</>,
      sv_SE: <>Blod & Kropp</>,
    }),
    Body: localize({
      en_GB: (
        <>
          The blood test helps us develop a baseline of your blood lipids as
          well as diabetes and inflammation markers.
          <ul>
            <li>Quick sampling</li>
            <li>Results within 15 minutes</li>
          </ul>
        </>
      ),
      sv_SE: (
        <>
          Blodprovet hjälper oss att ta fram en baslinje över dina blodfetter
          samt diabetes- och inflammationsmarkörer.
          <ul>
            <li>Snabb provtagning</li>
            <li>Resultat inom 15 minuter</li>
          </ul>
        </>
      ),
    }),
  },
  Consultation: {
    Header: localize({
      en_GB: <>Consultation</>,
      sv_SE: <>Konsultation</>,
    }),
    Body: localize({
      en_GB: (
        <>
          Unlike a health check, you don't have to wait for your results. Our
          doctors present your personal health report.
          <ul>
            <li>Personal health report</li>
            <li>Consult with our medical team</li>
            <li>
              We keep track of your personal health risks – so you can stay one
              step ahead of your health
            </li>
          </ul>
        </>
      ),
      sv_SE: (
        <>
          Till skillnad från en hälsokontroll behöver du inte vänta på dina
          resultat. Våra läkare presenterar din personliga hälsorapport så att
          du kan ligga steget före din hälsa.
          <ul>
            <li>Personlig hälsorapport</li>
            <li>Rådgör med vårt medicinska team</li>
            <li>
              Vi håller koll på dina personliga hälsorisker - så att du inte
              behöver göra det
            </li>
          </ul>
        </>
      ),
    }),
  },
};

const FindUs = {
  Title: localize({
    en_GB: <>Find us at</>,
    sv_SE: <>Hitta oss på</>,
  }),
  Description: localize({
    en_GB: <>Please arrive at least 10 minutes before</>,
    sv_SE: <>Vänligen kom minst 10 minuter i förväg</>,
  }),
};

const CompleteProfileButton = {
  Title: localize({
    en_GB: <>Complete Your Profile</>,
    sv_SE: <>Slutför din profil</>,
  }),
  Sub: localize({
    en_GB: <>Complete before your visit</>,
    sv_SE: <>Slutför innan ditt besök</>,
  }),
};

export const Trans = {
  CompleteProfileButton,
  EndTime,
  FAQ,
  FindUs,
  Status,
  Title,
};
