import { Path, PathCodec } from "@pomle/paths";
import { useLocation, useNav } from "@pomle/react-router-paths";
import { useCallback, useMemo } from "react";
import styles from "./styles.module.sass";

interface TabProps<T extends PathCodec = PathCodec> {
  path: Path<T>;
  params: T;
  label: JSX.Element;
  onClick?: () => void;
  exact?: boolean;
}

export interface TabsProps {
  children: React.ReactElement<TabProps>[];
}

function Item({ label, path, params, onClick }: TabProps) {
  const location = useLocation();
  const active = useMemo(
    () => path.match(location.pathname) === 0,
    [path, location.pathname]
  );
  const nav = useNav(path);
  const href = nav.to(params);
  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.preventDefault();
      if (active) {
        return;
      }
      onClick?.();
      nav.go(params);
    },
    [onClick, nav, active, params]
  );

  return (
    <a
      className={styles.NavItem}
      data-active={active}
      href={href}
      onClick={handleClick}
    >
      {label}
    </a>
  );
}

export function Nav({ children }: TabsProps) {
  const location = useLocation();
  const pathname = location.pathname;
  const paths = useMemo(
    () =>
      children.map(({ props }) => {
        return {
          path: props.path,
          exact: props.exact ?? true,
        };
      }),
    [children]
  );

  const matchedPath = paths.find(({ path, exact }) => {
    if (exact) {
      return path.match(pathname) === 0;
    }

    return path.match(pathname) >= 0;
  });
  const index = matchedPath ? paths.indexOf(matchedPath) : -1;

  const length = 100 / Math.max(paths.length, 1);
  const offSet = 100 * index;

  return (
    <div className={styles.Nav}>
      {children}
      <div
        style={{ width: `${length}%`, transform: `translateX(${offSet}%)` }}
        className={styles.line}
      />
    </div>
  );
}

Nav.Item = Item;
