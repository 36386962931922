import { Sticky, ViewStack } from "@pomle/react-viewstack";
import { Direction, Slide } from "@pomle/react-viewstack-transitions";
import { useMemo } from "react";
import { useBookingTokens } from "render/hooks/api/queries/useBookingTokens";
import { usePatientInferredDataQuery } from "render/hooks/api/queries/usePatientInferredDataQuery";
import { usePatientQuery } from "render/hooks/api/queries/usePatientQuery";
import { AccountVerificationStage } from "./components/AccountVerificationStage";
import { AppointmentBeingCreatedStage } from "./components/AppointmentBeingCreatedStage";
import { useUpcomingVisitQuery } from "render/hooks/api/queries/useUpcomingVisitQuery";

enum ViewActiveState {
  Loading = 0,
  ConfirmDetails = 1,
  SlotCreation = 2,
}

export function MemberInviteView() {
  const bookingTokensQuery = useBookingTokens();
  const patientQuery = usePatientQuery();
  const upcomingVisitQuery = useUpcomingVisitQuery({
    refetchInterval: (data) => {
      return data?.length ? false : 3000;
    },
  });
  const patientInferredDataQuery = usePatientInferredDataQuery();

  const activeStage = useMemo(() => {
    if (
      patientQuery.data == null ||
      upcomingVisitQuery.data == null ||
      !patientInferredDataQuery.isFetched
    ) {
      return ViewActiveState.Loading;
    }
    if (
      patientQuery.data.contactDetails.email != null &&
      patientQuery.data.contactDetails.phoneNumbers.length > 0
    ) {
      return ViewActiveState.SlotCreation;
    }
    return ViewActiveState.ConfirmDetails;
  }, [
    upcomingVisitQuery.data,
    patientQuery.data,
    patientInferredDataQuery.isFetched,
  ]);

  if (
    !patientQuery.data ||
    !bookingTokensQuery.data ||
    !patientInferredDataQuery.isFetched
  ) {
    return null;
  }

  return (
    <ViewStack>
      <Slide
        active={activeStage >= ViewActiveState.ConfirmDetails}
        direction={Direction.Right}
      >
        <Sticky>
          {activeStage === ViewActiveState.ConfirmDetails && (
            <AccountVerificationStage
              externalPatientData={{
                phoneNumber: patientInferredDataQuery.data?.phoneNumber,
              }}
              patient={patientQuery.data}
              onSuccess={() => {
                patientQuery.refetch();
              }}
            />
          )}
        </Sticky>
      </Slide>
      <Slide
        active={activeStage >= ViewActiveState.SlotCreation}
        direction={Direction.Right}
      >
        <Sticky>
          {activeStage === ViewActiveState.SlotCreation && (
            <AppointmentBeingCreatedStage />
          )}
        </Sticky>
      </Slide>
    </ViewStack>
  );
}
