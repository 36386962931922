import { useNav } from "@pomle/react-router-paths";
import { ReactComponent as CrossIcon } from "assets/cross.svg";
import { ReactComponent as QuestionIcon } from "assets/icons/chat/chat-36x36x.svg";
import { useCallback } from "react";
import { useMSAL } from "render/context/MSALContext";
import { useContactUsPopup } from "render/hooks/popups/useContactUsPopup";
import { paths } from "render/routes/paths";
import { BurgerLayout } from "render/ui/layout/BurgerLayout";
import { FullScreenPageLayout } from "render/ui/layout/FullScreenPageLayout";
import { LogoHeader } from "render/ui/layout/LogoHeader";
import { Typography } from "render/ui/presentation/Typography";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { IconButton } from "render/ui/trigger/IconButton";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

interface OnboardingIdvViewProps {
  slotId: string;
}

export function OnboardingIdvView({ slotId }: OnboardingIdvViewProps) {
  const contactUsPopup = useContactUsPopup();
  const { stepUpAccess } = useMSAL();

  const nav = {
    appointment: useNav(paths.appointment),
  };

  const handleVerifyCta = useCallback(() => {
    stepUpAccess(
      "idv",
      paths.appointmentPrereq.identityVerificationResult.url({
        slotId,
      })
    );
  }, [stepUpAccess, slotId]);

  return (
    <FullScreenPageLayout>
      <BurgerLayout>
        <LogoHeader
          leftElement={
            <IconButton
              onClick={nav.appointment.on({ slotId })}
              icon={<CrossIcon />}
            />
          }
          rightElement={
            <IconButton
              icon={<QuestionIcon display="block" />}
              onClick={contactUsPopup.emit}
            />
          }
        />
        <div className={styles.body}>
          <div className={styles.content}>
            <Typography variant="title-large">
              <Trans.Title />
            </Typography>
            <Typography style={{ marginTop: "12px" }} variant="paragraph">
              <Trans.Paragraph1 />
            </Typography>
            <Typography style={{ marginTop: "24px" }} variant="paragraph-small">
              {/* TODO-UK: Add wait icon here */}
              <Trans.SubParagraph1 />
            </Typography>
          </div>
        </div>
        <div className={styles.cta}>
          <div className={styles.action}>
            <ActionButton onClick={handleVerifyCta} variant="suggestion">
              <Trans.VerifyCta />
            </ActionButton>
          </div>
        </div>
      </BurgerLayout>
    </FullScreenPageLayout>
  );
}
