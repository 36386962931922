import { ReactComponent as LogoIcon } from "assets/logo.svg";
import { Typography } from "render/ui/presentation/Typography";
import BgImage from "./assets/background.jpg";
import styles from "./styles.module.sass";

interface ScanBookedAssetProps {
  name: string;
  date: string;
  scan: string;
  siteName: string;
  onReady?: () => void;
}

const ScanBookedAsset = ({
  name,
  date,
  scan,
  siteName,
  onReady,
}: ScanBookedAssetProps) => {
  return (
    <div className={styles.card}>
      <div className={styles.content}>
        <img
          className={styles.bg}
          src={BgImage}
          alt="Background"
          onLoad={onReady}
        />
        <div className={styles.body}>
          <div className={styles.logo}>
            <LogoIcon />
          </div>

          <Typography variant="title-large">
            Neko Body
            <br />
            Scan Booked!
          </Typography>
        </div>
      </div>
      <div className={styles.cardFooter}>
        <span>{name}</span>
        <span>{date}</span>
        <span>{scan}</span>
        <span>Neko {siteName}</span>
      </div>
    </div>
  );
};

export default ScanBookedAsset;
