import { Typography } from "render/ui/presentation/Typography";
import styles from "./styles.module.sass";

type TabsProps<T extends string> = {
  tabs: Array<{ label: JSX.Element; key: T }>;
  selected: T;
  setSelected: (href: T) => void;
  ariaLabel: string;
};

export function Tabs<T extends string>({
  tabs,
  selected,
  setSelected,
  ariaLabel,
}: TabsProps<T>) {
  return (
    <nav
      className={styles.tabControlsContainer}
      role="tablist"
      aria-label={ariaLabel}
    >
      {tabs.map(({ label, key }) => (
        <button
          key={key}
          id={key}
          className={styles.tab}
          role="tab"
          aria-selected={selected === key}
          tabIndex={selected === key ? 0 : -1}
          onClick={() => {
            setSelected(key);
          }}
        >
          <Typography variant="subtitle-medium" color="subtle">
            {label}
          </Typography>
        </button>
      ))}
    </nav>
  );
}
