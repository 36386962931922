import { Event, Action } from "render/hooks/useTracking";

const category = "Home";

export const homeEvent = {
  openProfileClick: (): Event => {
    return {
      category,
      action: Action.Click,
      label: "open_profile_button",
    };
  },

  giveConsentClick: (): Event => {
    return {
      category,
      action: Action.Click,
      label: "give_consent_click",
    };
  },

  openInvitesClick: (): Event => {
    return {
      category,
      action: Action.Click,
      label: "open_invite_tokens_page",
    };
  },

  openContactsSidebarClick: (): Event => {
    return {
      category,
      action: Action.Click,
      label: "open_contacts_sidebar",
    };
  },
};
