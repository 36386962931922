import { Visit, fromAPI } from "@cur8/rich-entity";
import { useNav } from "@pomle/react-router-paths";
import { useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useAppInsights } from "render/context/AppInsightsContext";
import { useScanBookedAssetDetailsQuery } from "render/hooks/api/queries/useScanBookedAssetDetailsQuery";
import { useScanBookedAssetQuery } from "render/hooks/api/queries/useScanBookedAssetQuery";
import { useUpcomingVisitQuery } from "render/hooks/api/queries/useUpcomingVisitQuery";
import { useAsyncHandle } from "render/hooks/useAsyncHandle";
import { useTimeOut } from "render/hooks/useTimeOut";
import { paths } from "render/routes/paths";
import { FullMonthDay } from "render/ui/format/FullMonthDay/FullMonthDay";
import { TimeHourMinute } from "render/ui/format/TimeHourMinute";
import { useBookSlotMutation } from "render/views/booking/MultiSiteMonthView/hooks/useBookSlotMutation";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

interface BookingActionsProps {
  slotId: string;
}

export function BookingActions({ slotId }: BookingActionsProps) {
  const api = useAPIClient();
  const slotQuery = useQuery({
    queryKey: ["slot-to-book", slotId],
    queryFn: ({ signal }) => {
      const req = api.bookingV2.getSlot({ slotId });
      signal?.addEventListener("abort", req.abandon);
      return req.result.then(fromAPI.toSlot);
    },
    refetchInterval: (data) => {
      return data?.isFree ? 3000 : false;
    },
    keepPreviousData: true,
  });
  const slot = slotQuery.data;

  const { refetch: refetchUpcomingVisitQuery } = useUpcomingVisitQuery({
    enabled: false,
  });

  const [isBooked, setIsBooked] = useState(false);
  const [createdVisit, setCreatedVisit] = useState<Visit>();

  // note(ford): Prefetch the shareable asset as soon as we have a created visit
  useScanBookedAssetDetailsQuery(createdVisit?.visitId, {
    enabled: !!createdVisit,
  });
  useScanBookedAssetQuery(createdVisit?.visitId, {
    enabled: !!createdVisit,
  });

  const bookSlotMutation = useBookSlotMutation();

  const appointment = useNav(paths.appointment);
  const shareBooking = useNav(paths.shareBooking);

  const appInsights = useAppInsights();

  const postBookingNavigation = useTimeOut(1000, () => {
    if (!slot) {
      return;
    }

    // note(ford): Visit was not created in time, navigate to appointment page instead of
    //             showing the shareable asset as it will fail if the visit is not created.
    if (!createdVisit) {
      appointment.go({ slotId: slot.slotId });
    } else {
      shareBooking.go({
        slotId: createdVisit.slotId,
        visitId: createdVisit.visitId,
      });
    }
  });

  useEffect(() => {
    if (!isBooked) {
      return;
    }

    postBookingNavigation.startTimeout();
  }, [isBooked, postBookingNavigation]);

  const handleBookSlot = useAsyncHandle(
    useCallback(async () => {
      if (!slot) {
        return;
      }

      const [result] = await Promise.all([
        bookSlotMutation(slot),
        new Promise((resolve) => {
          window.setTimeout(() => resolve(undefined), 3000);
        }),
      ]);

      let visit = undefined;
      let count = 0;

      do {
        try {
          let iteration = count;
          let [upcomingVisits] = await Promise.all([
            refetchUpcomingVisitQuery(),
            new Promise((resolve) => {
              window.setTimeout(() => resolve(undefined), 500 * iteration);
            }),
          ]);
          visit = upcomingVisits.data?.find((visit) => {
            return visit.slotId === result.slotId;
          });
        } catch (e) {
          const exception =
            e instanceof Error
              ? e
              : new Error("Refetching upcoming visits failed in booking flow");
          appInsights.trackException({
            exception: exception,
            properties: {
              description: "Refetching upcoming visits failed in booking flow",
            },
          });
        } finally {
          count += 1;
        }
      } while (!visit && count < 5);

      if (!visit) {
        appInsights.trackException({
          exception: new Error("Visit not created after booking"),
          properties: {
            description: "Refetching upcoming visits failed in booking flow",
          },
        });
      } else {
        setCreatedVisit(visit);
      }

      setIsBooked(true);
    }, [slot, bookSlotMutation, refetchUpcomingVisitQuery, appInsights])
  );

  if (!slot) {
    return undefined;
  }

  const isBooking = handleBookSlot.busy;
  const isOpen = slot.isFree;
  const disableButton = !isOpen || isBooking || isBooked;

  return (
    <div className={styles.BookingActions}>
      <div>
        <div className={styles.time}>
          <TimeHourMinute date={slot.startTime} /> -{" "}
          <TimeHourMinute date={slot.endTime} />
        </div>
        <div className={styles.day}>
          <FullMonthDay date={slot.startTime} />
        </div>
        <div className={styles.location}>{slot.room?.site?.siteName}</div>
      </div>

      <button
        disabled={disableButton}
        data-inprogress={isBooking}
        onClick={handleBookSlot.callback}
        className={styles.book}
      >
        {isBooked ? <Trans.Success /> : undefined}
        {isBooking ? <Trans.Booking /> : undefined}
        {!isBooking && isOpen ? <Trans.Book /> : undefined}
        {!isBooking && !isOpen && !isBooked ? (
          <Trans.Error.SlotIsTaken />
        ) : undefined}
      </button>
    </div>
  );
}
