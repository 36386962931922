import { APITypesV2 } from "@cur8/api-client";
import { Sticky, ViewStack } from "@pomle/react-viewstack";
import { Direction, Slide } from "@pomle/react-viewstack-transitions";
import { ReactComponent as CrossIcon } from "assets/cross.svg";
import { ReactComponent as QuestionIcon } from "assets/icons/chat/chat-36x36x.svg";
import { checkIfSigned } from "lib/consents/checkIfSigned";
import { mapConsents } from "lib/consents/mapConsents";
import { parseDocument } from "lib/legal/parseDocument";
import { useQuestionnaireLanguageCode } from "lib/questionnaire/locale";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLegalDocumentQuery } from "render/hooks/api/queries/useLegalDocumentQuery";
import { useLatestConsentsByType } from "render/hooks/api/queries/usePatientConsentsQuery";
import { usePreVisitConsentStudies } from "render/hooks/api/queries/useVisitsQuery";
import { useGiveConsentMutation } from "render/hooks/mutations/consent/useGiveConsentMutation";
import { useRevokeConsentMutation } from "render/hooks/mutations/consent/useRevokeConsentMutation";
import { useContactUsPopup } from "render/hooks/popups/useContactUsPopup";
import { useAsyncHandle } from "render/hooks/useAsyncHandle";
import { useTracking } from "render/hooks/useTracking";
import { BurgerLayout } from "render/ui/layout/BurgerLayout";
import { FullScreenPageLayout } from "render/ui/layout/FullScreenPageLayout";
import { LogoHeader } from "render/ui/layout/LogoHeader";
import { Skeleton } from "render/ui/presentation/Skeleton";
import { Toggle } from "render/ui/presentation/Toggle";
import { Typography } from "render/ui/presentation/Typography";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { IconButton } from "render/ui/trigger/IconButton";
import { onboardingEvent } from "render/views/OnboardingView/tracking";
import { ConsentDocument } from "render/views/ProfileView/components/ConsentDocument";
import { SharedTrans } from "render/views/trans";
import { Trans } from "../../trans";
import styles from "./styles.module.sass";

interface OnboardingStudiesConsentViewProps {
  onSave: () => void;
  onClose?: () => void;
}

type HandleToggleProps = {
  consentId: string;
  id: string | undefined;
  setIsChecked: React.Dispatch<React.SetStateAction<boolean>>;
  value: boolean;
  url: string;
};

type StudyToggleProps = {
  consent: {
    /** Description of a consent */
    consentMetadata: APITypesV2.ConsentMetadata;
    /** A summary of a signed consent */
    relevantSignature: APITypesV2.ConsentSummary | undefined;
  };
  setSelectedStudyId: React.Dispatch<React.SetStateAction<string | undefined>>;
  handleToggle: {
    busy: boolean;
    callback: ({
      consentId,
      id,
      setIsChecked,
      value,
      url,
    }: HandleToggleProps) => Promise<void> | undefined;
  };
  languageCode: string;
};

function StudyToggle({
  consent,
  setSelectedStudyId,
  handleToggle,
  languageCode,
}: StudyToggleProps) {
  const url = consent.consentMetadata?.documents?.find(
    (document) => document.language === languageCode
  )?.uri;

  const { data: legalDoc } = useLegalDocumentQuery(url, {
    enabled: Boolean(url),
  });

  const isSigned = useMemo(() => {
    return checkIfSigned(consent?.relevantSignature);
  }, [consent?.relevantSignature]);

  const [isChecked, setIsChecked] = useState(isSigned);

  // required to keep the toggle in sync with any changes made on the ConsentDocument page
  useEffect(() => {
    setIsChecked(isSigned);
  }, [consent.relevantSignature, isSigned]);

  const consentId = consent.consentMetadata?.consentId;
  const id = consent.relevantSignature?.id;

  if (legalDoc == null || consentId == null || url == null) {
    return (
      <div className={styles.skeletonWrapper}>
        <Skeleton />
      </div>
    );
  }

  const { title } = parseDocument(legalDoc);

  return (
    <li className={styles.study}>
      <Toggle
        name="consent"
        label=""
        checked={isChecked}
        onClick={(value) => {
          if (handleToggle.busy) return;
          handleToggle.callback({ consentId, id, url, setIsChecked, value });
        }}
      />
      <div
        onClick={() => {
          setSelectedStudyId(consent.consentMetadata?.consentId);
        }}
      >
        <Typography variant="paragraph-small" color="info">
          {title}
        </Typography>
      </div>
    </li>
  );
}

export function OnboardingStudiesConsentView({
  onSave,
  onClose,
}: OnboardingStudiesConsentViewProps) {
  const { data: studiesOnVisit } = usePreVisitConsentStudies();

  const { trackEvent } = useTracking();
  const contactUsPopup = useContactUsPopup();
  const { enqueueSnackbar } = useSnackbar();
  const { data: patientConsents } = useLatestConsentsByType();

  const [selectedStudyId, setSelectedStudyId] = useState<string>();
  const { mutate: giveStudyConsentMutation } = useGiveConsentMutation();
  const { mutate: revokeStudyConsentMutation } = useRevokeConsentMutation();

  const languageCode = useQuestionnaireLanguageCode();

  const toggleStudyConsent = useAsyncHandle(
    async ({
      consentId,
      id,
      value,
      url,
    }: Omit<HandleToggleProps, "setIsChecked">) => {
      try {
        if (value) {
          giveStudyConsentMutation({
            documentUri: url,
            consentMetadataId: consentId,
          });
        } else {
          revokeStudyConsentMutation({ consentId: id });
        }
      } catch (e) {
        enqueueSnackbar(<Trans.Error.FailedToGiveConsent />, {
          variant: "error",
        });
        throw e;
      }
    }
  );

  const toggleHandler = useCallback(
    async ({ consentId, id, setIsChecked, value, url }: HandleToggleProps) => {
      try {
        setIsChecked((prev) => !prev);
        await toggleStudyConsent.callback({ consentId, id, value, url });
      } catch (e) {
        setIsChecked((prev) => !prev);
      }
    },
    [toggleStudyConsent]
  );

  const handleToggle = useAsyncHandle(toggleHandler);

  const mappedConsents = mapConsents({
    requiredConsents: studiesOnVisit,
    previouslySignedConsents: patientConsents?.studies,
  });

  const selectedStudy = useMemo(() => {
    if (!patientConsents) {
      return undefined;
    }
    const selectedStudy = mappedConsents?.find(
      ({ consentMetadata }) => consentMetadata?.consentId === selectedStudyId
    );
    return {
      url: selectedStudy?.consentMetadata?.documents?.find(
        (document) => document.language === languageCode
      )?.uri,
      signature: selectedStudy?.relevantSignature,
    };
  }, [languageCode, mappedConsents, patientConsents, selectedStudyId]);

  const hasAgreedToAllStudies = useMemo(() => {
    const numberOfSignedStudies = mappedConsents?.filter((consent) =>
      checkIfSigned(consent?.relevantSignature)
    ).length;
    const numberOfStudies = studiesOnVisit?.length;

    return numberOfSignedStudies === numberOfStudies;
  }, [mappedConsents, studiesOnVisit?.length]);

  const handleSubmit = async () => {
    onSave();
  };

  const onCloseStudyDetail = () => {
    setSelectedStudyId(undefined);
  };
  const closeAriaLabel = SharedTrans.Close();
  const contactUsAriaLabel = SharedTrans.ContactUs();

  if (patientConsents == null || studiesOnVisit == null) {
    return undefined;
  }

  return (
    <FullScreenPageLayout>
      <ViewStack>
        <BurgerLayout>
          <LogoHeader
            leftElement={
              onClose && (
                <IconButton
                  ariaLabel={closeAriaLabel}
                  onClick={onClose}
                  icon={<CrossIcon />}
                />
              )
            }
            rightElement={
              <IconButton
                ariaLabel={contactUsAriaLabel}
                icon={<QuestionIcon display="block" />}
                onClick={() => {
                  trackEvent(onboardingEvent.openContactsSidebarClick("open"));
                  contactUsPopup.emit();
                }}
              />
            }
            hideLogo
          />

          <div className={styles.body}>
            <div className={styles.content}>
              <div className={styles.titleWrapper}>
                <Typography variant="title-large">
                  <Trans.StudyConsent.Title />
                </Typography>
                <div className={styles.textWrapper}>
                  <Typography variant="paragraph-small">
                    <Trans.StudyConsent.Paragraph1 />
                  </Typography>
                  <Typography variant="paragraph-small">
                    <Trans.StudyConsent.Paragraph2 />
                  </Typography>
                </div>
              </div>

              <ul className={styles.studies}>
                {mappedConsents?.map((study) => {
                  return (
                    <StudyToggle
                      key={study.consentMetadata?.consentId}
                      consent={study}
                      handleToggle={handleToggle}
                      setSelectedStudyId={setSelectedStudyId}
                      languageCode={languageCode}
                    />
                  );
                })}
              </ul>

              <div className={styles.textWrapper}>
                <Typography variant="paragraph-small">
                  <Trans.StudyConsent.ParticipationInfoPart1 />
                </Typography>
                <Typography variant="paragraph-small">
                  <Trans.StudyConsent.ParticipationInfoPart2 />
                </Typography>
              </div>
            </div>
          </div>

          <div className={styles.cta}>
            <div className={styles.action}>
              <ActionButton
                onClick={handleSubmit}
                disabled={!hasAgreedToAllStudies}
              >
                <Trans.StudyConsent.ConsentAndParticipateButton />
              </ActionButton>
            </div>
          </div>
        </BurgerLayout>
        <Slide active={selectedStudyId != null} direction={Direction.Right}>
          <Sticky>
            <ConsentDocument
              key={selectedStudyId}
              onClose={onCloseStudyDetail}
              study={selectedStudy?.signature}
              documentUrl={selectedStudy?.url}
            />
          </Sticky>
        </Slide>
      </ViewStack>
    </FullScreenPageLayout>
  );
}
