import { Path, codecs, createPath, createQuery } from "@pomle/paths";
import { Stage } from "render/views/appointment/AppointmentOnboarding";
const root = createPath("", {});
const patientHome = root;
const assignment = root.append("assignment/:assignmentId", {
  assignmentId: codecs.string,
});

const profile = root.append("profile", {});
const profileCredentials = profile.append("credentials", {});
const profileCredentialsEditName = profileCredentials.append("edit/name", {});
const profileCredentialsEditPhone = profileCredentials.append("edit/phone", {});
const profileCredentialsEditEmail = profileCredentials.append("edit/email", {});
const profileCredentialsEditLanguage = profileCredentials.append(
  "edit/language",
  {}
);

const profileTokens = profile.append("invitations", {});

const dataAndPrivacy = profile.append("data-and-privacy", {});
const dataAndPrivacyExportData = dataAndPrivacy.append("export-data", {});

const consent = root.append("consent", {});
const onboarding = root.append("onboarding", {});
const onboardingV2 = root.append("onboardingV2", {});
const report = root.append("report", {});
const appointmentSummary = root.append("summary", {});
const appointmentSummaryData = appointmentSummary.append("data", {});
const bloodOxygenMetric = appointmentSummaryData.append("blood-oxygen", {});
const heartRateMetric = appointmentSummaryData.append("heart-rate", {});

const bloodPressureMetric = appointmentSummaryData.append("blood-pressure", {});
const anklePressureMetric = appointmentSummaryData.append("ankle-pressure", {});
const abiMetric = appointmentSummaryData.append("abi", {});
const gripStrengthMetric = appointmentSummaryData.append("grip-strength", {});
const eyePressureMetric = appointmentSummaryData.append("eye-pressure", {});

const cholesterolMetric = appointmentSummaryData.append("cholesterol", {});
const ldlMetric = appointmentSummaryData.append("ldl", {});
const hdlMetric = appointmentSummaryData.append("hdl", {});
const triglyceridesMetric = appointmentSummaryData.append("triglycerides", {});
const nonHDLMetric = appointmentSummaryData.append("non-hdl", {});
const cholesterolHDLRatioMetric = appointmentSummaryData.append(
  "cholesterol-hdl-ratio",
  {}
);
const whiteBloodCellMetric = appointmentSummaryData.append(
  "whiteblood-cell",
  {}
);
const neutrophilMetric = appointmentSummaryData.append("neutrophil", {});
const basophilMetric = appointmentSummaryData.append("basophil", {});
const eosinophilMetric = appointmentSummaryData.append("eosinophil", {});
const lymphocyteMetric = appointmentSummaryData.append("lymphocyte", {});
const hsCRPMetric = appointmentSummaryData.append("hs-crp", {});
const hbA1cMetric = appointmentSummaryData.append("hba1c", {});
const haemoglobinMetric = appointmentSummaryData.append("haemoglobin", {});
const glucoseMetric = appointmentSummaryData.append("glucose", {});
const weightMetric = appointmentSummaryData.append("weight", {});
const bmiMetric = appointmentSummaryData.append("bmi", {});
const skinMetric = appointmentSummaryData.append("skin", {});

const booking = root.append("booking", {});
const shareBooking = booking.append("/share/:slotId/:visitId", {
  slotId: codecs.string,
  visitId: codecs.string,
});
const appointment = root.append("appointment/:slotId", {
  slotId: codecs.string,
});

const checkout = root.append("payment", {});
const checkoutSuccess = root.append("payment-complete", {});
const checkoutFail = root.append("payment-fail", {});
const contact = root.append("contact", {});
const claimBookingToken = root.append("claim/:segment", {
  segment: codecs.string,
});
const feedback = root.append("feedback", {});
const memberInviteCryptic = root.append("i/c63c5e2a0637", {});
const memberInvite = root.append("members-invite", {});
const partners = root.append("partners", {});
const atriumSignIn = partners.append("atrium/sign-in", {});

function createVisitPrereqPaths<T extends {}>(origin: Path<T>) {
  const root = origin.append("prereq", {});

  const identityVerification = root.append("idv", {});
  const identityVerificationResult = root.append("idv-result", {});

  const addPasskey = root.append("passkey", {});
  const addPasskeyResult = root.append("passkey-result", {});

  const onboarding = root.append("onboarding", {});

  return {
    identityVerification,
    identityVerificationResult,

    addPasskey,
    addPasskeyResult,

    onboarding,
  };
}

const concierge = {
  checkIn: root.append("concierge/check-in/:sessionId", {
    sessionId: codecs.string,
  }),
};

export const paths = {
  concierge,
  assignment,
  root,
  profile,
  profileCredentials,
  profileCredentialsEditName,
  profileCredentialsEditPhone,
  profileCredentialsEditEmail,
  profileCredentialsEditLanguage,
  patientHome,
  consent,
  onboarding,
  onboardingV2,
  report,
  appointmentSummary,
  appointmentSummaryData,
  bloodPressureMetric,
  anklePressureMetric,
  abiMetric,
  gripStrengthMetric,
  eyePressureMetric,
  bloodOxygenMetric,
  heartRateMetric,
  cholesterolMetric,
  ldlMetric,
  hdlMetric,
  triglyceridesMetric,
  nonHDLMetric,
  cholesterolHDLRatioMetric,
  whiteBloodCellMetric,
  neutrophilMetric,
  basophilMetric,
  eosinophilMetric,
  lymphocyteMetric,
  hsCRPMetric,
  hbA1cMetric,
  haemoglobinMetric,
  glucoseMetric,
  weightMetric,
  bmiMetric,
  skinMetric,
  booking,
  appointment,
  shareBooking,
  appointmentPrereq: createVisitPrereqPaths(appointment),
  checkout,
  checkoutSuccess,
  checkoutFail,
  contact,
  dataAndPrivacy,
  dataAndPrivacyExportData,
  profileTokens,
  claimBookingToken,
  feedback,
  memberInvite,
  memberInviteCryptic,
  atriumSignIn,
};

export const queries = {
  onboarding: createQuery({
    initialStep: codecs.oneOf([Stage.Onboarding, Stage.Studies]),
  }),
  booking: createQuery({
    day: codecs.string,
    slotId: codecs.string,
  }),
  payment: createQuery({
    code: codecs.string,
  }),
  contact: createQuery({
    status: codecs.string,
  }),
  checkout: createQuery({
    orderId: codecs.string,
  }),
  assignments: createQuery({
    start: codecs.string,
    end: codecs.string,
  }),
  checkIn: createQuery({
    state: codecs.string,
  }),
  metric: createQuery({
    side: codecs.oneOf(["left", "right"] as const),
    scan: codecs.number,
  }),
};
