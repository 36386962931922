import { HabitualStyle } from "@cur8/measurements";
import { ChoiceQuestion } from "@cur8/questionnaire";
import { RecencyChoices } from "./choice";
import { Trans } from "./trans";
export const IsSnuser = new ChoiceQuestion(<Trans.Snus.Question />, [
  [<Trans.Snus.HabitualStyleCurrently />, HabitualStyle.Currently],
  [<Trans.Snus.HabitualStylePreviously />, HabitualStyle.Previously],
]);

const SnusCanCountChoices: [string | JSX.Element, number][] = [
  [<Trans.Snus.HalfACan />, 0.5],
  ["1", 1],
  ["2", 2],
  ["3", 3],
  ["4", 4],
  ["5", 5],
  ["6", 6],
  ["7", 7],
];

export const WeeklySnusCanCount = new ChoiceQuestion(
  <Trans.Snus.WeeklySnusCanCount />,
  SnusCanCountChoices
);

export const QuitSnusingRecency = new ChoiceQuestion(
  <Trans.Snus.QuitSnusingRecency />,
  RecencyChoices
);
