import {
  AggregatedBloodPressureData,
  AktiiaMonitorBloodPressureAssignmentData,
  Assignment,
} from "@cur8/rich-entity";
import { classNames } from "@pomle/classnames";
import { ReactComponent as QuestionIcon } from "assets/icons/chat/chat-28x28.svg";
import { ReactComponent as ExIcon } from "assets/icons/ex/ex-28x28.svg";
import { DateTime, Interval } from "luxon";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useContactUsPopup } from "render/hooks/popups/useContactUsPopup";
import { ActionButton } from "render/ui/trigger/ActionButton/ActionButton";
import { IconButton } from "render/ui/trigger/IconButton";
import { SharedTrans } from "render/views/trans";
import { DayScroll } from "./components/DayScroll/DayScroll";
import { Header } from "./components/Header";
import { OverviewStep } from "./components/OverviewStep/OverviewStep";
import { Step as StepContainer } from "./components/Step";
import { Step } from "./shared";
import styles from "./styles.module.sass";
enum Mode {
  Day = "day",
  Night = "night",
  Final = "final",
}

const EMPTY = "";

function isInteger(value: string) {
  return value.length > 0 && !isNaN(Number(value));
}

interface BloodValuesFormProps {
  onSuccess?: () => void;
  onClose: () => void;
  onSelect: (selected: DateTime) => void;
  assignment: Assignment<AktiiaMonitorBloodPressureAssignmentData>;
  day: DateTime;
  interval: Interval;
}

export function BloodValuesForm({
  onClose,
  assignment,
  day,
  interval,
  onSelect,
}: BloodValuesFormProps) {
  const [currentStep, setCurrentStep] = useState<Step>(Step.AllSet);
  const contactUsPopup = useContactUsPopup();

  const [sysDay, setSysDay] = useState<string>(EMPTY);
  const [diaDay, setDiaDay] = useState<string>(EMPTY);
  const [sysNight, setSysNight] = useState<string>(EMPTY);
  const [diaNight, setDiaNight] = useState<string>(EMPTY);

  const resetForm = useCallback(
    (data: AggregatedBloodPressureData | undefined) => {
      setSysDay(data?.avgDay?.systolic?.toString() ?? EMPTY);
      setDiaDay(data?.avgDay?.diastolic?.toString() ?? EMPTY);
      setSysNight(data?.avgNight?.systolic?.toString() ?? EMPTY);
      setDiaNight(data?.avgNight?.diastolic?.toString() ?? EMPTY);
      setCurrentStep(Step.AllSet);
    },
    []
  );

  const selectedDayISO = useMemo(() => day.toISODate(), [day]);

  useEffect(() => {
    const data = assignment.data.measurements?.find(
      (m) => m.measuredAt!.toISODate() === selectedDayISO
    );
    resetForm(data);
  }, [assignment.data.measurements, selectedDayISO, resetForm]);

  const setValue = useCallback((callback: (value: string) => void) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      const data = value.trim();
      if (value.length === 0 || isInteger(data)) {
        callback(data);
      }
    };
  }, []);

  const inputRefs = {
    sysDay: useRef<HTMLInputElement>(null),
    diaDay: useRef<HTMLInputElement>(null),
    sysNight: useRef<HTMLInputElement>(null),
    diaNight: useRef<HTMLInputElement>(null),
  };

  const stepsRefsMap = useMemo(() => {
    const result: Record<Step, React.RefObject<HTMLInputElement> | null> = {
      [Step.EditDiastolicDay]: inputRefs.diaDay,
      [Step.EditSystolicDay]: inputRefs.sysDay,
      [Step.EditSystolicNight]: inputRefs.sysNight,
      [Step.EditDiastolicNight]: inputRefs.diaNight,
      [Step.AllSet]: null,
    };
    return result;
  }, [
    inputRefs.diaDay,
    inputRefs.sysDay,
    inputRefs.sysNight,
    inputRefs.diaNight,
  ]);

  const mode = useMemo(() => {
    if (
      currentStep === Step.EditSystolicDay ||
      currentStep === Step.EditDiastolicDay
    ) {
      return Mode.Day;
    }
    if (
      currentStep === Step.EditSystolicNight ||
      currentStep === Step.EditDiastolicNight
    ) {
      return Mode.Night;
    }
    return Mode.Final;
  }, [currentStep]);

  const focusInput = useCallback((e: HTMLInputElement) => e.focus(), []);

  const changeToStep = useCallback(
    (target: Step) => {
      setCurrentStep(target);
      const inputRef = stepsRefsMap[target];
      if (inputRef && inputRef.current) {
        focusInput(inputRef.current);
      }
    },
    [focusInput, stepsRefsMap]
  );

  const onFocusChangeTo = useCallback(
    (step: Step) => () => changeToStep(step),
    [changeToStep]
  );

  const nextStep = useCallback(() => {
    if (sysDay === EMPTY) {
      return changeToStep(Step.EditSystolicDay);
    }
    if (diaDay === EMPTY) {
      return changeToStep(Step.EditDiastolicDay);
    }
    if (sysNight === EMPTY) {
      return changeToStep(Step.EditSystolicNight);
    }
    if (diaNight === EMPTY) {
      return changeToStep(Step.EditDiastolicNight);
    }
    return changeToStep(Step.AllSet);
  }, [sysDay, diaDay, sysNight, diaNight, changeToStep]);

  const submitStep = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      nextStep();
    },
    [nextStep]
  );

  return (
    <div className={styles.BloodValuesForm} data-mode={mode}>
      <div className={styles.container}>
        <div>
          <Header
            mode={mode === Mode.Night ? "dark" : "light"}
            date={day}
            leftElement={
              <IconButton onClick={onClose} icon={<ExIcon display="block" />} />
            }
            rightElement={
              <IconButton
                icon={<QuestionIcon display="block" />}
                ariaLabel={SharedTrans.ContactUs()}
                onClick={contactUsPopup.emit}
              />
            }
          />
          <DayScroll
            measurements={assignment.data.measurements}
            mode={mode === Mode.Night ? "dark" : "light"}
            onSelect={onSelect}
            selected={day}
            interval={interval}
          />
        </div>

        <div className={styles.modeContainer}>
          <StepContainer
            isActive={mode === Mode.Final}
            className={styles.step}
            activeClass={styles.active}
            hiddenClass={styles.hidden}
          >
            <div className={styles.stepContent}>
              <OverviewStep
                day={day}
                onGoToStepClick={changeToStep}
                data={{ diaDay, diaNight, sysDay, sysNight }}
              />
            </div>
          </StepContainer>
          <StepContainer
            isActive={mode !== Mode.Final}
            className={classNames(styles.step, styles.modeContainer)}
            activeClass={styles.active}
            hiddenClass={styles.hidden}
          >
            <StepContainer
              isActive={mode === Mode.Night}
              className={styles.step}
              activeClass={styles.active}
              hiddenClass={styles.hidden}
            >
              <div className={styles.headings}>
                <div className={styles.header}>Night avarage</div>
                <div className={styles.subHeader}>
                  What was your systolic night average yesterday?
                </div>
                <div className={styles.formContainer}>
                  <form onSubmit={submitStep}>
                    <div
                      className={styles.inputContainer}
                      onFocus={onFocusChangeTo(Step.EditSystolicNight)}
                    >
                      <input
                        pattern="[0-9]*"
                        value={sysNight}
                        ref={inputRefs.sysNight}
                        onChange={setValue(setSysNight)}
                        className={classNames({
                          [styles.active]:
                            currentStep === Step.EditSystolicNight,
                        })}
                        placeholder="120"
                      />
                    </div>

                    <div className={styles.inputSepparator}>/</div>
                    <div
                      className={styles.inputContainer}
                      onFocus={onFocusChangeTo(Step.EditDiastolicNight)}
                    >
                      <input
                        pattern="[0-9]*"
                        ref={inputRefs.diaNight}
                        value={diaNight}
                        onChange={setValue(setDiaNight)}
                        placeholder="80"
                        className={classNames({
                          [styles.active]:
                            currentStep === Step.EditDiastolicNight,
                        })}
                      />
                    </div>
                    <button
                      className={styles.hiddenSubmitButton}
                      type="submit"
                    />
                  </form>
                </div>
              </div>
            </StepContainer>
            <StepContainer
              isActive={mode === Mode.Day}
              className={styles.step}
              activeClass={styles.active}
              hiddenClass={styles.hidden}
            >
              <div className={styles.headings}>
                <div className={styles.header}>Day avarage</div>
                <div className={styles.subHeader}>
                  What was your systolic day average yesterday?
                </div>
                <div className={styles.formContainer}>
                  <form onSubmit={submitStep}>
                    <div
                      className={styles.inputContainer}
                      onFocus={onFocusChangeTo(Step.EditSystolicDay)}
                    >
                      <input
                        pattern="[0-9]*"
                        ref={inputRefs.sysDay}
                        onChange={setValue(setSysDay)}
                        className={classNames({
                          [styles.active]: currentStep === Step.EditSystolicDay,
                        })}
                        placeholder="120"
                        value={sysDay}
                      />
                    </div>
                    <div className={styles.inputSepparator}>/</div>
                    <div
                      className={styles.inputContainer}
                      onFocus={onFocusChangeTo(Step.EditDiastolicDay)}
                    >
                      <input
                        pattern="[0-9]*"
                        ref={inputRefs.diaDay}
                        className={classNames({
                          [styles.active]:
                            currentStep === Step.EditDiastolicDay,
                        })}
                        onChange={setValue(setDiaDay)}
                        placeholder="80"
                        value={diaDay}
                      />
                    </div>
                    <button
                      className={styles.hiddenSubmitButton}
                      type="submit"
                    />
                  </form>
                </div>
              </div>
            </StepContainer>
          </StepContainer>
        </div>

        <div>
          <div style={{ display: "none" }} className={styles.actions}>
            <StepContainer
              className={styles.action}
              isActive={mode !== Mode.Final}
              activeClass={styles.active}
              hiddenClass={styles.hidden}
            >
              <ActionButton onClick={nextStep}>Next</ActionButton>
            </StepContainer>

            <StepContainer
              className={styles.action}
              isActive={mode === Mode.Final}
              activeClass={styles.active}
              hiddenClass={styles.hidden}
            >
              <ActionButton variant="suggestion" onClick={onClose}>
                Done
              </ActionButton>
            </StepContainer>
          </div>
        </div>
      </div>
    </div>
  );
}
