import { BookableSlot, fromAPI, Site } from "@cur8/rich-entity";
import { codecs, createCodec, createQuery } from "@pomle/paths";
import { useNav, useQueryParams } from "@pomle/react-router-paths";
import { Sticky } from "@pomle/react-viewstack";
import { silenceAbort } from "lib/error";
import { range } from "lib/mapRange";
import { DateTime } from "luxon";
import { ReactNode, useCallback, useEffect, useMemo } from "react";
import { useAPIClient } from "render/context/APIContext";
import { usePatientQuery } from "render/hooks/api/queries/usePatientQuery";
import { useLiveTime } from "render/hooks/useLiveTime";
import { paths } from "render/routes/paths";
import { FullMonthDay } from "render/ui/format/FullMonthDay/FullMonthDay";
import { MonthName } from "render/ui/format/MonthName";
import { FullScreenPageLayout } from "render/ui/layout/FullScreenPageLayout";
import { Expandable } from "render/ui/presentation/Expandable";
import { Typography } from "render/ui/presentation/Typography";
import { IconButton } from "render/ui/trigger/IconButton";
import { getFirstAvailableSlot } from "render/views/booking/MultiSiteMonthView/api/slot";
import { BookingActions } from "render/views/booking/MultiSiteMonthView/components/BookingActions";
import { ListView } from "render/views/booking/MultiSiteMonthView/components/ListView";
import { MiniCalendar } from "render/views/booking/MultiSiteMonthView/components/MiniCalendar";
import { WeeklyCalendar } from "render/views/booking/MultiSiteMonthView/components/WeeklyCalendar";
import { ReactComponent as ArrowBackIcon } from "./assets/arrow-back.svg";
import { ReactComponent as ChevronIcon } from "./assets/chevron.svg";
import { ReactComponent as CompasIcon } from "./assets/compass.svg";
import { ReactComponent as CrossIcon } from "./assets/cross.svg";
import { ReactComponent as LogoIcon } from "./assets/logo.svg";
import VenueIMG from "./assets/venue.png";
import { Day } from "./components/Day";
import { useSitesQuery } from "./hooks/useSitesQuery";
import styles from "./styles.module.sass";
import { Trans } from "./trans";
import { Country } from "lib/country";

const DATE_FORMAT = "yyyy-LL-dd't'HH.mm.ssZZZ";

const dateCodec = createCodec(
  (source: DateTime) => source.toFormat(DATE_FORMAT).replace("+", "z"),
  (source: string) => {
    return DateTime.fromFormat(source.replace("z", "+"), DATE_FORMAT);
  }
);

const queryParams = createQuery({
  sites: codecs.string,
  month: dateCodec,
  day: dateCodec,
  week: dateCodec,
  slotId: codecs.string,
});

function WeeklyCalendarSection({
  week,
  renderDay,
}: {
  week: DateTime;
  renderDay: (date: DateTime) => ReactNode;
}) {
  return (
    <ul>
      {range(0, 0, (offset) => {
        const date = week.startOf("week").plus({ week: offset });
        return (
          <li key={date.toISODate()}>
            <WeeklyCalendar
              key={date.toISODate()}
              date={date}
              renderDay={renderDay}
            />
          </li>
        );
      })}
    </ul>
  );
}

function isOnMobile() {
  return window.innerWidth <= 980;
}

// HACK-UK: This doesn't really scale but it'll do for now
function Location({ country, sites }: { country?: string; sites?: Site[] }) {
  const cityMap: Record<string, ReactNode> = {
    [Country.Sweden]: <Trans.City.Stockholm />,
    [Country.UK]: <Trans.City.London />,
  };

  const city = cityMap[country ?? ""];
  let description: undefined | ReactNode;

  if (sites) {
    if (sites.length === 1) {
      description = (
        <Trans.LocationDescription.SingleSite siteName={sites[0].siteName} />
      );
    } else if (sites.length > 1) {
      description = (
        <Trans.LocationDescription.MultiSite count={sites.length} />
      );
    }
  }

  return (
    <>
      {city}&nbsp;{description}
    </>
  );
}

export function MultiSiteMonthView() {
  const [params, setParams] = useQueryParams(queryParams);
  const patientQuery = usePatientQuery();
  const sitesQuery = useSitesQuery(
    {
      country: patientQuery.data?.preferredCountry,
    },
    {
      enabled: !!patientQuery.data?.preferredCountry,
    }
  );
  const api = useAPIClient();

  const month = params.month.at(0);
  const day = params.day.at(0);
  const week = params.week.at(0);
  const slotId = params.slotId.at(0);

  const nav = {
    home: useNav(paths.root),
  };

  const siteIds = useMemo(() => {
    if (params.sites.length) {
      return params.sites;
    }
    let result = sitesQuery.data?.map((site) => {
      return site.siteId;
    });

    return result;
  }, [sitesQuery.data, params.sites]);

  useEffect(() => {
    if (month?.isValid || !siteIds) {
      return;
    }

    const req = getFirstAvailableSlot(api, siteIds, DateTime.now());

    req.result
      .then((result) => {
        const slot = result.items.map(fromAPI.toBookableSlot).at(0);
        const date = slot ? slot.startTime : DateTime.now();

        const isMobile = isOnMobile();
        if (isMobile) {
          setParams({ month: [date] });
        } else {
          setParams({ month: [date], day: [date] });
        }
      })
      .catch(silenceAbort);

    return req.abandon;
  }, [api, siteIds, month, setParams]);

  const now = useLiveTime("hour");

  const dailyListControls = {
    onSlotSelect: useCallback(
      (target: BookableSlot) => {
        setParams({ slotId: target.slotId === slotId ? [] : [target.slotId] });
      },
      [setParams, slotId]
    ),
    onSitesReset: useCallback(() => {
      setParams({ sites: [] });
    }, [setParams]),
    allSitesSelected: useMemo(() => {
      return params.sites.length === 0;
    }, [params.sites.length]),
  };

  const calendarControls = {
    resetDay: useCallback(() => {
      setParams({ day: [], slotId: [], week: [] });
    }, [setParams]),
    canNavBack: useCallback(() => {
      return month != null && month.startOf("month") > now;
    }, [month, now]),
    canNavForward: useCallback(() => {
      return month != null && month.startOf("month") < now.plus({ month: 12 });
    }, [month, now]),
    isToday: useMemo(() => {
      const today = now.toISODate();
      return (target: DateTime) => {
        return target.toISODate() === today;
      };
    }, [now]),
    isDaySelected: useMemo(() => {
      const selectedDay = day?.toISODate();
      return (target: DateTime) => {
        return !!selectedDay && target.toISODate() === selectedDay;
      };
    }, [day]),
    onDaySelect: useCallback(
      (target: DateTime) => {
        setParams({ day: [target], week: [target], slotId: [] });
      },
      [setParams]
    ),
    navigateBy: useCallback(
      (offset: number) => () => {
        return month && setParams({ month: [month.plus({ month: offset })] });
      },
      [month, setParams]
    ),
  };

  const filterControls = {
    hasMultipleSites: sitesQuery.data && sitesQuery.data.length > 1,
    isSelected: useCallback(
      (id: string) => {
        return params.sites.some((siteId) => siteId === id);
      },
      [params]
    ),
    toggleFilterFor: useCallback(
      (siteId: string) => {
        return () => {
          setParams({ sites: [siteId], slotId: [] });
        };
      },
      [setParams]
    ),
    isAllSelected: params.sites.length === 0,
    resetSitesFilter: useCallback(() => {
      setParams({ sites: [] });
    }, [setParams]),
  };

  return (
    <FullScreenPageLayout>
      <div className={styles.MultiSiteMonthView}>
        <div className={styles.container} data-day-present={!!day}>
          <header className={styles.venue}>
            <div className={styles.siteLogo} data-day-present={!!day}>
              <img src={VenueIMG} alt="venue" />
              <div className={styles.nav}>
                <button
                  data-variant="light"
                  onClick={nav.home.on({})}
                  className={styles.backButton}
                >
                  <CrossIcon />
                </button>
                <LogoIcon className={styles.logo} />
              </div>
              <div className={styles.content}>
                <div className={styles.text}>
                  <h1 className={styles.title}>
                    <Trans.Header />
                  </h1>
                  <div className={styles.locationCount}>
                    <CompasIcon />
                    <Typography variant="small">
                      <Location
                        country={patientQuery.data?.preferredCountry}
                        sites={sitesQuery.data}
                      />
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
            <section className={styles.mobileBack} data-active={!!day}>
              <button
                data-variant="dark"
                className={styles.backButton}
                onClick={calendarControls.resetDay}
              >
                <ArrowBackIcon />
              </button>
              <div className={styles.day}>{day?.toFormat("LLLL")}</div>
            </section>
          </header>

          <section className={styles.locations}>
            <ul>
              {sitesQuery.data && filterControls.hasMultipleSites && (
                <>
                  <li>
                    <button
                      data-selected={filterControls.isAllSelected}
                      onClick={filterControls.resetSitesFilter}
                    >
                      <Trans.AllLocationsFilter />
                    </button>
                  </li>
                  {sitesQuery.data.map((site) => (
                    <li key={site.siteId}>
                      <button
                        data-selected={filterControls.isSelected(site.siteId)}
                        onClick={filterControls.toggleFilterFor(site.siteId)}
                      >
                        {site.siteName}
                      </button>
                    </li>
                  ))}
                </>
              )}
            </ul>
          </section>

          <section className={styles.weeklyCalendar}>
            {week && siteIds && (
              <WeeklyCalendarSection
                week={week}
                renderDay={(dayDate) => {
                  return (
                    <Day
                      key={dayDate.toISODate()}
                      now={now}
                      selected={calendarControls.isDaySelected(dayDate)}
                      isToday={calendarControls.isToday(dayDate)}
                      onClick={calendarControls.onDaySelect}
                      date={dayDate}
                      siteIds={siteIds}
                    />
                  );
                }}
              />
            )}
          </section>

          <div className={styles.body}>
            {month?.isValid && siteIds && (
              <div className={styles.bookingSection}>
                <div
                  className={styles.monthCalendar}
                  data-collapse-for-mobile={!!day}
                >
                  <header className={styles.navHeader}>
                    <div className={styles.monthHeader}>
                      {range(-1, 1, (offset) => {
                        const date = month.plus({ month: offset });
                        return (
                          <div
                            key={date.toISODate()}
                            className={styles.monthName}
                            style={{
                              transform: `translateX(${100 * offset}%)`,
                              opacity: offset === 0 ? 1 : 0,
                            }}
                          >
                            <MonthName date={date} />
                          </div>
                        );
                      })}
                    </div>
                    <nav>
                      <IconButton
                        disabled={!calendarControls.canNavBack()}
                        onClick={calendarControls.navigateBy(-1)}
                        icon={
                          <div className={styles.chevronContainer}>
                            <ChevronIcon
                              data-disabled={!calendarControls.canNavBack()}
                              className={styles.chevron}
                              data-direction="left"
                            />
                          </div>
                        }
                      />
                      <IconButton
                        disabled={!calendarControls.canNavForward()}
                        onClick={calendarControls.navigateBy(1)}
                        icon={
                          <div className={styles.chevronContainer}>
                            <ChevronIcon
                              data-disabled={!calendarControls.canNavForward()}
                              className={styles.chevron}
                            />
                          </div>
                        }
                      />
                    </nav>
                  </header>
                  <section className={styles.calendars}>
                    {range(-1, 1, (offset) => {
                      const date = month.plus({ month: offset });
                      return (
                        <div
                          key={date.toISODate()}
                          className={styles.calendar}
                          style={{
                            transform: `translateX(${100 * offset}%)`,
                            opacity: offset === 0 ? 1 : 0,
                          }}
                        >
                          <MiniCalendar
                            date={date}
                            renderDay={(dayDate) => {
                              return (
                                <Day
                                  now={now}
                                  selected={calendarControls.isDaySelected(
                                    dayDate
                                  )}
                                  isToday={calendarControls.isToday(dayDate)}
                                  onClick={calendarControls.onDaySelect}
                                  date={dayDate}
                                  siteIds={siteIds}
                                />
                              );
                            }}
                          />
                        </div>
                      );
                    })}
                  </section>
                </div>
                <section
                  className={styles.dayCalendar}
                  data-active={!!day}
                  key={`${day?.toISODate()}${siteIds.join("")}`}
                >
                  {day && (
                    <>
                      <time className={styles.time}>
                        <FullMonthDay date={day} />
                      </time>
                      <div className={styles.dayTimes}>
                        <ListView
                          onSiteResetClick={dailyListControls.onSitesReset}
                          allSitesSelected={dailyListControls.allSitesSelected}
                          onSelect={dailyListControls.onSlotSelect}
                          selectedSlotId={slotId}
                          date={day}
                          siteIds={siteIds}
                        />
                      </div>
                    </>
                  )}
                </section>
              </div>
            )}
          </div>

          <Expandable isOpen={slotId != null}>
            <section className={styles.cta}>
              <div className={styles.content}>
                <Sticky delay={300}>
                  {slotId != null && <BookingActions slotId={slotId} />}
                </Sticky>
              </div>
            </section>
          </Expandable>
        </div>
      </div>
    </FullScreenPageLayout>
  );
}
