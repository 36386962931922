import { Action, Event } from "render/hooks/useTracking";

const category = "identity-verification";

export const identityVerificationEvent = {
  verificationStateView: (value: string): Event => ({
    label: "verificationState",
    category,
    action: Action.View,
    value,
  }),
};
