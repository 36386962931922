import { APITypesV1, APITypesV2 } from "@cur8/api-client";
import { Visit, fromAPI } from "@cur8/rich-entity";
import { useQuery } from "@tanstack/react-query";
import { APIClient } from "lib/api/client";
import { useAPIClient } from "render/context/APIContext";
import { useSession } from "render/context/MSALContext";
import { QueryOptions } from "typings/query";

const queryKey = (patientId: string) => ["visits", patientId] as const;

const queryFn = (apiClient: APIClient, patientId: string) =>
  apiClient.visit
    .getPatientVisits(
      { patientId },
      {
        order: APITypesV1.SortOrder.Desc,
        pageSize: 100,
      }
    )
    .result.then((result) => {
      return result.items.map(fromAPI.toVisit);
    });

type Body = Awaited<ReturnType<typeof queryFn>>;
type Key = ReturnType<typeof queryKey>;

export function useVisitsQuery<T = Body>(
  options: QueryOptions<Body, Key, T> = {}
) {
  const { patientId } = useSession();
  const apiClient = useAPIClient();

  return useQuery({
    ...options,
    queryFn: () => queryFn(apiClient, patientId),
    queryKey: queryKey(patientId),
  });
}

const attendedTypes = new Set([
  APITypesV1.VisitStatus.CheckedIn,
  APITypesV1.VisitStatus.Ongoing,
  APITypesV1.VisitStatus.Completed,
]);

export function useHasAttendedVisitInSweden() {
  return useVisitsQuery({
    select: (data) => {
      const attendedVisits = data?.filter(
        (visit) => visit.status && attendedTypes.has(visit.status)
      );
      return attendedVisits?.some((visit) => visit.siteId.startsWith("SE-"));
    },
  });
}

export function getVisitLocation(visit: Visit) {
  return visit.siteId.split("-")[0];
}

export function useLatestVisitLocation() {
  return useVisitsQuery({
    select: (data) => {
      const visit = data.at(0);
      return visit ? getVisitLocation(visit) : undefined;
    },
  });
}

export function usePreVisitConsents() {
  return useVisitsQuery({
    select: (data) => {
      return data
        .at(0)
        ?.consents?.filter((consent) =>
          consent.displayStages?.includes(
            APITypesV2.ConsentDisplayStage.PreVisit
          )
        );
    },
  });
}

export function usePreVisitConsentStudies() {
  return useVisitsQuery({
    select: (data) => {
      return data
        .at(0)
        ?.consents?.filter(
          (consent) =>
            consent.displayStages?.includes(
              APITypesV2.ConsentDisplayStage.PreVisit
            ) && consent.consentType === APITypesV2.ConsentType.Study
        );
    },
  });
}

export function useDuringVisitSelfSignConsents() {
  return useVisitsQuery({
    select: (data) => {
      return data
        .at(0)
        ?.consents?.filter(
          (consent) =>
            consent.displayStages?.includes(
              APITypesV2.ConsentDisplayStage.DuringVisit
            ) && consent.signatureType === APITypesV2.ConsentSignatureType.Self
        );
    },
  });
}
