import { createResponsePersister } from "lib/questionnaire/question-flows/onboarding/persistV2";
import { useMemo } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useSession } from "render/context/MSALContext";

export function useOnboardingPersist() {
  const { patientId } = useSession();
  const api = useAPIClient();

  const handleResponse = useMemo(() => {
    return createResponsePersister(api, patientId);
  }, [api, patientId]);

  return {
    storeResponse: handleResponse,
  };
}
