import { useLocation } from "@pomle/react-router-paths";
import { useEffect } from "react";
import { useTracking } from "render/hooks/useTracking";
import { SessionRoutes } from "./routes/SessionRoutes";

export function Router() {
  const { pathname } = useLocation();
  const { trackPageView } = useTracking();

  useEffect(() => {
    trackPageView();
  }, [trackPageView, pathname]);

  return <SessionRoutes />;
}
