import { APITypesV1 } from "@cur8/api-client";
import { fromAPI, Visit } from "@cur8/rich-entity";
import { useQuery } from "@tanstack/react-query";
import { APIClient } from "lib/api/client";
import { DateTime } from "luxon";
import { useAPIClient } from "render/context/APIContext";
import { useSession } from "render/context/MSALContext";
import { QueryOptions } from "typings/query";

function isCanceled(visit: Visit) {
  return visit.status === APITypesV1.VisitStatus.Cancelled;
}

const queryKey = (patientId: string) =>
  ["upcomming-visits", patientId] as const;

const queryFn = (apiClient: APIClient, patientId: string) =>
  apiClient.visit
    .getPatientVisits(
      { patientId },
      {
        order: APITypesV1.SortOrder.Asc,
        pageSize: 100,
        endDateTimeOffsetRange: {
          start: DateTime.now().toISO(),
        },
      }
    )
    .result.then((result) => {
      return result.items
        .map(fromAPI.toVisit)
        .filter((visit) => !isCanceled(visit));
    });

type Body = Awaited<ReturnType<typeof queryFn>>;
type Key = ReturnType<typeof queryKey>;

export function useUpcomingVisitQuery<T = Body>(
  options: QueryOptions<Body, Key, T> = {}
) {
  const { patientId } = useSession();
  const apiClient = useAPIClient();

  return useQuery({
    queryFn: () => queryFn(apiClient, patientId),
    queryKey: queryKey(patientId),
    ...options,
  });
}
