import { APITypesV1 } from "@cur8/api-client";
import { copyToClipboard } from "lib/copyToClipboard";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import { useCallback, useMemo, useState } from "react";
import { useTrackingContext } from "render/context/TrackingContext";
import { useTimeOut } from "render/hooks/useTimeOut";
import { useTracking } from "render/hooks/useTracking";
import { Typography } from "render/ui/presentation/Typography";
import { discounTokenEvent } from "../../../../tracking";
import { calcDayDiff } from "./lib/calcDayDiff";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

interface DiscountCodeAvailableProps {
  token: APITypesV1.DiscountToken;
}

export function DiscountCodeAvailable({ token }: DiscountCodeAvailableProps) {
  const { trackEvent } = useTracking();
  const [copied, setCopied] = useState<boolean>();
  const { enqueueSnackbar } = useSnackbar();
  const { conversionsTracking, hasLoaded } = useTrackingContext();
  const clipboardResetTimeout = useTimeOut(2000, () => setCopied(false));

  const createInviteUrl = useCallback((code: string) => {
    const host = window.location.hostname.split(".").slice(1).join(".");
    const result = `https://invite.${host}/${code}`;

    return result;
  }, []);

  const onClick = useCallback(() => {
    if (token.code == null) {
      return;
    }
    if (copied) {
      return;
    }
    trackEvent(discounTokenEvent.copyToClipboardClick());

    const url = createInviteUrl(token.code);
    const didCopy = copyToClipboard(url);

    if (hasLoaded) {
      conversionsTracking.TrackCopyInviteCode(token.code);
    }
    if (!didCopy) {
      return;
    }
    setCopied(true);
    clipboardResetTimeout.startTimeout();
    enqueueSnackbar(<Trans.CoppiedToClipboard />, {
      variant: "success",
      autoHideDuration: 2000,
    });
  }, [
    clipboardResetTimeout,
    createInviteUrl,
    conversionsTracking,
    copied,
    enqueueSnackbar,
    hasLoaded,
    token.code,
    trackEvent,
  ]);

  const expiresInDays = useMemo(() => {
    if (token.validUntil == null) {
      return NaN;
    }
    return calcDayDiff(token.validUntil, DateTime.now().toISODate());
  }, [token.validUntil]);

  return (
    <>
      <div className={styles.title}>
        <Typography variant="subtitle-medium" color="subtle">
          <Trans.Title />
        </Typography>
        {expiresInDays === 0 && (
          <Typography variant="paragraph-x-small" color="warning">
            <Trans.Expires.Today />
          </Typography>
        )}
        {expiresInDays > 0 && (
          <Typography variant="paragraph-x-small" color="subtle">
            <Trans.Expires.In days={expiresInDays} />
          </Typography>
        )}
      </div>
      <div className={styles.row}>
        <div className={styles.code}>{token.code}</div>
        <button className={styles.copyButton} onClick={onClick}>
          {copied ? <Trans.Copied /> : <Trans.Copy />}
        </button>
      </div>
    </>
  );
}
