import { useNav } from "@pomle/react-router-paths";
import { ReactComponent as CrossIcon } from "assets/cross.svg";
import { ReactComponent as QuestionIcon } from "assets/icons/chat/chat-36x36x.svg";
import { ReactNode, useCallback, useEffect, useMemo } from "react";
import { useMSAL, useSession } from "render/context/MSALContext";
import { useContactUsPopup } from "render/hooks/popups/useContactUsPopup";
import { paths } from "render/routes/paths";
import { FullScreenPageLayout } from "render/ui/layout/FullScreenPageLayout";
import { LogoHeader } from "render/ui/layout/LogoHeader";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { IconButton } from "render/ui/trigger/IconButton";
import styles from "./styles.module.sass";
import { Trans } from "./trans";
import { useIdentityVerificationStatus } from "render/hooks/api/queries/useIdentityVerificationStatus";
import { APITypesV1 } from "@cur8/api-client";
import { Typography } from "render/ui/presentation/Typography";
import { useTracking } from "render/hooks/useTracking";
import { identityVerificationEvent } from "./tracking";
import { Skeleton } from "render/ui/presentation/Skeleton";
import { useVisitPrerequisites } from "render/hooks/api/useVisitPrerequisites";

interface OnboardingIdvResultViewProps {
  slotId: string;
}

enum VerificationStatus {
  Success = "success",
  Declined = "declined",
  Submitted = "submitted",
  Pending = "pending",
  Unknown = "unknown",
}

function veriffStateAdapter(state: APITypesV1.IdentityVerificationStatus) {
  const record: Record<
    APITypesV1.IdentityVerificationStatus,
    VerificationStatus
  > = {
    [APITypesV1.IdentityVerificationStatus.Verified]:
      VerificationStatus.Success,
    [APITypesV1.IdentityVerificationStatus.Declined]:
      VerificationStatus.Declined,
    [APITypesV1.IdentityVerificationStatus.Submitted]:
      VerificationStatus.Submitted,
    [APITypesV1.IdentityVerificationStatus.Pending]: VerificationStatus.Pending,
  };

  return record[state] ?? VerificationStatus.Unknown;
}

function Layout({ text, actions }: { text?: ReactNode; actions?: ReactNode }) {
  return (
    <>
      <div className={styles.text}>{text}</div>
      <div className={styles.actions}>{actions}</div>
    </>
  );
}

function PendingState({ slotId }: OnboardingIdvResultViewProps) {
  const nav = {
    appointment: useNav(paths.appointment),
  };

  return (
    <Layout
      text={
        <>
          <Typography variant="title-large">
            <Trans.Pending.Title />
          </Typography>
          <Typography variant="description" color="info">
            <Trans.Pending.Text />
          </Typography>
        </>
      }
      actions={
        <ActionButton
          onClick={nav.appointment.on({ slotId })}
          variant="suggestion"
        >
          <Trans.Done />
        </ActionButton>
      }
    />
  );
}

function SuccessState({ slotId }: OnboardingIdvResultViewProps) {
  const visitPrerequisites = useVisitPrerequisites();
  const nav = {
    appointment: useNav(paths.appointment),
  };

  const goToNextStep = useCallback(() => {
    if (visitPrerequisites.goToNextStep) {
      visitPrerequisites.goToNextStep(slotId);
    } else {
      nav.appointment.go({ slotId });
    }
  }, [slotId, nav.appointment, visitPrerequisites]);

  return (
    <Layout
      text={
        <>
          <Typography variant="title-large">
            <Trans.Pending.Title />
          </Typography>
          <Typography variant="description" color="info">
            <Trans.Pending.Text />
          </Typography>
        </>
      }
      actions={
        <ActionButton onClick={goToNextStep} variant="suggestion">
          <Trans.Done />
        </ActionButton>
      }
    />
  );
}

function DeclinedState() {
  const { stepUpAccess } = useMSAL();

  const handleTryAgain = useCallback(() => {
    stepUpAccess("idv");
  }, [stepUpAccess]);

  return (
    <Layout
      text={
        <>
          <Typography variant="title-large">
            <Trans.Title />
          </Typography>
          <Typography variant="description" color="info">
            <Trans.Paragraph1 />
          </Typography>
        </>
      }
      actions={
        <ActionButton onClick={handleTryAgain} variant="suggestion">
          <Trans.Done />
        </ActionButton>
      }
    />
  );
}

export function OnboardingIdvResultView({
  slotId,
}: OnboardingIdvResultViewProps) {
  const contactUsPopup = useContactUsPopup();
  const session = useSession();
  const { trackEvent } = useTracking();

  const nav = {
    appointment: useNav(paths.appointment),
  };
  const identityVerificationStatusQuery = useIdentityVerificationStatus();
  const verriffInClaims = session.authenticationMethods.includes("veriff");
  const identityVerificationStatus =
    identityVerificationStatusQuery.data?.identityVerificationStatus;

  const verificationStatus = useMemo(() => {
    if (session.authenticationMethods.includes("veriff")) {
      return VerificationStatus.Success;
    }

    if (identityVerificationStatus == null) {
      return VerificationStatus.Unknown;
    }

    return veriffStateAdapter(identityVerificationStatus);
  }, [session.authenticationMethods, identityVerificationStatus]);

  const isReady = verriffInClaims || identityVerificationStatusQuery.isFetched;

  useEffect(() => {
    if (!isReady) {
      return;
    }
    trackEvent(
      identityVerificationEvent.verificationStateView(verificationStatus)
    );
  }, [isReady, verificationStatus, trackEvent]);

  return (
    <FullScreenPageLayout>
      <div className={styles.OnboardingIdvResultView}>
        <div className={styles.sticky}>
          <LogoHeader
            leftElement={
              <IconButton
                onClick={nav.appointment.on({ slotId })}
                icon={<CrossIcon />}
              />
            }
            rightElement={
              <IconButton
                icon={<QuestionIcon display="block" />}
                onClick={contactUsPopup.emit}
              />
            }
          />
        </div>
        <div className={styles.body}>
          <div className={styles.content}>
            {isReady ? (
              <>
                {verificationStatus === VerificationStatus.Success ? (
                  <SuccessState slotId={slotId} />
                ) : null}
                {verificationStatus === VerificationStatus.Pending ? (
                  <PendingState slotId={slotId} />
                ) : null}
                {verificationStatus === VerificationStatus.Declined ? (
                  <DeclinedState />
                ) : null}
                {verificationStatus === VerificationStatus.Unknown ? (
                  <DeclinedState />
                ) : null}
                {verificationStatus === VerificationStatus.Submitted ? (
                  <PendingState slotId={slotId} />
                ) : null}
              </>
            ) : (
              <Layout
                text={
                  <>
                    <div className={styles.skeletonBig}>
                      <Skeleton />
                    </div>
                    <div className={styles.skeletonSmall}>
                      <Skeleton />
                    </div>
                  </>
                }
                actions={
                  <div className={styles.skeletonCTA}>
                    <Skeleton />
                  </div>
                }
              />
            )}
          </div>
        </div>
      </div>
    </FullScreenPageLayout>
  );
}
