import { ReactComponent as ArrowIcon } from "assets/arrow.svg";
import { useState } from "react";
import { Expandable } from "render/ui/presentation/Expandable";
import { Typography } from "render/ui/presentation/Typography";
import styles from "./styles.module.sass";

interface AccordionProps {
  header: JSX.Element | string;
  text: JSX.Element | string;
}

export function Accordion({ header, text }: AccordionProps) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className={styles.Accordion}>
      <div className={styles.header} onClick={() => setIsOpen((v) => !v)}>
        <Typography style={{ color: "rgb(38, 48, 50)" }} variant="description">
          {header}
        </Typography>
        <ArrowIcon
          style={{
            transform: `rotate(${!isOpen ? 180 : 0}deg)`,
            transition: "transform 0.6s",
          }}
          stroke="black"
        />
      </div>
      <Expandable isOpen={isOpen}>
        <div className={styles.content}>{text}</div>
      </Expandable>
    </div>
  );
}
