import { glsl } from "lib/glsl";

export const frag = glsl`
struct Shimmer {
	float width;
	float origin;
};

struct Wave {
  float progression;
  float length;
  vec3 direction;
};

struct Falloff {
  vec3 center;
  float near;
  float far;
};

uniform Shimmer shimmer;
uniform Wave wave;
uniform Falloff falloff;
uniform vec3 color;
uniform sampler2D pointTexture;

varying vec3 vColor;
varying vec3 vPosition;
varying vec3 vPos_Scatter;
varying vec3 vPos_Front;
varying vec3 vPos_Back;
varying vec3 vPos_Heart;

varying vec2 vUv;
varying float bump;

float limit(float t) {
  return pow(t, 12.0);
}

float calculateFalloffAlpha(vec3 center, vec3 point, float near, float far) {
  float alpha = clamp(
    (distance(center, point) - near) / (far - near),
    0.0, 1.0
  );
  return 1.0 - alpha;
}

float cubicInOut(float t) {
  return t < 0.5
    ? 4.0 * t * t * t
    : 0.5 * pow(2.0 * t - 2.0, 3.0) + 1.0;
}

void main() {
  vec3 texColor = vColor * 1.0;
  vec3 shadeColor = color * 1.0;

  float origin = shimmer.origin;
  float width = shimmer.width;

  float offset = origin - vPosition.z;
  float shimmerIntensity = 0.0;

  gl_FragColor = vec4(0.0, 0.0, 0.0, 1.0);
  
  if (offset < 0.0) {
    shimmerIntensity = cubicInOut(1.0 - min(width, abs(offset)) / width) * 1.5;
  }
  shadeColor *= 1.0 + shimmerIntensity * 0.25;
 

  gl_FragColor = vec4(
    mix(
      shadeColor * 1.2,
      texColor,
      bump * 0.2
    ),
    1.0
  );

  float wave = sin(wave.progression
    + (vPos_Front.x * wave.direction.x) / wave.length
    + (vPos_Front.y * wave.direction.y) / wave.length
    + (vPos_Front.z * wave.direction.z) / wave.length);
  wave = (1.0 + wave) / 2.0;
  gl_FragColor.rgb *= 1.0 + wave * 0.5;
  gl_FragColor.a = 0.2 + wave;

  gl_FragColor.rgb = mix(
    gl_FragColor.rgb,
    texture2D(pointTexture, gl_PointCoord).rgb,
    0.15
  );


  gl_FragColor.a *= texture2D(pointTexture, gl_PointCoord).a;

  gl_FragColor.a *= 0.35 + bump * 0.5;

  gl_FragColor.a *= calculateFalloffAlpha(
    falloff.center,
    vPosition,
    falloff.near,
    falloff.far
  );
}
`;
