import { Answers } from "@cur8/questionnaire";
import { useQuestionnaireLanguageCode } from "lib/questionnaire/locale";
import {
  DailyCigaretteCount,
  IsSmoker,
  IsSmokingEnum,
  QuitSmokingRecency,
} from "lib/questionnaire/question-flows/onboarding/questions/smokingV2";
import { Trans as TransQuestions } from "lib/questionnaire/question-flows/onboarding/questions/transV2";
import { ChoiceInput } from "render/ui/presentation/Questionnaire/components/ChoiceInput";
import { QuestionView } from "../../components/QuestionView";
import { Step, StepProps } from "../../step";
import { Trans } from "../../trans";

function IsSmokerStep({
  answers,
  onAnswer,
  onNext,
  onPrev,
  currentQuestion,
  totalQuestions,
  onResetAnswer,
}: StepProps) {
  const answer = answers.get(IsSmoker);
  const label = TransQuestions.Smoking.Question();
  const { englishLabel } = TransQuestions.Smoking.Question;
  const languageCode = useQuestionnaireLanguageCode();

  return (
    <QuestionView
      currentQuestion={currentQuestion}
      totalQuestionCount={totalQuestions}
      question={IsSmoker}
      onNext={() => {
        onResetAnswer(IsSmoker);
        onNext();
      }}
      onPrev={() => {
        onPrev();
        onResetAnswer(IsSmoker);
      }}
      explanation={{
        openText: <Trans.General.WhyAreWeAsking />,
        closeText: <Trans.General.OkIUnderstand />,
        explanationText: <Trans.Steps.SmokingExplanation />,
      }}
    >
      <ChoiceInput
        question={IsSmoker}
        answer={answer}
        onAnswer={(answer) => {
          onAnswer(IsSmoker, {
            answer,
            questionAnswer: {
              questionId: "smoking.style",
              englishLabel,
              label,
              languageCode,
              answer: {
                $type: "stringenum",
                value: IsSmoker.getValue(answer) || "",
              },
            },
          });
          onNext();
        }}
      />
    </QuestionView>
  );
}

function QuitSmokingRecencyStep({
  answers,
  onAnswer,
  onNext,
  onPrev,
  currentQuestion,
  totalQuestions,
  onResetAnswer,
}: StepProps) {
  const answer = answers.get(QuitSmokingRecency);
  const label = TransQuestions.Smoking.QuitSmokingRecency();
  const { englishLabel } = TransQuestions.Smoking.QuitSmokingRecency;
  const languageCode = useQuestionnaireLanguageCode();

  return (
    <QuestionView
      currentQuestion={currentQuestion}
      totalQuestionCount={totalQuestions}
      question={QuitSmokingRecency}
      onNext={onNext}
      onPrev={() => {
        onPrev();
        onResetAnswer(QuitSmokingRecency);
      }}
      explanation={{
        openText: <Trans.General.WhyAreWeAsking />,
        closeText: <Trans.General.OkIUnderstand />,
        explanationText: <Trans.Steps.SmokingExplanation />,
      }}
    >
      <ChoiceInput
        question={QuitSmokingRecency}
        answer={answer}
        onAnswer={(answer) => {
          onAnswer(QuitSmokingRecency, {
            answer,
            questionAnswer: {
              questionId: "smoking.quitSmokingAt",
              englishLabel,
              label,
              languageCode,
              answer: {
                $type: "stringenum",
                value: QuitSmokingRecency.getValue(answer) || "",
              },
            },
          });
          onNext();
        }}
      />
    </QuestionView>
  );
}

function CigaretteCountStep({
  answers,
  onAnswer,
  onNext,
  onPrev,
  currentQuestion,
  totalQuestions,
  onResetAnswer,
}: StepProps) {
  const answer = answers.get(DailyCigaretteCount);
  const label = TransQuestions.Smoking.DailyCigaretteCount();
  const { englishLabel } = TransQuestions.Smoking.DailyCigaretteCount;
  const languageCode = useQuestionnaireLanguageCode();

  return (
    <QuestionView
      currentQuestion={currentQuestion}
      totalQuestionCount={totalQuestions}
      question={DailyCigaretteCount}
      onNext={onNext}
      onPrev={() => {
        onPrev();
        onResetAnswer(DailyCigaretteCount);
      }}
      explanation={{
        openText: <Trans.General.WhyAreWeAsking />,
        closeText: <Trans.General.OkIUnderstand />,
        explanationText: <Trans.Steps.SmokingExplanation />,
      }}
    >
      <ChoiceInput
        question={DailyCigaretteCount}
        answer={answer}
        onAnswer={(answer) => {
          onAnswer(DailyCigaretteCount, {
            answer,
            questionAnswer: {
              questionId: "smoking.dailyCigarettes",
              englishLabel,
              label,
              languageCode,
              answer: {
                $type: "stringenum",
                value: DailyCigaretteCount.getValue(answer) || "",
              },
            },
          });
          onNext();
        }}
      />
    </QuestionView>
  );
}

export function filter(steps: Step[], answers: Answers) {
  steps.push(IsSmokerStep);

  const style = answers.lookup(IsSmoker);

  if (style === IsSmokingEnum.Yes) {
    steps.push(CigaretteCountStep);
  } else if (style === IsSmokingEnum.Quit) {
    steps.push(QuitSmokingRecencyStep);
  }
}
