import { useNav } from "@pomle/react-router-paths";
import {
  ValidatePhoneNumberLengthResult,
  parsePhoneNumber,
  validatePhoneNumberLength,
} from "libphonenumber-js";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { usePatientQuery } from "render/hooks/api/queries/usePatientQuery";
import {
  PhoneNumberTakenError,
  usePatientMutation,
} from "render/hooks/mutations/usePatientMutation";
import { useAsyncHandle } from "render/hooks/useAsyncHandle";
import { paths } from "render/routes/paths";
import { Input } from "render/ui/presentation/Input";
import { Typography } from "render/ui/presentation/Typography";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { EditPage } from "../EditPage/EditPage";
import { Trans } from "./Trans";
import styles from "./styles.module.sass";

function PhoneNumberError({
  error,
  showError,
}: {
  error: ValidatePhoneNumberLengthResult | PhoneNumberTakenError | undefined;
  showError: boolean;
}) {
  if (!error || !showError) {
    return;
  }
  if (error instanceof PhoneNumberTakenError) {
    return <Trans.Validation.Phone.DuplicatePhoneNumber />;
  }
  const record: Record<ValidatePhoneNumberLengthResult, React.ReactNode> = {
    INVALID_COUNTRY: <Trans.Validation.Phone.InvalidCountry />,
    NOT_A_NUMBER: <Trans.Validation.Phone.NotNumber />,
    TOO_SHORT: <Trans.Validation.Phone.TooShort />,
    TOO_LONG: <Trans.Validation.Phone.TooLong />,
    INVALID_LENGTH: <Trans.Validation.Phone.InvalidLength />,
  };
  return record[error];
}

export function EditPhoneNumberPage() {
  const patientQuery = usePatientQuery();
  const patientMutation = usePatientMutation();

  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errors, setErrors] = useState(
    new Map<string, { phoneNumber?: Error }>()
  );

  useEffect(() => {
    if (!patientQuery.data) {
      return;
    }

    const { contactDetails } = patientQuery.data;
    setPhoneNumber(contactDetails.phoneNumbers[0] ?? "");
  }, [patientQuery.data]);

  const nav = {
    profileCredentials: useNav(paths.profileCredentials),
  };

  const phoneValidError = useMemo(() => {
    return (
      errors.get(phoneNumber)?.phoneNumber ||
      validatePhoneNumberLength(phoneNumber)
    );
  }, [phoneNumber, errors]);

  const handleOnSave = useCallback(
    async (e: React.FormEvent<HTMLFormElement> | undefined = undefined) => {
      e?.preventDefault();
      setIsSubmitted(true);
      if (phoneValidError) {
        return;
      }

      return patientMutation({ phoneNumber })
        .then(() => patientQuery.refetch())
        .then(() => nav.profileCredentials.go({}))
        .catch((err) => {
          if (err instanceof PhoneNumberTakenError) {
            setErrors((map) => {
              return new Map(map.set(phoneNumber, { phoneNumber: err }));
            });
            return;
          }
        });
    },
    [
      nav.profileCredentials,
      patientMutation,
      patientQuery,
      phoneNumber,
      phoneValidError,
    ]
  );

  const onPhoneInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let newPhoneNumber = e.target.value;
      try {
        // we help filling out default country code if none is provided and strip redundant zeroes
        const parsedPhoneNumber = parsePhoneNumber(e.target.value, "SE");
        newPhoneNumber = parsedPhoneNumber.formatInternational();
      } catch (e) {
        // not yet valid so we do nothing
      }
      setPhoneNumber(newPhoneNumber);
    },
    []
  );
  const onSaveHandle = useAsyncHandle(handleOnSave);

  return (
    <EditPage
      title={<Trans.Title />}
      subTitle={<Trans.SubTitle />}
      onBack={nav.profileCredentials.on({})}
      cta={
        <ActionButton
          hideIcon
          variant="suggestion"
          onClick={onSaveHandle.callback}
        >
          <div className={styles.fullWidth}>
            <Typography variant="cta">
              {onSaveHandle.busy ? (
                <Trans.Actions.Saving />
              ) : (
                <Trans.Actions.Save />
              )}
            </Typography>
          </div>
        </ActionButton>
      }
    >
      <form className={styles.EditNamePage}>
        <Input
          autocomplete="tel"
          type="tel"
          name="phoneNumber"
          pattern="[0-9]*"
          inputmode="tel"
          label={<Trans.Label.Phone />}
          onChange={onPhoneInputChange}
          value={phoneNumber}
          onClear={() => {}}
          hideClearButton
          error={
            <PhoneNumberError error={phoneValidError} showError={isSubmitted} />
          }
        />
      </form>
    </EditPage>
  );
}
