import { useNav } from "@pomle/react-router-paths";
import { paths } from "render/routes/paths";
import { FullScreenPageLayout } from "render/ui/layout/FullScreenPageLayout";
import { LogoHeader } from "render/ui/layout/LogoHeader";
import { Typography } from "render/ui/presentation/Typography";
import { ActionButton } from "render/ui/trigger/ActionButton";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

export function PaymentRequiredView() {
  const nav = {
    payment: useNav(paths.checkout),
  };

  return (
    <FullScreenPageLayout>
      <div className={styles.PaymentRequiredView}>
        <LogoHeader />
        <div className={styles.body}>
          <div className={styles.content}>
            <div className={styles.description}>
              <Typography variant="title-large">
                <Trans.Header />
              </Typography>
              <Typography variant="description">
                <Trans.Description />
              </Typography>
            </div>
            <div className={styles.actions}>
              <ActionButton onClick={nav.payment.on({})}>
                <Trans.CallToAction />
              </ActionButton>
            </div>
          </div>
        </div>
      </div>
    </FullScreenPageLayout>
  );
}
