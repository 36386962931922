import { useMemo } from "react";
import { useDoctorProfileQuery } from "render/hooks/api/queries/useDoctorProfileQuery";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

interface DoctorCredentialsProps {
  doctorURI: string;
}

export function DoctorCredentials({ doctorURI }: DoctorCredentialsProps) {
  const doctorQuery = useDoctorProfileQuery({ doctorURI });
  const image = useMemo(() => {
    if (!doctorQuery.data?.photo) {
      return;
    }

    const image = new Image();
    image.src = URL.createObjectURL(doctorQuery.data.photo);
    return image.src;
  }, [doctorQuery.data?.photo]);

  if (doctorQuery.isFetching) {
    return <div className={styles.loading} />;
  }

  if (doctorQuery.error || !doctorQuery.data) {
    return null;
  }

  return (
    <div className={styles.DoctorCredentials}>
      <div>
        <div className={styles.name}>
          <Trans.Name name={doctorQuery.data.doctor.displayName} />
        </div>
        <div>
          <Trans.Subtitle />
        </div>
      </div>
      <img src={image} width="54px" height="54px" alt="doctor_image" />
    </div>
  );
}
