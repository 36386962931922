import { useNav } from "@pomle/react-router-paths";
import { useAppointmentQuery } from "render/hooks/api/queries/useAppointmentQuery";
import { paths } from "render/routes/paths";
import { FullScreenPageLayout } from "render/ui/layout/FullScreenPageLayout";
import { AppointmentInfoSection } from "./components/AppointmentInfoSection";

interface AppointmentViewProps {
  slotId: string;
}

export function AppointmentView({ slotId }: AppointmentViewProps) {
  const nav = {
    root: useNav(paths.root),
  };
  const appointmentQuery = useAppointmentQuery(
    { slotId },
    {
      onSuccess: (data) => {
        if (data == null) {
          nav.root.go({});
        }
      },
    }
  );

  return (
    <FullScreenPageLayout>
      {appointmentQuery.isFetched && appointmentQuery.data && (
        <AppointmentInfoSection slot={appointmentQuery.data} />
      )}
    </FullScreenPageLayout>
  );
}
