import styles from "./styles.module.sass";

interface IconButtonProps {
  icon: JSX.Element;
  onClick: () => void;
  ariaLabel?: string;
  disabled?: boolean;
}

export function IconButton({
  icon,
  onClick,
  ariaLabel,
  disabled,
}: IconButtonProps) {
  return (
    <button
      className={styles.IconButton}
      disabled={disabled}
      data-disabled={disabled}
      type="button"
      onClick={onClick}
      aria-label={ariaLabel}
    >
      {icon}
    </button>
  );
}
