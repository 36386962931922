import { APITypesV1 } from "@cur8/api-client";
import { useQuery } from "@tanstack/react-query";
import { APIClient } from "lib/api/client";
import { exhaust } from "lib/api/exhaust";
import { useAPIClient } from "render/context/APIContext";
import { useSession } from "render/context/MSALContext";
import { ExtractBody, ExtractKey, QueryOptions } from "typings/query";

export const queryKey = (patientId: string) => [
  "patient-consents/valid",
  patientId,
];
const queryFn = (apiClient: APIClient, patientId: string) => async () => {
  return exhaust((continuationToken) => {
    return apiClient.consent.getListOfConsents({
      continuationToken,
      patientId,
      validityState: APITypesV1.ConsentValidityState.WithinValidityRange,
    }).result;
  });
};

type Body = ExtractBody<typeof queryFn>;
type Key = ExtractKey<typeof queryKey>;

export function useValidPatientConsentsQuery<T = Body>(
  options: QueryOptions<Body, Key, T> = {}
) {
  const { patientId } = useSession();
  const apiClient = useAPIClient();

  return useQuery(queryKey(patientId), queryFn(apiClient, patientId), options);
}

useValidPatientConsentsQuery.queryKey = queryKey;
useValidPatientConsentsQuery.queryFn = queryFn;
