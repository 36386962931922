import { PropsWithChildren, forwardRef } from "react";
import styles from "./styles.module.sass";

interface FullScreenPageLayoutProps {
  disableBackground?: boolean;
}

export const FullScreenPageLayout = forwardRef<
  HTMLDivElement,
  PropsWithChildren<FullScreenPageLayoutProps>
>(({ children, disableBackground }, ref) => {
  return (
    <div
      ref={ref}
      style={{ background: disableBackground ? "transparent" : undefined }}
      className={styles.FullScreenPageLayout}
    >
      {children}
    </div>
  );
});
