import { localizeQuestion } from "lib/questionnaire/question-flows/onboarding/localizeQuestion";
import { localize } from "render/context/InternationalizationContext";

const AdditionalWorries = {
  Question: localizeQuestion({
    en_GB:
      "Is there something related to your health that you are worried about or would like us to address?",
    // TODO: update SE
    sv_SE: "Är det något du är orolig över?",
  }),
};

const Allergies = {
  Question: localizeQuestion({
    en_GB: "Do you have any allergies?",
    // TODO: update SE
    sv_SE: "Har du några allergier?",
  }),
  Yes: localize({
    en_GB: "Yes",
    sv_SE: "Ja",
  }),
  No: localize({
    en_GB: "No",
    sv_SE: "Nej",
  }),
  AllergiesListQuestion: localizeQuestion({
    en_GB: "What allergies do you have?",
    // TODO: update SE
    sv_SE: "Vad är du allergisk mot?",
  }),
  Dairy: localize({
    en_GB: <>Dairy</>,
    sv_SE: <>Mejeriprodukter</>,
  }),
  InsectSting: localize({
    en_GB: <>Insect Sting</>,
    sv_SE: <>Insektsbett</>,
  }),
  Latex: localize({
    en_GB: <>Latex</>,
    sv_SE: <>Latex</>,
  }),
  Morphine: localize({
    en_GB: <>Morphine</>,
    sv_SE: <>Morfin</>,
  }),
  Nuts: localize({
    en_GB: <>Nuts</>,
    sv_SE: <>Nötter</>,
  }),
  Pets: localize({
    en_GB: "Pets",
    // TODO: add SE
    sv_SE: "",
  }),
  Pollen: localize({
    en_GB: "Pollen",
    // TODO: add SE
    sv_SE: "",
  }),
  Wheat: localize({
    en_GB: <>Wheat</>,
    sv_SE: <>Vete</>,
  }),
  SomethingElse: localize({
    en_GB: "Something else",
    // TODO: add SE
    sv_SE: "",
  }),
  OtherAllergiesQuestion: localizeQuestion({
    en_GB: "Please tell us about your allergies",
    // TODO: add SE
    sv_SE: "",
  }),
};

const Choice = {
  LessThanSixMonths: localize({
    en_GB: "Less than 6 months ago",
    sv_SE: "Mindre än 6 månader sedan",
  }),
  SixMonths: localize({
    en_GB: "More than 6 months ago",
    sv_SE: "Mer än 6 månader sedan",
  }),
};

const Diet = {
  Question: localizeQuestion({
    en_GB: "What do your eating habits look like?",
    sv_SE: "Hur ser dina kostvanor ut?",
  }),
  Specific: localize({
    en_GB: <>To start, do you have any specific dietary habits?</>,
    sv_SE: <>Första frågan, har du några specifika kostvanor?</>,
  }),
  Vegan: localize({
    en_GB: <>Vegan</>,
    sv_SE: <>Vegan</>,
  }),
  Vegetarian: localize({
    en_GB: <>Vegetarian</>,
    sv_SE: <>Vegetarian</>,
  }),
  Pescatarian: localize({
    en_GB: <>Pescatarian</>,
    sv_SE: <>Pescatarian</>,
  }),
  Keto: localize({
    en_GB: <>Keto</>,
    sv_SE: <>Keto</>,
  }),
  Paleo: localize({
    en_GB: <>Paleo</>,
    sv_SE: <>Paleo</>,
  }),
  LowCarb: localize({
    en_GB: <>Low carb</>,
    sv_SE: <>Lågkalori</>,
  }),
  LowMeat: localize({
    en_GB: <>Low meat</>,
    sv_SE: <>Lågt kött intag</>,
  }),
  Other: localize({
    en_GB: <>Other</>,
    sv_SE: <>Annat</>,
  }),
  None: localize({
    en_GB: "None",
    sv_SE: "Nej",
  }),
  More: localize({
    en_GB: "More options",
    sv_SE: "Fler alternativ",
  }),
  LowCarbHighFat: localize({
    en_GB: "Low carb, High fat [LCHF]",
    sv_SE: "LCHF",
  }),
};

const Drinking = {
  Question: localizeQuestion({
    en_GB: "How many glasses of alcohol do you drink a week?",
    sv_SE: "Hur många glas dricker du i veckan?",
  }),
  DoYouDrinkAlcohol: localizeQuestion({
    en_GB: "Do you drink alcohol?",
    sv_SE: "Dricker du någon alkohol?",
  }),
  Yes: localize({
    en_GB: "Yes",
    sv_SE: "Ja",
  }),
  No: localize({
    en_GB: "No",
    sv_SE: "Nej",
  }),
  GlassesLessThanOne: localize({
    en_GB: "Less than 1 glass",
    sv_SE: "Mindre än ett glas",
  }),
  GlassesOneToTwo: localize({
    en_GB: "1-2 glasses",
    sv_SE: "1-2 glas",
  }),
  GlassesThreeToFour: localize({
    en_GB: "3-4 glasses",
    sv_SE: "3-4 glas",
  }),
  GlassesFiveToSix: localize({
    en_GB: "5-6 glasses",
    sv_SE: "5-6 glas",
  }),
  GlassesSevenToEight: localize({
    en_GB: "7-8 glasses",
    sv_SE: "7-8 glas",
  }),
  GlassesMoreThanNine: localize({
    en_GB: "More than 9 glasses",
    sv_SE: "Mer än 9 glas",
  }),
};

const HoursPerWeek = localize<{ hours: string }>({
  en_GB: ({ hours }) => `${hours} hours per week`,
  sv_SE: ({ hours }) => `${hours} timmar i veckan`,
});

const StrengthExercise = {
  Question: localizeQuestion({
    en_GB: "How much strength exercise do you get per week?",
    sv_SE: "Hur ofta styrketränar du per vecka?",
  }),
  MoreThanPerWeek: localize<{ qty: string }>({
    en_GB: ({ qty }) => `More than ${qty} hours`,
    sv_SE: ({ qty }) => `Mer än ${qty} timmar`,
  }),
  HoursPerWeek,
};

const CardioExercise = {
  Question: localizeQuestion({
    en_GB: "How much cardio exercise do you get per week?",
    sv_SE: "Hur ofta konditionstränar du per vecka?",
  }),
  MoreThanPerWeek: localize<{ qty: string }>({
    en_GB: ({ qty }) => `More than ${qty} hours`,
    sv_SE: ({ qty }) => `Mer än ${qty} timmar`,
  }),
  HoursPerWeek,
};

const FamilyConditions = {
  Question: localize({
    en_GB: "Do you have any diseases in the family?",
    sv_SE: "Har du några sjukdomar i släkten?",
  }),
};

const Heritage = {
  Question: localizeQuestion({
    en_GB: "What's your ethnicity?",
    // TODO: update SE
    sv_SE: "Vart kommer din familj från?",
  }),
};

const WorldRegion = {
  Europe: localize({
    en_GB: <>Europe</>,
    sv_SE: <>Europa</>,
  }),
  NorthAmerica: localize({
    en_GB: <>North America</>,
    sv_SE: <>Nordamerika</>,
  }),
  CentralAmerica: localize({
    en_GB: <>Central America</>,
    sv_SE: <>Centralamerika</>,
  }),
  SouthAmerica: localize({
    en_GB: <>South America</>,
    sv_SE: <>Sydamerika</>,
  }),
  NorthAsia: localize({
    en_GB: <>North Asia</>,
    sv_SE: <>Norra Asien</>,
  }),
  MiddleEast: localize({
    en_GB: <>Middle East</>,
    sv_SE: <>Mellanöstern</>,
  }),
  EastAsia: localize({
    en_GB: <>East Asia</>,
    sv_SE: <>Östra Asien</>,
  }),
  SouthAsia: localize({
    en_GB: <>South Asia</>,
    sv_SE: <>Södra Asien</>,
  }),
  SouthEastAsia: localize({
    en_GB: <>Southeast Asia</>,
    sv_SE: <>Sydöstra Asien</>,
  }),
  Oceania: localize({
    en_GB: <>Oceania</>,
    sv_SE: <>Oceanien</>,
  }),
  NorthAfrica: localize({
    en_GB: <>North Africa</>,
    sv_SE: <>Nordafrika</>,
  }),
  WestAfrica: localize({
    en_GB: <>West Africa</>,
    sv_SE: <>Västafrika</>,
  }),
  EastAfrica: localize({
    en_GB: <>East Africa</>,
    sv_SE: <>Östafrika</>,
  }),
  SouthAfrica: localize({
    en_GB: <>South Africa</>,
    sv_SE: <>Sydafrika</>,
  }),
};

const Medication = {
  Question: localizeQuestion({
    en_GB: "Do you take any medications?",
    sv_SE: "Tar du några mediciner?",
  }),
  Yes: localize({
    en_GB: "Yes",
    sv_SE: "Ja",
  }),
  No: localize({
    en_GB: "No",
    sv_SE: "Nej",
  }),
  TypeQuestion: localizeQuestion({
    en_GB: "What type of medications do you take?",
    // TODO: add SE
    sv_SE: "",
  }),
  AntiDiabetic: localize({
    en_GB: "Anti-diabetic",
    // TODO: add SE
    sv_SE: "",
  }),
  Cholesterol: localize({
    en_GB: "Cholesterol",
    // TODO: add SE
    sv_SE: "",
  }),
  BloodPressure: localize({
    en_GB: "Blood pressure",
    // TODO: add SE
    sv_SE: "",
  }),
  SomethingElse: localize({
    en_GB: "Something else",
    // TODO: add SE
    sv_SE: "",
  }),
  SomethingElseQuestion: localizeQuestion({
    en_GB: "What is the name and dosage of your medications?",
    // TODO: add SE
    sv_SE: "",
  }),
};

const PersonalConditions = {
  Question: localize({
    en_GB: "Have you had any health problems in the past year?",
    sv_SE: "Har du haft några problem med hälsan det senaste året?",
  }),
};

const Smoking = {
  Question: localizeQuestion({
    en_GB: "Do you smoke cigarettes?",
    sv_SE: "Röker du cigaretter?",
  }),
  Yes: localize({
    en_GB: "Yes",
    sv_SE: "Ja",
  }),
  No: localize({
    en_GB: "No",
    sv_SE: "Nej",
  }),
  Quit: localize({
    en_GB: "No, I quit",
    sv_SE: "Nej, jag har slutat",
  }),
  DailyCigaretteCount: localizeQuestion({
    en_GB: "How much do you smoke a day?",
    sv_SE: "Hur mycket röker du per dag?",
  }),
  CigaretteCountLessThanOne: localize({
    en_GB: "Less than 1 cigarette",
    sv_SE: "Mindre än en cigarett",
  }),
  CigaretteCountLessThanHalfAPack: localize({
    en_GB: "Less than half a pack",
    sv_SE: "Mindre än ett halvt paket",
  }),
  CigaretteCountHalfAPackOrMore: localize({
    en_GB: "Half a pack or more",
    sv_SE: "Ett halvt paket eller mer",
  }),
  CigaretteCountOnePackOrMore: localize({
    en_GB: "One pack or more",
    sv_SE: "Ett paket eller mer",
  }),
  QuitSmokingRecency: localizeQuestion({
    en_GB: "How long ago did you quit?",
    sv_SE: "Hur längesen slutade du?",
  }),
};

const Snus = {
  Question: localizeQuestion({
    en_GB: "Do you snus?",
    sv_SE: "Snusar du?",
  }),
  Yes: localize({
    en_GB: "Yes",
    sv_SE: "Ja",
  }),
  Quit: localize({
    en_GB: "No, I quit",
    sv_SE: "Nej, jag har slutat",
  }),
  No: localize({
    en_GB: "No",
    sv_SE: "Nej",
  }),
  HowOften: localizeQuestion({
    en_GB: "How often do you snus?",
    sv_SE: "Hur ofta snusar du?",
  }),
  QuitSnusingRecency: localizeQuestion({
    en_GB: "How long ago did you quit?",
    sv_SE: "Hur längesen slutade du?",
  }),
  SnusFrequencyAFewTimesAMonth: localize({
    en_GB: "A few times per month",
    sv_SE: "Några gånger per månad",
  }),
  SnusFrequencyOnWeekends: localize({
    en_GB: "On weekends",
    sv_SE: "Bara på helger",
  }),
  SnusFrequencyEveryOtherDay: localize({
    en_GB: "Every other day",
    sv_SE: "Varannan dag",
  }),
  SnusFrequencyEveryDay: localize({
    en_GB: "Every day",
    sv_SE: "Varje dag",
  }),
};

const Sunburn = {
  Question: localizeQuestion({
    en_GB: "Have you been sunburnt in the past year?",
    sv_SE: "Har du bränt dig i solen senaste året?",
  }),
  No: localize({
    en_GB: "No",
    sv_SE: "Nej",
  }),
  FrequencyOneToTwo: localize({
    en_GB: "1-2 times",
    sv_SE: "1-2 gånger",
  }),
  FrequencyThreeToFive: localize({
    en_GB: "3-5 times",
    sv_SE: "3-5 gånger",
  }),
  FrequencySixToEight: localize({
    en_GB: "6-10 times",
    sv_SE: "6-10 gånger",
  }),
  FrequencyMoreThanTen: localize({
    en_GB: "More than 10 times",
    sv_SE: "Mer än 10 gånger",
  }),
};

const Cardiovascular = {
  Question: localizeQuestion({
    en_GB: "Has anyone in your family had a cardiovascular disease?",
    // TODO: add SE
    sv_SE: "",
  }),
  Yes: localize({
    en_GB: "Yes",
    sv_SE: "Ja",
  }),
  No: localize({
    en_GB: "No",
    sv_SE: "Nej",
  }),
  Details: localizeQuestion({
    en_GB: "Who and what type of cardiovascular disease?",
    // TODO: add SE
    sv_SE: "",
  }),
};

const SkinCancer = {
  Question: localizeQuestion({
    en_GB: "Has anyone in your family had skin cancer?",
    // TODO: add SE
    sv_SE: "",
  }),
  Yes: localize({
    en_GB: "Yes",
    sv_SE: "Ja",
  }),
  No: localize({
    en_GB: "No",
    sv_SE: "Nej",
  }),
  NotSure: localize({
    en_GB: "I am not sure",
    // TODO: add SE
    sv_SE: "",
  }),
  MelanomaQuestion: localizeQuestion({
    en_GB: "Was your relative diagnosed with melanoma?",
    // TODO: add SE
    sv_SE: "",
  }),
  MelanomaWhoQuestion: localizeQuestion({
    en_GB: "Who was diagnosed with melanoma?",
    // TODO: add SE
    sv_SE: "",
  }),
  Child: localize({
    en_GB: "Child",
    // TODO: add SE
    sv_SE: "",
  }),
  Parent: localize({
    en_GB: "Parent",
    // TODO: add SE
    sv_SE: "",
  }),
  Sibling: localize({
    en_GB: "Sibling",
    // TODO: add SE
    sv_SE: "",
  }),
  OtherRelative: localize({
    en_GB: "Other relative",
    // TODO: add SE
    sv_SE: "",
  }),
};

const Diabetes = {
  Question: localizeQuestion({
    en_GB: "Has anyone in your family had diabetes",
    // TODO: add SE
    sv_SE: "",
  }),
  Yes: localize({
    en_GB: "Yes",
    sv_SE: "Ja",
  }),
  No: localize({
    en_GB: "No",
    sv_SE: "Nej",
  }),
  Details: localizeQuestion({
    en_GB: "Who and what type of diabetes?",
    // TODO: add SE
    sv_SE: "",
  }),
};

const RelevantConditions = {
  Question: localizeQuestion({
    en_GB: "Does your family have a history of other conditions?",
    // TODO: add SE
    sv_SE: "",
  }),
  Autoimmune: localize({
    en_GB: "Autoimmune disease",
    // TODO: add SE
    sv_SE: "",
  }),
  Cancer: localize({
    en_GB: "Cancer",
    // TODO: add SE
    sv_SE: "",
  }),
  Neurological: localize({
    en_GB: "Neurological disease",
    // TODO: add SE
    sv_SE: "",
  }),
  SomethingElse: localize({
    en_GB: "Something else",
    // TODO: add SE
    sv_SE: "",
  }),
  AutoimmuneDetails: localizeQuestion({
    en_GB: "Who and which autoimmune disease?",
    // TODO: add SE
    sv_SE: "",
  }),
  CancerDetails: localizeQuestion({
    en_GB: "Who and which cancer?",
    // TODO: add SE
    sv_SE: "",
  }),
  NeurologicalDetails: localizeQuestion({
    en_GB: "Who and which neurological disease?",
    // TODO: add SE
    sv_SE: "",
  }),
  SomethingElseDetails: localizeQuestion({
    en_GB: "Who and which health conditions?",
    // TODO: add SE
    sv_SE: "",
  }),
};

const Pregnant = {
  Question: localizeQuestion({
    en_GB: "Are you pregnant?",
    // TODO: add SE
    sv_SE: "",
  }),
  Yes: localize({
    en_GB: "Yes",
    sv_SE: "Ja",
  }),
  No: localize({
    en_GB: "No",
    sv_SE: "Nej",
  }),
};

const SkinConditions = {
  Question: localizeQuestion({
    en_GB: "Do you have a skin condition?",
    // TODO: add SE
    sv_SE: "",
  }),
  Yes: localize({
    en_GB: "Yes",
    sv_SE: "Ja",
  }),
  No: localize({
    en_GB: "No",
    sv_SE: "Nej",
  }),
  TypeQuestion: localizeQuestion({
    en_GB: "Which skin condition were you diagnosed with?",
    // TODO: add SE
    sv_SE: "",
  }),
  Acne: localize({
    en_GB: "Acne",
    // TODO: add SE
    sv_SE: "",
  }),
  AcneDescription: localize({
    en_GB: "This condition occurs when hair follicles in the skin are clogged.",
    // TODO: add SE
    sv_SE: "",
  }),
  Eczema: localize({
    en_GB: "Eczema",
    // TODO: add SE
    sv_SE: "",
  }),
  EczemaDescription: localize({
    en_GB: "This condition causes dry, itchy and inflamed skin.",
    // TODO: add SE
    sv_SE: "",
  }),
  Psoriasis: localize({
    en_GB: "Psoriasis",
    // TODO: add SE
    sv_SE: "",
  }),
  PsoriasisDescription: localize({
    en_GB:
      "This condition causes itchy, scaly patches, usually on knees and elbows.",
    // TODO: add SE
    sv_SE: "",
  }),
  Rosacea: localize({
    en_GB: "Rosacea",
    // TODO: add SE
    sv_SE: "",
  }),
  RosaceaDescription: localize({
    en_GB: "This condition causes long-term redness and bumps on the face.",
    // TODO: add SE
    sv_SE: "",
  }),
  SkinCancer: localize({
    en_GB: "Skin cancer",
    // TODO: add SE
    sv_SE: "",
  }),
  SkinCancerDescription: localize({
    en_GB:
      "This condition occurs when abnormal cells in the skin grow out of control.",
    // TODO: add SE
    sv_SE: "",
  }),
  SomethingElse: localize({
    en_GB: "Something else",
    // TODO: add SE
    sv_SE: "",
  }),
  SkinCancerQuestion: localizeQuestion({
    en_GB: "What type of skin cancer?",
    // TODO: add SE
    sv_SE: "",
  }),
  ActinicKeratosis: localize({
    en_GB: "Actinic Keratosis",
    // TODO: add SE
    sv_SE: "",
  }),
  ActinicKeratosisDescription: localize({
    en_GB:
      "This is a pre-cancerous condition where scaly or crusty bumps form.",
    // TODO: add SE
    sv_SE: "",
  }),
  BCC: localize({
    en_GB: "Basal Cell Cercinoma / Basalioma (BCC)",
    // TODO: add SE
    sv_SE: "",
  }),
  BCCDescription: localize({
    en_GB: "This type of skin cancer starts in the basal cell layer.",
    // TODO: add SE
    sv_SE: "",
  }),
  Melanoma: localize({
    en_GB: "Melanoma",
    // TODO: add SE
    sv_SE: "",
  }),
  MelanomaDescription: localize({
    en_GB:
      "This type of skin cancer starts in the pigment-making cells of the skin.",
    // TODO: add SE
    sv_SE: "",
  }),
  SCC: localize({
    en_GB: "Squamous Cell Carcinoma (SCC)",
    // TODO: add SE
    sv_SE: "",
  }),
  SCCDescription: localize({
    en_GB: "This type of skin cancer starts in the squamous cell layer.",
    // TODO: add SE
    sv_SE: "",
  }),
  NotSure: localize({
    en_GB: "I am not sure",
    // TODO: add SE
    sv_SE: "",
  }),
};

const MedicalHistoryDiabetes = {
  Question: localizeQuestion({
    en_GB: "Do you have diabetes?",
    // TODO: add SE
    sv_SE: "",
  }),
  Yes: localize({
    en_GB: "Yes",
    sv_SE: "Ja",
  }),
  No: localize({
    en_GB: "No",
    sv_SE: "Nej",
  }),
  TypeQuestion: localizeQuestion({
    en_GB: "Which type were you diagnosed with?",
    // TODO: add SE
    sv_SE: "",
  }),
  TypeOne: localize({
    en_GB: "Type 1 diabetes",
    // TODO: add SE
    sv_SE: "",
  }),
  TypeTwo: localize({
    en_GB: "Type 2 diabetes",
    // TODO: add SE
    sv_SE: "",
  }),
  TypeOneDescription: localize({
    en_GB:
      "This occurs when the body cannot produce insulin due to a problem in the immune system.",
    // TODO: add SE
    sv_SE: "",
  }),
  TypeTwoDescription: localize({
    en_GB:
      "This occurs when the body is resistant to insulin due to hereditary or lifestyle factors.",
    // TODO: add SE
    sv_SE: "",
  }),
  ComplicationsQuestion: localizeQuestion({
    en_GB: "Were you diagnosed with any complications from diabetes?",
    // TODO: add SE
    sv_SE: "",
  }),
  EyeProblems: localize({
    en_GB: "Eye problems (Retinopathy)",
    // TODO: add SE
    sv_SE: "",
  }),
  FootProblems: localize({
    en_GB: "Foot problems (Neuropathy)",
    // TODO: add SE
    sv_SE: "",
  }),
  HeartProblems: localize({
    en_GB: "Heart or vessel problems (Atherosclerosis)",
    // TODO: add SE
    sv_SE: "",
  }),
  KidneyProblems: localize({
    en_GB: "Kidney problems (Nephropathy)",
    // TODO: add SE
    sv_SE: "",
  }),
  EyeProblemsDescription: localize({
    en_GB: "This is when there is vision loss and potential blindness.",
    // TODO: add SE
    sv_SE: "",
  }),
  FootProblemsDescription: localize({
    en_GB:
      "This is when the nerves are damaged, causing pain or numbness in the feet.",
    // TODO: add SE
    sv_SE: "",
  }),
  HeartProblemsDescription: localize({
    en_GB:
      "This is when the arteries harden, thus decreasing blood flow to the heart.",
    // TODO: add SE
    sv_SE: "",
  }),
  KidneyProblemsDescription: localize({
    en_GB:
      "This is when the kidneys lose their function, and potential kidney failure.",
    // TODO: add SE
    sv_SE: "",
  }),
};

const BloodPressure = {
  Question: localizeQuestion({
    en_GB: "Have you been diagnosed with high blood pressure?",
    // TODO: add SE
    sv_SE: "",
  }),
  Yes: localize({
    en_GB: "Yes",
    sv_SE: "Ja",
  }),
  No: localize({
    en_GB: "No",
    sv_SE: "Nej",
  }),
};

const CardioConditions = {
  Question: localizeQuestion({
    en_GB: "Have you ever had any of these cardiovascular conditions?",
    // TODO: add SE
    sv_SE: "",
  }),
  AtrialFibrillation: localize({
    en_GB: "Atrial fibrillation",
    // TODO: add SE
    sv_SE: "",
  }),
  CongestiveHeartFailure: localize({
    en_GB: "Congestive heart failure",
    // TODO: add SE
    sv_SE: "",
  }),
  FamilialHypercholesterolemia: localize({
    en_GB: "Familial hypercholesterolemia",
    // TODO: add SE
    sv_SE: "",
  }),
  HeartAttack: localize({
    en_GB: "Heart attack",
    // TODO: add SE
    sv_SE: "",
  }),
  Stroke: localize({
    en_GB: "Stroke",
    // TODO: add SE
    sv_SE: "",
  }),
  ValvularDisease: localize({
    en_GB: "Valvular disease",
    // TODO: add SE
    sv_SE: "",
  }),
  AtrialFibrillationDescription: localize({
    en_GB:
      "Irregular and rapid heart rhythms that causes the heart to beat abnormally.",
    // TODO: add SE
    sv_SE: "",
  }),
  CongestiveHeartFailureDescription: localize({
    en_GB:
      "This is when the heart can't supply enough blood for the body's needs.",
    // TODO: add SE
    sv_SE: "",
  }),
  FamilialHypercholesterolemiaDescription: localize({
    en_GB:
      "A genetic condition which causes very high cholesterol levels in the blood.",
    // TODO: add SE
    sv_SE: "",
  }),
  HeartAttackDescription: localize({
    en_GB:
      "Blood flow to the heart is suddenly blocked, damaging the heart muscle.",
    // TODO: add SE
    sv_SE: "",
  }),
  StrokeDescription: localize({
    en_GB:
      "Blood flow to the brain is blocked, or there is sudden bleeding in the brain.",
    // TODO: add SE
    sv_SE: "",
  }),
  ValvularDiseaseDescription: localize({
    en_GB:
      "A condition where any of the heart’s  valves do not open or close properly. ",
    // TODO: add SE
    sv_SE: "",
  }),
  ValvularDiseaseQuestion: localizeQuestion({
    en_GB: "What type of valvular disease?",
    // TODO: add SE
    sv_SE: "",
  }),
  AorticValveInsufficiency: localize({
    en_GB: "Aortic valve insufficiency",
    // TODO: add SE
    sv_SE: "",
  }),
  AorticValveStenosis: localize({
    en_GB: "Aortic valve stenosis",
    // TODO: add SE
    sv_SE: "",
  }),
  MitralValveInsufficiency: localize({
    en_GB: "Mitral valve insufficiency",
    // TODO: add SE
    sv_SE: "",
  }),
  MitralValveStenosis: localize({
    en_GB: "Mitral valve stenosis",
    // TODO: add SE
    sv_SE: "",
  }),
  NotSure: localize({
    en_GB: "I am not sure",
    // TODO: add SE
    sv_SE: "",
  }),
  AorticValveInsufficiencyDescription: localize({
    en_GB:
      "Happens when the aortic valve does not close properly, leaking blood back.",
    // TODO: add SE
    sv_SE: "",
  }),
  AorticValveStenosisDescription: localize({
    en_GB:
      "Happens when the aortic valve does not close properly, leaking blood back.",
    // TODO: add SE
    sv_SE: "",
  }),
  MitralValveInsufficiencyDescription: localize({
    en_GB: "A condition where the mitral valve does not work properly.",
    // TODO: add SE
    sv_SE: "",
  }),
  MitralValveStenosisDescription: localize({
    en_GB:
      "Happens when the mitral valve does not close properly, leaking blood back.",
    // TODO: add SE
    sv_SE: "",
  }),
};

const HealthConditions = {
  Question: localizeQuestion({
    en_GB: "Have you ever had any of these conditions?",
    // TODO: add SE
    sv_SE: "",
  }),
  Arthritis: localize({
    en_GB: "Arthritis",
    // TODO: add SE
    sv_SE: "",
  }),
  KidneyDisease: localize({
    en_GB: "Kidney disease",
    // TODO: add SE
    sv_SE: "",
  }),
  PeripheralVascularDisease: localize({
    en_GB: "Peripheral vascular disease",
    // TODO: add SE
    sv_SE: "",
  }),
  RaynaudsSyndrome: localize({
    en_GB: "Raynaud’s syndrome",
    // TODO: add SE
    sv_SE: "",
  }),
  No: localize({
    en_GB: "No, none of the above",
    // TODO: add SE
    sv_SE: "",
  }),
  ArthritisDescription: localize({
    en_GB: "This is also known as joint pain or joint disease.",
    // TODO: add SE
    sv_SE: "",
  }),
  KidneyDiseaseDescription: localize({
    en_GB:
      "A condition where the kidneys are damaged and cannot filter blood well.",
    // TODO: add SE
    sv_SE: "",
  }),
  PeripheralVascularDiseaseDescription: localize({
    en_GB:
      "Blood flow to the limbs is reduced due to blockages in the blood vessels.",
    // TODO: add SE
    sv_SE: "",
  }),
  RaynaudsSyndromeDescription: localize({
    en_GB:
      "Causes fingers and toes to temporarily turn white, usually due to the cold.",
    // TODO: add SE
    sv_SE: "",
  }),
};

const OtherHealthConditions = {
  Question: localizeQuestion({
    en_GB: "Any other diagnoses or health conditions?",
    // TODO: add SE
    sv_SE: "",
  }),
};

const HealthProblems = {
  Question: localizeQuestion({
    en_GB: "Have you had any health problems in the past year?",
    sv_SE: "Har du haft några problem med hälsan det senaste året?",
  }),
};

export const Trans = {
  AdditionalWorries,
  Allergies,
  Choice,
  Diet,
  Drinking,
  StrengthExercise,
  CardioExercise,
  FamilyConditions,
  Heritage,
  Medication,
  PersonalConditions,
  Smoking,
  Snus,
  WorldRegion,
  Sunburn,
  Cardiovascular,
  SkinCancer,
  Diabetes,
  RelevantConditions,
  Pregnant,
  SkinConditions,
  MedicalHistoryDiabetes,
  BloodPressure,
  CardioConditions,
  HealthConditions,
  OtherHealthConditions,
  HealthProblems,
};
