import { useNav } from "@pomle/react-router-paths";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { usePatientQuery } from "render/hooks/api/queries/usePatientQuery";
import { usePatientMutation } from "render/hooks/mutations/usePatientMutation";
import { useAsyncHandle } from "render/hooks/useAsyncHandle";
import { paths } from "render/routes/paths";
import { Input } from "render/ui/presentation/Input";
import { Typography } from "render/ui/presentation/Typography";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { EditPage } from "../EditPage/EditPage";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

interface FormFields {
  firstName: ReactNode;
  lastName: ReactNode;
  displayName: ReactNode;
}

export function EditNamePage() {
  const patientQuery = usePatientQuery();
  const patientMutation = usePatientMutation();

  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [displayName, setDisplayName] = useState<string>("");

  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    if (!patientQuery.data) {
      return;
    }

    const { name } = patientQuery.data;

    setFirstName(name?.firstName ?? "");
    setLastName(name?.lastName ?? "");
    setDisplayName(name?.displayName ?? "");
  }, [patientQuery.data]);

  const nav = {
    profileCredentials: useNav(paths.profileCredentials),
  };

  const handleOnChange = useCallback(
    (handler: (value: string) => void) =>
      (evt: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = evt.target;
        handler(value);
      },
    []
  );

  const errors = useMemo<Partial<FormFields>>(() => {
    const errors: Partial<FormFields> = {};

    if (firstName.trim().length === 0) {
      errors.firstName = <Trans.Validation.FirstName.Required />;
    }

    if (lastName.trim().length === 0) {
      errors.lastName = <Trans.Validation.LastName.Required />;
    }

    if (displayName.trim().length === 0) {
      errors.displayName = <Trans.Validation.DisplayName.Required />;
    }

    return errors;
  }, [displayName, firstName, lastName]);

  const handleOnSave = useCallback(
    async (e: React.FormEvent<HTMLFormElement> | undefined = undefined) => {
      if (e) {
        e.preventDefault();
      }
      setIsSubmitted(true);
      if (Object.keys(errors).length > 0) {
        return;
      }

      await patientMutation({
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        displayName: displayName.trim(),
      });

      await patientQuery.refetch();
      nav.profileCredentials.go({});
    },
    [
      displayName,
      errors,
      firstName,
      lastName,
      nav.profileCredentials,
      patientMutation,
      patientQuery,
    ]
  );

  const onSaveHandle = useAsyncHandle(handleOnSave);

  const renderError = useCallback(
    (error: ReactNode | undefined) => {
      return !isSubmitted || !error ? undefined : error;
    },
    [isSubmitted]
  );

  return (
    <EditPage
      title={<Trans.Title />}
      onBack={nav.profileCredentials.on({})}
      cta={
        <ActionButton
          hideIcon
          variant="suggestion"
          onClick={onSaveHandle.callback}
        >
          <div className={styles.fullWidth}>
            <Typography variant="cta">
              {onSaveHandle.busy ? (
                <Trans.Actions.Saving />
              ) : (
                <Trans.Actions.Save />
              )}
            </Typography>
          </div>
        </ActionButton>
      }
    >
      <form className={styles.EditNamePage}>
        <Input
          label={<Trans.Label.DisplayName />}
          onChange={handleOnChange(setDisplayName)}
          value={displayName}
          onClear={() => {}}
          hideClearButton
          error={renderError(errors.displayName)}
        />
        <Input
          label={<Trans.Label.FirstName />}
          onChange={handleOnChange(setFirstName)}
          value={firstName}
          onClear={() => {}}
          error={renderError(errors.firstName)}
          hideClearButton
        />
        <Input
          label={<Trans.Label.LastName />}
          onChange={handleOnChange(setLastName)}
          value={lastName}
          onClear={() => {}}
          error={renderError(errors.lastName)}
          hideClearButton
        />
      </form>
    </EditPage>
  );
}
