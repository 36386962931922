import { ReactComponent as TickIcon } from "../assets/tick.svg";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

export function CheckInBadge() {
  return (
    <div className={styles.CheckInBadge}>
      <TickIcon />
      <span className={styles.text}>
        <Trans.CheckedIn />
      </span>
    </div>
  );
}
