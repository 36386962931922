import { useEffect, useRef } from "react";
import styles from "./styles.module.sass";

interface AutocompleteProps {
  value: string;
  onChange: (value: string) => void;
  options?: string[];
  placeholder?: string;
}

export function Autocomplete({
  value,
  onChange,
  placeholder,
}: AutocompleteProps) {
  const ref = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (!ref.current) {
      return;
    }
    ref.current.focus();
  }, []);

  return (
    <div className={styles.AutocompleteInput}>
      <input
        placeholder={placeholder}
        ref={ref}
        className={styles.input}
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      />
    </div>
  );
}
