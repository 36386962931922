import { EyePressure } from "@cur8/health-risks-calc";
import { Metric } from "lib/api/metrics";
import { getHighestSide } from "render/views/Report/components/MetricsSection/components/metrics/utils/getHighestSide";

interface GetBySideProps {
  left?: Metric<"risk_assessment.eye_pressure.left">;
  right?: Metric<"risk_assessment.eye_pressure.right">;
}

export function pickHighestSide({
  left: leftEyePressure,
  right: rightEyePressure,
}: GetBySideProps) {
  const highestEyePressureSide = getHighestSide({
    left: leftEyePressure?.unit.mmHg,
    right: rightEyePressure?.unit.mmHg,
  });

  return {
    eyePressure:
      highestEyePressureSide === "left" ? leftEyePressure : rightEyePressure,
    side: highestEyePressureSide,
  };
}

interface GetRiskForSideProps {
  side: "left" | "right";
  leftEyePressure?: Metric<"risk_assessment.eye_pressure.left">;
  rightEyePressure?: Metric<"risk_assessment.eye_pressure.right">;
}

export function getRiskForSide({
  side,
  leftEyePressure,
  rightEyePressure,
}: GetRiskForSideProps) {
  const riskRanges = EyePressure.rangesFor();
  if (side === "left") {
    if (leftEyePressure) {
      return riskRanges.findRisk(leftEyePressure.unit);
    }

    return undefined;
  }

  if (side === "right") {
    if (rightEyePressure) {
      return riskRanges.findRisk(rightEyePressure.unit);
    }

    return undefined;
  }

  return undefined;
}
