import { localize } from "render/context/InternationalizationContext";

export const Trans = {
  RemoveConsentError: localize({
    en_GB: <>An error happen while removing your consent</>,
    sv_SE: <>Ett fel inträffade när vi försökte återkalla ditt samtycke</>,
  }),
  ConsentError: localize({
    en_GB: <>An error happened while consenting</>,

    sv_SE: <>Ett fel inträffade när du försökte samtycka</>,
  }),
  Arrow: localize({
    en_GB: "back",
    sv_SE: "tillbaka",
  }),
  ErrorWhileDownloading: localize({
    en_GB: "An error happened while downloading the document",
    sv_SE: "Ett fel inträffade när vi försökte ladda ner dokumentet",
  }),
};
