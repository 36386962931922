import { PathLink } from "@pomle/react-router-paths";
import { useHasAttendedVisitInSweden } from "render/hooks/api/queries/useVisitsQuery";
import { paths } from "render/routes/paths";
import { useSkinLesionCountMetric } from "render/views/Report/components/MetricsSection/hooks/usePatientReportMetrics";
import { Metric, MetricLoading } from "../../layouts/Metric";
import { Trans } from "./trans";

export function MetricSkinLesions() {
  const { data, isLoading: isDataLoading } = useSkinLesionCountMetric();
  const { data: hasAttendedVisitInSweden } = useHasAttendedVisitInSweden();

  if (isDataLoading) {
    return <MetricLoading label={<Trans.MetricName />} isClinicalStudy />;
  }

  if (data == null) {
    return null;
  }

  const isClinicalStudy = hasAttendedVisitInSweden === true;

  return (
    <PathLink to={paths.skinMetric.url({})}>
      <Metric
        label={<Trans.MetricName />}
        metricRating={undefined}
        value={data}
        isClinicalStudy={isClinicalStudy}
        previousValue={undefined}
      />
    </PathLink>
  );
}
