import { ReactComponent as CrossIcon } from "assets/icons/ex/ex-28x28.svg";
import { PropsWithChildren } from "react";
import { useSwipeable } from "react-swipeable";
import { IconButton } from "render/ui/trigger/IconButton";
import styles from "./styles.module.sass";

interface PopupLayoutProps {
  onClose: () => void;
  header: React.ReactElement;
}

export function PopUpLayout({
  children,
  header,
  onClose,
}: PropsWithChildren<PopupLayoutProps>) {
  const handlers = useSwipeable({
    onSwipedDown: () => {
      onClose();
    },
    preventScrollOnSwipe: true,
  });

  return (
    <div {...handlers} className={styles.PopUpLayout}>
      <div className={styles.content}>
        <div className={styles.closeDrag}></div>
        <div className={styles.header}>
          <div>{header}</div>
          <div className={styles.closeButton}>
            <IconButton icon={<CrossIcon />} onClick={onClose} />
          </div>
        </div>
        <div>{children}</div>
      </div>
    </div>
  );
}
