import PointCloudVideoWideMP4 from "assets/video/pointcloud/background-pointcloud-wide.mp4";
import PointCloudImageWide from "assets/video/pointcloud/background-pointcloud-wide.png";
import PointCloudVideoWideWebM from "assets/video/pointcloud/background-pointcloud-wide.webm";
import PointCloudVideoMP4 from "assets/video/pointcloud/background-pointcloud.mp4";
import PointCloudImage from "assets/video/pointcloud/background-pointcloud.png";
import PointCloudVideoWebm from "assets/video/pointcloud/background-pointcloud.webm";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styles from "./styles.module.sass";

type ScreenVariant = "desktop" | "mobile";

export function VideoBackground() {
  const [opacity, setOpacity] = useState(0);
  const didRender = useRef(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const getVariant = useCallback(() => {
    return window.innerWidth > 980 ? "desktop" : "mobile";
  }, []);
  const [variant, setVariant] = useState<ScreenVariant>(getVariant());

  const sources = useMemo(() => {
    switch (variant) {
      case "mobile":
        return {
          poster: PointCloudImage,
          mp4: PointCloudVideoMP4,
          webm: PointCloudVideoWebm,
        };
      case "desktop":
        return {
          poster: PointCloudImageWide,
          mp4: PointCloudVideoWideMP4,
          webm: PointCloudVideoWideWebM,
        };
    }
  }, [variant]);

  useEffect(() => {
    const callback = () => {
      setVariant(getVariant());
    };
    window.addEventListener("resize", callback);

    return () => window.removeEventListener("resize", callback);
  }, [setVariant, getVariant]);

  useEffect(() => {
    const video = videoRef.current;

    if (!didRender.current) {
      didRender.current = true;
      return;
    }
    if (!video) {
      return;
    }

    // Check if browser supports the video format before playing
    const canPlayWebm =
      video.canPlayType("video/webm") === "probably" ||
      video.canPlayType("video/webm") === "maybe";
    const canPlayMp4 =
      video.canPlayType("video/mp4") === "probably" ||
      video.canPlayType("video/mp4") === "maybe";

    // Choose the appropriate video format based on browser support
    const videoSrc = canPlayWebm ? sources.webm : canPlayMp4 ? sources.mp4 : "";

    if (videoSrc) {
      video.pause();
      video.src = videoSrc;
      video.load();
      video.play();
    }
  }, [variant, sources]);

  return (
    <>
      <img
        className={styles.videoBackgroundImage}
        src={sources.poster}
        alt="background"
      />
      <video
        style={{
          opacity,
        }}
        onPlay={() => {
          setOpacity(1);
        }}
        poster={sources.poster}
        ref={videoRef}
        className={styles.videoBackground}
        preload="auto"
        autoPlay
        loop
        muted
        playsInline
      >
        <source src={sources.webm} type="video/webm" />
        <source src={sources.mp4} type="video/mp4" />
      </video>
    </>
  );
}
