import { Answers } from "@cur8/questionnaire";
import { useQuestionnaireLanguageCode } from "lib/questionnaire/locale";
import {
  Diet,
  DietOptions,
  OtherDietOption,
} from "lib/questionnaire/question-flows/onboarding/questions/diet";
import { Trans as TransQuestions } from "lib/questionnaire/question-flows/onboarding/questions/trans";
import { ChoiceInput } from "render/ui/presentation/Questionnaire/components/ChoiceInput";
import { TextInput } from "render/ui/presentation/Questionnaire/components/TextInput";
import { QuestionView } from "../components/QuestionView";
import { Trans } from "../trans";
import { Step, StepProps } from "../types";

function DietTakenStep({
  answers,
  onAnswer,
  onNext,
  onPrev,
  currentQuestion,
  totalQuestions,
  onResetAnswer,
}: StepProps) {
  const answer = answers.get(DietOptions);
  const label = TransQuestions.Diet.Question();
  const { englishLabel } = TransQuestions.Diet.Question;
  const languageCode = useQuestionnaireLanguageCode();

  return (
    <QuestionView
      currentQuestion={currentQuestion}
      totalQuestionCount={totalQuestions}
      question={DietOptions}
      answer={answer}
      onNext={() => {
        onResetAnswer(DietOptions);
        onNext();
      }}
      onPrev={() => {
        onPrev();
        onResetAnswer(DietOptions);
      }}
      cta={<Trans.General.NoNextQuestion />}
      explanation={{
        openText: <Trans.General.WhyAreWeAsking />,
        closeText: <Trans.General.OkIUnderstand />,
        explanationText: <Trans.Steps.DietExplanation />,
      }}
    >
      <ChoiceInput
        question={DietOptions}
        answer={answer}
        onAnswer={(answer) => {
          onAnswer(DietOptions, {
            answer,
            questionAnswer: {
              questionId: "diet",
              englishLabel,
              label,
              languageCode,
              answer: {
                $type: "text",
                value: DietOptions.choices[answer.response.choiceIndex].value,
              },
            },
          });
          onNext();
        }}
      />
    </QuestionView>
  );
}

function OtherDietTakenStep({
  answers,
  onAnswer,
  onNext,
  onPrev,
  currentQuestion,
  totalQuestions,
  onResetAnswer,
}: StepProps) {
  const answer = answers.get(OtherDietOption);
  const label = TransQuestions.Diet.Question();
  const { englishLabel } = TransQuestions.Diet.Question;
  const languageCode = useQuestionnaireLanguageCode();

  return (
    <QuestionView
      currentQuestion={currentQuestion}
      totalQuestionCount={totalQuestions}
      question={OtherDietOption}
      answer={answer}
      onNext={onNext}
      onPrev={() => {
        onPrev();
        onResetAnswer(OtherDietOption);
      }}
      cta={<Trans.General.NextQuestion />}
    >
      <TextInput
        placeholder={Trans.General.WriteYourAnswer()}
        answer={answer}
        onAnswer={(answer) => {
          onAnswer(OtherDietOption, {
            answer,
            questionAnswer: {
              questionId: "diet",
              englishLabel,
              label,
              languageCode,
              answer: {
                $type: "text",
                value: answer.response.content,
              },
            },
          });
        }}
      />
    </QuestionView>
  );
}

export function filter(steps: Step[], answers: Answers) {
  steps.push(DietTakenStep);

  if (answers.lookup(DietOptions) === Diet.Other) {
    steps.push(OtherDietTakenStep);
  }
}
