import { Answers, isMultiChoiceResponse } from "@cur8/questionnaire";
import { useQuestionnaireLanguageCode } from "lib/questionnaire/locale";
import {
  DoYouDrinkAlcohol,
  DrinkCount,
} from "lib/questionnaire/question-flows/onboarding/questions/drinking";
import { Trans as TransQuestions } from "lib/questionnaire/question-flows/onboarding/questions/trans";
import { ChoiceInput } from "render/ui/presentation/Questionnaire/components/ChoiceInput";
import { MultiChoiceInput } from "render/ui/presentation/Questionnaire/components/MultiChoiceInput";
import { QuestionView } from "../components/QuestionView";
import { Trans } from "../trans";
import { Step, StepProps } from "../types";

function DrinkAlcoholStep({
  answers,
  onAnswer,
  onNext,
  onPrev,
  currentQuestion,
  totalQuestions,
  onResetAnswer,
}: StepProps) {
  const answer = answers.get(DoYouDrinkAlcohol);
  const label = TransQuestions.Drinking.DoYouDrinkAlcohol();
  const { englishLabel } = TransQuestions.Drinking.DoYouDrinkAlcohol;
  const languageCode = useQuestionnaireLanguageCode();

  let cta = <Trans.General.NoNextQuestion />;
  if (answer && isMultiChoiceResponse(answer.response)) {
    cta =
      answer.response.choiceIndices.length > 0 ? (
        <Trans.General.YesNextQuestion />
      ) : (
        cta
      );
  }

  return (
    <QuestionView
      currentQuestion={currentQuestion}
      totalQuestionCount={totalQuestions}
      question={DoYouDrinkAlcohol}
      answer={answer}
      onNext={onNext}
      onPrev={() => {
        onResetAnswer(DoYouDrinkAlcohol);
        onPrev();
      }}
      cta={cta}
      explanation={{
        openText: <Trans.General.WhyAreWeAsking />,
        closeText: <Trans.General.OkIUnderstand />,
        explanationText: <Trans.Steps.DrinkAlcoholExplanation />,
      }}
    >
      <MultiChoiceInput
        question={DoYouDrinkAlcohol}
        answer={answer}
        onAnswer={(answer) => {
          onAnswer(DoYouDrinkAlcohol, {
            answer,
            questionAnswer: {
              questionId: "alcohol",
              englishLabel,
              label,
              languageCode,
              answer: {
                $type: "textarray",
                value: answer.response.choiceIndices.map(
                  (value) => DoYouDrinkAlcohol.choices[value].value
                ),
              },
            },
          });
        }}
      />
    </QuestionView>
  );
}

function DrinkCountStep({
  answers,
  onAnswer,
  onNext,
  onPrev,
  currentQuestion,
  totalQuestions,
  onResetAnswer,
}: StepProps) {
  const answer = answers.get(DrinkCount);
  const label = TransQuestions.Drinking.Question();
  const { englishLabel } = TransQuestions.Drinking.Question;
  const languageCode = useQuestionnaireLanguageCode();

  return (
    <QuestionView
      currentQuestion={currentQuestion}
      totalQuestionCount={totalQuestions}
      question={DrinkCount}
      answer={answer}
      onNext={() => {
        onResetAnswer(DrinkCount);
        onNext();
      }}
      onPrev={() => {
        onPrev();
        onResetAnswer(DrinkCount);
      }}
      explanation={{
        openText: <Trans.Steps.DrinkingOpenText />,
        closeText: <Trans.General.OkIUnderstand />,
        explanationText: <Trans.Steps.DrinkCountExplanation />,
      }}
    >
      <ChoiceInput
        question={DrinkCount}
        answer={answer}
        onAnswer={(answer) => {
          onAnswer(DrinkCount, {
            answer,
            questionAnswer: {
              questionId: "alcohol.weeklyDrinks",
              englishLabel,
              label,
              languageCode,
              answer: {
                $type: "number",
                value: DrinkCount.choices[answer.response.choiceIndex].value,
              },
            },
          });
          onNext();
        }}
      />
    </QuestionView>
  );
}

export function filter(steps: Step[], answers: Answers) {
  steps.push(DrinkAlcoholStep);
  const hasAnswer = !!answers
    .lookup(DoYouDrinkAlcohol)
    ?.filter((v) => v.length > 0)?.length;

  if (hasAnswer) {
    steps.push(DrinkCountStep);
  }
}
