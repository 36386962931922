import { useMemo } from "react";
import { Input } from "render/ui/presentation/Input";
import { Trans } from "./trans";

interface InviteCodeInputProps {
  code: string;
  applied: boolean;
  onChange: (value: string) => void;
  onSubmit: () => void;
  onClear: () => void;
  hideClearButton?: boolean;
  error?: string | JSX.Element;
}

export function InviteCodeInput({
  applied,
  code,
  onSubmit,
  onChange,
  onClear,
  hideClearButton,
  error,
}: InviteCodeInputProps) {
  const cta = useMemo(() => {
    if (applied) {
      return undefined;
    }

    return {
      label: <Trans.Use />,
      onClick: onSubmit,
    };
  }, [applied, onSubmit]);

  return (
    <Input
      error={error}
      autocomplete="off"
      disabled={applied}
      name="inviteCode"
      label={<Trans.InviteCode />}
      cta={cta}
      onChange={(e) => onChange(e.target.value)}
      onClear={() => onClear()}
      hideClearButton={hideClearButton}
      value={code}
    />
  );
}
