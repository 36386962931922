import {
  Answer,
  MultiChoiceQuestion,
  MultiChoiceResponse,
  createMultiChoiceAnswer,
  isMultiChoiceResponse,
} from "@cur8/questionnaire";
import { useCallback, useMemo } from "react";
import { Checkbox } from "../Checkbox";
import styles from "./styles.module.sass";

interface MultiChoiceInputWithDescriptionsProps<
  Value,
  Statement,
  ChoiceStatement
> {
  question: MultiChoiceQuestion<Value, Statement, ChoiceStatement>;
  answer?: Answer;
  onAnswer: (answer: Answer<MultiChoiceResponse>) => void;
}

export function MultiChoiceInputWithDescriptions<
  Value,
  Statement extends React.ReactNode,
  ChoiceStatement extends Record<"description" | "label", React.ReactNode>
>({
  question,
  answer,
  onAnswer,
}: MultiChoiceInputWithDescriptionsProps<Value, Statement, ChoiceStatement>) {
  const indices = useMemo(() => {
    const indices = [];
    if (answer && isMultiChoiceResponse(answer.response)) {
      indices.push(...answer.response.choiceIndices);
    }
    return new Set<number>(indices);
  }, [answer]);

  const handleChoice = useCallback(
    (choiceIndex: number) => {
      if (indices.has(choiceIndex)) {
        indices.delete(choiceIndex);
      } else {
        indices.add(choiceIndex);
      }

      const answer = createMultiChoiceAnswer([...indices]);

      onAnswer(answer);
    },
    [indices, onAnswer]
  );

  return (
    <fieldset data-hj-suppress className={styles.MultiChoiceInput}>
      {question.choices.map((choice, choiceIndex) => {
        const { label, description } = choice.statement;
        const checked = indices.has(choiceIndex) ?? false;

        return (
          <Checkbox
            choiceIndex={choiceIndex}
            checked={checked}
            choiceDescription={description}
            choice={{ statement: label, value: choice.value }}
            handleChoice={handleChoice}
          />
        );
      })}
    </fieldset>
  );
}
