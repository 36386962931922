import { DateTime } from "luxon";
import { Toggle } from "render/ui/presentation/Toggle";
import { Typography } from "render/ui/presentation/Typography";
import { ContentProps } from "render/views/ProfileView/components/ConsentDocument/components/shared";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

type ConsentToggleProps = Omit<ContentProps, "busy">;

export function ConsentToggle({
  allowEdit,
  checked,
  onClick,
  study,
}: ConsentToggleProps) {
  return (
    <div className={styles.participation}>
      <div className={styles.row}>
        <Typography variant="title-small">
          <Trans.Label />
        </Typography>
        {allowEdit && (
          <div className={styles.toggle}>
            <Typography variant="paragraph-sub" style={{ marginRight: "8px" }}>
              {checked ? <Trans.On /> : <Trans.Off />}
            </Typography>
            <Toggle
              name="consent"
              label={Trans.Label()}
              checked={checked}
              onClick={onClick}
            />
          </div>
        )}
      </div>
      <Typography variant="paragraph-sub">
        {checked ? (
          <>
            <Trans.YouGaveYourConsentAt />{" "}
            {study?.createdAt
              ? DateTime.fromISO(study.createdAt).toFormat("dd.LL.yyyy")
              : DateTime.now().toFormat("dd.LL.yyyy")}
          </>
        ) : (
          <Trans.YouDontParticipate />
        )}
      </Typography>
    </div>
  );
}
