import { Event, Action } from "render/hooks/useTracking";

const category = "PaymentView";

export const paymentEvents = {
  contactUsClick: (value: "open" | "close"): Event => {
    return {
      category,
      action: Action.Click,
      label: "open_contacts_sidebar",
      value,
    };
  },
};
