import { Sticky, ViewStack } from "@pomle/react-viewstack";
import { PropsWithChildren, useEffect } from "react";
import { usePopupDialog } from "render/context/PopupDialogContext";
import styles from "./styles.module.sass";

export function PopUpDialogFrame({ children }: PropsWithChildren<{}>) {
  const { dialogs, clear } = usePopupDialog();

  const content =
    dialogs.length > 0 ? dialogs[dialogs.length - 1].content : undefined;

  useEffect(() => {
    if (content) {
      window.document.documentElement.style.setProperty(
        "overscroll-behavior",
        "none"
      );
    } else {
      window.document.documentElement.style.setProperty(
        "overscroll-behavior",
        "auto"
      );
    }

    return () => {
      window.document.documentElement.style.setProperty(
        "overscroll-behavior",
        "auto"
      );
    };
  }, [content]);

  return (
    <div className={styles.PopupDialogFrame} data-active={!!content}>
      <ViewStack>
        <div className={styles.context}>{children}</div>
        <div onClick={() => clear()} className={styles.overlay}>
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className={styles.content}
          >
            <Sticky>{content}</Sticky>
          </div>
        </div>
      </ViewStack>
    </div>
  );
}
