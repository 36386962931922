import { Duration } from "luxon";
import { Trans } from "./trans";

const dur = Duration.fromObject;

const TenDays = dur({ days: 10 });

const SixMonths = dur({
  months: 6,
});

const OneYear = dur({
  years: 1,
});

const TwoYears = dur({
  years: 2,
});

const ThreeYears = dur({
  years: 3,
});

const FourYears = dur({
  years: 4,
});

export const RecencyChoices: [string | JSX.Element, Duration][] = [
  [<Trans.Choice.TenDays />, TenDays],
  [<Trans.Choice.SixMonths />, SixMonths],
  [<Trans.Choice.OneYear />, OneYear],
  [<Trans.Choice.TwoYears />, TwoYears],
  [<Trans.Choice.ThreeYears />, ThreeYears],
  [<Trans.Choice.FourYears />, FourYears],
];

export const Durations = {
  TenDays,
  SixMonths,
  OneYear,
  TwoYears,
  ThreeYears,
  FourYears,
};
