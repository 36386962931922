import { MultiChoiceQuestion, TextQuestion } from "@cur8/questionnaire";
import { Trans } from "./trans";
export enum Allergy {
  Dairy = "dairy",
  Eggs = "eggs",
  InsectSting = "insect-sting",
  Latex = "latex",
  Morphine = "morphine",
  Nuts = "nuts",
  Penicillin = "penicillin",
  Shellfish = "shellfish",
  Wheat = "wheat",
  Other = "other",
}

export const AllergyChoices: [string | JSX.Element, Allergy][] = [
  [<Trans.Allergy.Dairy />, Allergy.Dairy],
  [<Trans.Allergy.InsectSting />, Allergy.InsectSting],
  [<Trans.Allergy.Latex />, Allergy.Latex],
  [<Trans.Allergy.Morphine />, Allergy.Morphine],
  [<Trans.Allergy.Nuts />, Allergy.Nuts],
  [<Trans.Allergy.Penicillin />, Allergy.Penicillin],
  [<Trans.Allergy.Wheat />, Allergy.Wheat],
  [<Trans.Allergy.Other />, Allergy.Other],
];

export const Allergies = new MultiChoiceQuestion(
  <Trans.Allergy.AnyAllergies />,
  AllergyChoices
);

export const OtherAllergy = new TextQuestion(<Trans.Allergy.Question />);
