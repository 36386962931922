import { APITypesV2 } from "@cur8/api-client";
import { Patient } from "@cur8/rich-entity";
import { Sticky, ViewStack } from "@pomle/react-viewstack";
import { mapConsents } from "lib/consents/mapConsents";
import { ReactElement, useEffect, useMemo, useState } from "react";
import { useAppInsights } from "render/context/AppInsightsContext";
import { FullScreenPageLayout } from "render/ui/layout/FullScreenPageLayout";
import { LogoHeader } from "render/ui/layout/LogoHeader/LogoHeader";
import { ConsentRequiredStudiesExplanation } from "render/views/concierge/ConciergeCheckInView/components/ConciergeCheckInSessionView/components/CheckInFlowView/components/ConsentRequiredStudiesExplanation";
import { Transition } from "../__shared/Transition";
import { CheckInCompleteStep } from "./components/CheckInCompleteStep";
import { CheckInConsentDocument } from "./components/CheckInConsentDocument";
import { ConsentIntro } from "./components/ConsentIntro";
import styles from "./styles.module.sass";

interface CheckInFlowProps {
  onActive?: () => void;
  patient: Patient;
  duringVisitSelfSignConsents: APITypesV2.ConsentMetadata[] | undefined;
  patientConsents: APITypesV2.ConsentWithSignature[];
}

const Step = {
  Complete: "Complete",
  StudyConsentExplanation: "StudyConsentExplanation",
  RequiresConsentExplanation: "RequiresConsentExplanation",
};

interface View {
  key: string;
  component: () => ReactElement;
}

type Consent = {
  /** Description of a consent */
  consentMetadata: APITypesV2.ConsentMetadata;
  /** A summary of a signed consent */
  relevantSignature: APITypesV2.ConsentSummary | undefined;
};

function checkInConsentDocuments(consents: Consent[] | undefined): View[] {
  return (
    consents?.map<View>((consent) => {
      return {
        key: consent.consentMetadata.consentId,
        component: () => <CheckInConsentDocument consent={consent} />,
      };
    }) ?? []
  );
}

export function CheckInFlow({
  patient,
  duringVisitSelfSignConsents,
  patientConsents,
  onActive,
}: CheckInFlowProps) {
  useEffect(() => {
    onActive?.();
  }, [onActive]);

  const { trackEvent } = useAppInsights();
  const [showStudyExplanation, setShowStudyExplanation] = useState(true);
  const [showConsentExplanation, setShowConsentExplanation] = useState(true);

  const mappedConsents = mapConsents({
    requiredConsents: duringVisitSelfSignConsents,
    previouslySignedConsents: patientConsents,
  });

  const studyConsents = mappedConsents?.filter(
    (consent) =>
      consent.consentMetadata.consentType === APITypesV2.ConsentType.Study
  );
  const productAndOtherConsents = mappedConsents?.filter(
    (consent) =>
      consent.consentMetadata.consentType !== APITypesV2.ConsentType.Study
  );

  const unsignedStudyConsentKeys = useMemo(() => {
    return studyConsents
      ?.filter(
        (consent) =>
          consent.relevantSignature == null ||
          consent.relevantSignature?.expired ||
          consent.relevantSignature?.revoked ||
          consent.consentMetadata.askConsentEveryTime
      )
      .map((consent) => consent.consentMetadata.consentId);
  }, [studyConsents]);

  const unsignedProductAndOtherConsentKeys = useMemo(() => {
    return productAndOtherConsents
      ?.filter(
        (consent) =>
          consent.relevantSignature == null ||
          consent.relevantSignature?.expired ||
          consent.relevantSignature?.revoked ||
          consent.consentMetadata.askConsentEveryTime
      )
      .map((consent) => consent.consentMetadata.consentId);
  }, [productAndOtherConsents]);

  const studyConsentSteps = useMemo(() => {
    return checkInConsentDocuments(studyConsents);
  }, [studyConsents]);

  const productAndOtherConsentSteps = useMemo(() => {
    return checkInConsentDocuments(productAndOtherConsents);
  }, [productAndOtherConsents]);

  const steps = useMemo<View[]>(() => {
    const result: View[] = [];
    if (studyConsentSteps != null) {
      result.push(
        {
          key: Step.StudyConsentExplanation,
          component: () => (
            <ConsentRequiredStudiesExplanation
              onDone={() => {
                setShowStudyExplanation(false);
              }}
            />
          ),
        },
        ...studyConsentSteps
      );
    }

    if (productAndOtherConsentSteps != null) {
      result.push(
        {
          key: Step.RequiresConsentExplanation,
          component: () => (
            <ConsentIntro onDone={() => setShowConsentExplanation(false)} />
          ),
        },

        ...productAndOtherConsentSteps
      );
    }
    result.push({
      key: Step.Complete,
      component: () => <CheckInCompleteStep patient={patient} />,
    });

    return result;
  }, [patient, productAndOtherConsentSteps, studyConsentSteps]);

  const currentStep = useMemo(() => {
    if (unsignedStudyConsentKeys && unsignedStudyConsentKeys.length > 0) {
      if (showStudyExplanation) {
        return Step.StudyConsentExplanation;
      }
      return unsignedStudyConsentKeys[0];
    }

    if (
      unsignedProductAndOtherConsentKeys &&
      unsignedProductAndOtherConsentKeys.length > 0
    ) {
      if (showConsentExplanation) {
        return Step.RequiresConsentExplanation;
      }
      return unsignedProductAndOtherConsentKeys[0];
    }

    return Step.Complete;
  }, [
    showConsentExplanation,
    showStudyExplanation,
    unsignedProductAndOtherConsentKeys,
    unsignedStudyConsentKeys,
  ]);

  const currentStepIndex = useMemo(() => {
    return steps.findIndex(({ key }) => key === currentStep);
  }, [steps, currentStep]);

  useEffect(() => {
    trackEvent({
      name: "concierge-uk-check-in-success",
      properties: { step: currentStep },
    });
  }, [currentStep, trackEvent]);

  return (
    <FullScreenPageLayout disableBackground>
      <div className={styles.CheckInFlowView}>
        <ViewStack>
          {steps.map(({ key, component }, index) => {
            const match = key === currentStep;
            const offset = index - currentStepIndex;
            return (
              <Transition offset={offset} key={key}>
                <Sticky delay={300}>{match && component()}</Sticky>
              </Transition>
            );
          })}
        </ViewStack>
        <div className={styles.header}>
          <LogoHeader />
        </div>
      </div>
    </FullScreenPageLayout>
  );
}
