import { ChoiceQuestion } from "@cur8/questionnaire";
import { Trans } from "./transV2";

export enum PregnantEnum {
  Yes = "Yes",
  No = "No",
}

export const Pregnant = new ChoiceQuestion(<Trans.Pregnant.Question />, [
  [<Trans.Pregnant.No />, PregnantEnum.No],
  [<Trans.Pregnant.Yes />, PregnantEnum.Yes],
]);
