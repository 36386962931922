import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAPIClient } from "render/context/APIContext";
import { useSession } from "render/context/MSALContext";
import { useGdprExportQuery } from "render/hooks/api/queries/useGdprExportQuery";

export function useGdprExportMutation() {
  const queryClient = useQueryClient();
  const { patientId } = useSession();
  const apiClient = useAPIClient();

  return useMutation({
    mutationFn() {
      return apiClient.gdprExport.createGdprExport({ patientId }).result;
    },
    onSuccess() {
      const exportDataQueryKey = useGdprExportQuery.queryKey(patientId);
      return queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey[0] === exportDataQueryKey[0] &&
          query.queryKey[1] === exportDataQueryKey[1],
      });
    },
  });
}
