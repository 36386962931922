import { BookableSlot } from "@cur8/rich-entity";
import { useCallback } from "react";
import { useSitesQuery } from "render/views/booking/MultiSiteMonthView/hooks/useSitesQuery";
import { ReactComponent as CheckIcon } from "../../../../assets/check.svg";
import styles from "./styles.module.sass";

interface BookableTimeSlotProps {
  slot: BookableSlot;
  isSelected: boolean;
  onClick: (slot: BookableSlot) => void;
}

export function BookableTimeSlot({
  onClick,
  slot,
  isSelected,
}: BookableTimeSlotProps) {
  const sitesQuery = useSitesQuery();
  const siteInfo = sitesQuery.data?.find((site) => {
    return site.siteId === slot.siteId;
  });
  const onClickHandler = useCallback(() => {
    onClick(slot);
  }, [slot, onClick]);

  return (
    <button
      onClick={onClickHandler}
      data-selected={isSelected}
      className={styles.BookableTimeSlot}
    >
      <div className={styles.content}>
        <div className={styles.time}>{slot.startTime.toFormat("HH:mm")}</div>
        <div className={styles.location}>
          <div className={styles.name}>{siteInfo?.siteName}</div>
          <div className={styles.address}>{siteInfo?.address.street}</div>
        </div>
        <div className={styles.checkMark}>
          <CheckIcon />
        </div>
      </div>
    </button>
  );
}
