import { APITypesV2 } from "@cur8/api-client";
import { HabitualFrequency, HabitualStyle } from "@cur8/measurements";
import { Answers } from "@cur8/questionnaire";
import { useQuestionnaireLanguageCode } from "lib/questionnaire/locale";
import {
  DailyCigaretteCount,
  IsSmoker,
  QuitSmokingRecency,
  SmokingFrequency,
} from "lib/questionnaire/question-flows/onboarding/questions/smoking";
import { Trans as TransQuestions } from "lib/questionnaire/question-flows/onboarding/questions/trans";
import { DateTime } from "luxon";
import { ChoiceInput } from "render/ui/presentation/Questionnaire/components/ChoiceInput";
import { QuestionView } from "../components/QuestionView";
import { Trans } from "../trans";
import { Step, StepProps } from "../types";

function IsSmokerStep({
  answers,
  onAnswer,
  onNext,
  onPrev,
  currentQuestion,
  totalQuestions,
  onResetAnswer,
}: StepProps) {
  const answer = answers.get(IsSmoker);
  const label = TransQuestions.Smoking.Question();
  const { englishLabel } = TransQuestions.Smoking.Question;
  const languageCode = useQuestionnaireLanguageCode();

  const isSmokerLabelMap = {
    [SmokingFrequency.Currently]: TransQuestions.Smoking.FrequencyCurrently(),
    [SmokingFrequency.Occasionally]:
      TransQuestions.Smoking.FrequencyOccasionally(),
    [SmokingFrequency.Quit]: TransQuestions.Smoking.FrequencyQuit(),
  };

  const getIsSmokerLabelForChoiceIndex = (choiceIndex: number) => {
    const value = IsSmoker.choices[choiceIndex].value;
    return isSmokerLabelMap[value as keyof typeof isSmokerLabelMap];
  };

  const styleMap = {
    [SmokingFrequency.Currently]: HabitualStyle.Currently,
    [SmokingFrequency.Quit]: HabitualStyle.Previously,
  };

  return (
    <QuestionView
      currentQuestion={currentQuestion}
      totalQuestionCount={totalQuestions}
      question={IsSmoker}
      answer={answer}
      onNext={() => {
        onResetAnswer(IsSmoker);
        onNext();
      }}
      onPrev={() => {
        onPrev();
        onResetAnswer(IsSmoker);
      }}
      cta={<Trans.General.NoNextQuestion />}
      explanation={{
        openText: <Trans.General.WhyAreWeAsking />,
        closeText: <Trans.General.OkIUnderstand />,
        explanationText: <Trans.Steps.SmokingExplanation />,
      }}
    >
      <ChoiceInput
        question={IsSmoker}
        answer={answer}
        onAnswer={(answer) => {
          const choiceValue = IsSmoker.choices[answer.response.choiceIndex]
            .value as SmokingFrequency;
          const isOcasionallySmoker =
            choiceValue === SmokingFrequency.Occasionally;

          const value = isOcasionallySmoker
            ? HabitualFrequency.Occasionally
            : styleMap[choiceValue];

          onAnswer(IsSmoker, {
            answer,
            questionAnswer: {
              englishLabel,
              label,
              languageCode,
              questionId: isOcasionallySmoker
                ? "smoking.frequency"
                : "smoking.style",
              answer: {
                $type: "enum",
                value,
                label: getIsSmokerLabelForChoiceIndex(
                  answer.response.choiceIndex
                ),
              } as APITypesV2.EnumAnswerPayload,
            },
          });
          onNext();
        }}
      />
    </QuestionView>
  );
}

function QuitSmokingRecencyStep({
  answers,
  onAnswer,
  onNext,
  onPrev,
  currentQuestion,
  totalQuestions,
  onResetAnswer,
}: StepProps) {
  const answer = answers.get(QuitSmokingRecency);
  const label = TransQuestions.Smoking.QuitSmokingRecency();
  const { englishLabel } = TransQuestions.Smoking.QuitSmokingRecency;
  const languageCode = useQuestionnaireLanguageCode();

  return (
    <QuestionView
      currentQuestion={currentQuestion}
      totalQuestionCount={totalQuestions}
      question={QuitSmokingRecency}
      answer={answer}
      onNext={onNext}
      onPrev={() => {
        onPrev();
        onResetAnswer(QuitSmokingRecency);
      }}
    >
      <ChoiceInput
        question={QuitSmokingRecency}
        answer={answer}
        onAnswer={(answer) => {
          onAnswer(QuitSmokingRecency, {
            answer,
            questionAnswer: {
              questionId: "smoking.quitSmokingAt",
              englishLabel,
              label,
              languageCode,
              answer: {
                $type: "text",
                value: DateTime.now()
                  .minus(
                    QuitSmokingRecency.choices[answer.response.choiceIndex]
                      .value
                  )
                  .toJSDate()
                  .toISOString(),
              },
            },
          });
          onNext();
        }}
      />
    </QuestionView>
  );
}

function CigaretteCountStep({
  answers,
  onAnswer,
  onNext,
  onPrev,
  currentQuestion,
  totalQuestions,
  onResetAnswer,
}: StepProps) {
  const answer = answers.get(DailyCigaretteCount);
  const label = TransQuestions.Smoking.DailyCigaretteCount();
  const { englishLabel } = TransQuestions.Smoking.DailyCigaretteCount;
  const languageCode = useQuestionnaireLanguageCode();

  const dailyCigarettesCountLabelMap = {
    2: TransQuestions.Smoking.CigaretteCount2(),
    6: TransQuestions.Smoking.CigaretteCount6(),
    12: TransQuestions.Smoking.CigaretteCount12(),
    18: TransQuestions.Smoking.CigaretteCount18(),
    24: TransQuestions.Smoking.CigaretteCount24(),
  };

  const getDailyCigaretteCountLabelForChoiceIndex = (choiceIndex: number) => {
    const value = DailyCigaretteCount.choices[choiceIndex].value;
    return dailyCigarettesCountLabelMap[
      value as keyof typeof dailyCigarettesCountLabelMap
    ];
  };

  return (
    <QuestionView
      currentQuestion={currentQuestion}
      totalQuestionCount={totalQuestions}
      question={DailyCigaretteCount}
      answer={answer}
      onNext={onNext}
      onPrev={() => {
        onPrev();
        onResetAnswer(DailyCigaretteCount);
      }}
    >
      <ChoiceInput
        question={DailyCigaretteCount}
        answer={answer}
        onAnswer={(answer) => {
          onAnswer(DailyCigaretteCount, {
            answer,
            questionAnswer: {
              questionId: "smoking.dailyCigarettes",
              englishLabel,
              label,
              languageCode,
              answer: {
                $type: "enum",
                value:
                  DailyCigaretteCount.choices[answer.response.choiceIndex]
                    .value,
                label: getDailyCigaretteCountLabelForChoiceIndex(
                  answer.response.choiceIndex
                ),
              },
            },
          });
          onNext();
        }}
      />
    </QuestionView>
  );
}

export function filter(steps: Step[], answers: Answers) {
  steps.push(IsSmokerStep);

  const style = answers.lookup(IsSmoker);

  if (style === SmokingFrequency.Currently) {
    steps.push(CigaretteCountStep);
  } else if (style === SmokingFrequency.Quit) {
    steps.push(QuitSmokingRecencyStep);
  }
}
