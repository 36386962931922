import { useNav } from "@pomle/react-router-paths";
import { paths } from "render/routes/paths";
import { ContactUs } from "render/views/_shared/ContactUs";

export function ContactView() {
  const nav = {
    home: useNav(paths.root),
    booking: useNav(paths.booking),
    contact: useNav(paths.contact),
  };

  return <ContactUs onBackClick={nav.home.on({})} />;
}
