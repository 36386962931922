import { useQuery } from "@tanstack/react-query";
import { APIClient } from "lib/api/client";
import { useAPIClient } from "render/context/APIContext";
import { useSession } from "render/context/MSALContext";
import { ExtractBody, ExtractKey, QueryOptions } from "typings/query";

const queryKey = (patientId: string) => [
  "phonenumber-external-sources",
  patientId,
];
const queryFn =
  (apiClient: APIClient, patientId: string): any =>
  async () => {
    const result = await apiClient.patient.fetchInferredPhoneNumbers(patientId)
      .result;

    return result;
  };

type Body = ExtractBody<typeof queryFn>;
type Key = ExtractKey<typeof queryKey>;

export function usePatientInferredDataQuery<T = Body>(
  options: QueryOptions<Body, Key, T> = {}
) {
  const { patientId } = useSession();
  const apiClient = useAPIClient();

  return useQuery(queryKey(patientId), queryFn(apiClient, patientId), options);
}

usePatientInferredDataQuery.queryKey = queryKey;
usePatientInferredDataQuery.queryFn = queryFn;
