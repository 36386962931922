import { QuestionnaireResponse } from "@cur8/api-client/dist/api/cur8/generated-types/v2/Api";
import { APIClient } from "lib/api/client";
import { v4 } from "uuid";

export function getOnboarding({
  apiClient,
  patientId,
}: {
  apiClient: APIClient;
  patientId: string;
}) {
  return apiClient.questionnaire
    .getQuestionnaireResponses(
      {
        patientId,
      },
      {
        questionnaireType: "onboarding/2",
      }
    )
    .result.then((responses) => {
      const response = responses.items.at(0);

      if (!response?.questionsAndAnswers) {
        return undefined;
      }

      return response;
    });
}

export function saveOnboarding({
  apiClient,
  patientId,
  questionnaireResponse,
}: {
  questionnaireResponse: QuestionnaireResponse;
  apiClient: APIClient;
  patientId: string;
}) {
  return apiClient.questionnaire.createOrUpdateQuestionnaireResponse(
    {
      patientId,
      responseId: questionnaireResponse.responseId ?? v4(),
    },
    questionnaireResponse
  );
}
