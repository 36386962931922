import { localize } from "render/context/InternationalizationContext";

export const Validation = {
  Phone: {
    Required: localize({
      en_GB: "Phone number is required",
      sv_SE: "Telefonnummer krävs",
    }),
    InvalidCountry: localize({
      en_GB: "Country code not provided",
      sv_SE: "Landskod saknas",
    }),
    NotNumber: localize({
      en_GB: "Not a valid phone number",
      sv_SE: "Ogiltigt telefonnummer",
    }),
    TooShort: localize({
      en_GB: "Phone number is too short",
      sv_SE: "Telefonnumret är för kort",
    }),
    TooLong: localize({
      en_GB: "Phone number is too long",
      sv_SE: "Telefonnumret är för långt",
    }),
    InvalidLength: localize({
      en_GB: "Phone number length is invalid",
      sv_SE: "Telefonnumrets längd är ogiltig",
    }),
    DuplicatePhoneNumber: localize({
      en_GB: "This phone number is already registered",
      sv_SE: "Det här telefonnumret finns redan registrerat",
    }),
  },
};

const Title = localize({
  en_GB: "Phone number",
  sv_SE: "Telefonnummer",
});

const SubTitle = localize({
  en_GB: "The Medical Team will use this number to contact you.",
  sv_SE:
    "Det nummer som det medicinska teamet kommer att använda för att kontakta dig.",
});

const Label = {
  Phone: localize({
    en_GB: "Phone Number",
    sv_SE: "Telefonnummer",
  }),
};

const Actions = {
  Save: localize({
    en_GB: "Save",
    sv_SE: "Spara",
  }),
  Saving: localize({
    en_GB: "Saving...",
    sv_SE: "Sparar...",
  }),
};

export const Trans = {
  Title,
  SubTitle,
  Validation,
  Label,
  Actions,
};
