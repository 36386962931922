import {
  Answer,
  MultiChoiceQuestion,
  MultiChoiceResponse,
  createMultiChoiceAnswer,
  isMultiChoiceResponse,
} from "@cur8/questionnaire";
import { useCallback, useMemo } from "react";
import { Checkbox } from "../Checkbox";
import styles from "./styles.module.sass";

interface MultiChoiceInputProps<T> {
  question: MultiChoiceQuestion<T, React.ReactNode, React.ReactNode>;
  answer?: Answer;
  onAnswer: (answer: Answer<MultiChoiceResponse>) => void;
}

export function MultiChoiceInput<T>({
  question,
  answer,
  onAnswer,
}: MultiChoiceInputProps<T>) {
  const indices = useMemo(() => {
    const indices = [];
    if (answer && isMultiChoiceResponse(answer.response)) {
      indices.push(...answer.response.choiceIndices);
    }
    return new Set<number>(indices);
  }, [answer]);

  const handleChoice = useCallback(
    (choiceIndex: number) => {
      if (indices.has(choiceIndex)) {
        indices.delete(choiceIndex);
      } else {
        indices.add(choiceIndex);
      }

      const answer = createMultiChoiceAnswer([...indices]);

      onAnswer(answer);
    },
    [indices, onAnswer]
  );

  return (
    <fieldset data-hj-suppress className={styles.MultiChoiceInput}>
      {question.choices.map((choice, choiceIndex) => {
        const checked = indices.has(choiceIndex) ?? false;

        return (
          <Checkbox
            key={choiceIndex}
            choiceIndex={choiceIndex}
            checked={checked}
            choice={choice}
            handleChoice={handleChoice}
          />
        );
      })}
    </fieldset>
  );
}
