import { HabitualStyle } from "@cur8/measurements";
import { Answers } from "@cur8/questionnaire";
import { useQuestionnaireLanguageCode } from "lib/questionnaire/locale";
import {
  IsSnuser,
  QuitSnusingRecency,
  WeeklySnusCanCount,
} from "lib/questionnaire/question-flows/onboarding/questions/snus";
import { Trans as TransQuestions } from "lib/questionnaire/question-flows/onboarding/questions/trans";
import { DateTime } from "luxon";
import { ChoiceInput } from "render/ui/presentation/Questionnaire/components/ChoiceInput";
import { QuestionView } from "../components/QuestionView";
import { Trans } from "../trans";
import { Step, StepProps } from "../types";

function IsSnuserStep({
  answers,
  onAnswer,
  onNext,
  onPrev,
  currentQuestion,
  totalQuestions,
  onResetAnswer,
}: StepProps) {
  const answer = answers.get(IsSnuser);
  const label = TransQuestions.Snus.Question();
  const { englishLabel } = TransQuestions.Snus.Question;
  const languageCode = useQuestionnaireLanguageCode();

  const labelMap = {
    [HabitualStyle.Currently]: TransQuestions.Snus.HabitualStyleCurrently(),
    [HabitualStyle.Previously]: TransQuestions.Snus.HabitualStylePreviously(),
  };
  const noLabel = Trans.General.NoNextQuestion();

  const getIsSnusUserLabelForChoiceIndex = (choiceIndex: number) => {
    const value = IsSnuser.choices[choiceIndex].value;
    return labelMap[value as unknown as keyof typeof labelMap] ?? noLabel;
  };

  return (
    <QuestionView
      currentQuestion={currentQuestion}
      totalQuestionCount={totalQuestions}
      question={IsSnuser}
      answer={answer}
      onNext={() => {
        onResetAnswer(IsSnuser);
        onNext();
      }}
      onPrev={() => {
        onPrev();
        onResetAnswer(IsSnuser);
      }}
      cta={<Trans.General.NoNextQuestion />}
      explanation={{
        openText: <Trans.General.WhyAreWeAsking />,
        closeText: <Trans.General.OkIUnderstand />,
        explanationText: <Trans.Steps.SnusExplanation />,
      }}
    >
      <ChoiceInput
        question={IsSnuser}
        answer={answer}
        onAnswer={(answer) => {
          onAnswer(IsSnuser, {
            answer,
            questionAnswer: {
              questionId: "snus.style",
              englishLabel,
              label,
              languageCode,
              answer: {
                $type: "enum",
                value: IsSnuser.choices[answer.response.choiceIndex].value,
                label: getIsSnusUserLabelForChoiceIndex(
                  answer.response.choiceIndex
                ),
              },
            },
          });
          onNext();
        }}
      />
    </QuestionView>
  );
}

function WeeklySnusCanCountStep({
  answers,
  onAnswer,
  onNext,
  onPrev,
  currentQuestion,
  totalQuestions,
  onResetAnswer,
}: StepProps) {
  const answer = answers.get(WeeklySnusCanCount);
  const label = TransQuestions.Snus.WeeklySnusCanCount();
  const { englishLabel } = TransQuestions.Snus.WeeklySnusCanCount;
  const languageCode = useQuestionnaireLanguageCode();

  return (
    <QuestionView
      currentQuestion={currentQuestion}
      totalQuestionCount={totalQuestions}
      question={WeeklySnusCanCount}
      answer={answer}
      onNext={onNext}
      onPrev={() => {
        onPrev();
        onResetAnswer(WeeklySnusCanCount);
      }}
    >
      <ChoiceInput
        question={WeeklySnusCanCount}
        answer={answer}
        onAnswer={(answer) => {
          onAnswer(WeeklySnusCanCount, {
            answer,
            questionAnswer: {
              questionId: "snus.weeklyCans",
              englishLabel,
              label,
              languageCode,
              answer: {
                $type: "number",
                value:
                  WeeklySnusCanCount.choices[answer.response.choiceIndex].value,
              },
            },
          });
          onNext();
        }}
      />
    </QuestionView>
  );
}

function QuitSnusingRecencyStep({
  answers,
  onAnswer,
  onNext,
  onPrev,
  currentQuestion,
  totalQuestions,
  onResetAnswer,
}: StepProps) {
  const answer = answers.get(QuitSnusingRecency);
  const label = TransQuestions.Snus.QuitSnusingRecency();
  const { englishLabel } = TransQuestions.Snus.QuitSnusingRecency;
  const languageCode = useQuestionnaireLanguageCode();

  return (
    <QuestionView
      currentQuestion={currentQuestion}
      totalQuestionCount={totalQuestions}
      question={QuitSnusingRecency}
      answer={answer}
      onNext={onNext}
      onPrev={() => {
        onPrev();
        onResetAnswer(QuitSnusingRecency);
      }}
    >
      <ChoiceInput
        question={QuitSnusingRecency}
        answer={answer}
        onAnswer={(answer) => {
          onAnswer(QuitSnusingRecency, {
            answer,
            questionAnswer: {
              questionId: "snus.quitSnusingAt",
              englishLabel,
              label,
              languageCode,
              answer: {
                $type: "text",
                value: DateTime.now()
                  .minus(
                    QuitSnusingRecency.choices[answer.response.choiceIndex]
                      .value
                  )
                  .toJSDate()
                  .toISOString(),
              },
            },
          });
          onNext();
        }}
      />
    </QuestionView>
  );
}

export function filter(steps: Step[], answers: Answers) {
  steps.push(IsSnuserStep);

  if (answers.lookup(IsSnuser) === HabitualStyle.Currently) {
    steps.push(WeeklySnusCanCountStep);
  } else if (answers.lookup(IsSnuser) === HabitualStyle.Previously) {
    steps.push(QuitSnusingRecencyStep);
  }
}
