import { Sticky, ViewStack } from "@pomle/react-viewstack";
import { Fade } from "@pomle/react-viewstack-transitions";
import { isAtriumOnboardingComplete } from "lib/onboarding/isAtriumOnboardingComplete";
import { useEffect, useMemo, useState } from "react";
import { useOnboardingQuery } from "render/hooks/api/queries/useOnboardingQuery";
import { useValidPatientConsentsQuery } from "render/hooks/api/queries/useValidPatientConsentsQuery";
import { FullScreenPageLayout } from "render/ui/layout/FullScreenPageLayout";
import { useConsentsMap } from "render/views/ProfileView/components/DataAndPrivacySection/hooks/useConsentsMap";
import { VideoBackground } from "render/views/_shared/VideoBackground";
import { AtriumOnboarding } from "./components/AtriumOnboarding";
import { AtriumOnboardingCompleted } from "./components/AtriumOnboardingCompleted";

enum ViewActiveState {
  Loading,
  OnboardingInProgress,
  Complete,
}

export function AtriumSignIn() {
  const [activeState, setActiveState] = useState<ViewActiveState>(
    ViewActiveState.Loading
  );
  const onboardingQuery = useOnboardingQuery();
  const patientConsentsQuery = useValidPatientConsentsQuery();
  const { studies } = useConsentsMap();

  const onboardingComplete = useMemo(() => {
    if (patientConsentsQuery.data == null) {
      return false;
    }

    return isAtriumOnboardingComplete({
      requiredStudies: studies,
      onboardingData: onboardingQuery.data,
      patientConsents: patientConsentsQuery.data,
    });
  }, [onboardingQuery, patientConsentsQuery, studies]);

  useEffect(() => {
    const isFetching = !onboardingQuery.isFetched || !patientConsentsQuery.data;
    if (isFetching) {
      setActiveState(ViewActiveState.Loading);
      return;
    }

    if (
      onboardingComplete &&
      activeState !== ViewActiveState.OnboardingInProgress
    ) {
      setActiveState(ViewActiveState.Complete);
      return;
    }

    setActiveState(ViewActiveState.OnboardingInProgress);
  }, [
    onboardingQuery,
    patientConsentsQuery.data,
    onboardingComplete,
    activeState,
  ]);

  return (
    <FullScreenPageLayout disableBackground>
      <ViewStack>
        <Fade active={activeState === ViewActiveState.Loading}>
          <FullScreenPageLayout disableBackground />
        </Fade>
        <Fade active={activeState === ViewActiveState.OnboardingInProgress}>
          <AtriumOnboarding
            onSuccess={() => {
              Promise.all([
                onboardingQuery.refetch(),
                patientConsentsQuery.refetch(),
              ]);
            }}
          />
        </Fade>
        <Fade active={activeState === ViewActiveState.Complete}>
          <Sticky>
            {activeState === ViewActiveState.Complete && (
              <AtriumOnboardingCompleted />
            )}
          </Sticky>
        </Fade>
      </ViewStack>
      <VideoBackground />
    </FullScreenPageLayout>
  );
}
