import { CheckInBadge } from "render/views/concierge/ConciergeCheckInView/components/ConciergeCheckInSessionView/components/__shared/CheckInBadge";
import { CheckInStepExplanationLayout } from "render/views/concierge/ConciergeCheckInView/components/ConciergeCheckInSessionView/components/__shared/CheckInStepExplanationLayout";
import styles from "./styles.module.sass";
import { Trans } from "./trans";
import { Typography } from "render/ui/presentation/Typography";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { useMSAL } from "render/context/MSALContext";
import { useCallback, useEffect } from "react";
import { useIdentityVerificationStatus } from "render/hooks/api/queries/useIdentityVerificationStatus";
import { Patient } from "@cur8/rich-entity";
import { APITypesV1 } from "@cur8/api-client";
import { Busy } from "render/views/concierge/ConciergeCheckInView/components/ConciergeCheckInSessionView/components/RequiresIdentityVerificationView/components/Busy";

export function RequiresIdentityVerificationView({
  onActive,
}: {
  onActive?: () => void;
  patient: Patient;
  hasMFA: boolean;
}) {
  useEffect(() => {
    onActive?.();
  }, [onActive]);

  const identityVerificationStatus = useIdentityVerificationStatus({
    keepPreviousData: true,
    refetchInterval: (data) => {
      return data?.identityVerificationStatus ===
        APITypesV1.IdentityVerificationStatus.Verified
        ? false
        : 5000;
    },
  });
  const msal = useMSAL();
  const verifyIdentity = useCallback(() => msal.stepUpAccess("idv"), [msal]);

  if (!identityVerificationStatus.data) {
    return null;
  }

  if (
    identityVerificationStatus.data.identityVerificationStatus ===
    APITypesV1.IdentityVerificationStatus.Declined
  ) {
    return (
      <CheckInStepExplanationLayout
        SubHeader={<CheckInBadge />}
        Content={
          <div className={styles.text}>
            <Typography variant="title-large">
              <Trans.Failed.Title />
            </Typography>
            <Typography variant="title-small">
              <Trans.Failed.Paragraph />
            </Typography>
          </div>
        }
        Actions={
          <ActionButton onClick={verifyIdentity}>
            <Trans.Failed.CTA />
          </ActionButton>
        }
      />
    );
  }

  if (
    identityVerificationStatus.data.identityVerificationStatus ===
    APITypesV1.IdentityVerificationStatus.Submitted
  ) {
    return (
      <CheckInStepExplanationLayout
        SubHeader={<CheckInBadge />}
        Content={
          <div className={styles.pending}>
            <div className={styles.text}>
              <Typography variant="title-large">
                <Trans.InProgress.Title />
              </Typography>
              <Typography variant="title-medium">
                <Trans.InProgress.Paragraph />
              </Typography>
            </div>
            <div className={styles.loading}>
              <Busy />
            </div>
          </div>
        }
      />
    );
  }

  // once identity is confirmed if token does not have MFA setup app will refresh because of global handling 40x
  // For future we would need a better solution as there is an indirect dependency now
  // But that requires a deeper redesign

  return (
    <CheckInStepExplanationLayout
      SubHeader={<CheckInBadge />}
      Content={
        <div className={styles.text}>
          <Typography variant="title-large">
            <Trans.NotDone.Title />
          </Typography>
          <Typography variant="title-medium">
            <span className={styles.list}>
              <Trans.NotDone.Paragraph />
            </span>
          </Typography>
        </div>
      }
      Actions={
        <ActionButton onClick={verifyIdentity}>
          <Trans.NotDone.CTA />
        </ActionButton>
      }
    />
  );
}
