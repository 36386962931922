import { Visit } from "@cur8/rich-entity";
import { ViewStack } from "@pomle/react-viewstack";
import { DateTime } from "luxon";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppInsights } from "render/context/AppInsightsContext";
import { useAppointmentQuery } from "render/hooks/api/queries/useAppointmentQuery";
import { useLatestConsentsQuery } from "render/hooks/api/queries/usePatientConsentsQuery";
import { usePatientQuery } from "render/hooks/api/queries/usePatientQuery";
import {
  isCheckedIn,
  useTodayVisitsQuery,
} from "render/hooks/api/queries/useTodayVisitsQuery";
import { useDuringVisitSelfSignConsents } from "render/hooks/api/queries/useVisitsQuery";
import { useJoinConciergeSessionMutation } from "render/hooks/mutations/concierge/useJoinConciergeSessionMutation";
import { FullScreenPageLayout } from "render/ui/layout/FullScreenPageLayout";
import { CheckInFlow } from "render/views/concierge/ConciergeCheckInView/components/ConciergeCheckInSessionView/components/CheckInFlowView";
import { CheckInFailView } from "./components/CheckInFailView";
import { Loader, LoaderStatus } from "./components/Loader";
import { RequiresIdentityVerificationView } from "./components/RequiresIdentityVerificationView";
import {
  AppointmentValidity,
  getAppointmentSlotValidity,
} from "./lib/getAppointmentSlotValidity";
import styles from "./styles.module.sass";
import { Trans } from "./trans";
import { useSession } from "render/context/MSALContext";

interface ConciergeCheckInSessionViewProps {
  sessionId: string;
}

function hasCheckedInSlot(visits?: Visit[]) {
  return !!visits?.find(isCheckedIn);
}

enum ViewState {
  RequiresIdVeriffFail = "requires-id-veriff-fail",
  JoinSessionFail = "join-session-fail",
  QueryBookingsFail = "query-bookings-fail",
  QueryConsentsFail = "query-consents-fail",
  TodayNoSlots = "today-no-slots",
  Fetching = "fetching",
  CheckInEarly = "check-in-early",
  CheckInLate = "check-in-late",
  CheckInTimeOut = "check-in-timeout",
  Success = "Success",
  WaitingForCheckIn = "WaitingForCheckIn",
}

const TIME_OUT_TIME_SECONDS = 30;

interface CheckInFlowContainerProps {
  onStateChange: (viewState: ViewState, status: LoaderStatus) => void;
  sessionId: string;
}

function CheckInFlowContainer({
  onStateChange,
  sessionId,
}: CheckInFlowContainerProps) {
  const { mfa } = useSession();

  const patient = usePatientQuery();
  const joinSessionMutation = useJoinConciergeSessionMutation();
  const todayVisitsQuery = useTodayVisitsQuery();

  const todaysVisit = todayVisitsQuery.data?.at(0);

  const duringVisitSelfSignConsents = useDuringVisitSelfSignConsents();
  const patientConsents = useLatestConsentsQuery({
    enabled: patient.data?.hasVerifiedIdentity === true && mfa,
  });

  const appointmentQuery = useAppointmentQuery(
    { slotId: todaysVisit?.slotId },
    { enabled: !!todaysVisit?.slotId }
  );

  const sessionStart = useMemo(() => {
    return joinSessionMutation.data?.sessionId ? DateTime.now() : undefined;
  }, [joinSessionMutation.data?.sessionId]);

  useEffect(() => {
    if (joinSessionMutation.data || joinSessionMutation.error) {
      return;
    }
    joinSessionMutation.mutate(sessionId);
  }, [joinSessionMutation, sessionId]);

  const triggerChangeTo = useMemo(() => {
    const memo = new Map<string, () => void>();
    return (newState: ViewState, newLoader: LoaderStatus) => {
      const key = `${newState}_${newLoader}`;
      let memoCallback = memo.get(key);
      if (memoCallback) {
        return memoCallback;
      }
      memoCallback = () => {
        onStateChange(newState, newLoader);
      };
      memo.set(key, memoCallback);

      return memoCallback;
    };
  }, [onStateChange]);

  //------------------------FETCH_ERROR_HANDLING_BLOCK_START------------------------
  if (joinSessionMutation.error) {
    return (
      <CheckInFailView
        onActive={triggerChangeTo(ViewState.JoinSessionFail, LoaderStatus.Fail)}
        Header={<Trans.FailToConnect.Header />}
        Text={<Trans.FailToConnect.Description />}
      />
    );
  }
  if (appointmentQuery.error) {
    return (
      <CheckInFailView
        onActive={triggerChangeTo(ViewState.JoinSessionFail, LoaderStatus.Fail)}
        Header={<Trans.FailToConnect.Header />}
        Text={<Trans.FailToConnect.Description />}
      />
    );
  }
  if (todayVisitsQuery.error) {
    return (
      <CheckInFailView
        onActive={triggerChangeTo(ViewState.JoinSessionFail, LoaderStatus.Fail)}
        Header={<Trans.FailToConnect.Header />}
        Text={<Trans.FailToConnect.Description />}
      />
    );
  }
  if (patientConsents.error) {
    return (
      <CheckInFailView
        onActive={triggerChangeTo(ViewState.JoinSessionFail, LoaderStatus.Fail)}
        Header={<Trans.FailToConnect.Header />}
        Text={<Trans.FailToConnect.Description />}
      />
    );
  }
  if (duringVisitSelfSignConsents.error) {
    return (
      <CheckInFailView
        onActive={triggerChangeTo(ViewState.JoinSessionFail, LoaderStatus.Fail)}
        Header={<Trans.FailToConnect.Header />}
        Text={<Trans.FailToConnect.Description />}
      />
    );
  }
  //------------------------FETCH_ERROR_HANDLING_BLOCK_END------------------------

  if (!todayVisitsQuery.data) {
    return null;
  }

  // Check if has a visit today
  if (todayVisitsQuery.data.length === 0) {
    return (
      <CheckInFailView
        onActive={triggerChangeTo(ViewState.TodayNoSlots, LoaderStatus.Fail)}
        Header={<Trans.NoAppointment.Header />}
        Text={<Trans.NoAppointment.Description />}
      />
    );
  }

  if (!patient.data || !appointmentQuery.data) {
    return null;
  }

  // check if there is a non checked in early or late arrival
  if (!hasCheckedInSlot(todayVisitsQuery.data)) {
    const appointmentValidity = getAppointmentSlotValidity(
      appointmentQuery.data
    );
    if (appointmentValidity === AppointmentValidity.EarlyArrival) {
      return (
        <CheckInFailView
          onActive={triggerChangeTo(ViewState.CheckInEarly, LoaderStatus.Fail)}
          Header={<Trans.EarlyArrival.Header />}
          Text={<Trans.EarlyArrival.Description />}
        />
      );
    }

    if (appointmentValidity === AppointmentValidity.LateArrival) {
      return (
        <CheckInFailView
          onActive={triggerChangeTo(ViewState.CheckInLate, LoaderStatus.Fail)}
          Header={<Trans.LateArrival.Header />}
          Text={<Trans.LateArrival.Description />}
        />
      );
    }

    if (
      sessionStart &&
      Math.abs(sessionStart.diffNow("second").seconds) > TIME_OUT_TIME_SECONDS
    ) {
      return (
        <CheckInFailView
          onActive={triggerChangeTo(
            ViewState.CheckInTimeOut,
            LoaderStatus.Fail
          )}
          Header={<Trans.UnableToCheckIn.Header />}
          Text={<Trans.UnableToCheckIn.Description />}
        />
      );
    }
    // has slot today but no slot checked in
    return null;
  }

  // member identity is not verified or mfa is not setup
  // identity could be verified but token could be out of sync
  // can't proceed
  if (patient.data && (!patient.data.hasVerifiedIdentity || !mfa)) {
    return (
      <RequiresIdentityVerificationView
        hasMFA={mfa}
        patient={patient.data}
        onActive={triggerChangeTo(
          ViewState.JoinSessionFail,
          LoaderStatus.Complete
        )}
      />
    );
  }

  if (!patientConsents.data || !duringVisitSelfSignConsents.data) {
    return null;
  }

  return (
    <CheckInFlow
      onActive={triggerChangeTo(ViewState.Success, LoaderStatus.Complete)}
      patient={patient.data}
      duringVisitSelfSignConsents={duringVisitSelfSignConsents.data}
      patientConsents={patientConsents.data}
    />
  );
}

export function ConciergeCheckInSessionView({
  sessionId,
}: ConciergeCheckInSessionViewProps) {
  const [loaderActive, setLoaderActive] = useState(true);
  const appInsights = useAppInsights();
  const [status, setStatus] = useState(LoaderStatus.Loading);
  const [viewState, setViewState] = useState<ViewState>(ViewState.Fetching);

  useEffect(() => {
    appInsights.trackEvent({
      name: "concierge-check-in",
      properties: { checkInState: viewState },
    });
  }, [viewState, appInsights]);

  const viewControls = {
    onStateChange: useCallback(
      (viewState: ViewState, loaderStatus: LoaderStatus) => {
        setViewState(viewState);
        setStatus(loaderStatus);
      },
      []
    ),
  };

  const loaderControls = {
    hide: useCallback(() => {
      setLoaderActive(false);
    }, []),
  };

  return (
    <FullScreenPageLayout>
      <div className={styles.ConciergeCheckInView} data-status={status}>
        <ViewStack>
          <section data-active={loaderActive}>
            <div className={styles.body}>
              <div className={styles.content}>
                <Loader status={status} onDone={loaderControls.hide} />
              </div>
            </div>
          </section>
          <section data-active={!loaderActive}>
            <CheckInFlowContainer
              onStateChange={viewControls.onStateChange}
              sessionId={sessionId}
            />
          </section>
        </ViewStack>
      </div>
    </FullScreenPageLayout>
  );
}
