import { ChoiceQuestion } from "@cur8/questionnaire";
import { Trans } from "./transV2";

export enum StrengthExerciseWeeklyHoursEnum {
  None = "Zero hours",
  OneToTwo = "One to two hours",
  ThreeToFour = "Three to four hours",
  MoreThanFive = "More than five hours",
}

const PhysicalActivityDurationChoices: [
  JSX.Element,
  StrengthExerciseWeeklyHoursEnum
][] = [
  [
    <Trans.StrengthExercise.HoursPerWeek hours="0" />,
    StrengthExerciseWeeklyHoursEnum.None,
  ],
  [
    <Trans.StrengthExercise.HoursPerWeek hours="1-2" />,
    StrengthExerciseWeeklyHoursEnum.OneToTwo,
  ],
  [
    <Trans.StrengthExercise.HoursPerWeek hours="3-4" />,
    StrengthExerciseWeeklyHoursEnum.ThreeToFour,
  ],
  [
    <Trans.StrengthExercise.MoreThanPerWeek qty="5" />,
    StrengthExerciseWeeklyHoursEnum.MoreThanFive,
  ],
];

export const StrengthExerciseDuration = new ChoiceQuestion(
  <Trans.StrengthExercise.Question />,
  PhysicalActivityDurationChoices
);
