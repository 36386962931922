import { APITypesV1 } from "@cur8/api-client";
import { APIClient } from "lib/api/client";
import { useCallback, useMemo, useState } from "react";
import { useAPIClient } from "render/context/APIContext";

function joinConciergeSessionMutation(apiClient: APIClient, sessionId: string) {
  return apiClient.checkinSession.joinSession({
    sessionId,
  });
}

export function useJoinConciergeSessionMutation() {
  const apiClient = useAPIClient();
  const [error, setError] = useState<unknown>();
  const [data, setData] = useState<APITypesV1.CheckinSessionState>();

  const mutate = useCallback(
    (sessionId: string) => {
      const req = joinConciergeSessionMutation(apiClient, sessionId);

      req.result.then((data) => {
        setData(data);
        setError(undefined);
      });
      req.result.catch((e) => {
        setError(e);
        setData(undefined);
        throw e;
      });

      return req.result;
    },
    [apiClient]
  );

  return useMemo(() => {
    return {
      mutate,
      error,
      data,
    };
  }, [mutate, error, data]);
}
