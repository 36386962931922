import { fromAPI } from "@cur8/rich-entity";
import { useQuery } from "@tanstack/react-query";
import { APIClient } from "lib/api/client";
import { useAPIClient } from "render/context/APIContext";
import { useSession } from "render/context/MSALContext";
import { ExtractBody, ExtractKey, QueryOptions } from "typings/query";

const queryKey = (patientId: string) => ["appointment-summaries", patientId];
const queryFn = (apiClient: APIClient, patientId: string) => async () => {
  return apiClient.visitSummary
    .querySummary({ patientId }, { pageSize: 100 })
    .result.then(({ items }) => items.map(fromAPI.toVisitSummary));
};

type Body = ExtractBody<typeof queryFn>;
type Key = ExtractKey<typeof queryKey>;

export function useAppointmentSummariesQuery<T = Body>(
  options: QueryOptions<Body, Key, T> = {}
) {
  const { patientId } = useSession();
  const apiClient = useAPIClient();

  return useQuery(queryKey(patientId), queryFn(apiClient, patientId), options);
}

useAppointmentSummariesQuery.queryKey = queryKey;
useAppointmentSummariesQuery.queryFn = queryFn;
