import {
  QuestionAnswer,
  QuestionnaireResponse,
} from "@cur8/api-client/dist/api/cur8/generated-types/v2/Api";
import { HabitualFrequency, HabitualStyle } from "@cur8/measurements";
import { APIClient } from "lib/api/client";
import { v4 } from "uuid";
import { OnboardingData } from "./type";

export function getOnboarding({
  apiClient,
  patientId,
}: {
  apiClient: APIClient;
  patientId: string;
}) {
  return apiClient.questionnaire
    .getQuestionnaireResponses(
      {
        patientId,
      },
      {
        questionnaireType: "onboarding/1",
      }
    )
    .result.then((responses) => {
      //can we assume that the first response is the most recent one?
      const response = responses.items.at(0);

      if (!response?.questionsAndAnswers) {
        return undefined;
      }

      const answersForQuestionId = response.questionsAndAnswers.reduce<
        Record<string, QuestionAnswer["answer"]>
      >((acc, questionAndAnswer) => {
        const questionId = questionAndAnswer.questionId;

        if (!questionId) {
          return acc;
        }

        const answer = questionAndAnswer.answer;

        return {
          ...acc,
          [questionId]: answer,
        };
      }, {});

      const additionalWorries = answersForQuestionId["additionalWorries"];
      const diet = answersForQuestionId["diet"];
      const healthConditions = answersForQuestionId["healthConditions"];
      const healthConditionsFamily =
        answersForQuestionId["healthConditionsFamily"];
      const medication = answersForQuestionId["medication"];
      const ethnicities = answersForQuestionId["ethnicities"];
      const allergies = answersForQuestionId["allergies"];
      const beer = answersForQuestionId["alcohol.beer"];
      const wine = answersForQuestionId["alcohol.wine"];
      const liquor = answersForQuestionId["alcohol.liquor"];
      const weeklyDrinks = answersForQuestionId["alcohol.weeklyDrinks"];
      const weeklyExerciseHours = answersForQuestionId["weeklyExerciseHours"];
      const smokingStyle = answersForQuestionId["smoking.style"];
      const smokingFrequency = answersForQuestionId["smoking.frequency"];
      const dailyCigarettes = answersForQuestionId["smoking.dailyCigarettes"];
      const quitSmokingAt = answersForQuestionId["smoking.quitSmokingAt"];
      const snusStyle = answersForQuestionId["snus.style"];
      const weeklyCans = answersForQuestionId["snus.weeklyCans"];
      const quitSnusingAt = answersForQuestionId["snus.quitSnusingAt"];

      const onboardingData: OnboardingData = {
        responseId: response.responseId ?? v4(),
        state: response.state === "complete" ? "complete" : "incomplete",
        additionalWorries: additionalWorries?.value
          ? String(additionalWorries.value)
          : undefined,
        diet: diet?.value
          ? {
              currentDiet: String(diet.value),
            }
          : undefined,
        healthConditions: healthConditions?.value
          ? { conditions: String(healthConditions.value) }
          : undefined,
        healthConditionsFamily: healthConditionsFamily?.value
          ? { conditions: String(healthConditionsFamily.value) }
          : undefined,
        medication: medication?.value
          ? {
              currentMedication: medication.value as string[],
            }
          : undefined,
        ethnicities: ethnicities?.value
          ? (ethnicities.value as string[])
          : undefined,
        allergies: allergies?.value ? (allergies.value as string[]) : [],
        alcohol: {
          beer: beer?.value ? (beer.value as boolean) : false,
          wine: wine?.value ? (wine.value as boolean) : false,
          liquor: liquor?.value ? (liquor.value as boolean) : false,
          weeklyDrinks: weeklyDrinks?.value
            ? (weeklyDrinks.value as number)
            : 0,
        },
        weeklyExerciseHours: weeklyExerciseHours?.value
          ? {
              value: weeklyExerciseHours.value as number,
            }
          : undefined,
        smoking: {
          style: smokingStyle?.value
            ? {
                value:
                  HabitualStyle[
                    smokingStyle.value as keyof typeof HabitualStyle
                  ] ?? HabitualStyle.Unknown,
              }
            : undefined,
          frequency: smokingFrequency?.value
            ? {
                value:
                  HabitualFrequency[
                    smokingFrequency.value as keyof typeof HabitualFrequency
                  ] ?? HabitualFrequency.Unknown,
              }
            : undefined,
          dailyCigarettes: {
            value: dailyCigarettes?.value
              ? (dailyCigarettes.value as number)
              : 0,
          },
          quitSmokingAt: quitSmokingAt?.value
            ? (quitSmokingAt.value as string)
            : undefined,
        },
        snus: {
          style: snusStyle?.value
            ? {
                value:
                  HabitualStyle[snusStyle.value as keyof typeof HabitualStyle],
              }
            : undefined,
          weeklyCans: weeklyCans?.value
            ? {
                value:
                  HabitualFrequency[
                    weeklyCans.value as keyof typeof HabitualFrequency
                  ],
              }
            : undefined,
          quitSnusingAt: quitSnusingAt?.value
            ? (quitSnusingAt.value as string)
            : undefined,
        },
      };

      return onboardingData;
    });
}

export class InvalidBlobError extends Error {}

export function saveOnboarding({
  apiClient,
  patientId,
  questionnaireResponse,
}: {
  questionnaireResponse: QuestionnaireResponse;
  apiClient: APIClient;
  patientId: string;
}) {
  return apiClient.questionnaire.createOrUpdateQuestionnaireResponse(
    {
      patientId,
      responseId: questionnaireResponse.responseId ?? v4(),
    },
    questionnaireResponse
  );
}
