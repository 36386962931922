import { Answers } from "@cur8/questionnaire";
import { useQuestionnaireLanguageCode } from "lib/questionnaire/locale";
import {
  CardioConditions,
  CardioConditionsEnum,
  CardioConditionsValvularDisease,
} from "lib/questionnaire/question-flows/onboarding/questions/cardioConditions";
import { Trans as TransQuestions } from "lib/questionnaire/question-flows/onboarding/questions/transV2";
import { MultiChoiceInputWithDescriptions } from "render/ui/presentation/Questionnaire/components/MultiChoiceInputWithDescriptions";
import { QuestionView } from "../../components/QuestionView";
import { Step, StepProps } from "../../step";
import { Trans } from "../../trans";

function CardioConditionsStep({
  answers,
  onAnswer,
  onNext,
  onPrev,
  currentQuestion,
  totalQuestions,
  onResetAnswer,
}: StepProps) {
  const answer = answers.get(CardioConditions);
  const label = TransQuestions.CardioConditions.Question();
  const { englishLabel } = TransQuestions.CardioConditions.Question;
  const languageCode = useQuestionnaireLanguageCode();

  return (
    <QuestionView
      currentQuestion={currentQuestion}
      totalQuestionCount={totalQuestions}
      question={CardioConditions}
      onNext={onNext}
      onPrev={() => {
        onPrev();
        onResetAnswer(CardioConditions);
      }}
      cta={<Trans.General.Continue />}
      explanation={{
        openText: <Trans.General.WhyAreWeAsking />,
        closeText: <Trans.General.OkIUnderstand />,
        explanationText: <Trans.Steps.CardioConditionsExplanation />,
      }}
    >
      <MultiChoiceInputWithDescriptions
        question={CardioConditions}
        answer={answer}
        onAnswer={(answer) => {
          onAnswer(CardioConditions, {
            answer,
            questionAnswer: {
              questionId: "cardioConditions",
              englishLabel,
              label,
              languageCode,
              answer: {
                $type: "textarray",
                value: CardioConditions.getValue(answer),
              },
            },
          });
        }}
      />
    </QuestionView>
  );
}

function CardioConditionsValvularDiseaseStep({
  answers,
  onAnswer,
  onNext,
  onPrev,
  currentQuestion,
  totalQuestions,
  onResetAnswer,
}: StepProps) {
  const answer = answers.get(CardioConditionsValvularDisease);
  const label = TransQuestions.CardioConditions.ValvularDiseaseQuestion();
  const { englishLabel } =
    TransQuestions.CardioConditions.ValvularDiseaseQuestion;
  const languageCode = useQuestionnaireLanguageCode();

  return (
    <QuestionView
      currentQuestion={currentQuestion}
      totalQuestionCount={totalQuestions}
      question={CardioConditionsValvularDisease}
      onNext={onNext}
      onPrev={() => {
        onPrev();
        onResetAnswer(CardioConditionsValvularDisease);
      }}
      cta={<Trans.General.Continue />}
      explanation={{
        openText: <Trans.General.WhyAreWeAsking />,
        closeText: <Trans.General.OkIUnderstand />,
        explanationText: (
          <Trans.Steps.CardioConditionsValvularDiseaseExplanation />
        ),
      }}
    >
      <MultiChoiceInputWithDescriptions
        question={CardioConditionsValvularDisease}
        answer={answer}
        onAnswer={(answer) => {
          onAnswer(CardioConditionsValvularDisease, {
            answer,
            questionAnswer: {
              questionId: "cardioConditions.valvularDisease",
              englishLabel,
              label,
              languageCode,
              answer: {
                $type: "textarray",
                value: CardioConditionsValvularDisease.getValue(answer),
              },
            },
          });
        }}
      />
    </QuestionView>
  );
}

export function filter(steps: Step[], answers: Answers) {
  steps.push(CardioConditionsStep);

  if (
    answers
      .lookup(CardioConditions)
      ?.includes(CardioConditionsEnum.ValvularDisease)
  ) {
    steps.push(CardioConditionsValvularDiseaseStep);
  }
}
