import "polyfils";
import React from "react";
import { createRoot } from "react-dom/client";
import { App } from "./render/app";
import { reportWebVitals } from "./reportWebVitals";

const element = document.getElementById("root");
if (element) {
  const root = createRoot(element);
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
