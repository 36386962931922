import { localize } from "render/context/InternationalizationContext";

const Title = localize({
  en_GB: "Something went wrong",
  sv_SE: "Något gick fel",
});

const Paragraph1 = localize({
  en_GB: "We were not able to verify your identity.",
  sv_SE: "Vi kunde inte verifiera din identitet.",
});

const TryAgain = localize({
  en_GB: "Try again",
  sv_SE: "Försök igen",
});

const Skip = localize({
  en_GB: "Setup up later",
  sv_SE: "Lägg till senare",
});

const Done = localize({
  en_GB: "Done",
  sv_SE: "Klart",
});

const Pending = {
  Title: localize({
    en_GB: "We're reviewing your id",
    sv_SE: "Vi kontrollerar din ID-handling",
  }),
  Text: localize({
    en_GB:
      "Thanks for completing this important step. We’ll let you know soon if we need any more info from you.",
    sv_SE:
      "Tack för att du har slutfört detta viktiga steg. Vi meddelar dig om vi behöver någon mer information från dig.",
  }),
};

export const Trans = {
  Pending,
  Title,
  Paragraph1,
  TryAgain,
  Skip,
  Done,
};
