import { ReactComponent as CrossIcon } from "assets/ex.svg";
import { ReactNode } from "react";
import styles from "./styles.module.sass";

interface InputProps {
  required?: boolean;
  label: string | JSX.Element;
  name?: string;
  error?: ReactNode;
  value: string | number;
  onClear: () => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  cta?: {
    label: string | JSX.Element;
    onClick: () => void;
  };
  autocomplete?: React.InputHTMLAttributes<HTMLInputElement>["autoComplete"];
  disabled?: boolean;
  pattern?: string;
  inputmode?: React.InputHTMLAttributes<HTMLInputElement>["inputMode"];
  type?: React.InputHTMLAttributes<HTMLInputElement>["type"];
  hideClearButton?: boolean;
}

export function Input({
  label,
  error,
  onClear,
  value,
  onChange,
  cta,
  autocomplete,
  name,
  disabled,
  required = false,
  inputmode,
  pattern,
  type,
  hideClearButton = false,
}: InputProps) {
  const hasValue = value != null && value !== "";

  return (
    <div className={styles.inputContainer}>
      {error && <div className={styles.error}>{error}</div>}
      {cta && hasValue && (
        <button onClick={cta.onClick} className={styles.cta}>
          {cta.label}
        </button>
      )}
      {!hideClearButton && hasValue && (
        <CrossIcon onClick={onClear} className={styles.clearButton} />
      )}
      <input
        type={type}
        inputMode={inputmode}
        pattern={pattern}
        required={required}
        disabled={disabled}
        name={name}
        onChange={onChange}
        value={value}
        placeholder={" "}
        autoComplete={autocomplete}
        className={styles.input}
        onKeyUp={async (e) => {
          if (e.key === "Enter") {
            await cta?.onClick();
          }
        }}
      />
      <label className={styles.labelTop}>{label}</label>
      <label className={styles.label}>{label}</label>
    </div>
  );
}
