import { PropsWithChildren } from "react";
import styles from "./styles.module.sass";

interface BigButtonProps {
  onClick?: () => void;
}

export function BigButton({
  onClick,
  children,
}: PropsWithChildren<BigButtonProps>) {
  return (
    <button onClick={onClick} className={styles.button}>
      {children}
    </button>
  );
}
