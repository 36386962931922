import { PaymentElement } from "@stripe/react-stripe-js";
import { StripeElement } from "@stripe/stripe-js";

interface PaymentFormProps {
  onReady?: (element: StripeElement) => void;
  active?: boolean;
}

export function PaymentForm({ onReady }: PaymentFormProps) {
  return (
    <PaymentElement
      onReady={(e) => onReady?.(e)}
      options={{
        wallets: {
          googlePay: "never",
          applePay: "never",
        },
      }}
    />
  );
}
