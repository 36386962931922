import { Patient } from "@cur8/rich-entity";
import { PatientName } from "render/fragments/patient/PatientName";
import { PatientPhysicalIdentifier } from "render/fragments/patient/PatientPhysicalIdentifier";
import styles from "./styles.module.sass";
import { Country } from "lib/country";

interface PatientIdentifierProps {
  patient: Patient;
}

export function PatientIdentifier({ patient }: PatientIdentifierProps) {
  const showPatientPhysicalIdentifier =
    patient.preferredCountry === Country.Sweden;

  return (
    <div data-hj-suppress className={styles.container}>
      <div className={styles.name}>
        <PatientName patient={patient} />
      </div>
      <div className={styles.id}>
        {showPatientPhysicalIdentifier ? (
          <PatientPhysicalIdentifier patient={patient} />
        ) : undefined}
      </div>
    </div>
  );
}
