import { APITypesV1 } from "@cur8/api-client";
import { checkIfSigned } from "lib/consents/checkIfSigned";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { useGiveConsentMutation } from "render/hooks/mutations/consent/useGiveConsentMutation";
import { useRevokeConsentMutation } from "render/hooks/mutations/consent/useRevokeConsentMutation";
import { ReactComponent as ArrowIcon } from "render/ui/icons/arrow.svg";
import { BurgerLayout } from "render/ui/layout/BurgerLayout";
import { FullScreenPageLayout } from "render/ui/layout/FullScreenPageLayout";
import { LogoHeader } from "render/ui/layout/LogoHeader";
import { IconButton } from "render/ui/trigger/IconButton";
import { ConsentDetails } from "render/views/ProfileView/components/ConsentDocument/components/ConsentDetails";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

interface ConsentDocumentProps {
  allowEdit?: boolean;
  onClose: () => void;
  onToggle?: () => void;
  study: APITypesV1.ConsentSummary | undefined;
  documentUrl?: string;
}

export function ConsentDocument({
  allowEdit = true,
  onClose,
  study,
  onToggle,
  documentUrl,
}: ConsentDocumentProps) {
  const { mutate: giveConsentMutation, status: giveConsentStatus } =
    useGiveConsentMutation();
  const { mutate: revokeConsentMutation, status: revokeConsentStatus } =
    useRevokeConsentMutation();
  const { enqueueSnackbar } = useSnackbar();
  const [checked, setChecked] = useState(checkIfSigned(study));

  // required to keep checked in sync with any actions on other pages
  useEffect(() => {
    setChecked(checkIfSigned(study));
  }, [study]);

  const documentUri = study?.documentUrl || documentUrl;

  const onClick = useCallback(
    (isChecked: boolean) => {
      if (isChecked) {
        giveConsentMutation({
          documentUri,
          consentMetadataId: study?.consentId,
        });
        setChecked(isChecked);
      } else {
        if (study) {
          revokeConsentMutation({ consentId: study.id });
          setChecked(isChecked);
        }
      }
      onToggle && onToggle();
      if (giveConsentStatus === "error") {
        enqueueSnackbar(<Trans.ConsentError />, {
          variant: "error",
        });
        setChecked(!isChecked);
      }
      if (revokeConsentStatus === "error") {
        enqueueSnackbar(<Trans.RemoveConsentError />, {
          variant: "error",
        });
        setChecked(!isChecked);
      }
    },
    [
      documentUri,
      enqueueSnackbar,
      giveConsentMutation,
      giveConsentStatus,
      onToggle,
      revokeConsentMutation,
      revokeConsentStatus,
      study,
    ]
  );

  return (
    <FullScreenPageLayout>
      <BurgerLayout>
        <div className={styles.header}>
          <LogoHeader
            leftElement={
              <IconButton
                ariaLabel={Trans.Arrow()}
                onClick={onClose}
                icon={<ArrowIcon />}
              />
            }
          />
        </div>
        <div data-hj-suppress className={styles.body}>
          <ConsentDetails
            onClick={onClick}
            allowEdit={allowEdit}
            checked={checked}
            study={study}
            documentUrl={documentUrl}
          />
        </div>
        {null}
      </BurgerLayout>
    </FullScreenPageLayout>
  );
}
