import { localize } from "render/context/InternationalizationContext";

const Error = {
  FailedToSaveMetric: localize({
    en_GB: <>An error occurred saving your data</>,
    sv_SE: <>Ett fel uppstod när ditt data skulle sparas</>,
  }),
  FailedToGiveConsent: localize({
    en_GB: <>An unexpected error happend</>,
    sv_SE: <>Ett oväntat fel har inträffat</>,
  }),
};

const StudyConsent = {
  Title: localize({
    en_GB: <>Your participation in our clinical studies</>,
    sv_SE: <>Ditt deltagande i våra kliniska studier.</>,
  }),
  Paragraph1: localize({
    en_GB: (
      <>
        Your health data helps us develop our tools so we can identify diseases
        and prevent or treat them earlier. As part of the Neko Body Scan we
        conduct clinical studies using novel scanning techniques.
      </>
    ),
    sv_SE: (
      <>
        Dina hälsouppgifter hjälper oss att utveckla våra verktyg så att vi kan
        hitta sjukdomar ännu snabbare och bättre. För att göra en Neko Body Scan
        behöver vi ditt samtycke till att deltaga i våra kliniska studier.
      </>
    ),
  }),
  Paragraph2: localize({
    en_GB: (
      <>
        Please access the study information via the following links and take
        your time to read the information carefully before providing your
        consent to participate:
      </>
    ),
    sv_SE: (
      <>
        Var god ta del av informationen om våra studier genom att följa länkarna
        nedan och ta dig tid att noggrant läsa informationen innan du ger ditt
        samtycke:
      </>
    ),
  }),
  ParticipationInfoPart1: localize({
    en_GB: (
      <>
        You can manage and change your participation any time on your profile or
        by contacting Neko Health AB which is the data controller and study
        principal by email at{" "}
        <a href="mailto:research@nekohealth.com">research@nekohealth.com</a>.
      </>
    ),
    sv_SE: (
      <>
        Du kan hantera och ändra ditt deltagande när som helst på din profil
        eller genom att kontakta Neko Health AB, huvudansvarig för studien, via
        e-post på{" "}
        <a href="mailto:research@nekohealth.com">research@nekohealth.com</a>.
      </>
    ),
  }),
  ParticipationInfoPart2: localize({
    en_GB: (
      <>
        I hereby consent to participation in the studies. I acknowledge that
        participation is voluntary and that I may choose to withdraw from
        participating at any time, even after I have provided my consent.
      </>
    ),
    sv_SE: (
      <>
        Jag ger härmed mitt samtycke till att delta i studierna. Jag erkänner
        att deltagande är frivilligt och att jag när som helst kan välja att
        avstå från att delta, även efter att jag har gett mitt samtycke.
      </>
    ),
  }),
  ConsentAndParticipateButton: localize({
    en_GB: <>Consent and Participate</>,
    sv_SE: <>Samtyck och delta</>,
  }),
};

export const Trans = { Error, StudyConsent };
