import { APITypesV1, APITypesV2 } from "@cur8/api-client";
import { ImmutableScan, Patient, Slot, VisitSummary } from "@cur8/rich-entity";
import { useNav, useQueryParams } from "@pomle/react-router-paths";
import { ReactComponent as ArrowBigIcon } from "assets/arrow-big.svg";
import { DateTime } from "luxon";
import { useEffect, useMemo, useState } from "react";
import { useSession } from "render/context/MSALContext";
import { useAppointmentQuery } from "render/hooks/api/queries/useAppointmentQuery";
import { useBookingTokens } from "render/hooks/api/queries/useBookingTokens";
import { useInvitationCodesQuery } from "render/hooks/api/queries/useInvitationCodesQuery";
import { useLatestScanQuery } from "render/hooks/api/queries/useLatestScanQuery";
import { usePatientQuery } from "render/hooks/api/queries/usePatientQuery";
import { useUpcomingVisitQuery } from "render/hooks/api/queries/useUpcomingVisitQuery";
import { useAppointmentSummary } from "render/hooks/api/useAppointmentSummary";
import { useTracking } from "render/hooks/useTracking";
import { paths, queries } from "render/routes/paths";
import { BurgerLayout } from "render/ui/layout/BurgerLayout";
import { FullScreenPageLayout } from "render/ui/layout/FullScreenPageLayout";
import { Typography } from "render/ui/presentation/Typography";
import { BookStage } from "./components/BookStage";
import { BookingUpcomingStage } from "./components/BookingUpcomingStage";
import { GiveConsentStage } from "./components/GiveConsentStage";
import { Header } from "./components/Header";
import { VisitSummaryReadyStage } from "./components/VisitSummaryReadyStage";
import styles from "./styles.module.sass";
import { homeEvent } from "./tracking";
import { Trans } from "./trans";

interface HomeViewContentRendererProps {
  patient: Patient;
  scan?: ImmutableScan;
  appointments: VisitSummary[];
  bookingTokens: APITypesV2.BookingTokenV2[];
  upcomingSlot?: Slot;
}

function HomeViewContentRenderer({
  patient,
  appointments,
  upcomingSlot,
  bookingTokens,
  scan,
}: HomeViewContentRendererProps) {
  const { consent } = patient;

  const showConsentScreen = useMemo(() => {
    const hasConsent = consent.createdAt;
    const consentHasExpired =
      consent.expiresAt && consent.expiresAt < DateTime.now();
    return !hasConsent || consentHasExpired;
  }, [consent]);

  if (
    showConsentScreen &&
    patient.primaryConsentRuleset === APITypesV1.ConsentRuleset.Gdpr
  ) {
    return <GiveConsentStage />;
  }

  if (appointments.length > 0 || scan) {
    return (
      <VisitSummaryReadyStage
        visitSummary={appointments[0]}
        scan={scan}
        slot={upcomingSlot}
        bookingTokens={bookingTokens}
        patient={patient}
      />
    );
  }
  if (showConsentScreen) {
    return <GiveConsentStage />;
  }

  if (upcomingSlot) {
    return <BookingUpcomingStage patient={patient} slot={upcomingSlot} />;
  } else {
    return <BookStage bookingTokens={bookingTokens} patient={patient} />;
  }
}

export function HomeView() {
  const { trackEvent } = useTracking();
  const { isNewUser, mfa } = useSession();
  const [isReady, setIsReady] = useState(false);
  const [params] = useQueryParams(queries.payment);

  const nav = {
    discountTokens: useNav(paths.profileTokens),
    contact: useNav(paths.contact),
    checkout: useNav(paths.checkout, queries.payment),
    idv: useNav(paths.appointmentPrereq.identityVerification),
  };
  const patientQuery = usePatientQuery();
  const bookingTokensQuery = useBookingTokens();
  const latestSkinScanQuery = useLatestScanQuery("skin/", { enabled: mfa });
  const appointmentSummaryQuery = useAppointmentSummary({ enabled: mfa });
  const upcomingVisitQuery = useUpcomingVisitQuery();

  const visit = upcomingVisitQuery.data?.at(0);

  const upcomingSlotQuery = useAppointmentQuery(
    { slotId: visit?.slotId },
    { enabled: visit?.slotId != null }
  );

  const upcomingSlot = upcomingSlotQuery.data;

  const upcomingVisitsCount = upcomingVisitQuery.data
    ? upcomingVisitQuery.data.length
    : undefined;
  const bookingTokensCount = bookingTokensQuery.data
    ? bookingTokensQuery.data?.length
    : undefined;
  const hasCode = params.code.length !== 0;

  useEffect(() => {
    if (isReady) {
      return;
    }

    if (!isNewUser && !hasCode) {
      setIsReady(true);
      return;
    }
    if (upcomingVisitsCount == null || bookingTokensCount == null) {
      return;
    }
    if (upcomingVisitsCount === 0 && bookingTokensCount === 0) {
      nav.checkout.go({}, { code: params.code });
      return;
    }

    setIsReady(true);
  }, [
    isReady,
    bookingTokensCount,
    hasCode,
    isNewUser,
    nav.checkout,
    params.code,
    upcomingVisitsCount,
  ]);

  const invitationCodeQuery = useInvitationCodesQuery({ enabled: mfa });

  const availableDiscountTokensCount = useMemo(() => {
    if (!invitationCodeQuery.data) {
      return;
    }
    let partialDiscounts = invitationCodeQuery.data.filter(
      (token) =>
        token.discountTokenType ===
          APITypesV1.DiscountTokenType.SinglePartialDiscount &&
        token.isRedeemed === false
    );
    let fullDiscounts = invitationCodeQuery.data.filter(
      (token) =>
        token.discountTokenType ===
          APITypesV1.DiscountTokenType.SingleFullDiscount &&
        token.isRedeemed === false
    );
    return partialDiscounts.length
      ? partialDiscounts.length
      : fullDiscounts.length;
  }, [invitationCodeQuery.data]);
  const patient = useMemo(() => patientQuery.data, [patientQuery.data]);

  return (
    <FullScreenPageLayout disableBackground>
      <BurgerLayout>
        {isReady && patient && <Header patient={patient} />}
        <div className={styles.body}>
          <div className={styles.content}>
            {(() => {
              if (!patientQuery.data) {
                return null;
              }

              if (!isReady) {
                return null;
              }

              return (
                <>
                  <HomeViewContentRenderer
                    patient={patientQuery.data}
                    scan={latestSkinScanQuery.data?.[0]}
                    bookingTokens={bookingTokensQuery.data ?? []}
                    appointments={appointmentSummaryQuery.data ?? []}
                    upcomingSlot={upcomingSlot}
                  />
                  {availableDiscountTokensCount != null ? (
                    <button
                      className={styles.actionButton}
                      onClick={() => {
                        trackEvent(homeEvent.openInvitesClick());
                        nav.discountTokens.go({});
                      }}
                    >
                      <div>
                        <Typography variant="cta">
                          <Trans.Invite.Title />
                        </Typography>
                        <Typography variant="paragraph-sub">
                          <Trans.Invite.Description
                            tokenCount={availableDiscountTokensCount}
                          />
                        </Typography>
                      </div>
                      <ArrowBigIcon />
                    </button>
                  ) : null}
                </>
              );
            })()}
          </div>
        </div>
        <></>
      </BurgerLayout>
    </FullScreenPageLayout>
  );
}
