import { ReactComponent as PlusIcon } from "assets/plus.svg";
import React, { useState } from "react";
import { Typography } from "render/ui/presentation/Typography";
import styles from "./styles.module.sass";

type AccordionItemProps = {
  header: React.ReactNode;
  text: React.ReactNode;
  id: string;
  isOpen?: boolean;
};

function AccordionItem({
  header,
  text,
  id,
  isOpen: isExpanded = false,
}: AccordionItemProps) {
  const [isOpen, setIsOpen] = useState(isExpanded);
  return (
    <div className={styles.item}>
      <h3
        className={styles.header}
        onClick={() => setIsOpen((isOpen) => !isOpen)}
      >
        <button
          className={styles.button}
          aria-expanded={isOpen}
          aria-controls={`${id}-panel`}
        >
          <Typography variant="paragraph">{header}</Typography>
        </button>
        <PlusIcon
          className={styles.icon}
          data-open={isOpen}
          aria-hidden={true}
        />
      </h3>
      <section
        className={styles.expandable}
        data-open={isOpen}
        id={`${id}-panel`}
        hidden={!isOpen}
      >
        <div className={styles.content}>
          <div className={styles.contentSpacing}>
            <Typography variant="paragraph">{text}</Typography>
          </div>
        </div>
      </section>
    </div>
  );
}

Accordion.Item = AccordionItem;

type AccordionProps = {
  children: React.ReactNode;
};

export function Accordion({ children }: AccordionProps) {
  return <div className={styles.Accordion}>{children}</div>;
}
