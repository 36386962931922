import { Patient } from "@cur8/rich-entity";
import { useMemo } from "react";

interface PatientNameProps {
  patient: Patient;
}

export function PatientName({ patient }: PatientNameProps) {
  const { name } = patient;

  const result = useMemo(() => {
    if (name?.displayName) {
      return name.displayName;
    }

    const patientName = [name?.firstName, name?.lastName]
      .filter((n) => n !== undefined)
      .join(" ");

    if (patientName.length === 0) {
      return "----";
    }

    return patientName;
  }, [name?.firstName, name?.lastName, name?.displayName]);

  return <>{result}</>;
}
