import { ChoiceQuestion } from "@cur8/questionnaire";
import { RecencyChoices } from "./choiceV2";
import { Trans } from "./transV2";

export enum IsSnusingEnum {
  No = "No",
  Quit = "Quit",
  Yes = "Yes",
}

export enum SnusCanCountEnum {
  AFewTimesAMonth = "A few times a month",
  OnWeekends = "On weekends",
  EveryOtherDay = "Every other day",
  EveryDay = "Every day",
}

export const IsSnuser = new ChoiceQuestion(<Trans.Snus.Question />, [
  [<Trans.Snus.No />, IsSnusingEnum.No],
  [<Trans.Snus.Quit />, IsSnusingEnum.Quit],
  [<Trans.Snus.Yes />, IsSnusingEnum.Yes],
]);

const SnusCanCountChoices: [string | JSX.Element, SnusCanCountEnum][] = [
  [
    <Trans.Snus.SnusFrequencyAFewTimesAMonth />,
    SnusCanCountEnum.AFewTimesAMonth,
  ],
  [<Trans.Snus.SnusFrequencyOnWeekends />, SnusCanCountEnum.OnWeekends],
  [<Trans.Snus.SnusFrequencyEveryOtherDay />, SnusCanCountEnum.EveryOtherDay],
  [<Trans.Snus.SnusFrequencyEveryDay />, SnusCanCountEnum.EveryDay],
];

export const SnusHowOften = new ChoiceQuestion(
  <Trans.Snus.HowOften />,
  SnusCanCountChoices
);

export const QuitSnusingRecency = new ChoiceQuestion(
  <Trans.Snus.QuitSnusingRecency />,
  RecencyChoices
);
