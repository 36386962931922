import { PathRoute } from "@pomle/react-router-paths";
import { Sticky, ViewStack } from "@pomle/react-viewstack";
import { Direction, Fade } from "@pomle/react-viewstack-transitions";
import { useBookingTokens } from "render/hooks/api/queries/useBookingTokens";
import { paths } from "render/routes/paths";
import { mount } from "render/routes/routes/SessionRoutes/lib/mount";
import { ShareBookingView } from "render/views/ShareBookingView";
import { MultiSiteMonthView } from "render/views/booking/MultiSiteMonthView";
import { PaymentRequiredView } from "render/views/booking/PaymentRequiredView";

function PaymentWall() {
  const bookingTokens = useBookingTokens();

  const isReady = bookingTokens.data != null;
  const canBook = !!bookingTokens.data?.length;

  return (
    <ViewStack>
      <Fade active={isReady && !canBook}>
        <Sticky>{!canBook && <PaymentRequiredView />}</Sticky>
      </Fade>
      <Fade active={isReady && canBook}>
        <Sticky>{canBook && <MultiSiteMonthView />}</Sticky>
      </Fade>
    </ViewStack>
  );
}

export function BookingRoutes() {
  return (
    <>
      <PathRoute path={paths.booking}>
        {mount(PaymentWall, { animation: "slide", direction: Direction.Down })}
      </PathRoute>
      <PathRoute path={paths.shareBooking}>
        {mount(ShareBookingView, {
          animation: "slide",
          direction: Direction.Down,
        })}
      </PathRoute>
    </>
  );
}
