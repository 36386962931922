import { MultiChoiceQuestion } from "@cur8/questionnaire";
import { Trans } from "./transV2";

export enum Heritage {
  Unknown = "unknown",
  Europe = "europe",
  NorthAmerica = "north-america",
  CentralAmerica = "central-america",
  SouthAmerica = "south-america",
  NorthAsia = "north-asia",
  MiddleEast = "middle-east",
  EastAsia = "east-asia",
  SouthAsia = "south-asia",
  SouthEastAsia = "south-east-asia",
  Oceania = "oceania",
  NorthAfrica = "north-africa",
  WestAfrica = "west-africa",
  EastAfrica = "east-africa",
  SouthAfrica = "south-africa",
}

export const HeritageChoices: [string | JSX.Element, Heritage][] = [
  [<Trans.WorldRegion.Europe />, Heritage.Europe],
  [<Trans.WorldRegion.NorthAmerica />, Heritage.NorthAmerica],
  [<Trans.WorldRegion.CentralAmerica />, Heritage.CentralAmerica],
  [<Trans.WorldRegion.SouthAmerica />, Heritage.SouthAmerica],
  [<Trans.WorldRegion.NorthAsia />, Heritage.NorthAsia],
  [<Trans.WorldRegion.MiddleEast />, Heritage.MiddleEast],
  [<Trans.WorldRegion.EastAsia />, Heritage.EastAsia],
  [<Trans.WorldRegion.SouthAsia />, Heritage.SouthAsia],
  [<Trans.WorldRegion.SouthEastAsia />, Heritage.SouthEastAsia],
  [<Trans.WorldRegion.Oceania />, Heritage.Oceania],
  [<Trans.WorldRegion.NorthAfrica />, Heritage.NorthAfrica],
  [<Trans.WorldRegion.WestAfrica />, Heritage.WestAfrica],
  [<Trans.WorldRegion.EastAfrica />, Heritage.EastAfrica],
  [<Trans.WorldRegion.SouthAfrica />, Heritage.SouthAfrica],
];

export const FamilyHeritage = new MultiChoiceQuestion(
  <Trans.Heritage.Question />,
  HeritageChoices
);
