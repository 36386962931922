import { PathRoute, useNav } from "@pomle/react-router-paths";
import { Sticky, ViewStack } from "@pomle/react-viewstack";
import { Direction, Slide } from "@pomle/react-viewstack-transitions";
import { ReactComponent as CrossIcon } from "assets/cross.svg";
import { useCallback, useMemo, useState } from "react";
import { useInvitationCodesQuery } from "render/hooks/api/queries/useInvitationCodesQuery";
import { useLatestConsentsQuery } from "render/hooks/api/queries/usePatientConsentsQuery";
import { useTracking } from "render/hooks/useTracking";
import { paths } from "render/routes/paths";
import { redirectTo } from "render/routes/routes/SessionRoutes/lib/redirectTo";
import { Backdrop } from "render/ui/layout/Backdrop/Backdrop";
import { FullScreenPageLayout } from "render/ui/layout/FullScreenPageLayout";
import { LogoHeader } from "render/ui/layout/LogoHeader";
import { Nav } from "render/ui/layout/Nav";
import { IconButton } from "render/ui/trigger/IconButton";
import { GdprExport } from "render/views/ProfileView/components/DataAndPrivacySection/components/GdprExport";
import { ConsentDocument } from "./components/ConsentDocument";
import { DataAndPrivacySection } from "./components/DataAndPrivacySection";
import { DiscountTokensSection } from "./components/DiscountTokensSection";
import { Profile } from "./components/PatientProfile";
import { EditEmailPage } from "./components/PatientProfile/components/EditEmailPage";
import { EditLanguagePage } from "./components/PatientProfile/components/EditLanguagePage/EditLanguagePage";
import { EditNamePage } from "./components/PatientProfile/components/EditNamePage";
import { EditPhoneNumberPage } from "./components/PatientProfile/components/EditPhoneNumberPage";
import styles from "./styles.module.sass";
import { profileEvent } from "./tracking";
import { Trans } from "./trans";

export function ProfileView() {
  const nav = {
    root: useNav(paths.root),
    credentials: useNav(paths.profileCredentials),
    dataAndPrivacy: useNav(paths.dataAndPrivacy),
  };
  const { trackEvent } = useTracking();
  const invitationCodesQuery = useInvitationCodesQuery();
  const [selectedConsentId, setOpenedConsentId] = useState<string>();
  const { data } = useLatestConsentsQuery();

  const selectedConsent = useMemo(() => {
    if (data == null) {
      return undefined;
    }
    return data.find(
      (consent) => consent?.relevantSignature?.consentId === selectedConsentId
    );
  }, [data, selectedConsentId]);

  const onBackClick = useCallback(() => {
    trackEvent(profileEvent.backButtonClick());
    nav.root.go({});
  }, [nav.root, trackEvent]);

  return (
    <ViewStack>
      <FullScreenPageLayout>
        <div className={styles.header}>
          <LogoHeader
            leftElement={
              <IconButton
                onClick={onBackClick}
                icon={<CrossIcon />}
                ariaLabel={Trans.TabHeader.Cross()}
              />
            }
          />
          <div className={styles.tabs}>
            <Nav>
              <Nav.Item
                label={<Trans.TabHeader.General />}
                path={paths.profileCredentials}
                params={{}}
                onClick={() => trackEvent(profileEvent.clickTab("info"))}
                exact={false}
              />
              <Nav.Item
                label={<Trans.TabHeader.DataAndPrivacy />}
                path={paths.dataAndPrivacy}
                params={{}}
                onClick={() =>
                  trackEvent(profileEvent.clickTab("data and privacy"))
                }
                exact={false}
              />
              <Nav.Item
                label={<Trans.TabHeader.Discounts />}
                path={paths.profileTokens}
                params={{}}
                onClick={() => trackEvent(profileEvent.clickTab("invites"))}
              />
            </Nav>
          </div>
          <div className={styles.frame} />
        </div>
        <div className={styles.content}>
          <PathRoute path={paths.profile}>
            {redirectTo({ to: paths.profileCredentials.url, replace: true })}
          </PathRoute>
          <PathRoute path={paths.profileCredentials}>
            {(match) => (
              <div
                className={styles.tab}
                data-active={!!match}
                aria-hidden={!match}
              >
                <div className={styles.tabContent}>
                  <div className={styles.content}>
                    <Profile />
                  </div>
                </div>
              </div>
            )}
          </PathRoute>
          <PathRoute path={paths.dataAndPrivacy}>
            {(match) => (
              <div
                className={styles.tab}
                data-active={!!match}
                aria-hidden={!match}
              >
                <div className={styles.tabContent}>
                  <DataAndPrivacySection
                    onOpenConsentClick={(id) => setOpenedConsentId(id)}
                  />
                </div>
              </div>
            )}
          </PathRoute>
          <PathRoute path={paths.profileTokens}>
            {(match) => (
              <div
                className={styles.tab}
                data-active={!!match}
                aria-hidden={!match}
              >
                <div className={styles.tabContent}>
                  {invitationCodesQuery.data && (
                    <DiscountTokensSection tokens={invitationCodesQuery.data} />
                  )}
                </div>
              </div>
            )}
          </PathRoute>
        </div>
      </FullScreenPageLayout>
      <Slide active={selectedConsentId != null} direction={Direction.Right}>
        <Sticky>
          {selectedConsentId != null &&
          selectedConsent?.relevantSignature != null ? (
            <ConsentDocument
              key={selectedConsentId}
              study={selectedConsent.relevantSignature}
              onClose={() => setOpenedConsentId(undefined)}
            />
          ) : null}
        </Sticky>
      </Slide>
      <PathRoute path={paths.profileCredentialsEditName}>
        {(match) => (
          <Backdrop active={!!match} onOutsideClick={nav.credentials.on({})}>
            <Sticky>{match && <EditNamePage />}</Sticky>
          </Backdrop>
        )}
      </PathRoute>
      <PathRoute path={paths.profileCredentialsEditPhone}>
        {(match) => (
          <Backdrop active={!!match} onOutsideClick={nav.credentials.on({})}>
            <Sticky>{match && <EditPhoneNumberPage />}</Sticky>
          </Backdrop>
        )}
      </PathRoute>
      <PathRoute path={paths.profileCredentialsEditEmail}>
        {(match) => (
          <Backdrop active={!!match} onOutsideClick={nav.credentials.on({})}>
            <Sticky>{match && <EditEmailPage />}</Sticky>
          </Backdrop>
        )}
      </PathRoute>
      <PathRoute path={paths.profileCredentialsEditLanguage}>
        {(match) => (
          <Backdrop active={!!match} onOutsideClick={nav.credentials.on({})}>
            <Sticky>{match && <EditLanguagePage />}</Sticky>
          </Backdrop>
        )}
      </PathRoute>
      <PathRoute path={paths.dataAndPrivacyExportData}>
        {(match) => (
          <Backdrop active={!!match} onOutsideClick={nav.dataAndPrivacy.on({})}>
            <Sticky>{match && <GdprExport />}</Sticky>
          </Backdrop>
        )}
      </PathRoute>
    </ViewStack>
  );
}
