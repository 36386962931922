import { DateTime } from "luxon";
import { useMemo } from "react";
import { Separator } from "render/ui/presentation/Separator";
import { Typography } from "render/ui/presentation/Typography";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

interface NextScanRecomendationProps {
  scanDate: DateTime;
  followUpDate: DateTime;
}

export function NextScanRecomendation({
  followUpDate,
  scanDate,
}: NextScanRecomendationProps) {
  const months = useMemo(
    () => followUpDate.diff(scanDate, "months").months,
    [scanDate, followUpDate]
  );

  const isDue = useMemo(() => {
    return followUpDate.diffNow("month").months < 1;
  }, [followUpDate]);

  const dateFormated = useMemo(
    () => followUpDate.toFormat("MMMM yyyy"),
    [followUpDate]
  );

  return (
    <div>
      <Typography
        variant="subtitle-medium"
        style={{
          textAlign: "left",
        }}
      >
        <Trans.NextScan />
      </Typography>
      <Separator width="24px" margin="24px 0 20px 0" />
      <div className={styles.followUp}>
        {isDue ? (
          <Trans.FollowUpDue date={dateFormated} />
        ) : (
          <Trans.FollowUpIn date={dateFormated} months={months} />
        )}
      </div>
    </div>
  );
}
