import { useQueryParams } from "@pomle/react-router-paths";
import { queries } from "render/routes/paths";
import { MetricDetails } from "render/views/Report/components/MetricsSection/components/layouts/Details";
import { useGripStrengthValues } from "render/views/Report/components/MetricsSection/components/metrics/MetricGripStrength/useGripStrengthValues";
import { useGripStrengthMetric } from "render/views/Report/components/MetricsSection/hooks/usePatientReportMetrics";
import { Trans } from "./trans";

export function MetricGripStrengthDetails() {
  const [params, setParams] = useQueryParams(queries.metric);
  const { data } = useGripStrengthMetric();
  const side = params.side[0] ?? "left";
  const setSide = (side: "left" | "right") =>
    setParams({
      side: [side],
    });

  const numOfScansLeft = data?.left.length || 1;
  const numOfScansRight = data?.right.length || 1;
  const numOfScans = side === "left" ? numOfScansLeft : numOfScansRight;

  const scanNum = params.scan[0] ?? numOfScans;
  const setScanNum = (num: number) =>
    setParams({
      scan: [num],
    });
  const values = useGripStrengthValues({ scanNum, side });

  return (
    <MetricDetails
      label={<Trans.MetricName />}
      values={values}
      scanNum={scanNum}
      setScanNum={setScanNum}
      side={side}
      setSide={setSide}
      numOfScans={numOfScans}
      FAQText={Trans.FAQ}
    />
  );
}
