import {
  Answer,
  createMultiTextAnswer,
  isMultiTextResponse,
} from "@cur8/questionnaire";
import { useCallback, useMemo } from "react";
import { Typography } from "render/ui/presentation/Typography";
import { ReactComponent as TickIcon } from "./assets/tick.svg";
import { Autocomplete } from "./components/Input";
import styles from "./styles.module.sass";

interface MultiTextInputProps {
  answer?: Answer;
  onAnswer: (answer: Answer<any>) => void;
  addLabel: string | JSX.Element;
  placeholder?: string;
}

export function MultiTextInput({
  answer,
  onAnswer,
  addLabel,
  placeholder,
}: MultiTextInputProps) {
  const choices = useMemo(() => {
    if (answer && isMultiTextResponse(answer.response)) {
      return answer.response.choices;
    }
    return [];
  }, [answer]);

  const onChange = useCallback(
    (value: string, index: number) => {
      const newList = [...choices];
      newList[index] = value;
      onAnswer(createMultiTextAnswer(newList));
    },
    [choices, onAnswer]
  );

  const onAdd = useCallback(() => {
    onAnswer(createMultiTextAnswer([...choices, ""]));
  }, [onAnswer, choices]);

  const onRemove = useCallback(
    (index: number) => {
      const newList = choices.filter((_, i) => i !== index);
      onAnswer(createMultiTextAnswer(newList));
    },
    [choices, onAnswer]
  );

  return (
    <div data-hj-suppress className={styles.MultiTextInput}>
      <ul className={styles.list}>
        {choices.map((value, index) => (
          <li key={index} className={styles.listItem}>
            <button
              className={styles.clearButton}
              onClick={() => onRemove(index)}
            >
              <div className={styles.clearButtonIcon}>
                <TickIcon />
              </div>
            </button>
            <Autocomplete
              placeholder={placeholder}
              onChange={(v) => onChange(v, index)}
              value={value}
            />
          </li>
        ))}
      </ul>
      <button className={styles.addButton} onClick={onAdd}>
        <div className={styles.actionIconContainer}>
          <div className={styles.actionIcon} />
        </div>
        <Typography variant="cta">{addLabel}</Typography>
      </button>
    </div>
  );
}
