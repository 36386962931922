import { PropsWithChildren, useCallback, useRef } from "react";
import styles from "./styles.module.sass";

interface BackdropProps {
  active: boolean;
  onOutsideClick?: () => void;
  isTransparent?: boolean;
  isHalfWidth?: boolean;
}

export function Backdrop({
  children,
  active,
  onOutsideClick,
  isTransparent = false,
  isHalfWidth = false,
}: PropsWithChildren<BackdropProps>) {
  const ref = useRef<HTMLDivElement>(null);
  const onBackgroundClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (e.target !== ref.current) {
        return;
      }
      onOutsideClick?.();
    },
    [onOutsideClick]
  );

  return (
    <div
      ref={ref}
      className={styles.Backdrop}
      data-active={active}
      onClick={onBackgroundClick}
      data-transparent={isTransparent}
    >
      <div className={styles.content} data-half-width={isHalfWidth}>
        {children}
      </div>
    </div>
  );
}
