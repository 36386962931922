import { localize } from "render/context/InternationalizationContext";

export const Trans = {
  Off: localize({
    en_GB: "Off",
    sv_SE: "Av",
  }),
  On: localize({
    en_GB: "On",
    sv_SE: "På",
  }),
  YouDontParticipate: localize({
    en_GB: "You have not given your consent",
    sv_SE: "Du har inte gett ditt samtycke",
  }),
  YouGaveYourConsentAt: localize({
    en_GB: "You gave your consent on",
    sv_SE: "Du gav ditt samtycke",
  }),
  Label: localize({
    en_GB: "Consent",
    sv_SE: "Samtycke",
  }),
};
