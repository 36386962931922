import { Choice } from "@cur8/questionnaire";
import { ReactComponent as CheckIcon } from "assets/check.svg";
import { Typography } from "render/ui/presentation/Typography";
import styles from "./styles.module.sass";

type CheckboxProps<T> = {
  choiceIndex: number;
  checked: boolean;
  choiceDescription?: React.ReactNode;
  choice: Choice<T, React.ReactNode>;
  handleChoice: (choiceIndex: number) => void;
};

export function Checkbox<T>({
  choiceIndex,
  checked,
  choiceDescription,
  choice,
  handleChoice,
}: CheckboxProps<T>) {
  return (
    <label
      className={styles.input}
      key={choiceIndex}
      data-checked={checked}
      htmlFor={choice.value as string}
    >
      <input
        className={styles.checkbox}
        type="checkbox"
        checked={checked}
        value={choice.statement as string}
        id={choice.value as string}
        name={choice.value as string}
        onChange={() => handleChoice(choiceIndex)}
      />
      <div className={styles.content}>
        {choiceDescription ? (
          <div>
            <Typography variant="cta">{choice.statement}</Typography>
            <Typography variant="paragraph-small" color="subtle">
              {choiceDescription}
            </Typography>
          </div>
        ) : (
          <Typography variant="cta">{choice.statement}</Typography>
        )}
        <div
          className={styles.checkboxIcon}
          data-checked={checked}
          aria-hidden={true}
        >
          <CheckIcon />
        </div>
      </div>
    </label>
  );
}
