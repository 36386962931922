import { BookableSlot, fromAPI } from "@cur8/rich-entity";
import { silenceAbort } from "lib/error";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { querySlots } from "render/views/booking/MultiSiteMonthView/api/slot";
import styles from "./styles.module.sass";

interface DayProps {
  now: DateTime;
  date: DateTime;
  siteIds: string[];
  onClick: (date: DateTime) => void;
  selected?: boolean;
  isToday?: boolean;
}

export function Day({
  date,
  siteIds,
  onClick,
  isToday,
  selected,
  now,
}: DayProps) {
  const api = useAPIClient();
  const [slots, setSlots] = useState<BookableSlot[]>();
  const isInThePast = date.startOf("day") < now.startOf("day");

  useEffect(() => {
    if (isInThePast) {
      return;
    }
    const interval = {
      from: date.startOf("day"),
      to: date.endOf("day"),
    };
    const req = querySlots(api, {
      siteIds,
      start: interval.from,
      end: interval.to,
      pageSize: 1,
    });

    req.result
      .then((response) => {
        const slots = response.items.map(fromAPI.toBookableSlot);
        setSlots(slots);
      })
      .catch(silenceAbort);
    return () => {
      req.abandon();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, date, isInThePast, siteIds.join("")]);

  return (
    <button
      data-selected={selected}
      data-today={isToday}
      onClick={() => onClick(date)}
      className={styles.Day}
      disabled={!slots?.length || isInThePast}
    >
      {date.day}
    </button>
  );
}
