import { localizeQuestion } from "lib/questionnaire/question-flows/onboarding/localizeQuestion";
import { localize } from "render/context/InternationalizationContext";

const AdditionalWorries = {
  Question: localizeQuestion({
    en_GB: "Is there something you are worried about?",
    sv_SE: "Är det något du är orolig över?",
  }),
};

const Allergy = {
  Question: localizeQuestion({
    en_GB: "What are you allergic to?",
    sv_SE: "Vad är du allergisk mot?",
  }),
  AnyAllergies: localize({
    en_GB: "Do you have any allergies?",
    sv_SE: "Har du några allergier?",
  }),
  Dairy: localize({
    en_GB: <>Dairy</>,
    sv_SE: <>Mejeriprodukter</>,
  }),
  InsectSting: localize({
    en_GB: <>Insect Sting</>,
    sv_SE: <>Insektsbett</>,
  }),
  Latex: localize({
    en_GB: <>Latex</>,
    sv_SE: <>Latex</>,
  }),
  Morphine: localize({
    en_GB: <>Morphine</>,
    sv_SE: <>Morfin</>,
  }),
  Nuts: localize({
    en_GB: <>Nuts</>,
    sv_SE: <>Nötter</>,
  }),
  Penicillin: localize({
    en_GB: <>Penicillin</>,
    sv_SE: <>Penicillin</>,
  }),
  Wheat: localize({
    en_GB: <>Wheat</>,
    sv_SE: <>Vete</>,
  }),
  Other: localize({
    en_GB: <>Other</>,
    sv_SE: <>Annat</>,
  }),
};

const Choice = {
  TenDays: localize({
    en_GB: <>Recently</>,
    sv_SE: <>Nyligen</>,
  }),
  SixMonths: localize({
    en_GB: <>6 months ago</>,
    sv_SE: <>6 månader sedan</>,
  }),
  OneYear: localize({
    en_GB: <>1 year ago</>,
    sv_SE: <>1 år sen</>,
  }),
  TwoYears: localize({
    en_GB: <>2 years ago</>,
    sv_SE: <>2 år sen</>,
  }),
  ThreeYears: localize({
    en_GB: <>3 years ago</>,
    sv_SE: <>3 år sen</>,
  }),
  FourYears: localize({
    en_GB: <>More than 4 years ago</>,
    sv_SE: <>Mer än 4 år sen</>,
  }),
};

const Diet = {
  Question: localizeQuestion({
    en_GB: "What do your eating habits look like?",
    sv_SE: "Hur ser dina kostvanor ut?",
  }),
  Specific: localize({
    en_GB: <>Do you have any specific dietary habits?</>,
    sv_SE: <>Har du några specifika kostvanor?</>,
  }),
  Vegan: localize({
    en_GB: <>Vegan</>,
    sv_SE: <>Vegan</>,
  }),
  Vegetarian: localize({
    en_GB: <>Vegetarian</>,
    sv_SE: <>Vegetarian</>,
  }),
  Pescatarian: localize({
    en_GB: <>Pescatarian</>,
    sv_SE: <>Pescatarian</>,
  }),
  Keto: localize({
    en_GB: <>Keto</>,
    sv_SE: <>Keto</>,
  }),
  Paleo: localize({
    en_GB: <>Paleo</>,
    sv_SE: <>Paleo</>,
  }),
  LowCarb: localize({
    en_GB: <>Low carb</>,
    sv_SE: <>Lågkalori</>,
  }),
  LowMeat: localize({
    en_GB: <>Low meat</>,
    sv_SE: <>Lågt kött intag</>,
  }),
  Other: localize({
    en_GB: <>Other</>,
    sv_SE: <>Annat</>,
  }),
};

const Drinking = {
  Question: localizeQuestion({
    en_GB: "How many glasses do you drink a week?",
    sv_SE: "Hur många glas dricker du i veckan?",
  }),
  DoYouDrinkAlcohol: localizeQuestion({
    en_GB: "Do you drink any alcohol?",
    sv_SE: "Dricker du någon alkohol?",
  }),
  Beer: localize({
    en_GB: <>Beer</>,
    sv_SE: <>Öl</>,
  }),
  Liquor: localize({
    en_GB: <>Liquor</>,
    sv_SE: <>Sprit</>,
  }),
  Wine: localize({
    en_GB: <>Wine</>,
    sv_SE: <>Vin</>,
  }),
};

const Exercise = {
  Question: localizeQuestion({
    en_GB: "Do you exercise? How often per week?",
    sv_SE: "Tränar du något? Hur ofta per vecka?",
  }),
  HoursPerWeek: localize<{ hours: string }>({
    en_GB: ({ hours }) => `${hours} hours per week`,
    sv_SE: ({ hours }) => `${hours} timmar i veckan`,
  }),
};

const FamilyConditions = {
  Question: localizeQuestion({
    en_GB: "Do you have any diseases in the family?",
    sv_SE: "Har du några sjukdomar i släkten?",
  }),
};

const Heritage = {
  Question: localizeQuestion({
    en_GB: "What is your heritage?",
    sv_SE: "Vart kommer din familj från?",
  }),
};

const WorldRegion = {
  Europe: localize({
    en_GB: <>Europe</>,
    sv_SE: <>Europa</>,
  }),
  NorthAmerica: localize({
    en_GB: <>North America</>,
    sv_SE: <>Nordamerika</>,
  }),
  CentralAmerica: localize({
    en_GB: <>Central America</>,
    sv_SE: <>Centralamerika</>,
  }),
  SouthAmerica: localize({
    en_GB: <>South America</>,
    sv_SE: <>Sydamerika</>,
  }),
  NorthAsia: localize({
    en_GB: <>North Asia</>,
    sv_SE: <>Norra Asien</>,
  }),
  MiddleEast: localize({
    en_GB: <>Middle East</>,
    sv_SE: <>Mellanöstern</>,
  }),
  EastAsia: localize({
    en_GB: <>East Asia</>,
    sv_SE: <>Östra Asien</>,
  }),
  SouthAsia: localize({
    en_GB: <>South Asia</>,
    sv_SE: <>Södra Asien</>,
  }),
  SouthEastAsia: localize({
    en_GB: <>Southeast Asia</>,
    sv_SE: <>Sydöstra Asien</>,
  }),
  Oceania: localize({
    en_GB: <>Oceania</>,
    sv_SE: <>Oceanien</>,
  }),
  NorthAfrica: localize({
    en_GB: <>North Africa</>,
    sv_SE: <>Nordafrika</>,
  }),
  WestAfrica: localize({
    en_GB: <>West Africa</>,
    sv_SE: <>Västafrika</>,
  }),
  EastAfrica: localize({
    en_GB: <>East Africa</>,
    sv_SE: <>Östafrika</>,
  }),
  SouthAfrica: localize({
    en_GB: <>South Africa</>,
    sv_SE: <>Sydafrika</>,
  }),
};

const Medication = {
  Question: localizeQuestion({
    en_GB: "Do you take any medications?",
    sv_SE: "Tar du några mediciner?",
  }),
};

const PersonalConditions = {
  Question: localizeQuestion({
    en_GB: "Have you had any health problems in the past year?",
    sv_SE: "Har du haft några problem med hälsan det senaste året?",
  }),
};

const Smoking = {
  Question: localizeQuestion({
    en_GB: "Do you smoke?",
    sv_SE: "Röker du?",
  }),
  FrequencyCurrently: localize({
    en_GB: "Yes",
    sv_SE: "Ja",
  }),
  FrequencyOccasionally: localize({
    en_GB: "Occasionally",
    sv_SE: "Ibland",
  }),
  FrequencyQuit: localize({
    en_GB: "I have quit",
    sv_SE: "Jag har slutat",
  }),
  DailyCigaretteCount: localizeQuestion({
    en_GB: "How much do you smoke per day?",
    sv_SE: "Hur mycket röker du per dag?",
  }),
  CigaretteCount2: localize({
    en_GB: "1-3 cigarettes",
    sv_SE: "1-3 cigaretter",
  }),
  CigaretteCount6: localize({
    en_GB: "Less than half a pack",
    sv_SE: "Mindre än ett halvt paket",
  }),
  CigaretteCount12: localize({
    en_GB: "Half a pack",
    sv_SE: "Ett halvt paket",
  }),
  CigaretteCount18: localize({
    en_GB: "More than half a pack",
    sv_SE: "Mer än ett halvt paket",
  }),
  CigaretteCount24: localize({
    en_GB: "One pack",
    sv_SE: "Ett paket",
  }),
  QuitSmokingRecency: localizeQuestion({
    en_GB: "How long ago did you quit?",
    sv_SE: "Hur längesen slutade du?",
  }),
};

const Snus = {
  Question: localizeQuestion({
    en_GB: "Do you use snus?",
    sv_SE: "Snusar du?",
  }),
  HabitualStyleCurrently: localize({
    en_GB: "Yes",
    sv_SE: "Ja",
  }),
  HabitualStylePreviously: localize({
    en_GB: "I have quit",
    sv_SE: "Jag har slutat",
  }),
  WeeklySnusCanCount: localizeQuestion({
    en_GB: "How many cans of snus do you use per week?",
    sv_SE: "Hur många dosor snusar du per vecka?",
  }),
  QuitSnusingRecency: localizeQuestion({
    en_GB: "How long ago did you quit?",
    sv_SE: "Hur längesen slutade du?",
  }),
  HalfACan: localize({
    en_GB: "Half a can",
    sv_SE: "En halv",
  }),
};

export const Trans = {
  AdditionalWorries,
  Allergy,
  Choice,
  Diet,
  Drinking,
  Exercise,
  FamilyConditions,
  Heritage,
  Medication,
  PersonalConditions,
  Smoking,
  Snus,
  WorldRegion,
};
