import { MultiChoiceQuestion } from "@cur8/questionnaire";
import { Trans } from "./transV2";

export enum HealthConditionsEnum {
  Athritis = "Arthritis",
  KidneyDisease = "Kidney disease",
  PeripheralVascularDisease = "Peripheral Vascular Disease",
  RaynaudsSyndrome = "Raynaud’s Syndrome",
  No = "No",
}

export const HealthConditions = new MultiChoiceQuestion(
  <Trans.HealthConditions.Question />,
  [
    [
      {
        label: <Trans.HealthConditions.Arthritis />,
        description: <Trans.HealthConditions.ArthritisDescription />,
      },
      HealthConditionsEnum.Athritis,
    ],
    [
      {
        label: <Trans.HealthConditions.KidneyDisease />,
        description: <Trans.HealthConditions.KidneyDiseaseDescription />,
      },
      HealthConditionsEnum.KidneyDisease,
    ],
    [
      {
        label: <Trans.HealthConditions.PeripheralVascularDisease />,
        description: (
          <Trans.HealthConditions.PeripheralVascularDiseaseDescription />
        ),
      },
      HealthConditionsEnum.PeripheralVascularDisease,
    ],
    [
      {
        label: <Trans.HealthConditions.RaynaudsSyndrome />,
        description: <Trans.HealthConditions.RaynaudsSyndromeDescription />,
      },
      HealthConditionsEnum.RaynaudsSyndrome,
    ],
    [
      { label: <Trans.HealthConditions.No />, description: undefined },
      HealthConditionsEnum.No,
    ],
  ]
);
