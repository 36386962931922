import { MultiChoiceQuestion } from "@cur8/questionnaire";
import { Trans } from "./transV2";

export enum CardioConditionsEnum {
  AtrialFibrillation = "Atrial fibrillation",
  CongestiveHeartFailure = "Congestive heart failure",
  FamilialHypercholesterolemia = "Familial hypercholesterolemia",
  HeartAttack = "Heart attack",
  Stroke = "Stroke",
  ValvularDisease = "Valvular disease",
}

export enum CardioConditionsValvularDiseaseEnum {
  AorticValveInsufficiency = "Aortic valve insufficiency",
  AorticValveStenosis = "Aortic valve stenosis",
  MitralValveInsufficiency = "Mitral valve insufficiency",
  MitralValveStenosis = "Mitral valve stenosis",
  NotSure = "Not sure",
}

export const CardioConditions = new MultiChoiceQuestion(
  <Trans.CardioConditions.Question />,
  [
    [
      {
        label: <Trans.CardioConditions.AtrialFibrillation />,
        description: <Trans.CardioConditions.AtrialFibrillationDescription />,
      },
      CardioConditionsEnum.AtrialFibrillation,
    ],
    [
      {
        label: <Trans.CardioConditions.CongestiveHeartFailure />,
        description: (
          <Trans.CardioConditions.CongestiveHeartFailureDescription />
        ),
      },
      CardioConditionsEnum.CongestiveHeartFailure,
    ],
    [
      {
        label: <Trans.CardioConditions.FamilialHypercholesterolemia />,
        description: (
          <Trans.CardioConditions.FamilialHypercholesterolemiaDescription />
        ),
      },
      CardioConditionsEnum.FamilialHypercholesterolemia,
    ],
    [
      {
        label: <Trans.CardioConditions.HeartAttack />,
        description: <Trans.CardioConditions.HeartAttackDescription />,
      },
      CardioConditionsEnum.HeartAttack,
    ],
    [
      {
        label: <Trans.CardioConditions.Stroke />,
        description: <Trans.CardioConditions.StrokeDescription />,
      },
      CardioConditionsEnum.Stroke,
    ],
    [
      {
        label: <Trans.CardioConditions.ValvularDisease />,
        description: <Trans.CardioConditions.ValvularDiseaseDescription />,
      },
      CardioConditionsEnum.ValvularDisease,
    ],
  ]
);

export const CardioConditionsValvularDisease = new MultiChoiceQuestion(
  <Trans.CardioConditions.ValvularDiseaseQuestion />,
  [
    [
      {
        label: <Trans.CardioConditions.AorticValveInsufficiency />,
        description: (
          <Trans.CardioConditions.AorticValveInsufficiencyDescription />
        ),
      },
      CardioConditionsValvularDiseaseEnum.AorticValveInsufficiency,
    ],
    [
      {
        label: <Trans.CardioConditions.AorticValveStenosis />,
        description: <Trans.CardioConditions.AorticValveStenosisDescription />,
      },
      CardioConditionsValvularDiseaseEnum.AorticValveStenosis,
    ],
    [
      {
        label: <Trans.CardioConditions.MitralValveInsufficiency />,
        description: (
          <Trans.CardioConditions.MitralValveInsufficiencyDescription />
        ),
      },
      CardioConditionsValvularDiseaseEnum.MitralValveInsufficiency,
    ],
    [
      {
        label: <Trans.CardioConditions.MitralValveStenosis />,
        description: <Trans.CardioConditions.MitralValveStenosisDescription />,
      },
      CardioConditionsValvularDiseaseEnum.MitralValveStenosis,
    ],
    [
      { label: <Trans.CardioConditions.NotSure />, description: undefined },
      CardioConditionsValvularDiseaseEnum.NotSure,
    ],
  ]
);
