import { useQuery } from "@tanstack/react-query";
import { APIClient } from "lib/api/client";
import { getOnboarding } from "lib/api/onboarding";
import { useAPIClient } from "render/context/APIContext";
import { useSession } from "render/context/MSALContext";
import { ExtractBody, ExtractKey, QueryOptions } from "typings/query";

const queryKey = (patientId: string) => ["onboarding-blob", patientId];
const queryFn = (apiClient: APIClient, patientId: string) => async () => {
  const response = await getOnboarding({ apiClient, patientId });

  return response ?? null;
};

type Body = ExtractBody<typeof queryFn>;
type Key = ExtractKey<typeof queryKey>;

export function useOnboardingQuery<T = Body>(
  options: QueryOptions<Body, Key, T> = {}
) {
  const { patientId } = useSession();
  const apiClient = useAPIClient();

  return useQuery(queryKey(patientId), queryFn(apiClient, patientId), options);
}

useOnboardingQuery.queryKey = queryKey;
useOnboardingQuery.queryFn = queryFn;
