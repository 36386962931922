import { APITypesV1 } from "@cur8/api-client";
import { useNav, useQueryParams } from "@pomle/react-router-paths";
import { ReactComponent as QuestionIcon } from "assets/icons/chat/chat-36x36x.svg";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { useTrackingContext } from "render/context/TrackingContext";
import { usePatientQuery } from "render/hooks/api/queries/usePatientQuery";
import {
  Cart,
  UpdatePatientCartRequest,
  useUpdateCartMutation,
} from "render/hooks/mutations/useCreateCartMutation";
import { useGetDiscountCodeMutation } from "render/hooks/mutations/useGetDiscountCode";
import {
  Checkout,
  useInitializeCheckoutMutation,
} from "render/hooks/mutations/useInitializeCheckoutMutation";
import { useContactUsPopup } from "render/hooks/popups/useContactUsPopup";
import { useTracking } from "render/hooks/useTracking";
import { paths, queries } from "render/routes/paths";
import { BurgerLayout } from "render/ui/layout/BurgerLayout";
import { FullScreenPageLayout } from "render/ui/layout/FullScreenPageLayout";
import { LogoHeader } from "render/ui/layout/LogoHeader";
import { IconButton } from "render/ui/trigger/IconButton";

import { SharedTrans } from "render/views/trans";
import { CheckoutForm } from "./components/CheckoutForm";
import { PaymentProvider } from "./components/CheckoutForm/components/payments/PaymentProvider";
import { Trans } from "./components/CheckoutForm/trans";
import styles from "./styles.module.sass";
import { paymentEvents } from "./tracking";
import { useAsyncHandle } from "render/hooks/useAsyncHandle";

export function CheckoutView() {
  const [params] = useQueryParams(queries.payment);
  const nav = {
    root: useNav(paths.root),
  };
  const { trackEvent } = useTracking();
  const contactUsPopup = useContactUsPopup();

  const { hasLoaded, conversionsTracking } = useTrackingContext();
  const [checkout, setCheckout] = useState<Checkout>();
  const patientQuery = usePatientQuery();
  const [cart, setCart] = useState<Cart>();
  const [isPlaceboPayment, setIsPlaceboPayment] = useState<boolean>(false);
  const [canEditDiscountCode, setCanEditDiscountCode] = useState<boolean>(true);
  const [isFreeBooking, setIsFreeBooking] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const createCartMutation = useUpdateCartMutation();
  const getDiscountCodeMutation = useGetDiscountCodeMutation();
  const initializeCheckoutMutation = useInitializeCheckoutMutation();
  const [validationErrors, setValidationErrors] = useState<{
    discountCodeInvalid: boolean;
  }>({ discountCodeInvalid: false });

  const callCreateCart = useCallback(
    async (body: UpdatePatientCartRequest) => {
      const { discountCodes, ...rest } = body;
      const discounts: APITypesV1.DiscountToken[] = [];

      try {
        const discountCode = discountCodes?.at(0)?.trim();

        if (discountCode != null && discountCode.length > 0) {
          const discountToken = await getDiscountCodeMutation(discountCode);
          if (
            discountToken.isRedeemed &&
            discountToken.referrer != null &&
            discountToken.redeemerId === patientQuery.data?.patientId
          ) {
            // this discount code has already been redeemed by this patient, we redirect them to the root
            // which prevents a creation of a new cart abandonment flow trigger on the backend
            nav.root.go({});
            throw new Error();
          } else if (discountToken.referrer === patientQuery.data?.patientId) {
            // this prevents a patient from using their own referral code to create a new cart/checkout
            // which prevents a creation of a new cart abandonment flow trigger on the backend
            nav.root.go({});
            throw new Error();
          }
          if (discountToken.isValid) {
            discounts.push(discountToken);
          } else {
            setValidationErrors({ discountCodeInvalid: true });
          }
        }
      } catch (err: any) {
        if ([404, 403].includes(err.response?.status)) {
          setValidationErrors({ discountCodeInvalid: true });
        } else {
          // bubble up to prevent initiate checkout from happening
          throw err;
        }
      }
      try {
        const cart = await createCartMutation({
          ...rest,
          // our typings are wrong, code is required field in the DiscountToken type
          discountCodes: discounts.map((discount) => discount.code!),
        });

        if (cart.discountCodes.length > 0) {
          const discountCode = cart.discountCodes.at(0);
          if (cart.cartTotal === 0) {
            if (params.code.length === 0) {
              conversionsTracking.TrackAddInviteCode(discountCode!);
            }
          } else {
            if (params.code.length === 0) {
              conversionsTracking.TrackAddDiscountCode(discountCode!);
            }
          }
        }

        conversionsTracking.TrackAddToCart(cart);

        setCart(cart);
        // if any of the discounts are placebo, we want to inform the user that they are not paying for the booking
        setIsPlaceboPayment(() =>
          discounts.some((discount) => discount.tags?.includes("placebo"))
        );
        // if any of the discounts are sticky, we don't want to allow the user to edit the discount code
        setCanEditDiscountCode(
          () => !discounts.some((discount) => discount.tags?.includes("sticky"))
        );
        // if any of the discounts are 100%, we want to inform the user that they are not paying for the booking
        setIsFreeBooking(() =>
          discounts.some((discount) => discount.tags?.includes("full-discount"))
        );
        return cart;
      } catch (err) {
        enqueueSnackbar(<Trans.Error.Unexpected />, {
          variant: "error",
        });
      }
    },
    [
      patientQuery.data,
      params,
      enqueueSnackbar,
      createCartMutation,
      getDiscountCodeMutation,
      conversionsTracking,
      nav.root,
    ]
  );
  const createCartHandler = useAsyncHandle(callCreateCart);
  const createIntent = useCallback(async () => {
    try {
      const result = await initializeCheckoutMutation();
      setCheckout(result);
      return result;
    } catch {
      enqueueSnackbar(<Trans.Error.Unexpected />, { variant: "error" });
    }
  }, [setCheckout, enqueueSnackbar, initializeCheckoutMutation]);

  useEffect(() => {
    if (!hasLoaded) {
      return;
    }

    const codes = params.code;
    const body = {
      entries: [
        { count: 1, productId: "126cc5ed-ce71-4965-845d-bf3423bdb6b9" },
      ],
      discountCodes: codes,
    };
    createCartHandler
      .callback(body)
      ?.then(() => createIntent())
      .catch((e) => {
        enqueueSnackbar(<Trans.Error.Unexpected />, { variant: "error" });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversionsTracking, hasLoaded]);

  return (
    <FullScreenPageLayout>
      <BurgerLayout>
        <LogoHeader
          rightElement={
            <IconButton
              ariaLabel={SharedTrans.ContactUs()}
              icon={<QuestionIcon display="block" />}
              onClick={() => {
                trackEvent(paymentEvents.contactUsClick("open"));
                contactUsPopup.emit();
              }}
            />
          }
        />
        <div data-hj-suppress className={styles.body}>
          <div className={styles.content}>
            {checkout && cart && conversionsTracking && (
              <PaymentProvider checkout={checkout}>
                <CheckoutForm
                  clearErrors={(props) => {
                    setValidationErrors((v) => ({ ...v, ...props }));
                  }}
                  isCodeEditable={canEditDiscountCode}
                  isPlaceboPayment={isPlaceboPayment}
                  isFreeBooking={isFreeBooking}
                  validationErrors={validationErrors}
                  cart={cart}
                  checkout={checkout}
                  onDiscountSubmit={(discount) => {
                    callCreateCart({
                      entries: [
                        {
                          count: 1,
                          productId: "126cc5ed-ce71-4965-845d-bf3423bdb6b9",
                        },
                      ],
                      discountCodes: discount ? [discount] : [],
                    });
                  }}
                />
              </PaymentProvider>
            )}
          </div>
        </div>
        {null}
      </BurgerLayout>
    </FullScreenPageLayout>
  );
}
