import { PropsWithChildren } from "react";
import { VideoBackground } from "render/views/_shared/VideoBackground";
import { PopUpDialogFrame } from "./components/PopUpDialogFrame";

export function ApplicationLayout({ children }: PropsWithChildren<{}>) {
  return (
    <PopUpDialogFrame>
      {children}
      <VideoBackground />
    </PopUpDialogFrame>
  );
}
