import { APITypesV1, APITypesV2 } from "@cur8/api-client";
import { ImmutableScan, Patient, Slot, VisitSummary } from "@cur8/rich-entity";
import { useNav } from "@pomle/react-router-paths";
import { ReactComponent as EightCornerStarIcon } from "assets/eight-corner-star.svg";
import { DateTime } from "luxon";
import { useCallback, useMemo } from "react";
import { paths } from "render/routes/paths";
import { ReactComponent as ArrowIcon } from "render/ui/icons/arrow.svg";
import { Typography } from "render/ui/presentation/Typography";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

interface VisitSummaryReadyStageProps {
  scan?: ImmutableScan;
  patient: Patient;
  visitSummary: VisitSummary;
  bookingTokens: APITypesV2.BookingTokenV2[];
  slot?: Slot;
}

export function VisitSummaryReadyStage({
  scan,
  patient,
  visitSummary,
  slot,
  bookingTokens,
}: VisitSummaryReadyStageProps) {
  const nav = {
    report: useNav(paths.appointmentSummary),
    booking: useNav(paths.booking),
    payment: useNav(paths.checkout),
    appointment: useNav(paths.appointment),
  };
  const onBookClick = useCallback(() => {
    if (bookingTokens.length === 0) {
      nav.payment.go({});
    } else {
      nav.booking.go({});
    }
  }, [bookingTokens, nav.booking, nav.payment]);
  const onAppointmentInfoClick = useCallback(() => {
    if (!slot) {
      return;
    }

    nav.appointment.go({ slotId: slot.slotId });
  }, [nav.appointment, slot]);
  const time = useMemo(() => {
    if (!slot) {
      return undefined;
    }

    const format = "HH:mm";
    const start = slot.startTime;
    const end = slot.endTime;

    return `${start.toFormat(format)} - ${end.toFormat(format)}`;
  }, [slot]);
  const date = useMemo(() => {
    if (!slot) {
      return undefined;
    }

    const start = slot.startTime;
    const isDifferentYear = start.year !== DateTime.now().year;

    return start.toFormat(`d MMMM${isDifferentYear ? " yyyy" : ""}`);
  }, [slot]);

  const appointmentDate = useMemo(() => {
    const time = scan ? scan.timestamp : visitSummary.visitDate;

    return time.toFormat("d MMMM yyyy");
  }, [scan, visitSummary]);

  const canBook = useMemo(() => {
    return patient.flags.includes(APITypesV1.PatientFlag.CanBook);
  }, [patient.flags]);

  return (
    <div>
      <Typography variant="title-large">
        <Trans.Greeting patient={patient} />
      </Typography>
      <div className={styles.cta}>
        <button className={styles.button} onClick={nav.report.on({})}>
          <EightCornerStarIcon />
          <div className={styles.text}>
            <Typography variant="cta">
              <Trans.HealthReport />
            </Typography>
            <Typography variant="paragraph-sub">{appointmentDate}</Typography>
          </div>
        </button>
        {slot && (
          <button className={styles.button} onClick={onAppointmentInfoClick}>
            <ArrowIcon style={{ transform: "rotate(180deg)" }} />
            <div className={styles.text}>
              <Typography variant="cta">
                <Trans.ScanBooked />
              </Typography>
              <Typography variant="paragraph-sub">
                {time} • {date}
              </Typography>
            </div>
          </button>
        )}
        {canBook && !slot && (
          <button className={styles.button} onClick={onBookClick}>
            <ArrowIcon style={{ transform: "rotate(180deg)" }} />
            <div className={styles.text}>
              <Typography variant="cta">
                <Trans.Resever.Title />
              </Typography>
              <Typography variant="paragraph-sub">
                <Trans.Resever.Description />
              </Typography>
            </div>
          </button>
        )}
      </div>
    </div>
  );
}
