import { useCallback } from "react";
import { usePopupDialog } from "render/context/PopupDialogContext";
import { ContactUsPopup } from "render/views/_shared/ContactUsPopup";

export function useContactUsPopup() {
  const { create } = usePopupDialog();

  const emit = useCallback(() => {
    const actions = create();

    actions.emit(<ContactUsPopup onClose={actions.close} />);
  }, [create]);

  return { emit };
}
