import { useQueryParams } from "@pomle/react-router-paths";
import { queries } from "render/routes/paths";
import { MetricDetails } from "render/views/Report/components/MetricsSection/components/layouts/Details";
import { useBloodPressureValues } from "render/views/Report/components/MetricsSection/components/metrics/MetricBloodPressure/useBloodPressureValues";
import { useNumOfScans } from "render/views/Report/components/MetricsSection/components/metrics/MetricBloodPressure/useNumOfScans";
import { Trans } from "./trans";

export function MetricBloodPressureDetails() {
  const [params, setParams] = useQueryParams(queries.metric);
  const side = params.side[0] || "left";
  const setSide = (side: "left" | "right") =>
    setParams({
      side: [side],
    });
  const numOfScans = useNumOfScans(side) || 1;
  const scanNum = params.scan[0] || numOfScans;
  const setScanNum = (num: number) =>
    setParams({
      scan: [num],
    });
  const values = useBloodPressureValues({ scanNum, side });

  if (values === "loading") {
    return "loading";
  }

  return (
    <MetricDetails
      label={<Trans.MetricName />}
      values={values}
      scanNum={scanNum}
      setScanNum={setScanNum}
      side={side}
      setSide={setSide}
      numOfScans={numOfScans}
      FAQText={Trans.FAQ}
    />
  );
}
