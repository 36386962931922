import { Typography } from "render/ui/presentation/Typography";

export function formatToDesignSystem(elements: HTMLCollectionOf<Element>) {
  const designSystemified = Array.from(elements).map((element) => {
    const parsedInnerHTML = element.innerHTML.replace(
      /href="/g,
      `target="_blank" rel="noreferrer" href="`
    );
    if (element.tagName === "P") {
      return (
        <Typography variant="paragraph-medium">
          <span dangerouslySetInnerHTML={{ __html: parsedInnerHTML }} />
        </Typography>
      );
    }
    if (element.tagName === "H1") {
      return (
        <Typography variant="title-medium">
          <span dangerouslySetInnerHTML={{ __html: parsedInnerHTML }} />
        </Typography>
      );
    }
    if (element.tagName === "H2") {
      return (
        <Typography variant="paragraph-large">
          <span dangerouslySetInnerHTML={{ __html: parsedInnerHTML }} />
        </Typography>
      );
    }
    return undefined;
  });
  return designSystemified.filter((element) => element != null);
}
