import { localize } from "render/context/InternationalizationContext";

export const Trans = {
  MoreInfo: localize({
    en_GB: <>More information</>,
    sv_SE: <>Mer information</>,
  }),
  FindMoreInfo: localize({
    en_GB: <>Details about the study</>,
    sv_SE: <>Detaljer om studien</>,
  }),
  ConsentHistory: localize({
    en_GB: <>Download signed consent form</>,
    sv_SE: <>Ladda ner signerat samtycke</>,
  }),
};
