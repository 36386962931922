import { useNav } from "@pomle/react-router-paths";
import { ViewStack } from "@pomle/react-viewstack";
import { Direction, Slide } from "@pomle/react-viewstack-transitions";
import { useState } from "react";
import { usePreVisitConsents } from "render/hooks/api/queries/useVisitsQuery";
import { paths } from "render/routes/paths";
import { FullScreenPageLayout } from "render/ui/layout/FullScreenPageLayout";
import { OnboardingFlowView } from "./components/OnboardingFlowView";
import { OnboardingStudiesConsentView } from "./components/OnboardingStudyConsentView";

enum ActiveView {
  Studies = 0,
  OnBoarding = 1,
}

export function OnboardingView() {
  const [activeView, setActiveView] = useState<number>(ActiveView.Studies);
  const nav = {
    home: useNav(paths.root),
  };
  const { data } = usePreVisitConsents();
  const hasPreVisitConsents = data && data.length > 0;

  return (
    <FullScreenPageLayout>
      <ViewStack>
        {data != null ? (
          hasPreVisitConsents ? (
            <>
              <Slide
                active={activeView >= ActiveView.Studies}
                direction={Direction.Right}
              >
                <OnboardingStudiesConsentView
                  onClose={nav.home.on({})}
                  onSave={() => {
                    setActiveView(ActiveView.OnBoarding);
                  }}
                />
              </Slide>
              <Slide
                active={activeView >= ActiveView.OnBoarding}
                direction={Direction.Right}
              >
                <OnboardingFlowView
                  onSuccess={() => {
                    nav.home.go({});
                  }}
                  onNavBack={() => {
                    setActiveView(ActiveView.Studies);
                  }}
                />
              </Slide>
            </>
          ) : (
            <Slide active={true} direction={Direction.Right}>
              <OnboardingFlowView
                onSuccess={() => {
                  nav.home.go({});
                }}
                onNavBack={() => {
                  nav.home.go({});
                }}
              />
            </Slide>
          )
        ) : undefined}
      </ViewStack>
    </FullScreenPageLayout>
  );
}
