import { APITypesV1, APITypesV2 } from "@cur8/api-client";
import { Patient } from "@cur8/rich-entity";
import { useNav } from "@pomle/react-router-paths";
import { ReactComponent as EightCornerStarIcon } from "assets/eight-corner-star.svg";
import { useMemo } from "react";
import { paths } from "render/routes/paths";
import { Typography } from "render/ui/presentation/Typography";
import { BigButton } from "render/ui/trigger/BigButton";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

interface BookStageProps {
  patient: Patient;
  bookingTokens: APITypesV2.BookingTokenV2[];
}

export function BookStage({ patient, bookingTokens }: BookStageProps) {
  const nav = {
    booking: useNav(paths.booking),
    payment: useNav(paths.checkout),
  };

  const canBook = useMemo(() => {
    return patient.flags.includes(APITypesV1.PatientFlag.CanBook);
  }, [patient.flags]);

  const hasBookingTokens = useMemo(() => {
    return (
      bookingTokens.filter((bookingToken) => bookingToken.availableForBooking)
        .length > 0
    );
  }, [bookingTokens]);

  return (
    <div className={styles.BookStage}>
      {!canBook && hasBookingTokens && (
        <>
          <Typography variant="title-large">
            <Trans.ScanPrebooked />
          </Typography>
          <Typography variant="title-small" color="subtle">
            <Trans.WeWillReachYou />
          </Typography>
        </>
      )}

      {!canBook && !hasBookingTokens && (
        <>
          <Typography variant="title-large">
            <Trans.NoToken.Welcome />
          </Typography>
          <Typography variant="title-small" color="subtle">
            <Trans.NoToken.ReserveYourAppointment />
          </Typography>

          <BigButton
            onClick={() => {
              nav.payment.go({});
            }}
          >
            <div className={styles.button}>
              <div className={styles.icon}>
                <EightCornerStarIcon />
              </div>
              <div className={styles.text}>
                <Typography variant="cta">
                  <Trans.NoToken.BuyButton />
                </Typography>
              </div>
            </div>
          </BigButton>
        </>
      )}

      {canBook && (
        <>
          <Typography variant="title-large">
            <Trans.Greeting patient={patient} />
          </Typography>
          <BigButton
            onClick={() => {
              if (hasBookingTokens) {
                nav.booking.go({});
              } else {
                nav.payment.go({});
              }
            }}
          >
            <div className={styles.button}>
              <div className={styles.icon}>
                <EightCornerStarIcon />
              </div>
              <div className={styles.text}>
                <Typography variant="cta">
                  <Trans.Book />
                </Typography>
                <Typography variant="paragraph-sub">
                  <Trans.SlotStatus.Available />
                </Typography>
              </div>
            </div>
          </BigButton>
        </>
      )}
    </div>
  );
}
