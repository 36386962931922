import { fromAPI } from "@cur8/rich-entity";
import { useQuery } from "@tanstack/react-query";
import { APIClient } from "lib/api/client";
import { useAPIClient } from "render/context/APIContext";
import { useSession } from "render/context/MSALContext";
import { ExtractBody, ExtractKey, QueryOptions } from "typings/query";

function queryFn(apiClient: APIClient, patientId: string) {
  return () => {
    return apiClient.visitSummary
      .querySummary({ patientId }, {})
      .result.then((value) => value.items.map(fromAPI.toVisitSummary));
  };
}
function queryKey(patientId: string) {
  return ["appointment-summaries", patientId];
}

type Body = ExtractBody<typeof queryFn>;
type Key = ExtractKey<typeof queryKey>;

export function useAppointmentSummary<T = Body>(
  options: QueryOptions<Body, Key, T> = {}
) {
  const { patientId } = useSession();
  const api = useAPIClient();
  return useQuery(queryKey(patientId), queryFn(api, patientId), options);
}
