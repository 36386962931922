import { PathLink } from "@pomle/react-router-paths";
import { paths } from "render/routes/paths";
import { useBloodPressureValues } from "render/views/Report/components/MetricsSection/components/metrics/MetricBloodPressure/useBloodPressureValues";
import { getHighestSide } from "render/views/Report/components/MetricsSection/components/metrics/utils/getHighestSide";
import { Metric, MetricLoading } from "../../layouts/Metric";
import { Trans } from "./trans";

export function MetricBloodPressure() {
  const left = useBloodPressureValues({ side: "left" });
  const right = useBloodPressureValues({ side: "right" });

  if (left === "loading" || right === "loading") {
    return <MetricLoading label={<Trans.MetricName />} />;
  }

  const highestSide = getHighestSide({
    left: left.value,
    right: right.value,
  });
  const highestValue = highestSide === "left" ? left : right;

  return (
    <PathLink to={paths.bloodPressureMetric.url({})}>
      <Metric
        label={<Trans.MetricName />}
        value={highestValue?.value}
        subLabel={highestValue?.subLabel}
        metricRating={highestValue?.metricRating}
        previousValue={highestValue?.previousValue}
      />
    </PathLink>
  );
}
