import { Event, Action } from "render/hooks/useTracking";

const category = "ContactFormView";

export const contactFormEvent = {
  formSubmit: (): Event => {
    return {
      category,
      action: Action.Submit,
      label: "contact_form",
    };
  },

  numberTextClick: (): Event => {
    return {
      category,
      action: Action.Submit,
      label: "text_number_click",
    };
  },

  formSubmitButtonClick: (): Event => {
    return {
      category,
      action: Action.Click,
      label: "submit_button",
    };
  },
};
