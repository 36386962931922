import { calcAge } from "lib/age";
import { calcScore2 } from "lib/scores/score2/calcScore2";
import { isScore2HighRisk } from "lib/scores/score2/score2";
import { DateTime } from "luxon";
import { usePatientQuery } from "render/hooks/api/queries/usePatientQuery";
import {
  useBrachialPressureMetric,
  useNonHdlMetric,
  useSmokingMetric,
} from "render/views/Report/components/MetricsSection/hooks/usePatientReportMetrics";

export function useIsScore2HighRisk(): boolean | undefined {
  const { data: patient } = usePatientQuery();
  const { data: nonHdl } = useNonHdlMetric();
  const { data: brachialPressure } = useBrachialPressureMetric();
  const { data: smoking } = useSmokingMetric();

  if (!nonHdl || !brachialPressure || !smoking || !patient) {
    return undefined;
  }

  const score2 = calcScore2({
    patient,
    date: DateTime.now(),
    nonHDL: nonHdl.at(0),
    brachial: {
      left: brachialPressure.left.at(0),
      right: brachialPressure.right.at(0),
    },
    smoking: smoking.at(0),
  });

  const age = patient.dateOfBirth
    ? calcAge(patient.dateOfBirth, DateTime.now())
    : NaN;

  return isScore2HighRisk(age, score2?.score);
}
