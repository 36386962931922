import { APITypesV1 } from "@cur8/api-client";
import { useNav } from "@pomle/react-router-paths";
import { findNotParticipatedStudies } from "lib/studies/findNotParticipatedStudies";
import { useCallback, useMemo } from "react";
import { useSession } from "render/context/MSALContext";
import { useIdentityVerificationStatus } from "render/hooks/api/queries/useIdentityVerificationStatus";
import { useOnboardingQuery } from "render/hooks/api/queries/useOnboardingQuery";
import { usePatientQuery } from "render/hooks/api/queries/usePatientQuery";
import { useValidPatientConsentsQuery } from "render/hooks/api/queries/useValidPatientConsentsQuery";
import { paths, queries } from "render/routes/paths";
import { useConsentsMap } from "render/views/ProfileView/components/DataAndPrivacySection/hooks/useConsentsMap";
import { Stage } from "render/views/appointment/AppointmentOnboarding";

export function useVisitPrerequisites() {
  const { mfa } = useSession();

  const patientQuery = usePatientQuery();
  const identityVerificationStatusQuery = useIdentityVerificationStatus();

  const onboardingQuery = useOnboardingQuery({
    enabled: mfa,
  });

  const patientConsentsQuery = useValidPatientConsentsQuery({
    enabled: mfa,
  });

  const { studies } = useConsentsMap();

  const hasIdentityVerified = useMemo(() => {
    if (patientQuery.data == null) {
      return false;
    }

    return patientQuery.data.hasVerifiedIdentity;
  }, [patientQuery.data]);

  const hasIdentityVerificationInProgress =
    identityVerificationStatusQuery.data?.identityVerificationStatus ===
    APITypesV1.IdentityVerificationStatus.Submitted;

  const hasPasskey = useMemo(() => {
    if (patientQuery.data == null) {
      return false;
    }

    return patientQuery.data.hasPasskey;
  }, [patientQuery.data]);

  const hasStudies = useMemo(() => {
    if (patientConsentsQuery.data == null) {
      return false;
    }

    return (
      findNotParticipatedStudies(patientConsentsQuery.data, studies).length ===
      0
    );
  }, [patientConsentsQuery.data, studies]);

  const needsIdentityVerified = patientQuery.data?.preferredCountry === "GB";
  const needsPasskey = patientQuery.data?.preferredCountry === "GB";
  const needsStudies = patientQuery.data?.preferredCountry === "SE";

  const hasCompletedOnboarding = onboardingQuery.data != null;

  const nav = {
    onboarding: useNav(paths.appointmentPrereq.onboarding, queries.onboarding),
    identityVerification: useNav(paths.appointmentPrereq.identityVerification),
    addPassKey: useNav(paths.appointmentPrereq.addPasskey),
  };

  const goToIdentityVerification = useCallback(
    (slotId: string) => {
      nav.identityVerification.go({ slotId });
    },
    [nav.identityVerification]
  );

  const goToPassKeySetup = useCallback(
    (slotId: string) => {
      nav.addPassKey.go({ slotId });
    },
    [nav.addPassKey]
  );

  const goToOnboarding = useCallback(
    (slotId: string) => {
      nav.onboarding.go({ slotId }, { initialStep: [Stage.Onboarding] });
    },
    [nav.onboarding]
  );

  const goToStudies = useCallback(
    (slotId: string) => {
      nav.onboarding.go({ slotId }, { initialStep: [Stage.Studies] });
    },
    [nav.onboarding]
  );

  const goToNextStep = useMemo(() => {
    if (
      needsIdentityVerified &&
      !hasIdentityVerified &&
      !hasIdentityVerificationInProgress
    ) {
      return goToIdentityVerification;
    }

    if (needsPasskey && !hasPasskey) {
      return goToPassKeySetup;
    }

    if (needsStudies && !hasStudies) {
      return goToStudies;
    }

    if (!hasCompletedOnboarding) {
      return goToOnboarding;
    }
  }, [
    needsIdentityVerified,
    needsPasskey,
    needsStudies,

    hasIdentityVerificationInProgress,
    hasIdentityVerified,
    hasPasskey,
    hasStudies,
    hasCompletedOnboarding,

    goToIdentityVerification,
    goToPassKeySetup,
    goToStudies,
    goToOnboarding,
  ]);

  const canCompleteOnboarding = mfa;

  const isReady = goToNextStep == null;

  return {
    isReady,

    canCompleteOnboarding,

    needsIdentityVerified,
    needsStudies,
    needsPasskey,

    hasIdentityVerified,
    hasIdentityVerificationInProgress,
    hasPasskey,
    hasStudies,
    hasCompletedOnboarding,

    goToIdentityVerification,
    goToPassKeySetup,
    goToOnboarding,
    goToStudies,

    goToNextStep,
  };
}
