import { localize } from "render/context/InternationalizationContext";

const InviteCode = localize({
  en_GB: <>Invitation Code</>,
  sv_SE: <>Inbjudningskod</>,
});

const Use = localize({
  en_GB: <>Apply</>,
  sv_SE: <>Använd</>,
});

export const Trans = { InviteCode, Use };
