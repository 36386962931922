import { ReactComponent as CrossIcon } from "assets/cross.svg";
import { usePatientQuery } from "render/hooks/api/queries/usePatientQuery";
import { useTracking } from "render/hooks/useTracking";
import { BurgerLayout } from "render/ui/layout/BurgerLayout";
import { FullScreenPageLayout } from "render/ui/layout/FullScreenPageLayout";
import { LogoHeader } from "render/ui/layout/LogoHeader";
import { Typography } from "render/ui/presentation/Typography";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { IconButton } from "render/ui/trigger/IconButton";
import styles from "./styles.module.sass";
import { contactFormEvent } from "./tracking";
import { Trans, contactUsNumber } from "./trans";

interface ContactUsProps {
  onBackClick: () => void;
}

export function ContactUs({ onBackClick }: ContactUsProps) {
  const { trackEvent } = useTracking();
  const { data: patient, isLoading } = usePatientQuery();

  if (isLoading) {
    return null;
  }

  const countryCode = patient?.preferredCountry || "SE";

  return (
    <FullScreenPageLayout>
      <BurgerLayout>
        <LogoHeader
          leftElement={
            <IconButton
              icon={<CrossIcon display="block" />}
              onClick={onBackClick}
            />
          }
        />
        <div className={styles.body}>
          <div className={styles.content}>
            <div className={styles.info}>
              <Typography variant="title-large">
                <Trans.ContactUs.Title />
              </Typography>
              <Typography variant="paragraph-large">
                <Trans.ContactUs.Text countryCode={countryCode} />{" "}
              </Typography>
              <Typography variant="paragraph-large">
                <Trans.ContactUs.ChestPain countryCode={countryCode} />
              </Typography>
            </div>

            <a href={`sms:${contactUsNumber(countryCode)}`}>
              <ActionButton
                onClick={() => {
                  trackEvent(contactFormEvent.formSubmitButtonClick());
                  trackEvent(contactFormEvent.formSubmit());
                }}
              >
                <div className={styles.buttonText}>
                  <Typography variant="cta">
                    <Trans.ContactUs.TextUs />
                  </Typography>
                  <Typography variant="paragraph-small" color="subtle">
                    {contactUsNumber(countryCode)}
                  </Typography>
                </div>
              </ActionButton>
            </a>
          </div>
        </div>
        {null}
      </BurgerLayout>
    </FullScreenPageLayout>
  );
}
