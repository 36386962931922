import {
  APIContextOptions,
  createAPIContext,
  createAnnotationAPI,
  createAssignmentAPI,
  createBillingAPIv1,
  createBillingAPIv2,
  createBlobAPI,
  createBookingAPIv1,
  createBookingAPIv2,
  createCheckinSessionAPI,
  createConsentAPIv1,
  createConsentAPIv2,
  createGdprExportAPI,
  createMeasurementsAPI,
  createMeshugaAPI,
  createPatientAPI,
  createQuestionnaireAPI,
  createScanAPI,
  createSiteAPI,
  createUserAPI,
  createVisitAPI,
  createVisitSummaryAPI,
} from "@cur8/api-client";

export function createClient(options: APIContextOptions) {
  const context = createAPIContext(options);

  return {
    annotation: createAnnotationAPI(context),
    blob: createBlobAPI(context),
    billing: createBillingAPIv1(context),
    billingV2: createBillingAPIv2(context),
    booking: createBookingAPIv1(context),
    bookingV2: createBookingAPIv2(context),
    checkinSession: createCheckinSessionAPI(context),
    consent: createConsentAPIv1(context),
    consentV2: createConsentAPIv2(context),
    gdprExport: createGdprExportAPI(context),
    measurement: createMeasurementsAPI(context),
    meshuga: createMeshugaAPI(context),
    patient: createPatientAPI(context),
    scan: createScanAPI(context),
    user: createUserAPI(context),
    visit: createVisitAPI(context),
    visitSummary: createVisitSummaryAPI(context),
    assignment: createAssignmentAPI(context),
    site: createSiteAPI(context),
    questionnaire: createQuestionnaireAPI(context),
  };
}

export type APIClient = ReturnType<typeof createClient>;
