import { useCallback } from "react";
import { APITypesV2 } from "@cur8/api-client";
import { useAPIClient } from "render/context/APIContext";
import { useSession } from "render/context/MSALContext";
import { paths } from "render/routes/paths";

type DeepRequired<T> = Required<{
  [P in keyof T]: Required<DeepRequired<T[P]>>;
}>;

export type Checkout = DeepRequired<APITypesV2.InitiateCheckoutResponse>;

export function useInitializeCheckoutMutation() {
  const { patientId } = useSession();
  const apiClient = useAPIClient().billingV2;
  const result = useCallback(() => {
    return apiClient.initiateCheckout(patientId, {
      successUrl: `${window.origin}${paths.checkoutSuccess.url({})}`,
      failedUrl: `${window.origin}${paths.checkoutFail.url({})}`,
    }).result as Promise<Required<Checkout>>;
  }, [apiClient, patientId]);

  return result;
}
