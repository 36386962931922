import {
  ApplicationInsights,
  DistributedTracingModes,
} from "@microsoft/applicationinsights-web";
import { createContext, useContext, useState } from "react";
import { useConfig } from "./ConfigContext";
export { SeverityLevel } from "@microsoft/applicationinsights-web";

export const Context = createContext<ApplicationInsights | null>(null);

interface AppInsightsContextProps {
  children: React.ReactNode;
}

export let insights: ApplicationInsights;

export function AppInsightsContext({ children }: AppInsightsContextProps) {
  const config = useConfig();
  const [instance] = useState(() => {
    const appInsights = new ApplicationInsights({
      config: {
        connectionString: config.appInsights.connectionString,
        distributedTracingMode: DistributedTracingModes.W3C,
        // we need to prevent adding traceparent header field to external requests
        disableAjaxTracking: true,
        disableFetchTracking: false,
        enableCorsCorrelation: true,
        enableAutoRouteTracking: false,
        autoTrackPageVisitTime: false,
        enableUnhandledPromiseRejectionTracking: true,
      },
    });

    appInsights.loadAppInsights();
    appInsights.addTelemetryInitializer((envelope) => {
      if (envelope.data) {
        envelope.data.appName = "PatientApp";
        envelope.data.appVersion = process.env.REACT_APP_VERSION;
        envelope.data.origin = window.location.origin;
        envelope.data.href = window.location.href;
      }
    });

    insights = appInsights;

    return appInsights;
  });

  return <Context.Provider value={instance}>{children}</Context.Provider>;
}

export function useAppInsights() {
  const context = useContext(Context);
  if (!context) {
    throw new Error("useAppInsights without AppInsightsContext");
  }
  return context;
}
