import { ChoiceQuestion } from "@cur8/questionnaire";
import { Trans } from "./trans";

const PhysicalActivityDurationChoices: [JSX.Element, number][] = [
  [<Trans.Exercise.HoursPerWeek hours="1-2" />, 2],
  [<Trans.Exercise.HoursPerWeek hours="3-4" />, 4],
  [<Trans.Exercise.HoursPerWeek hours="5-6" />, 6],
  [<Trans.Exercise.HoursPerWeek hours="7-8" />, 8],
  [<Trans.Exercise.HoursPerWeek hours="9-10" />, 10],
  [<Trans.Exercise.HoursPerWeek hours="10+" />, 11],
];

export const ExerciseDuration = new ChoiceQuestion(
  <Trans.Exercise.Question />,
  PhysicalActivityDurationChoices
);
