import { localize } from "render/context/InternationalizationContext";

export const Trans = {
  Title: localize({
    en_GB: "Thanks for sharing!",
    // TODO: add SE
    sv_SE: "",
  }),
  Content: localize({
    en_GB: (
      <>
        Next, let’s talk about your family and background.
        <br />
        Knowing your background will help us better understand your overall
        health and tailor preventative measures.
      </>
    ),
    // TODO: add SE
    sv_SE: <></>,
  }),
  Button: localize({
    en_GB: "OK, continue",
    // TODO: add SE
    sv_SE: "",
  }),
};
