import { classNames } from "@pomle/classnames";
import { ReactComponent as LogoIcon } from "assets/logo.svg";
import { ReactElement, useMemo } from "react";
import styles from "./styles.module.sass";

interface LogoHeaderProps {
  leftElement?: ReactElement;
  rightElement?: ReactElement;
  hideLogo?: boolean;
}

export function LogoHeader({
  leftElement,
  rightElement,
  hideLogo = false,
}: LogoHeaderProps) {
  const className = useMemo(() => {
    const result: string[] = [styles.header];

    if (!hideLogo) {
      result.push(styles.centered);
    }

    return classNames(result);
  }, [hideLogo]);

  return (
    <div className={className}>
      <div className={styles.left}>{leftElement}</div>
      {!hideLogo && <LogoIcon />}
      <div className={styles.right}>{rightElement}</div>
    </div>
  );
}
