import { localize } from "render/context/InternationalizationContext";

const PhoneNumber = localize({
  en_GB: "Phone number",
  sv_SE: "Telefonnummer",
});

const Email = localize({
  en_GB: "Email",
  sv_SE: "Email",
});

const LastName = localize({
  en_GB: "Last name",
  sv_SE: "Efternamn",
});

const FirstName = localize({
  en_GB: "First Name",
  sv_SE: "Förnamn",
});

const Submit = {
  Submitting: localize({
    en_GB: <span>Submitting...</span>,
    sv_SE: <span>Skickar</span>,
  }),
  Create: localize({
    en_GB: <span>Confirm</span>,
    sv_SE: <span>Bekräfta</span>,
  }),
};

export const Validation = {
  FirstName: {
    Required: localize({
      en_GB: "First name is required",
      sv_SE: "Förnamn krävs",
    }),
  },
  LastName: {
    Required: localize({
      en_GB: "Last name is required",
      sv_SE: "Efternamn krävs",
    }),
  },
  Email: {
    Required: localize({
      en_GB: "Email is required",
      sv_SE: "Email krävs",
    }),
    NotValid: localize({
      en_GB: "Not a valid email address",
      sv_SE: "Ogiltig mail",
    }),
  },
  Phone: {
    Required: localize({
      en_GB: "Phone number is required",
      sv_SE: "Telefonnummer krävs",
    }),
    InvalidCountry: localize({
      en_GB: "Country code not provided",
      sv_SE: "Landskod saknas",
    }),
    NotNumber: localize({
      en_GB: "Not a valid phone number",
      sv_SE: "Ogiltigt telefonnummer",
    }),
    TooShort: localize({
      en_GB: "Phone number is too short",
      sv_SE: "Telefonnumret är för kort",
    }),
    TooLong: localize({
      en_GB: "Phone number is too long",
      sv_SE: "Telefonnumret är för långt",
    }),
    InvalidLength: localize({
      en_GB: "Phone number length is invalid",
      sv_SE: "Telefonnumrets längd är ogiltig",
    }),
  },
};

export const Error = {
  Unexpected: localize({
    en_GB: "An error occurred submitting your data",
    sv_SE: "Ett fel uppstod när din data skickades",
  }),
};

export const Trans = {
  PhoneNumber,
  Email,
  LastName,
  FirstName,
  Submit,
  Validation,
  Error,
};
