import { useNav } from "@pomle/react-router-paths";
import { useTracking } from "render/hooks/useTracking";
import { paths } from "render/routes/paths";
import { ReactComponent as ArrowIcon } from "render/ui/icons/arrow.svg";
import { BigButton } from "render/ui/trigger/BigButton";
import { homeEvent } from "../../tracking";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

export function GiveConsentStage() {
  const nav = {
    consent: useNav(paths.consent),
  };
  const { trackEvent } = useTracking();

  return (
    <>
      <div className={styles.message}>{<Trans.Greeting />}</div>
      <BigButton
        onClick={() => {
          trackEvent(homeEvent.giveConsentClick());
          nav.consent.go({});
        }}
      >
        <>
          <ArrowIcon className={styles.arrow} />
          <div>
            <Trans.GiveConsentAction />
          </div>
        </>
      </BigButton>
    </>
  );
}
