import { Action, Event } from "render/hooks/useTracking";

const category = "AppointmentView";

export const appointmentEvents = {
  completeProfileClick: (): Event => {
    return {
      category,
      action: Action.Click,
      label: "complete_profile",
    };
  },

  completeProfileStepClick: (
    step:
      | "verify_identity"
      | "add_passkey"
      | "agree_to_studies"
      | "do_onboarding"
  ): Event => {
    return {
      category,
      action: Action.Click,
      label: "complete_profile_click",
      value: step,
    };
  },
};
