import { useNav } from "@pomle/react-router-paths";
import { useMSAL } from "render/context/MSALContext";
import { usePatientQuery } from "render/hooks/api/queries/usePatientQuery";
import { useTracking } from "render/hooks/useTracking";
import { paths } from "render/routes/paths";
import { Separator } from "render/ui/presentation/Separator/Separator";
import { Skeleton } from "render/ui/presentation/Skeleton";
import { Typography } from "render/ui/presentation/Typography/Typography";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { profileEvent } from "../../tracking";
import { NoConsentState } from "./components/NoConsentState";
import { PatientInfo } from "./components/PatientInfo";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

export function Profile() {
  const { logout } = useMSAL();
  const { trackEvent } = useTracking();
  const patientQuery = usePatientQuery();
  const nav = {
    home: useNav(paths.root),
  };

  return (
    <div data-hj-suppress className={styles.profile}>
      <div className={styles.content}>
        {!patientQuery.data && (
          <div className={styles.loading}>
            <div className={styles.skeletonHead}>
              <Skeleton />
            </div>
            <Separator variant="small" />
            <div className={styles.skeletonFields}>
              {Array.from({ length: 5 }).map((_, index) => {
                return (
                  <div className={styles.skeletonField} key={index}>
                    <div className={styles.skeletonLabel}>
                      <Skeleton />
                    </div>
                    <div className={styles.skeletonValue}>
                      <Skeleton />
                      <Skeleton />
                    </div>
                  </div>
                );
              })}
            </div>

            <div className={styles.skeletonButton}>
              <Skeleton />
            </div>
          </div>
        )}
        {patientQuery.data && (
          <>
            <Typography variant="title-large">
              <Trans.Title />
            </Typography>
            <div className={styles.separator}>
              <Separator variant="small" />
            </div>

            {patientQuery.data.consent.createdAt != null ? (
              <PatientInfo patient={patientQuery.data} />
            ) : (
              <NoConsentState />
            )}

            <div className={styles.buttons}>
              <ActionButton
                onClick={() => {
                  trackEvent(profileEvent.signOutClick());
                  logout({ postLogoutRedirectUrl: nav.home.to({}) });
                }}
              >
                <Trans.Button.Logout />
              </ActionButton>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
