import { useEffect, useMemo } from "react";
import * as THREE from "three";
import { createTimer } from "lib/timer";

function createRenderer(canvas: HTMLCanvasElement) {
  const renderer = new THREE.WebGLRenderer({
    alpha: true,
    canvas,
  });

  renderer.setClearColor(0x000000, 0);
  renderer.outputEncoding = THREE.sRGBEncoding;

  return renderer;
}

interface RendererProps {
  canvas: HTMLCanvasElement;
  scene: THREE.Scene;
  camera: THREE.PerspectiveCamera;
}

export function Renderer({ canvas, scene, camera }: RendererProps) {
  const renderer = useMemo(() => {
    return createRenderer(canvas);
  }, [canvas]);

  useEffect(() => {
    return createTimer(() => {
      renderer.render(scene, camera);
    });
  }, [renderer, scene, camera]);

  return null;
}
