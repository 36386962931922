import { usePatientQuery } from "render/hooks/api/queries/usePatientQuery";
import { useTracking } from "render/hooks/useTracking";
import { PopUpLayout } from "render/ui/layout/PopUpLayout/PopUpLayout";
import { Typography } from "render/ui/presentation/Typography";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { Trans, contactUsNumber } from "../ContactUs/trans";
import styles from "./styles.module.sass";
import { contactFormEvent } from "./tracking";

interface ContactUsPopupProps {
  onClose: () => void;
}

export function ContactUsPopup({ onClose }: ContactUsPopupProps) {
  const { trackEvent } = useTracking();
  const { data: patient, isLoading } = usePatientQuery();

  if (isLoading) {
    return null;
  }

  const countryCode = patient?.preferredCountry || "SE";

  return (
    <PopUpLayout
      onClose={onClose}
      header={
        <Typography variant="title-medium">
          <Trans.ContactUs.Title />
        </Typography>
      }
    >
      <div className={styles.body}>
        <div className={styles.content}>
          <div className={styles.info}>
            <Typography variant="paragraph-small">
              <Trans.ContactUs.Text countryCode={countryCode} />{" "}
            </Typography>
            <Typography variant="paragraph-small">
              <Trans.ContactUs.ChestPain countryCode={countryCode} />
            </Typography>
          </div>

          <a href={`sms:${contactUsNumber(countryCode)}`}>
            <ActionButton
              onClick={() => {
                trackEvent(contactFormEvent.formSubmitButtonClick());
                trackEvent(contactFormEvent.formSubmit());
              }}
            >
              <div className={styles.buttonText}>
                <Typography variant="cta">
                  <Trans.ContactUs.TextUs />
                </Typography>
                <Typography variant="paragraph-small" color="subtle">
                  {contactUsNumber(countryCode)}
                </Typography>
              </div>
            </ActionButton>
          </a>
        </div>
      </div>
    </PopUpLayout>
  );
}
