import { Patient } from "@cur8/rich-entity";
import { useNav } from "@pomle/react-router-paths";
import { ReactComponent as QuestionIcon } from "assets/icons/chat/chat-36x36x.svg";
import { ReactComponent as MenuIcon } from "assets/icons/menu/menu-36x36.svg";
import { useContactUsPopup } from "render/hooks/popups/useContactUsPopup";
import { useTracking } from "render/hooks/useTracking";
import { paths } from "render/routes/paths";
import { LogoHeader } from "render/ui/layout/LogoHeader";
import { PatientIdentifier } from "render/ui/presentation/PatientIdentifier";
import { IconButton } from "render/ui/trigger/IconButton";
import { SharedTrans } from "render/views/trans";
import { homeEvent } from "../tracking";
import styles from "./styles.module.sass";

interface HeaderProps {
  patient: Patient;
}

export function Header({ patient }: HeaderProps) {
  const nav = {
    profile: useNav(paths.profileCredentials),
    contact: useNav(paths.contact),
  };
  const { trackEvent } = useTracking();
  const contactUsPopup = useContactUsPopup();
  const showIdentifier = patient.consent.createdAt != null;

  return (
    <LogoHeader
      leftElement={
        <button
          className={styles.button}
          onClick={() => {
            trackEvent(homeEvent.openProfileClick());
            nav.profile.go({});
          }}
        >
          <span className={styles.icon}>
            <MenuIcon />
          </span>
          {showIdentifier && <PatientIdentifier patient={patient} />}
        </button>
      }
      rightElement={
        <IconButton
          ariaLabel={SharedTrans.ContactUs()}
          icon={<QuestionIcon display="block" />}
          onClick={() => {
            trackEvent(homeEvent.openContactsSidebarClick());
            contactUsPopup.emit();
          }}
        />
      }
      hideLogo
    />
  );
}
