import { Answers } from "@cur8/questionnaire";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { StepView } from "./components/StepView";
import * as AdditionalWorries from "./steps/additionalWorries";
import * as Allergy from "./steps/allergy";
import * as Diet from "./steps/diet";
import * as Drinking from "./steps/drinking";
import * as Exercise from "./steps/exercise";
import * as FamilyConditions from "./steps/familyConditions";
import * as Heritage from "./steps/heritage";
import * as Medication from "./steps/medication";
import * as PersonalConditions from "./steps/personalConditions";
import * as Smoking from "./steps/smoking";
import * as Snus from "./steps/snus";
import { Trans } from "./trans";
import { Step, StepProps } from "./types";
import { Patient } from "@cur8/rich-entity";
import { Country } from "lib/country";

function EndStep({
  onComplete,
  currentQuestion,
  totalQuestions,
  onPrev,
  isSubmitting,
}: StepProps) {
  return (
    <StepView
      onPrev={onPrev}
      hideProgressBar={true}
      cta={
        <ActionButton variant="primary" onClick={onComplete}>
          {isSubmitting ? (
            <Trans.General.Saving />
          ) : (
            <Trans.General.CloseAndExit />
          )}
        </ActionButton>
      }
      totalQuestionCount={totalQuestions}
      currentQuestion={currentQuestion}
      caption={<Trans.General.ThanksForYourAnswers />}
      subCaption={<Trans.General.ThanksSubCaption />}
    >
      {null}
    </StepView>
  );
}

export function getSteps(patient: Patient, answers: Answers) {
  const steps: Step[] = [];
  Diet.filter(steps, answers);
  Allergy.filter(steps, answers);
  Exercise.filter(steps, answers);
  Medication.filter(steps);
  Smoking.filter(steps, answers);
  if (patient.preferredCountry === Country.Sweden) {
    Snus.filter(steps, answers);
  }
  Drinking.filter(steps, answers);
  FamilyConditions.filter(steps);
  Heritage.filter(steps, answers);
  PersonalConditions.filter(steps);
  AdditionalWorries.filter(steps);

  steps.push(EndStep);

  return steps;
}
