import { PropsWithChildren } from "react";
import { Typography } from "render/ui/presentation/Typography";
import styles from "./styles.module.sass";

interface MetricGroupProps {
  title: React.ReactElement;
  headerHeight: number;
}

export function MetricGroup({
  title,
  children,
  headerHeight,
}: PropsWithChildren<MetricGroupProps>) {
  return (
    <section className={styles.groupContainer}>
      <div className={styles.title} style={{ top: `${headerHeight}px` }}>
        <Typography variant="title-medium">{title}</Typography>
      </div>
      <div className={styles.cardContainer}>{children}</div>
    </section>
  );
}
