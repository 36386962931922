import { ReactNode } from "react";
import { Typography } from "render/ui/presentation/Typography";
import { ReactComponent as EditIcon } from "./assets/edit-28.svg";
import styles from "./styles.module.sass";

interface InfoFieldProps {
  label: ReactNode;
  value: ReactNode;
  onClick?: () => void;
}

export function InfoField({ label, onClick, value }: InfoFieldProps) {
  return (
    <button
      onClick={onClick}
      className={styles.InfoField}
      data-canedit={!!onClick}
    >
      <div className={styles.label}>
        <Typography variant="subtitle-medium" color="subtle">
          {label}
        </Typography>
      </div>
      <div className={styles.row}>
        <div className={styles.value}>{value}</div>
        {onClick && <EditIcon />}
      </div>
    </button>
  );
}
