import { Fragment, useMemo } from "react";
import { Typography } from "render/ui/presentation/Typography";
import { sanitize } from "./sanitize";
import styles from "./styles.module.sass";

interface FreeTextProps {
  text: string;
}

export function FreeText({ text }: FreeTextProps) {
  const content = useMemo(() => {
    const source = sanitize(text);

    return source.split("\n\n").map((chunk, index) => {
      return (
        <div className={styles.FreeText} key={index}>
          <Typography variant={"text"}>
            {chunk.split("\n").map((line, index) => {
              return (
                <Fragment key={line}>
                  {index > 0 && <br />}
                  {line}
                </Fragment>
              );
            })}
          </Typography>
        </div>
      );
    });
  }, [text]);

  return <>{content}</>;
}
