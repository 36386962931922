import { APITypesV1 } from "@cur8/api-client";
import { formatToDesignSystem } from "lib/consents/formatToDesignSystem";
import { parseDocument } from "lib/legal/parseDocument";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useSession } from "render/context/MSALContext";
import { useLegalDocumentQuery } from "render/hooks/api/queries/useLegalDocumentQuery";
import { Typography } from "render/ui/presentation/Typography";
import LinkItem from "render/ui/trigger/LinkItem";
import { ListItem } from "render/ui/trigger/ListItem";
import ConsentToggle from "render/views/ProfileView/components/ConsentDocument/components/ConsentToggle";
import { ContentProps } from "render/views/ProfileView/components/ConsentDocument/components/shared";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

export function ConsentDetails({
  allowEdit,
  checked,
  onClick,
  study,
  documentUrl,
}: ContentProps) {
  const [ephemeralToken, setEphemeralToken] = useState<string>();
  const api = useAPIClient();
  const { patientId } = useSession();

  const documentUri = study?.documentUrl || documentUrl;

  const fetchEphemeralToken = useCallback(() => {
    api.patient
      .createEphermeralToken(
        { patientId },
        { requestMethod: APITypesV1.RequestMethod.RenderPatientConsent }
      )
      .result.then((response) => {
        setEphemeralToken(response.token);
      });
  }, [api.patient, patientId]);

  useEffect(() => {
    fetchEphemeralToken();
  }, [fetchEphemeralToken]);

  const consentPDFDownloadLink = useMemo(() => {
    if (ephemeralToken == null || documentUri == null) return;

    const url = api.consent.getRenderConsentDocumentURL({
      patientId,
      token: ephemeralToken,
      documentUri,
    });

    return url.toString();
  }, [api.consent, ephemeralToken, patientId, documentUri]);

  const { data } = useLegalDocumentQuery(documentUri, {
    select: parseDocument,
    enabled: Boolean(documentUri),
  });

  const title = data?.title;
  const description = data?.description;
  const headElements = data?.summary?.getElementsByTagName("*");
  const parsedHead = headElements && formatToDesignSystem(headElements);
  const bodyElements = data?.scrollable?.getElementsByTagName("*");
  const parsedBody = bodyElements && formatToDesignSystem(bodyElements);

  if (parsedHead == null || parsedBody == null) {
    return;
  }

  return (
    <section className={styles.consentContainer}>
      <div className={styles.text}>
        <Typography variant="title-medium">{title}</Typography>
        <Typography variant="paragraph">{description}</Typography>
      </div>
      <ConsentToggle
        allowEdit={allowEdit}
        checked={checked}
        onClick={onClick}
        study={study}
      />
      <div className={styles.divider} />
      <div className={styles.text}>
        {parsedHead}
        {parsedBody}
      </div>
      <section>
        <div className={styles.sectionTitle}>
          <Typography variant="title-medium">
            <Trans.MoreInfo />
          </Typography>
        </div>
        <ListItem
          name={<Trans.FindMoreInfo />}
          onClick={() => window.open(documentUri, "_blank")}
          iconVariant="download"
        />
        {checked && ephemeralToken && (
          <>
            <div className={styles.consentBorder} />
            {consentPDFDownloadLink && (
              <LinkItem
                name={<Trans.ConsentHistory />}
                target="_blank"
                href={consentPDFDownloadLink}
                iconVariant="download"
              />
            )}
          </>
        )}
      </section>
    </section>
  );
}
