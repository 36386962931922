import { useQuestionnaireLanguageCode } from "lib/questionnaire/locale";
import { StrengthExerciseDuration } from "lib/questionnaire/question-flows/onboarding/questions/strengthExercise";
import { Trans as TransQuestions } from "lib/questionnaire/question-flows/onboarding/questions/transV2";
import { ChoiceInput } from "render/ui/presentation/Questionnaire/components/ChoiceInput";
import { QuestionView } from "../../components/QuestionView";
import { Step, StepProps } from "../../step";
import { Trans } from "../../trans";

function StrengthExerciseDurationStep({
  answers,
  onAnswer,
  onNext,
  onPrev,
  currentQuestion,
  totalQuestions,
  onResetAnswer,
}: StepProps) {
  const answer = answers.get(StrengthExerciseDuration);
  const label = TransQuestions.StrengthExercise.Question();
  const { englishLabel } = TransQuestions.StrengthExercise.Question;
  const languageCode = useQuestionnaireLanguageCode();

  return (
    <QuestionView
      currentQuestion={currentQuestion}
      totalQuestionCount={totalQuestions}
      question={StrengthExerciseDuration}
      onNext={() => {
        onResetAnswer(StrengthExerciseDuration);
        onNext();
      }}
      onPrev={() => {
        onPrev();
        onResetAnswer(StrengthExerciseDuration);
      }}
      explanation={{
        openText: <Trans.General.WhatCounts />,
        closeText: <Trans.General.OkIUnderstand />,
        explanationText: <Trans.Steps.StrengthExerciseExplanation />,
      }}
    >
      <ChoiceInput
        question={StrengthExerciseDuration}
        answer={answer}
        onAnswer={(answer) => {
          onAnswer(StrengthExerciseDuration, {
            answer,
            questionAnswer: {
              questionId: "weeklyStrengthExerciseHours",
              englishLabel,
              label,
              languageCode,
              answer: {
                $type: "stringenum",
                value: StrengthExerciseDuration.getValue(answer) || "",
              },
            },
          });
          onNext();
        }}
      />
    </QuestionView>
  );
}

export function filter(steps: Step[]) {
  steps.push(StrengthExerciseDurationStep);
}
