import { PropsWithChildren, createElement } from "react";
import styles from "./styles.module.sass";

type WhiteSpaceType =
  | "normal"
  | "pre"
  | "nowrap"
  | "pre-wrap"
  | "pre-line"
  | "break-spaces";

interface TypographyProps {
  variant:
    | "number-large"
    | "number-medium"
    | "number-small"
    | "title-large"
    | "title-medium"
    | "title-small"
    | "cta"
    | "paragraph-large"
    | "paragraph-medium"
    | "paragraph-small"
    | "paragraph-x-small"
    | "subtitle-medium"
    | "subtitle-small"
    /**
     * @deprecated
     */
    | "paragraph"
    /**
     * @deprecated
     */
    | "description"
    /**
     * @deprecated
     */
    | "small"
    /**
     * @deprecated
     */
    | "text"
    /**
     * @deprecated
     */
    | "paragraph-sub";

  whiteSpace?: WhiteSpaceType;
  style?: Partial<React.CSSProperties>;
  color?: "info" | "subtle" | "warning";
}

const VARIANT_PROPS_MAP: Record<TypographyProps["variant"], VariantProps> = {
  "number-large": {
    tag: "span",
    styles: styles.numberLarge,
  },
  "number-medium": {
    tag: "span",
    styles: styles.numberMedium,
  },
  "number-small": {
    tag: "span",
    styles: styles.numberSmall,
  },
  "title-large": {
    tag: "h1",
    styles: styles.titleLarge,
  },
  "title-medium": {
    tag: "h2",
    styles: styles.titleMedium,
  },
  "title-small": {
    tag: "h3",
    styles: styles.titleSmall,
  },
  cta: {
    tag: "p",
    styles: styles.cta,
  },
  "paragraph-large": {
    tag: "p",
    styles: styles.paragraphLarge,
  },
  "paragraph-medium": {
    tag: "p",
    styles: styles.paragraphMedium,
  },
  "paragraph-small": {
    tag: "p",
    styles: styles.paragraphSmall,
  },
  "paragraph-x-small": {
    tag: "p",
    styles: styles.paragraphXSmall,
  },
  "subtitle-medium": {
    tag: "p",
    styles: styles.subtitleMedium,
  },
  "subtitle-small": {
    tag: "p",
    styles: styles.subtitleSmall,
  },
  /**
   * @deprecated
   */
  description: {
    tag: "p",
    styles: styles.description,
  },
  /**
   * @deprecated
   */
  paragraph: {
    tag: "p",
    styles: styles.p,
  },
  /**
   * @deprecated
   */
  "paragraph-sub": {
    tag: "p",
    styles: styles.paragraphSub,
  },
  /**
   * @deprecated
   */
  small: {
    tag: "p",
    styles: styles.small,
  },
  /**
   * @deprecated
   */
  text: {
    tag: "p",
    styles: styles.text,
  },
};

type VariantProps = {
  tag: string;
  styles: string;
};

export function Typography({
  variant,
  children,
  whiteSpace = "normal",
  style = {},
  color,
}: PropsWithChildren<TypographyProps>) {
  const variantProps = VARIANT_PROPS_MAP[variant];

  return createElement(variantProps.tag, {
    style: { whiteSpace, ...style },
    className: variantProps.styles,
    "data-color": color,
    children,
  });
}
