import { useMutation, useQueryClient } from "@tanstack/react-query";
import { APIClient } from "lib/api/client";
import { useAPIClient } from "render/context/APIContext";
import { useSession } from "render/context/MSALContext";
import { queryKey as patientConsentsQueryKey } from "render/hooks/api/queries/usePatientConsentsQuery";
import { queryKey as validPatientConsentsQueryKey } from "render/hooks/api/queries/useValidPatientConsentsQuery";

type RevokeConsentPayload = {
  consentId: string | undefined;
};

function mutationFunction(
  apiClient: APIClient,
  patientId: string,
  payload: RevokeConsentPayload
) {
  const { consentId } = payload;
  if (consentId == null) {
    return Promise.reject(new Error("No id for the consent provided"));
  }
  return apiClient.consent.revokeConsent({ patientId, consentId }).result;
}

export function useRevokeConsentMutation() {
  const queryClient = useQueryClient();
  const { patientId } = useSession();
  const apiClient = useAPIClient();

  return useMutation({
    mutationFn: (payload: RevokeConsentPayload) =>
      mutationFunction(apiClient, patientId, payload),
    onSuccess: () => {
      const currentPatientConsentsQueryKey = patientConsentsQueryKey(patientId);
      const currentPatientValidConsentQueryKey =
        validPatientConsentsQueryKey(patientId);
      return queryClient.invalidateQueries({
        predicate: (query) =>
          (query.queryKey[0] === currentPatientConsentsQueryKey[0] &&
            query.queryKey[1] === currentPatientConsentsQueryKey[1]) ||
          (query.queryKey[0] === currentPatientValidConsentQueryKey[0] &&
            query.queryKey[1] === currentPatientValidConsentQueryKey[1]),
      });
    },
  });
}
