import { createTimer } from "lib/timer";
import { useEffect, useMemo } from "react";
import { normalizePCDBuffers } from "render/views/Report/components/AvatarSection/components/PointCloud/point";
import { ANTICIPATED_POINT_COUNT } from "render/views/Report/components/AvatarSection/hooks/usePointCloud";
import * as THREE from "three";
import { useTexture } from "../../hooks/useTexture";
import pointImage from "./assets/moon5.png";
import { AvatarPointCloud } from "./components/AvatarPointCloud";

interface PointCloudControlProps {
  podium: THREE.Object3D;
  points?: THREE.Points;
  smplPCD?: THREE.Points;
  canvas: HTMLCanvasElement;
}

export function PointCloudControl({ podium, points }: PointCloudControlProps) {
  const frontPCD = points;

  const pointTexture = useTexture(pointImage);

  const pointCloudBuffers = useMemo(() => {
    if (!frontPCD) {
      return;
    }

    const buffers = normalizePCDBuffers(
      ANTICIPATED_POINT_COUNT * 3,
      frontPCD.geometry,
      frontPCD.geometry,
      frontPCD.geometry
    );

    return buffers;
  }, [frontPCD]);

  useEffect(() => {
    let acc = 0;

    return createTimer((deltaTime) => {
      acc += deltaTime;

      const s = acc / 1000;

      podium.rotation.z = Math.sin(s * 0.24) * (Math.PI / 6);
    });
  }, [podium]);

  return (
    <>
      {pointTexture && (
        <AvatarPointCloud
          podium={podium}
          pointTexture={pointTexture}
          pointBuffers={pointCloudBuffers}
        />
      )}
    </>
  );
}
