import { isMultiTextResponse } from "@cur8/questionnaire";
import { useQuestionnaireLanguageCode } from "lib/questionnaire/locale";
import { MedicationTake } from "lib/questionnaire/question-flows/onboarding/questions/medication";
import { Trans as TransQuestions } from "lib/questionnaire/question-flows/onboarding/questions/trans";
import { MultiTextInput } from "render/ui/presentation/Questionnaire/components/MultiTextInput";
import { QuestionView } from "../components/QuestionView";
import { Trans } from "../trans";
import { Step, StepProps } from "../types";

function TakeMedicationStep({
  answers,
  onAnswer,
  onNext,
  onPrev,
  currentQuestion,
  totalQuestions,
  onResetAnswer,
}: StepProps) {
  const answer = answers.get(MedicationTake);
  const label = TransQuestions.Medication.Question();
  const { englishLabel } = TransQuestions.Medication.Question;
  const languageCode = useQuestionnaireLanguageCode();

  const cta =
    answer &&
    isMultiTextResponse(answer.response) &&
    answer.response.choices.length > 0 ? (
      <Trans.General.YesNextQuestion />
    ) : (
      <Trans.General.NoNextQuestion />
    );

  return (
    <QuestionView
      currentQuestion={currentQuestion}
      totalQuestionCount={totalQuestions}
      question={MedicationTake}
      answer={answer}
      onNext={onNext}
      onPrev={() => {
        onPrev();
        onResetAnswer(MedicationTake);
      }}
      cta={cta}
      explanation={{
        openText: <Trans.General.WhyAreWeAsking />,
        closeText: <Trans.General.OkIUnderstand />,
        explanationText: <Trans.Steps.MedicationExplanation />,
      }}
    >
      <MultiTextInput
        placeholder={Trans.General.WriteYourAnswer()}
        answer={answer}
        addLabel={<Trans.Steps.MedicationAdd />}
        onAnswer={(newAnswer) => {
          onAnswer(MedicationTake, {
            answer: newAnswer,
            questionAnswer: {
              questionId: "medication",
              englishLabel,
              label,
              languageCode,
              answer: {
                $type: "textarray",
                value: newAnswer.response.choices,
              },
            },
          });
        }}
      />
    </QuestionView>
  );
}

export function filter(steps: Step[]) {
  steps.push(TakeMedicationStep);
}
