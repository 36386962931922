import { localize } from "render/context/InternationalizationContext";

const Title = localize({
  en_GB: <>It's time to validate your identification</>,
  sv_SE: <>Bekräfta din ID-handling</>,
});

const Paragraph1 = localize({
  en_GB: (
    <>
      We keep your health data safe in a number of different ways. In order to
      access your Neko Health data, we need to verify it’s you since this is the
      first time.
    </>
  ),
  sv_SE: (
    <>
      Vi håller din hälsodata säker på flera olika sätt. För att få tillgång
      till din data hos Neko Health behöver vi verifiera din identitet första
      gången du sätter upp ditt konto.
    </>
  ),
});

const SubParagraph1 = localize({
  en_GB: <>It takes about 2 minutes to complete</>,
  sv_SE: <>Det tar ca 2 minuter</>,
});

const VerifyCta = localize({
  en_GB: <>Verify myself</>,
  sv_SE: <>Verifiera mitt ID</>,
});

export const Trans = {
  Title,
  Paragraph1,
  SubParagraph1,
  VerifyCta,
};
