import { APITypesV1 } from "@cur8/api-client";

export function findNotParticipatedStudies(
  studies: APITypesV1.ConsentSummary[],
  studyMap: { [x: string]: string }
) {
  const notParticipated = Object.values(studyMap).reduce((acc, uri) => {
    acc.add(uri);
    return acc;
  }, new Set<string>());

  studies.forEach((study) => {
    notParticipated.delete(study.documentUrl);
  });

  return Array.from(notParticipated);
}
