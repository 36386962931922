import { ChoiceQuestion, MultiChoiceQuestion } from "@cur8/questionnaire";
import React from "react";
import { Trans } from "./trans";

export const DrinkChoices: [React.ReactNode, number][] = [
  ["1", 1],
  ["2", 2],
  ["3", 3],
  ["4", 4],
  ["5", 5],
  ["6", 6],
  ["7", 7],
  ["8", 8],
  ["9+", 9],
];

export enum AlcoholTypes {
  Wine = "wine",
  Beer = "beer",
  Liquor = "liquor",
}

export const DoYouDrinkAlcohol = new MultiChoiceQuestion(
  <Trans.Drinking.DoYouDrinkAlcohol />,
  [
    [<Trans.Drinking.Beer />, AlcoholTypes.Beer],
    [<Trans.Drinking.Liquor />, AlcoholTypes.Liquor],
    [<Trans.Drinking.Wine />, AlcoholTypes.Wine],
  ]
);

export const DrinkCount = new ChoiceQuestion(
  <Trans.Drinking.Question />,
  DrinkChoices
);
