import { Patient } from "@cur8/rich-entity";
import * as EmailValidator from "email-validator";
import { parsePhoneNumber, validatePhoneNumberLength } from "libphonenumber-js";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Locale,
  language2Locale,
  locale2lang,
  useInternationalization,
} from "render/context/InternationalizationContext";
import { LanguageSelector } from "render/fragments/LanguageSelector";
import { usePatientMutation } from "render/hooks/mutations/usePatientMutation";
import { useAsyncHandle } from "render/hooks/useAsyncHandle";
import { Input } from "render/ui/presentation/Input";
import { ActionButton } from "render/ui/trigger/ActionButton";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

interface AccountValidateFormProps {
  externalPatientData: {
    phoneNumber: string | null | undefined;
  };
  patient: Patient;
  onSuccess: () => void;
}

type Validation = Partial<{
  firstName: JSX.Element;
  lastName: JSX.Element;
  phoneNumber: JSX.Element;
  email: JSX.Element;
}>;

export function AccountValidateForm({
  externalPatientData,
  patient,
  onSuccess,
}: AccountValidateFormProps) {
  const initialPhoneNo = useMemo(() => {
    return (
      patient.contactDetails.phoneNumbers.at(0) ??
      externalPatientData.phoneNumber ??
      ""
    );
  }, [patient, externalPatientData]);

  const { enqueueSnackbar } = useSnackbar();
  const patientMutation = usePatientMutation();
  const { locale, setLocale } = useInternationalization();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [validation, setValidation] = useState<Validation>({});
  const [firstName, setFirstName] = useState(patient.name?.firstName ?? "");
  const [lastName, setLastName] = useState(patient.name?.lastName ?? "");
  const [email, setEmail] = useState(patient.contactDetails.email ?? "");
  const [phoneNumber, setPhoneNumber] = useState(initialPhoneNo);
  const [prefLang, setPrefLang] = useState(locale2lang(locale));

  const validateEmail = useCallback(
    (result: Validation) => {
      const valid = EmailValidator.validate(email);
      if (email.length === 0) {
        return result;
      }
      if (!valid) {
        result.email = <Trans.Validation.Email.NotValid />;
        setValidation(result);
      }
      return result;
    },
    [email]
  );

  const validatePhoneNumber = useCallback(
    (result: Validation) => {
      if (phoneNumber.length === 0) {
        return result;
      }
      const phoneError = validatePhoneNumberLength(phoneNumber);
      if (phoneError != null) {
        switch (phoneError) {
          case "INVALID_COUNTRY":
            result.phoneNumber = <Trans.Validation.Phone.InvalidCountry />;
            break;
          case "NOT_A_NUMBER":
            result.phoneNumber = <Trans.Validation.Phone.NotNumber />;
            break;
          case "TOO_SHORT":
            result.phoneNumber = <Trans.Validation.Phone.TooShort />;
            break;
          case "TOO_LONG":
            result.phoneNumber = <Trans.Validation.Phone.TooLong />;
            break;
          case "INVALID_LENGTH":
            result.phoneNumber = <Trans.Validation.Phone.InvalidLength />;
            break;
          default:
        }
      }
      return result;
    },
    [phoneNumber]
  );

  useEffect(() => {
    const result = validatePhoneNumber({});
    setValidation(result);
  }, [phoneNumber, validatePhoneNumber]);

  useEffect(() => {
    const result = validateEmail({});
    setValidation(result);
  }, [email, validateEmail]);

  const validate = useCallback(() => {
    const result: Validation = {};
    if (firstName.trim().length < 1) {
      result.firstName = <Trans.Validation.FirstName.Required />;
    }
    if (lastName.trim().length < 1) {
      result.lastName = <Trans.Validation.LastName.Required />;
    }
    if (email.trim().length < 1) {
      result.email = <Trans.Validation.Email.Required />;
    }
    if (phoneNumber.trim().length < 1) {
      result.phoneNumber = <Trans.Validation.Phone.Required />;
    }
    validateEmail(result);
    validatePhoneNumber(result);
    return result;
  }, [
    firstName,
    lastName,
    email,
    phoneNumber,
    validateEmail,
    validatePhoneNumber,
  ]);
  const setApiErrors = useCallback((e: any) => {
    const result: Validation = {};
    if (!e.errors) {
      return;
    }
    result.phoneNumber = e.errors["contactDetails.phoneNumbers[0]"];
    result.email = e.errors["contactDetails.email"];
    result.firstName = e.errors["name.firstName"];
    result.lastName = e.errors["name.lastName"];

    setValidation(result);
  }, []);
  const clearError = useCallback(
    (prop: keyof Validation) => {
      setValidation((v) => ({ ...v, [prop]: undefined }));
    },
    [setValidation]
  );

  const onSetLanguage = useCallback(
    (locale: Locale) => {
      setPrefLang(locale2lang(locale));
      setLocale(locale);
    },
    [setLocale, setPrefLang]
  );

  const submit = useAsyncHandle(() => {
    const errors = validate();
    if (Object.keys(errors).length > 0) {
      setValidation(errors);
      return new Promise((resolve) => resolve(undefined));
    }
    setIsSubmitting(true);
    return patientMutation({
      phoneNumber: parsePhoneNumber(phoneNumber, "SE").number,
      email,
      lastName,
      firstName,
      preferredLang: prefLang,
    })
      .then(() => onSuccess())
      .catch((e) => {
        setApiErrors(e);
        enqueueSnackbar(<Trans.Error.Unexpected />, {
          variant: "error",
        });
        setIsSubmitting(false);
      });
  });

  return (
    <div data-hj-suppress className={styles.form}>
      <div className={styles.inputs}>
        <LanguageSelector
          locale={language2Locale(prefLang)}
          onSetLanguage={onSetLanguage}
        />
        <Input
          required
          error={validation.firstName}
          name="name"
          label={<Trans.FirstName />}
          onChange={(e) => {
            clearError("firstName");
            setFirstName(e.target.value);
          }}
          onClear={() => {
            setFirstName("");
          }}
          value={firstName}
        />
        <Input
          required
          error={validation.lastName}
          name="lastName"
          label={<Trans.LastName />}
          onChange={(e) => {
            clearError("lastName");
            setLastName(e.target.value);
          }}
          onClear={() => {
            setLastName("");
          }}
          value={lastName}
        />
        <Input
          type="email"
          error={validation.email}
          inputmode="email"
          name="email"
          label={<Trans.Email />}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          onClear={() => {
            setEmail("");
          }}
          value={email}
        />
        <Input
          autocomplete="tel"
          type="tel"
          name="phoneNumber"
          pattern="[0-9]*"
          inputmode="tel"
          error={validation.phoneNumber}
          required
          label={<Trans.PhoneNumber />}
          onChange={(e) => {
            let newPhoneNumber = e.target.value;
            try {
              // we help filling out default country code if none is provided and strip redundant zeroes
              const parsedPhoneNumber = parsePhoneNumber(e.target.value, "SE");
              newPhoneNumber = parsedPhoneNumber.formatInternational();
            } catch (e) {
              // not yet valid so we do nothing
            }
            setPhoneNumber(newPhoneNumber);
          }}
          onClear={() => {
            setPhoneNumber("");
          }}
          value={phoneNumber}
        />
      </div>
      <div className={styles.cta}>
        <div>
          <ActionButton
            disabled={isSubmitting}
            style={{ whiteSpace: "nowrap" }}
            onClick={submit.callback}
          >
            {isSubmitting ? (
              <Trans.Submit.Submitting />
            ) : (
              <Trans.Submit.Create />
            )}
          </ActionButton>
        </div>
      </div>
    </div>
  );
}
