import { Typography } from "render/ui/presentation/Typography";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { CheckInBadge } from "../../../__shared/CheckInBadge";
import { CheckInStepExplanationLayout } from "../../../__shared/CheckInStepExplanationLayout";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

interface ConsentIntroProps {
  onDone: () => void;
}

export function ConsentIntro({ onDone }: ConsentIntroProps) {
  return (
    <CheckInStepExplanationLayout
      actionsSticky
      SubHeader={<CheckInBadge />}
      Content={
        <div className={styles.text}>
          <Typography variant="title-large">
            <Trans.Header />
          </Typography>
          <Typography variant="title-small">
            <Trans.Description />
          </Typography>
        </div>
      }
      Actions={
        <ActionButton onClick={onDone}>
          <Trans.Continue />
        </ActionButton>
      }
    />
  );
}
