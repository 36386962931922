import { Metric } from "lib/api/metrics";
import { getHighestSide } from "render/views/Report/components/MetricsSection/components/metrics/utils/getHighestSide";

interface GetBySideProps {
  left?: Metric<"cardio.ankle_pressure.left">;
  right?: Metric<"cardio.ankle_pressure.right">;
}

export function pickHighestSide({
  left: leftAnklePressure,
  right: rightAnklePressure,
}: GetBySideProps) {
  const highestAnklePressureSide = getHighestSide({
    left: leftAnklePressure?.unit.mmHg,
    right: rightAnklePressure?.unit.mmHg,
  });

  return {
    anklePressure:
      highestAnklePressureSide === "left"
        ? leftAnklePressure
        : rightAnklePressure,
    side: highestAnklePressureSide,
  };
}
