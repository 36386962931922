import { Slot } from "@cur8/rich-entity";
import { ReactComponent as CheckIcon } from "assets/icons/check/check-24x24.svg";
import { ReactComponent as ExIcon } from "assets/icons/ex/ex-24x24.svg";
import { useVisitPrerequisites } from "render/hooks/api/useVisitPrerequisites";
import { useTracking } from "render/hooks/useTracking";
import { appointmentEvents } from "../../tracking";
import { CompleteProfileButton } from "../CompleteProfileButton/";
import { StepButton } from "./components/StepButton/StepButton";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

export interface StepsSectionProps {
  slot: Slot;
}

export function StepsSection({ slot }: StepsSectionProps) {
  const { trackEvent } = useTracking();

  const {
    isReady,

    needsIdentityVerified,
    needsPasskey,
    needsStudies,

    canCompleteOnboarding,

    hasIdentityVerificationInProgress,
    hasIdentityVerified,
    hasPasskey,
    hasStudies,
    hasCompletedOnboarding,

    goToIdentityVerification,
    goToPassKeySetup,
    goToOnboarding,
    goToStudies,
  } = useVisitPrerequisites();

  return (
    <div className={styles.stepsContainer}>
      <div className={styles.onboarding}>
        <div className={styles.stepsHeader}>
          {isReady ? (
            <Trans.Title.Ready.Header />
          ) : (
            <Trans.Title.NotReady.Header />
          )}
        </div>
        <div className={styles.stepsDescription}>
          {isReady ? (
            <Trans.Title.Ready.SubHeader />
          ) : (
            <Trans.Title.NotReady.SubHeader />
          )}
        </div>
        <ul className={styles.todoList}>
          {needsIdentityVerified && (
            <li>
              <StepButton
                completed={
                  hasIdentityVerified === true ||
                  hasIdentityVerificationInProgress === true
                }
                onClick={() => {
                  trackEvent(
                    appointmentEvents.completeProfileStepClick(
                      "verify_identity"
                    )
                  );
                  goToIdentityVerification(slot.slotId);
                }}
              >
                {hasIdentityVerified ? (
                  <Trans.TodoList.Done.IdentityVerification />
                ) : (
                  <Trans.TodoList.Todo.IdentityVerification />
                )}
              </StepButton>
            </li>
          )}

          {needsPasskey && (
            <li>
              <StepButton
                completed={hasPasskey === true}
                onClick={() => {
                  trackEvent(
                    appointmentEvents.completeProfileStepClick("add_passkey")
                  );
                  goToPassKeySetup(slot.slotId);
                }}
              >
                {hasPasskey ? (
                  <Trans.TodoList.Done.Passkey />
                ) : (
                  <Trans.TodoList.Todo.Passkey />
                )}
              </StepButton>
            </li>
          )}

          {needsStudies && (
            <li>
              <StepButton
                completed={hasStudies}
                onClick={() => {
                  trackEvent(
                    appointmentEvents.completeProfileStepClick(
                      "agree_to_studies"
                    )
                  );
                  goToStudies(slot.slotId);
                }}
              >
                {hasStudies ? (
                  <Trans.TodoList.Done.RenewStudies />
                ) : (
                  <Trans.TodoList.Todo.RenewStudies />
                )}
              </StepButton>
            </li>
          )}

          <li>
            <StepButton
              disabled={!canCompleteOnboarding}
              completed={hasCompletedOnboarding}
              onClick={() => {
                trackEvent(
                  appointmentEvents.completeProfileStepClick("do_onboarding")
                );
                goToOnboarding(slot.slotId);
              }}
            >
              {hasCompletedOnboarding ? (
                <Trans.TodoList.Done.CompleteOnboarding />
              ) : (
                <Trans.TodoList.Todo.CompleteOnboarding />
              )}
            </StepButton>
          </li>
        </ul>
        <div data-completed={isReady} className={styles.profileButton}>
          <CompleteProfileButton slot={slot} />
        </div>
      </div>
      <div className={styles.stepsTips}>
        <div className={styles.stepsTipsHeader}>
          <Trans.BeforeYourAppointment />
        </div>
        <ul className={styles.stepsTipsList}>
          <li>
            <CheckIcon />
            <Trans.CheckList.Eating />
          </li>
          <li>
            <CheckIcon />
            <Trans.CheckList.Exercises />
          </li>
          <li>
            <ExIcon />
            <Trans.CheckList.Caffeine />
          </li>
          <li>
            <ExIcon />
            <Trans.CheckList.Skin />
          </li>
        </ul>
      </div>
    </div>
  );
}
