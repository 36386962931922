import { localize } from "render/context/InternationalizationContext";

const Title = localize({
  en_GB: <>Invite Friends and Family</>,
  sv_SE: <>Bjud in familj och vänner</>,
});

const DiscountFull = localize({
  en_GB: (
    <>
      As one of our first Neko members, you can give away a free Neko Body Scan.
      Your codes are exclusive and can only be used one time.
    </>
  ),
  sv_SE: (
    <>
      Som en av våra första medlemmar kan du skänka en helt gratis Neko Body
      Scan. Dina inbjudningskoder är exklusiva och kan bara användas en gång.
    </>
  ),
});
const DiscountPartial = localize({
  en_GB: (
    <>
      When someone books a Neko Body Scan with your invitation code, they get
      priority in the queue. Your codes are unique and can only be used once.
    </>
  ),
  sv_SE: (
    <>
      När någon bokar en Neko Body Scan med din inbjudningskod får de företräde
      i kön. Dina koder är exklusiva och kan bara användas en gång.
    </>
  ),
});

const EmptyList = localize({
  en_GB: <>You have no codes to give away.</>,
  sv_SE: <>Du har inga koder att ge bort.</>,
});

export const Trans = { DiscountFull, DiscountPartial, Title, EmptyList };
