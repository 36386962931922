import { localize } from "render/context/InternationalizationContext";
import styles from "./styles.module.sass";

const NotDone = {
  Title: localize({
    en_GB: (
      <>
        Almost <span className={styles.light}>there...</span>
      </>
    ),
    sv_SE: (
      <>
        Nästan <span className={styles.light}>klar...</span>
      </>
    ),
  }),
  Paragraph: localize({
    en_GB: (
      <>
        <span>
          You have not verified your identity, which is required in order to get
          a Neko Body Scan.
        </span>
        <span>
          It will take a few minutes, but we need to verify it’s you since this
          is the first time.
        </span>
      </>
    ),
    sv_SE: (
      <>
        <span>
          Du har inte verifierat din identitet, vilket krävs för att kunna göra
          en Neko Body Scan.
        </span>
        <span>
          Det kommer att ta några minuter, men vi behöver verifiera att det är
          du eftersom detta är första gången.
        </span>
      </>
    ),
  }),
  CTA: localize({
    en_GB: "Verify identity",
    sv_SE: "Verifiera identitet",
  }),
};

const InProgress = {
  Title: localize({
    en_GB: "We're reviewing your id",
    sv_SE: "Vi kontrollerar din ID-handling",
  }),
  Paragraph: localize({
    en_GB: "Thanks for completing this important step.",
    sv_SE: "Tack för att du har slutfört detta viktiga steg.",
  }),
};

const Failed = {
  Title: localize({
    en_GB: "Something went wrong",
    sv_SE: "Något gick fel",
  }),
  Paragraph: localize({
    en_GB: "We were not able to verify your identity.",
    sv_SE: "Vi kunde inte verifiera din identitet.",
  }),
  CTA: localize({
    en_GB: "Try again",
    sv_SE: "Försök igen",
  }),
};

export const Trans = {
  Failed,
  InProgress,
  NotDone,
};
