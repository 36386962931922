import { localize } from "render/context/InternationalizationContext";

export const Trans = {
  Title: localize({
    en_GB: "Our commitment to your data",
    sv_SE: "Vårt åtagande för din data",
  }),
  Paragraph: localize<{ link: string }>({
    en_GB: ({ link }) => (
      <>
        Your data is encrypted and can only be shared with your permission. Read
        more about{" "}
        <a href={link} target="_blank" rel="noreferrer">
          how we process your data.
        </a>
      </>
    ),
    sv_SE: ({ link }) => (
      <>
        Dina uppgifter är krypterade och kan endast delas med ditt tillstånd.
        Läs mer om{" "}
        <a href={link} target="_blank" rel="noreferrer">
          hur vi behandlar dina uppgifter.
        </a>
      </>
    ),
  }),
  ResearchStudies: localize({
    en_GB: "Research Studies",
    sv_SE: "Forskningsstudier",
  }),
  Products: localize({
    en_GB: "Product & Services",
    sv_SE: "Produkter & tjänster",
  }),
  Agreed: localize({
    en_GB: "Agreed",
    sv_SE: "Signerat",
  }),
  Expired: localize({
    en_GB: "Expired",
    sv_SE: "Löpte ut",
  }),
  Revoked: localize({
    en_GB: "Revoked",
    sv_SE: "Återkallades",
  }),
  OptedOut: localize({
    en_GB: "Opted out",
    sv_SE: "Valt bort",
  }),
  Status: localize<{ verb: JSX.Element; date: string }>({
    en_GB: ({ verb, date }) => (
      <>
        {verb} on {date}
      </>
    ),
    sv_SE: ({ verb, date }) => (
      <>
        {verb} {date}
      </>
    ),
  }),
  YourData: localize({
    en_GB: "Your data",
    sv_SE: "Din data",
  }),
  ExportYourData: localize({
    en_GB: "Export your data",
    sv_SE: "Exportera din data",
  }),
};
