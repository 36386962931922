import { localize } from "render/context/InternationalizationContext";

const General = {
  WhyAreWeAsking: localize({
    en_GB: <>Why are we asking this?</>,
    sv_SE: <>Varför frågar vi det här?</>,
  }),
  OkIUnderstand: localize({
    en_GB: <>Okay, I Understand</>,
    sv_SE: <>Okej, jag förstår</>,
  }),
  WriteYourAnswer: localize({
    en_GB: "Write your answer here",
    sv_SE: "Skriv ditt svar här",
  }),
  YesWriteHere: localize({
    en_GB: "Yes, write here",
    sv_SE: "Ja, skriv här",
  }),
  YesSubmit: localize({
    en_GB: <>Yes, submit</>,
    sv_SE: <>Ja, skicka in</>,
  }),
  NoSubmit: localize({
    en_GB: <>No, submit</>,
    sv_SE: <>Nej, skicka in</>,
  }),
  NextQuestion: localize({
    en_GB: <>Next Question</>,
    sv_SE: <>Nästa fråga</>,
  }),
  YesNextQuestion: localize({
    en_GB: <>Yes, Next Question</>,
    sv_SE: <>Ja, nästa fråga</>,
  }),
  NoNextQuestion: localize({
    en_GB: "No, Next Question",
    sv_SE: "Nej, nästa fråga",
  }),
  Saving: localize({
    en_GB: <>Saving...</>,
    sv_SE: <>Sparar...</>,
  }),
  CloseAndExit: localize({
    en_GB: <>Close and Exit</>,
    sv_SE: <>Stäng och avsluta</>,
  }),
  ThanksForYourAnswers: localize({
    en_GB: <>Thank you for answering.</>,
    sv_SE: <>Tack för dina svar.</>,
  }),
  ThanksSubCaption: localize({
    en_GB: (
      <>
        Our medical team will now analyze your answers and prepare everything
        for your visit.
      </>
    ),
    sv_SE: (
      <>
        Vårt medicinska team kommer nu att analysera dina svar och förbereda
        allt inför ditt besök.
      </>
    ),
  }),
};

/*

*/

const Steps = {
  AdditionalWorriesExplanation: localize({
    en_GB: (
      <>
        We would like to know if there is anything in particular that you are
        concerned about so that we can help you with it.
      </>
    ),
    sv_SE: (
      <>
        Vi vill gärna veta om det är något speciellt du är orolig över så att vi
        kan hjälpa dig med det.
      </>
    ),
  }),
  AllergyExplanation: localize({
    en_GB: (
      <>
        Allergies are sometimes associated with different types of diseases and
        give the doctor a better understanding of your health.
      </>
    ),
    sv_SE: (
      <>
        Allergier är ibland associerade med olika typer av sjukdomar och ger
        läkaren en bättre förståelse för din hälsa.
      </>
    ),
  }),
  DietExplanation: localize({
    en_GB: (
      <>
        Dietary habits are sometimes seen in test answers and surveys. It is
        therefore important that your doctor knows them in order to give you an
        optimal recommendation.
      </>
    ),
    sv_SE: (
      <>
        Kostvanor syns ibland i provsvar och undersökningar. Det är därför
        viktigt att din läkare känner till dom för att ge en optimal
        rekommendation till dig.
      </>
    ),
  }),
  DrinkAlcoholExplanation: localize({
    en_GB: (
      <>
        It is important for the doctor to understand your lifestyle habits.
        Alcohol is an important one, which in excessive amounts has a negative
        effect on health.
      </>
    ),
    sv_SE: (
      <>
        Det är viktigt för läkaren att förstå dina livsstilsvanor. Alkohol är en
        viktig sådan, som i omåttliga mängder påverkar hälsan negativt.
      </>
    ),
  }),
  DrinkCountExplanation: localize({
    en_GB: (
      <>
        A standard glass of alcohol is usually equivalent to a pint or 500ml can
        of regular beer, a small glass of wine, or 40ml of spirits.
      </>
    ),
    sv_SE: (
      <>
        Ett standarglas alkohol innehåller 12 gram ren alkohol. Vilket motsvarar
        till exempel 50 cl folköl, ett litet glas vin eller 4 cl sprit.
      </>
    ),
  }),
  DrinkingOpenText: localize({
    en_GB: <>How much is a glass?</>,
    sv_SE: <>Hur stort är ett standardglas?</>,
  }),
  ExerciseExplanation: localize({
    en_GB: <>Exercise habits are an important predictor of future health.</>,
    sv_SE: <>Träningsvanor är en viktig prediktor för framtida hälsa.</>,
  }),
  FamilyConditionOpenText: localize({
    en_GB: <>Who counts as family?</>,
    sv_SE: <>Vilka räknas som familj?</>,
  }),
  FamilyConditionExplanation: localize({
    en_GB: <>Siblings, parents, grandparents and parents' siblings.</>,
    sv_SE: (
      <>Syskon, föräldrar, mor- och farföräldrar samt föräldrars syskon.</>
    ),
  }),
  HeritageExplanation: localize({
    en_GB: (
      <>
        Many diseases have a higher prevalence in certain parts of the world,
        mostly due to genetics. It is therefore essential information for the
        doctor to understand here.
      </>
    ),
    sv_SE: (
      <>
        Många sjukdomar har högre prevalens i vissa delar av världen, mestadels
        pga genetik. Det är därför väsentlig information för läkaren att förstå
        det här.
      </>
    ),
  }),
  MedicationExplanation: localize({
    en_GB: (
      <>
        Medicines can sometimes cause side effects and affect test results. That
        is something the doctor needs to know.
      </>
    ),
    sv_SE: (
      <>
        Mediciner kan ibland ge biverkningar och påverka testresultat. Det är
        något som läkaren behöver veta.
      </>
    ),
  }),
  MedicationAdd: localize({
    en_GB: <>Add medication</>,
    sv_SE: <>Lägg till medicin</>,
  }),
  PersonalConditionExplanation: localize({
    en_GB: (
      <>
        To be able to give a good recommendation, it helps to know what problems
        you have had before so that you avoid them again.
      </>
    ),
    sv_SE: (
      <>
        För att kunna ge en bra rekommendation hjälper det att veta vilka
        problem du har haft innan så man undviker dom igen.
      </>
    ),
  }),
  SmokingExplanation: localize({
    en_GB: (
      <>
        Smoking has a strong correlation to ill health and therefore relevant to
        understand.
      </>
    ),
    sv_SE: (
      <>
        Rökning har en stark korrelation till ohälsa och därför relevant att
        förstå.
      </>
    ),
  }),
  SnusExplanation: localize({
    en_GB: (
      <>
        It is important for the doctor to understand your lifestyle habits, and
        snus is one of them.
      </>
    ),
    sv_SE: (
      <>
        Det är viktigt för läkaren att förstå dina livsstilsvanor, och snus en
        av dom.
      </>
    ),
  }),
};

export const Trans = { General, Steps };
