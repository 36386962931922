import { Eosinophils } from "@cur8/health-risks-calc";
import { useQueryParams } from "@pomle/react-router-paths";
import { queries } from "render/routes/paths";
import { toChartRanges } from "render/ui/presentation/RangeChart/lib/risk";
import { MetricDetails } from "render/views/Report/components/MetricsSection/components/layouts/Details";
import { getMetricHistory } from "render/views/Report/components/MetricsSection/components/metrics/utils/getMetricHistory";
import { useEosinophilsMetric } from "render/views/Report/components/MetricsSection/hooks/usePatientReportMetrics";
import { Trans } from "./trans";

export function MetricEosinophilsDetails() {
  const { data } = useEosinophilsMetric();

  const [params, setParams] = useQueryParams(queries.metric);
  const numOfScans = data?.length || 1;
  const scanNum = params.scan[0] || numOfScans;
  const setScanNum = (num: number) =>
    setParams({
      scan: [num],
    });
  const ranges = Eosinophils.rangesFor();

  if (!data) {
    return undefined;
  }

  const { cur: current, prev: previous } = getMetricHistory(data, scanNum);

  const riskRanges = Eosinophils.rangesFor();

  const values = {
    subLabel: "x10⁹/L",
    value: current?.unit["x10⁹/L"],
    previousValue: previous?.unit["x10⁹/L"],
    metricRating: current && riskRanges.findRisk(current.unit),
    scanDate: current?.measurement.timestampStart,
    ranges: toChartRanges(ranges.entries),
  };

  return (
    <MetricDetails
      label={<Trans.MetricName />}
      values={values}
      scanNum={scanNum}
      setScanNum={setScanNum}
      numOfScans={numOfScans}
      FAQText={Trans.FAQ}
      hideChange
    />
  );
}
