import { APITypesV1 } from "@cur8/api-client";
import { useCallback } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useSession } from "render/context/MSALContext";

export type Cart = Required<APITypesV1.Cart>;
export type UpdatePatientCartRequest = APITypesV1.UpdatePatientCartRequest;

export function useUpdateCartMutation() {
  const { patientId } = useSession();
  const apiClient = useAPIClient().billing;
  return useCallback(
    (body: APITypesV1.UpdatePatientCartRequest) => {
      return apiClient
        .updateCart(patientId, body)
        .result.then((body) => body as Cart);
    },
    [apiClient, patientId]
  );
}
