import { AnklePressure } from "@cur8/health-risks-calc";
import { toChartRanges } from "render/ui/presentation/RangeChart/lib/risk";
import { getMetricHistory } from "render/views/Report/components/MetricsSection/components/metrics/utils/getMetricHistory";
import { useAnklePressureMetric } from "render/views/Report/components/MetricsSection/hooks/usePatientReportMetrics";
import { Trans } from "./trans";

type useAnklePressureValuesType = {
  scanNum?: number;
  side: "left" | "right";
};

export function useAnklePressureValues({
  scanNum,
  side,
}: useAnklePressureValuesType) {
  const { data } = useAnklePressureMetric();

  if (!data) {
    return undefined;
  }

  const { cur: curLeft, prev: prevLeft } = getMetricHistory(data.left, scanNum);
  const { cur: curRight, prev: prevRight } = getMetricHistory(
    data.right,
    scanNum
  );

  const subLabel = <Trans.MetricUnit />;

  const prevAnklePressureLeft = prevLeft?.unit.mmHg;
  const prevAnklePressureRight = prevRight?.unit.mmHg;

  const valueLeft = curLeft?.unit.mmHg;
  const valueRight = curRight?.unit.mmHg;

  const riskRanges = AnklePressure.rangesFor();
  const metricRatingLeft = curLeft && riskRanges.findRisk(curLeft.unit);
  const metricRatingRight = curRight && riskRanges.findRisk(curRight.unit);

  const curScanDateLeft = curLeft?.measurement.timestampStart;
  const curScanDateRight = curRight?.measurement.timestampStart;

  if (side === "left") {
    return {
      subLabel,
      previousValue: prevAnklePressureLeft,
      value: valueLeft,
      metricRating: metricRatingLeft,
      scanDate: curScanDateLeft,
      ranges: toChartRanges(riskRanges.entries),
    };
  }

  if (side === "right") {
    return {
      subLabel,
      previousValue: prevAnklePressureRight,
      value: valueRight,
      metricRating: metricRatingRight,
      scanDate: curScanDateRight,
      ranges: toChartRanges(riskRanges.entries),
    };
  }
}
