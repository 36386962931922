import { ChoiceQuestion, TextQuestion } from "@cur8/questionnaire";
import { Trans } from "./trans";
export enum Diet {
  Vegan = "vegan",
  Vegetarian = "vegetarian",
  Pescatarian = "pescatarian",
  Keto = "ketogenic",
  Paleo = "paleo",
  LowCarb = "low carbohydrates",
  LowMeat = "low meat",
  Other = "other",
}

export const DietOptions = new ChoiceQuestion(<Trans.Diet.Specific />, [
  [<Trans.Diet.Vegan />, Diet.Vegan],
  [<Trans.Diet.Vegetarian />, Diet.Vegetarian],
  [<Trans.Diet.Pescatarian />, Diet.Pescatarian],
  [<Trans.Diet.Keto />, Diet.Keto],
  [<Trans.Diet.Paleo />, Diet.Paleo],
  [<Trans.Diet.LowCarb />, Diet.LowCarb],
  [<Trans.Diet.LowMeat />, Diet.LowMeat],
  [<Trans.Diet.Other />, Diet.Other],
]);

export const OtherDietOption = new TextQuestion(<Trans.Diet.Question />);
