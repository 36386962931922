import { ChoiceQuestion } from "@cur8/questionnaire";
import { Trans } from "./transV2";

export enum CardioExerciseWeeklyHoursEnum {
  None = "Zero hours",
  OneToTwo = "One to two hours",
  ThreeToFour = "Three to four hours",
  MoreThanFive = "More than five hours",
}

const CardioExerciseWeeklyHours: [
  JSX.Element,
  CardioExerciseWeeklyHoursEnum
][] = [
  [
    <Trans.CardioExercise.HoursPerWeek hours="0" />,
    CardioExerciseWeeklyHoursEnum.None,
  ],
  [
    <Trans.CardioExercise.HoursPerWeek hours="1-2" />,
    CardioExerciseWeeklyHoursEnum.OneToTwo,
  ],
  [
    <Trans.CardioExercise.HoursPerWeek hours="3-4" />,
    CardioExerciseWeeklyHoursEnum.ThreeToFour,
  ],
  [
    <Trans.CardioExercise.MoreThanPerWeek qty="5" />,
    CardioExerciseWeeklyHoursEnum.MoreThanFive,
  ],
];

export const CardioExerciseDuration = new ChoiceQuestion(
  <Trans.CardioExercise.Question />,
  CardioExerciseWeeklyHours
);
