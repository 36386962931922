import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, Stripe, StripeElementLocale } from "@stripe/stripe-js";
import { PropsWithChildren } from "react";
import { useConfig } from "render/context/ConfigContext";
import {
  locale2lang,
  useInternationalization,
} from "render/context/InternationalizationContext";
import { Checkout } from "render/hooks/mutations/useInitializeCheckoutMutation";

let stripePromise: Promise<Stripe | null>;

interface PaymentProviderProps {
  checkout: Checkout;
}

export function PaymentProvider({
  children,
  checkout,
}: PropsWithChildren<PaymentProviderProps>) {
  const config = useConfig();
  const i18nContext = useInternationalization();

  const stripePublicKey =
    config.appConfig.stripePublicKeys[checkout.checkoutData.countryIso];

  const stripeLocale = locale2lang(i18nContext.locale) as StripeElementLocale;
  if (!stripePromise) {
    stripePromise = loadStripe(stripePublicKey, {
      locale: stripeLocale,
    });
  }

  return (
    <Elements
      stripe={stripePromise}
      options={{
        locale: stripeLocale,
        fonts: [
          {
            family: "IBM Plex Sans",
            style: "normal",
            weight: "400",
            src: "url(https://publicprodneucur8assets.blob.core.windows.net/publicassets/IBMPlexSans-Regular.ttf)",
          },
          {
            family: "IBM Plex Sans",
            style: "normal",
            weight: "500",
            src: "url(https://publicprodneucur8assets.blob.core.windows.net/publicassets/IBMPlexSans-Medium.ttf)",
          },
        ],
        clientSecret: checkout.checkoutData?.clientSecret,
        appearance: {
          theme: "none",
          variables: {
            spacingGridRow: "20px",
          },
          rules: {
            ".Input": {
              backgroundColor: "transparent",
              fontFamily: "IBM Plex Sans",
              paddingTop: "0",
              paddingLeft: "0",
              paddingBottom: "22px",
              border: "none",
              borderBottom: "1px solid #4C6A70",
              borderRadius: "0",
              fontWeight: "400",
              fontSize: "24px",
              lineHeight: "31px",
              letterSpacing: "-0.03em",
              color: "#3A4C50",
            },
            ".Input--empty": {
              borderBottom: "1px solid #019DC9",
            },
            ".Input:focus": {
              outline: "none",
              borderBottom: "1px solid #019DC9",
            },
            ".Input::placeholder": {
              fontWeight: "400",
              fontSize: "24px",
              lineHeight: "31px",
              letterSpacing: "-0.03em",
              color: "#9EBCC3",
            },
            ".Label": {
              marginBottom: "8px",
              transition: " all 0.3s ease",
              color: "#4C6A70",
              fontFamily: "IBM Plex Sans",
              fontWeight: "500",
              fontSize: "12px",
              lineHeight: "16px",
              letterSpacing: "-0.03em",
              textTransform: "uppercase",
            },
            ".Label--empty": {
              color: "#019DC9",
            },
            ".Tab": {
              border: "1px solid #9ebcc3",
              borderRadius: "12px",
              marginBottom: "12px",
            },
            ".Tab--selected": {
              border: "1px solid #019dc9",
            },
            ".Block": {
              borderRadius: "12px",
            },
            ".TermsText": { color: "rgba(0,0,0,0)", lineHeight: "0px" },
            ".TabIcon": {},
            ".TabIcon--selected": {},
            ".TabLabel": {},
          },
        },
      }}
    >
      {children}
    </Elements>
  );
}
