import { useNav } from "@pomle/react-router-paths";
import { ReactComponent as CrossIcon } from "assets/cross.svg";
import { downloadFile } from "lib/file/downloadFile";
import { DateTime } from "luxon";
import { useState } from "react";
import { useSession } from "render/context/MSALContext/MSALContext";
import { useFileQuery } from "render/hooks/api/queries/useFileQuery";
import {
  GdprExport as GdprExportType,
  useGdprExportQuery,
} from "render/hooks/api/queries/useGdprExportQuery";
import { usePatientQuery } from "render/hooks/api/queries/usePatientQuery";
import { useVisitsQuery } from "render/hooks/api/queries/useVisitsQuery";
import { useGdprExportMutation } from "render/hooks/mutations/useGdprExportMutation";
import { paths } from "render/routes/paths";
import { FileSize } from "render/ui/format/FileSize";
import { FullScreenPageLayout } from "render/ui/layout/FullScreenPageLayout";
import { LogoHeader } from "render/ui/layout/LogoHeader";
import { Badge } from "render/ui/presentation/Badge";
import { Skeleton } from "render/ui/presentation/Skeleton";
import { Spinner } from "render/ui/presentation/Spinner";
import { Typography } from "render/ui/presentation/Typography";
import { Button } from "render/ui/trigger/Button";
import { Card } from "render/ui/trigger/Card";
import { IconButton } from "render/ui/trigger/IconButton";
import { CopyToClipboard } from "render/views/ProfileView/components/DataAndPrivacySection/components/CopyToClipboard";
import styles from "./styles.module.sass";
import * as Trans from "./Trans";

const pollInterval = 1000;

export function GdprExport() {
  const nav = {
    dataAndPrivacy: useNav(paths.dataAndPrivacy),
  };
  const { data: visits } = useVisitsQuery();
  const { data: patient } = usePatientQuery();
  const { data: gdprExport, isLoading } = useGdprExportQuery({
    // ideally we want to subscribe to a Server-Sent Event,
    // but for now we can aggressively poll if the member has no scans,
    // since it will be super fast to create the GDPR export.
    // We absolutely do NOT want to poll if they have scans,
    // as then it takes several minutes or even hours
    refetchInterval(data) {
      const scanCount = visits?.reduce(
        (sum, v) => sum + Object.keys(v.immutableScansPerformed ?? {}).length,
        0
      );
      if (scanCount || !data || data.completedOn) {
        return false;
      }
      return pollInterval;
    },
  });
  const { mutate } = useGdprExportMutation();

  return (
    <FullScreenPageLayout>
      <LogoHeader
        leftElement={
          <IconButton
            onClick={nav.dataAndPrivacy.on({})}
            icon={<CrossIcon />}
          />
        }
      />
      <div data-hj-suppress className={styles.body}>
        <div data-hj-suppress className={styles.text}>
          <Typography variant="title-medium">
            <Trans.Title />
          </Typography>
          <Typography variant="paragraph-medium" color="subtle">
            <Trans.Description />
          </Typography>
          {!gdprExport?.completedOn && (
            <Typography variant="paragraph-medium" color="subtle">
              <Trans.Disclaimer />
            </Typography>
          )}
        </div>
        <Action
          create={() => mutate()}
          email={patient?.contactDetails.email}
          gdprExport={gdprExport}
          isLoading={isLoading}
        />
      </div>
    </FullScreenPageLayout>
  );
}

type ActionProps = {
  create: () => void;
  email: string | undefined;
  gdprExport: GdprExportType | undefined;
  isLoading: boolean;
};

function Action({ create, email, gdprExport, isLoading }: ActionProps) {
  if (isLoading) {
    return <Loading />;
  }

  if (!gdprExport?.id) {
    return (
      <Button variant="outlined" onClick={create}>
        <Trans.CreateFiles />
      </Button>
    );
  }

  const requestedOn = gdprExport.requestedOn.toLocaleString(DateTime.DATE_MED);

  if (!gdprExport.completedOn) {
    return <Creating email={email} requestedOn={requestedOn} />;
  }

  return (
    <Completed
      onCreate={create}
      path={gdprExport.path}
      requestedOn={requestedOn}
    />
  );
}

function Loading() {
  return (
    <div className={styles.cardSkeleton}>
      <Skeleton />
    </div>
  );
}

type CreatingProps = {
  email?: string;
  requestedOn: string;
};

function Creating({ email, requestedOn }: CreatingProps) {
  return (
    <Card>
      <div className={styles.text}>
        <Typography variant="title-small">
          <Trans.CreatingFiles />
        </Typography>
        <Typography variant="paragraph" color="subtle">
          <Trans.EmailNotification email={email} />
        </Typography>
      </div>
      <Typography variant="subtitle-medium" color="subtle">
        <Trans.RequestedOn date={requestedOn} />
      </Typography>
    </Card>
  );
}

type CompletedProps = {
  onCreate?: () => void;
  path: string;
  requestedOn: string;
};

function Completed({ onCreate, path, requestedOn }: CompletedProps) {
  const { patientId } = useSession();
  const { data } = useFileQuery(path);

  return (
    <>
      <Card>
        <div className={styles.header}>
          <Typography variant="title-small">
            <Trans.DataRequest />
          </Typography>
          <FileSizeBadge size={data?.size} />
        </div>
        <div className={styles.content}>
          <Typography variant="subtitle-medium" color="subtle">
            <Trans.DecryptionPassword />
          </Typography>
          <CopyToClipboard>{patientId}</CopyToClipboard>
          <Typography variant="paragraph" color="subtle">
            <Trans.Decrypt />
          </Typography>
        </div>
        <Typography variant="subtitle-medium" color="subtle">
          <Trans.RequestedOn date={requestedOn} />
        </Typography>
        <Download getFile={data?.getFile} />
      </Card>
      <Button variant="outlined" onClick={onCreate}>
        <Trans.CreateNewFiles />
      </Button>
    </>
  );
}

type FileSizeBadgeProps = {
  size: string | undefined | null;
};

function FileSizeBadge({ size }: FileSizeBadgeProps) {
  if (!size) {
    return (
      <div className={styles.fileSizeSkeleton}>
        <Skeleton />
      </div>
    );
  }

  return (
    <Badge variant="noData">
      <FileSize size={Number(size)} />
    </Badge>
  );
}

type DownloadProps = {
  getFile: (() => Promise<File>) | undefined;
};

function Download({ getFile }: DownloadProps) {
  const [downloading, setDownloading] = useState(false);
  const [error, setError] = useState(false);

  if (!getFile) {
    return (
      <div className={styles.downloadButtonSkeleton}>
        <Skeleton />
      </div>
    );
  }

  if (downloading) {
    return (
      <>
        <hr className={styles.divider} />
        <div className={styles.content}>
          <div className={styles.row}>
            <Spinner />
            <Typography variant="cta">
              <Trans.StartingDownload />
            </Typography>
          </div>
          <Typography variant="paragraph-medium" color="subtle">
            <Trans.PleaseWait />
          </Typography>
        </div>
      </>
    );
  }

  const download = async () => {
    setDownloading(true);
    try {
      downloadFile(await getFile());
    } catch {
      setError(true);
    }
    setDownloading(false);
  };

  if (error) {
    return (
      <>
        <hr className={styles.divider} />
        <div className={styles.content}>
          <Typography variant="cta">
            <Trans.DownloadFailed />
          </Typography>
          <Typography variant="paragraph-medium" color="subtle">
            <Trans.SomethingWrong />
          </Typography>
        </div>
        <Button
          variant="active"
          onClick={() => {
            setError(false);
            download();
          }}
        >
          <Trans.TryAgain />
        </Button>
      </>
    );
  }

  return (
    <Button variant="active" onClick={download}>
      <Trans.Download />
    </Button>
  );
}
