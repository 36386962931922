import { useCallback } from "react";
import { useAPIClient } from "render/context/APIContext";

export function useGetDiscountCodeMutation() {
  const apiClient = useAPIClient().billing;
  return useCallback(
    (discountTokenCode: string) => {
      return apiClient.getDiscountTokenCode(discountTokenCode).result;
    },
    [apiClient]
  );
}
