import { useNav, useQueryParams } from "@pomle/react-router-paths";
import { ViewStack } from "@pomle/react-viewstack";
import { Direction, Slide } from "@pomle/react-viewstack-transitions";
import { useState } from "react";
import { useOnboardingQuery } from "render/hooks/api/queries/useOnboardingQuery";
import { paths, queries } from "render/routes/paths";
import { FullScreenPageLayout } from "render/ui/layout/FullScreenPageLayout";
import { OnboardingFlowView } from "render/views/OnboardingView/components/OnboardingFlowView";
import { OnboardingStudiesConsentView } from "render/views/OnboardingView/components/OnboardingStudyConsentView";

enum ActiveView {
  Studies = 0,
  OnBoarding = 1,
}

export enum Stage {
  Studies = "studies",
  Onboarding = "onboarding",
}

interface AppointmentOnboardingProps {
  slotId: string;
}

export function AppointmentOnboarding({ slotId }: AppointmentOnboardingProps) {
  const [params] = useQueryParams(queries.onboarding);
  const onboardingQuery = useOnboardingQuery();
  const [activeView, setActiveView] = useState<number>(() => {
    const val = params.initialStep[0];
    if (val === Stage.Studies) {
      return ActiveView.Studies;
    }
    if (val === Stage.Onboarding) {
      return ActiveView.OnBoarding;
    }

    return ActiveView.Studies;
  });
  const nav = {
    home: useNav(paths.root),
    appointment: useNav(paths.appointment),
  };

  return (
    <FullScreenPageLayout>
      <ViewStack>
        <Slide
          active={activeView >= ActiveView.Studies}
          direction={Direction.Right}
        >
          <OnboardingStudiesConsentView
            onClose={nav.appointment.on({ slotId })}
            onSave={() => {
              if (onboardingQuery.data != null) {
                nav.appointment.go({ slotId });
              } else {
                setActiveView(ActiveView.OnBoarding);
              }
            }}
          />
        </Slide>
        <Slide
          active={activeView >= ActiveView.OnBoarding}
          direction={Direction.Right}
        >
          <OnboardingFlowView
            onSuccess={nav.appointment.on({ slotId })}
            onNavBack={() => {
              if (params.initialStep[0] === "onboarding") {
                nav.appointment.go({ slotId });
              } else {
                setActiveView(ActiveView.Studies);
              }
            }}
          />
        </Slide>
      </ViewStack>
    </FullScreenPageLayout>
  );
}
