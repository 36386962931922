import { localize } from "render/context/InternationalizationContext";

const Title = localize({
  en_GB: <>We are glad you are here</>,
  sv_SE: <>Vi är glada att du är här</>,
});

const SubTitle = localize({
  en_GB: <>Confirm your contact details.</>,
  sv_SE: <>Bekräfta dina kontaktuppgifter.</>,
});

export const Trans = { Title, SubTitle };
