import { localize } from "render/context/InternationalizationContext";

const Error = {
  Unexpected: localize({
    en_GB: <>An unexpected error has occurred</>,
    sv_SE: <>Ett oväntat fel uppstod</>,
  }),
  InvalidInvitationCode: localize({
    en_GB: <>Invalid invitation code</>,
    sv_SE: <>Ogiltig inbjudningskod</>,
  }),
};

const PaymentInformation = localize({
  en_GB: <>Payment Details</>,
  sv_SE: <>Betalningsinformation</>,
});

const OneStepLeft = localize({
  en_GB: <>One step left</>,
  sv_SE: <>Ett steg kvar innan du har reserverat en plats</>,
});

const OneStepLeftCapture = localize({
  en_GB: <>One step closer to a scan</>,
  sv_SE: <>Ett steg närmre en scan</>,
});

const NekoTakesAllCreditCards = localize({
  en_GB: <>We accept all major debit and credit cards.</>,
  sv_SE: <>Neko Health tar alla stora debit- och kreditkort.</>,
});

const AfterPayment = localize({
  en_GB: <>You will be able to choose a time after the payment is completed.</>,
  sv_SE: <>Du kommer att kunna välja en tid efter genomförd betalning.</>,
});

const AfterPaymentDetails = localize({
  en_GB: (
    <>
      You will be able to choose a time after you have confirmed your payment
      details. You will not be charged for the visit.
    </>
  ),
  sv_SE: (
    <>
      Du kan själv välja din tid efter att du bekräftat dina
      betalningsuppgifter. Du kommer inte att bli debiterad för besöket
    </>
  ),
});

const CaptureOnly = localize({
  en_GB: (
    <>
      We will need your payment details to confirm your visit time. You will not
      be charged for the visit.
    </>
  ),
  sv_SE: (
    <>
      Vi behöver dina betalningsuppgifter för att bekräfta din bokning. Du
      kommer inte att bli debiterad för besöket.
    </>
  ),
});

const Place = localize<{ place: string }>({
  en_GB: ({ place }) => (
    <>
      Place: <b>{place}</b>
    </>
  ),
  sv_SE: ({ place }) => (
    <>
      Plats: <b>{place}</b>
    </>
  ),
});

const Time = localize<{ time: string | number }>({
  en_GB: ({ time }) => (
    <>
      Time: <b>{time} minutes</b>
    </>
  ),
  sv_SE: ({ time }) => (
    <>
      Tid: <b>{time} minuter</b>
    </>
  ),
});

const City = {
  London: localize({
    en_GB: "United Kingdom [London]",
    sv_SE: "Storbritannien [London]",
  }),
  Stockholm: localize({
    en_GB: "Sweden [Stockholm]",
    sv_SE: "Sverige [Stockholm]",
  }),
};

const OrderOverview = localize({
  en_GB: <>Order Overview</>,
  sv_SE: <>Beställningsöversikt</>,
});

const Submitting = localize({
  en_GB: <>Sending...</>,
  sv_SE: <>Skickar...</>,
});

const PayNow = localize({
  en_GB: <>Confirm</>,
  sv_SE: <>Betala nu</>,
});

const ConfirmDetails = localize({
  en_GB: <>Confirm details</>,
  sv_SE: <>Bekräfta uppgifterna</>,
});

const CreditCard = localize({
  en_GB: <>Credit Card</>,
  sv_SE: <>Kreditkort</>,
});

const PaymentDetails = localize({
  en_GB: <>Payment Details</>,
  sv_SE: <>Betalningsinformation</>,
});

const Back = localize({
  en_GB: <>Back</>,
  sv_SE: <>Tillbaka</>,
});

const Total = localize({
  en_GB: <>Total</>,
  sv_SE: <>Summa</>,
});

const ActiveCode = localize<{ code: string }>({
  en_GB: ({ code }) => <>Code: {code}</>,
  sv_SE: ({ code }) => <>Kod: {code}</>,
});

export const Trans = {
  Error,
  PaymentInformation,
  OneStepLeft,
  OneStepLeftCapture,
  NekoTakesAllCreditCards,
  AfterPayment,
  AfterPaymentDetails,
  CaptureOnly,
  Place,
  Time,
  OrderOverview,
  Submitting,
  ActiveCode,
  PayNow,
  ConfirmDetails,
  CreditCard,
  Back,
  PaymentDetails,
  Total,
  City,
};
