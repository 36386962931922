import { Locale, useLocale } from "render/context/InternationalizationContext";

export function useQuestionnaireLanguageCode() {
  const locale = useLocale();
  return toQuestionnaireLanguageCode(locale);
}

enum QuestionnaireLanguageCode {
  En = "en",
  Sv = "sv",
}

function toQuestionnaireLanguageCode(locale: Locale) {
  switch (locale) {
    case "en_GB":
      return QuestionnaireLanguageCode.En;
    case "sv_SE":
      return QuestionnaireLanguageCode.Sv;
  }
}
