import {
  AccountInfo,
  AuthenticationResult,
  Configuration,
  PublicClientApplication,
} from "@azure/msal-browser";
import { DateTime } from "luxon";
import { Session, UseMSALReturnProps } from "../types";

import { PropsWithChildren, useMemo } from "react";

export function MSALContext({ children }: PropsWithChildren<{}>) {
  return <>{children}</>;
}

export function useSession(): Session {
  return {
    patientId: "P000000000000",
    isNewUser: false,
    authenticationMethods: ["mfa"],
    stepUpUnmet: false,
    mfa: true,
  };
}

const account: AccountInfo = {
  homeAccountId: "1",
  localAccountId: "1",
  environment: "cypress",
  tenantId: "1",
  username: "integration@nekohealth.com",
  name: "Abe Lincoln",
};

export function useMSAL(): UseMSALReturnProps {
  const msalConfig = useMemo<Configuration>(
    () => ({
      auth: {
        clientId: "de020d12-18e9-4066-a70b-1d8840784a58",
        authority:
          "https://cur8dev.b2clogin.com/cur8dev.onmicrosoft.com/B2C_1_signupsignin",
        redirectUri: "http://localhost:3001",
        knownAuthorities: ["cur8dev.b2clogin.com"],
      },
    }),
    []
  );

  const instance = useMemo(() => {
    const result = new PublicClientApplication(msalConfig);
    result.acquireTokenSilent = async (): Promise<AuthenticationResult> => {
      return {
        accessToken: "mock-access-token",
        account,
        authority: "",
        correlationId: "",
        expiresOn: DateTime.now().plus({ days: 7 }).toJSDate(),
        fromCache: true,
        idToken: "",
        idTokenClaims: {},
        uniqueId: "",
        tenantId: "",
        scopes: [],
        tokenType: "",
      };
    };

    return result;
  }, [msalConfig]);

  return {
    logout: () => {},
    stepUpAccess: async () => {},
    instance,
    isReady: true,
    auth: { account },
    session: {
      patientId: "P000000000000",
      isNewUser: false,
      authenticationMethods: ["mfa"],
      stepUpUnmet: false,
      mfa: true,
    },
  };
}
