import { localize } from "render/context/InternationalizationContext";

const Baseline = localize({
  en_GB: <>Baseline</>,
  sv_SE: <>Baseline</>,
});

const FollowUp = localize({
  en_GB: <>Follow up</>,
  sv_SE: <>Uppföljning</>,
});

const WrittenOn = localize<{ date: JSX.Element }>({
  en_GB: ({ date }) => <>Written on {date}</>,
  sv_SE: ({ date }) => <>Skriven den {date}</>,
});

const ReportHistory = localize({
  en_GB: <>Report History</>,
  sv_SE: <>Rapporthistorik</>,
});

export const Trans = {
  ReportHistory,
  Baseline,
  FollowUp,
  WrittenOn,
};
