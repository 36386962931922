import { useCallback, useMemo } from "react";
import { useConfig } from "render/context/ConfigContext";

export function useConsentsMap() {
  const { appConfig } = useConfig();
  const getUrl = useCallback(
    (url: string) => new URL(url, appConfig.legalApiUrl).toString(),
    [appConfig.legalApiUrl]
  );
  return useMemo(
    () => ({
      studies: {
        CARDIO: getUrl("forms/study/2023-cardio.en.html"),
        DERMAFLOW: getUrl("forms/study/2023-dermaflow.en.html"),
        SPECTRUM: getUrl("forms/study/2023-spectrum.en.html"),
      },
      other: {
        PATIENTJOURNAL: getUrl(
          "forms/sweden/2023-december-journal-access.en.html"
        ),
        DEVELOPMENTANDTESTING: getUrl(
          "forms/gdpr/2023-june-testing-and-development.en.html"
        ),
        PRIVACYPOLICY: getUrl(
          "/pdfs/privacy-policy/2023-10-18-Neko-Health-Privacy-Notice.en.pdf"
        ),
      },
    }),
    [getUrl]
  );
}
