import { classNames } from "@pomle/classnames";
import { useMemo } from "react";
import styles from "./styles.module.sass";

type Variant = "contained" | "outlined" | "active" | "unfocused";
type Align = "center" | "spread";
type Padding = "big" | "small";

interface ButtonProps extends React.ButtonHTMLAttributes<any> {
  variant?: Variant;
  padding?: Padding;
  align?: Align;
  iconStart?: JSX.Element;
  iconEnd?: JSX.Element;
}

export function Button({
  children,
  variant = "contained",
  align = "center",
  padding = "big",
  iconStart,
  iconEnd,
  ...props
}: ButtonProps) {
  const variantStyle = useMemo(() => {
    switch (variant) {
      case "contained":
        return styles.variantContained;
      case "active":
        return styles.variantActive;
      case "outlined":
        return styles.variantOutlined;
      case "unfocused":
        return styles.variantUnfocused;
      default:
        return "";
    }
  }, [variant]);
  const alignStyle = useMemo(() => {
    switch (align) {
      case "center":
        return styles.alignCenter;
      case "spread":
        return styles.alignSpread;
      default:
        return "";
    }
  }, [align]);
  const paddingStyle = useMemo(() => {
    switch (padding) {
      case "big":
        return styles.paddingBig;
      case "small":
        return styles.paddingSmall;
      default:
        return "";
    }
  }, [padding]);

  return (
    <button
      className={classNames(
        styles.Button,
        variantStyle,
        alignStyle,
        paddingStyle
      )}
      type="button"
      {...props}
    >
      {iconStart}
      {children}
      {iconEnd}
    </button>
  );
}
