import { useNav } from "@pomle/react-router-paths";
import { useCallback, useState } from "react";
import {
  Locale,
  language2Locale,
  locale2lang,
  useInternationalization,
} from "render/context/InternationalizationContext";
import { LanguageSelector } from "render/fragments/LanguageSelector/LanguageSelector";
import { usePatientQuery } from "render/hooks/api/queries/usePatientQuery";
import { usePatientMutation } from "render/hooks/mutations/usePatientMutation";
import { useAsyncHandle } from "render/hooks/useAsyncHandle";
import { paths } from "render/routes/paths";
import { Typography } from "render/ui/presentation/Typography";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { EditPage } from "../EditPage/EditPage";
import { Trans } from "./Trans";
import styles from "./styles.module.sass";

export function EditLanguagePage() {
  const patientQuery = usePatientQuery();
  const patientMutation = usePatientMutation();
  const { locale, setLocale } = useInternationalization();

  const [prefLang, setPrefLang] = useState(locale2lang(locale));

  const nav = {
    profileCredentials: useNav(paths.profileCredentials),
  };

  const handleOnSave = useCallback(
    async (e: React.FormEvent<HTMLFormElement> | undefined = undefined) => {
      if (e) {
        e.preventDefault();
      }

      await patientMutation({
        preferredLang: prefLang,
      });

      const locale = language2Locale(prefLang);
      if (locale) {
        setLocale(locale);
      }
      await patientQuery.refetch();
      nav.profileCredentials.go({});
    },
    [patientMutation, prefLang, patientQuery, nav.profileCredentials, setLocale]
  );

  const onSaveHandle = useAsyncHandle(handleOnSave);

  const onSetLanguage = useCallback(
    (locale: Locale) => {
      setPrefLang(locale2lang(locale));
    },
    [setPrefLang]
  );

  return (
    <EditPage
      title={<Trans.Title />}
      subTitle={<Trans.Subtitle />}
      onBack={nav.profileCredentials.on({})}
      cta={
        <ActionButton
          hideIcon
          variant="suggestion"
          onClick={onSaveHandle.callback}
        >
          <div className={styles.fullWidth}>
            <Typography variant="cta">
              {onSaveHandle.busy ? (
                <Trans.Actions.Saving />
              ) : (
                <Trans.Actions.Save />
              )}
            </Typography>
          </div>
        </ActionButton>
      }
    >
      <form className={styles.EditNamePage} onSubmit={onSaveHandle.callback}>
        <LanguageSelector
          locale={language2Locale(prefLang)}
          onSetLanguage={onSetLanguage}
        />
      </form>
    </EditPage>
  );
}
