import { ChoiceQuestion } from "@cur8/questionnaire";
import { Trans } from "./transV2";

export enum BloodPressureEnum {
  Yes = "Yes",
  No = "No",
}

export const BloodPressure = new ChoiceQuestion(
  <Trans.BloodPressure.Question />,
  [
    [<Trans.BloodPressure.No />, BloodPressureEnum.No],
    [<Trans.BloodPressure.Yes />, BloodPressureEnum.Yes],
  ]
);
