import { VisitSummary } from "@cur8/rich-entity";
import { lastAuditChangeDate } from "lib/lastAuditChangeDate";
import { DateTime } from "luxon";
import { useMemo } from "react";
import { Separator } from "render/ui/presentation/Separator";
import { Typography } from "render/ui/presentation/Typography/Typography";
import { Summary } from "render/ui/presentation/doctor/Summary";
import { NextScanRecomendation } from "./components/NextScanRecomendation/NextScanRecomendation";
import { PreviousSummaries } from "./components/PreviousSummaries";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

interface SummariesTabProps {
  visitsSummaries: VisitSummary[] | undefined;
  latestScanDate: DateTime;
  onGoToScanData: () => void;
}

export function SummariesTab({
  visitsSummaries,
  latestScanDate,
  onGoToScanData,
}: SummariesTabProps) {
  const latestSummary = useMemo(() => {
    if (visitsSummaries == null) {
      return undefined;
    }
    return visitsSummaries.find(
      (summary) =>
        summary.visitDate.startOf("day") >= latestScanDate.startOf("day")
    );
  }, [visitsSummaries, latestScanDate]);

  const previousSummaries = useMemo(() => {
    if (!latestSummary || !visitsSummaries) {
      return visitsSummaries ?? [];
    }
    return visitsSummaries.filter((summary) => summary.id !== latestSummary.id);
  }, [visitsSummaries, latestSummary]);

  if (!visitsSummaries) {
    return null;
  }

  return (
    <div className={styles.SummariesTab}>
      <div className={styles.header}>
        <Typography variant="title-large">
          <Trans.Title />
        </Typography>
        {latestSummary && (
          <Typography variant="small">
            <Trans.WrittenOn
              date={
                <span className={styles.bolded}>
                  {lastAuditChangeDate(latestSummary.audit)?.toFormat(
                    "dd MMMM yyyy"
                  )}
                </span>
              }
            />
          </Typography>
        )}
      </div>
      <Separator width="24px" />
      <div className={styles.texts}>
        {!latestSummary && (
          <span className={styles.summaryIsBeingWritten}>
            <Trans.FollowUpBeingPrepared onClick={onGoToScanData} />
          </span>
        )}
        {latestSummary && latestSummary?.followUpDate && (
          <>
            <div className={styles.latestSummary}>
              <Summary
                text={latestSummary.summaryText}
                doctorURI={latestSummary.audit.created?.userUri?.toString()}
                onClick={onGoToScanData}
              />
            </div>
            <NextScanRecomendation
              scanDate={latestSummary.visitDate}
              followUpDate={latestSummary.followUpDate}
            />
          </>
        )}

        {previousSummaries.length > 0 && (
          <PreviousSummaries visitsSummaries={previousSummaries} />
        )}
      </div>
    </div>
  );
}
