import {
  AccountInfo,
  AuthenticationResult,
  EventType,
  InteractionStatus,
  PublicClientApplication,
} from "@azure/msal-browser";
import { MsalProvider, useMsal } from "@azure/msal-react";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useAppInsights } from "render/context/AppInsightsContext";
import { useConfig } from "../ConfigContext";
import { AuthenticationMethod, Session, UseMSALReturnProps } from "./types";
export { useMsalAuthentication } from "@azure/msal-react";

type Claims = {
  extension_PatientId?: string;
  newuser?: string;
  amr?: AuthenticationMethod[];
  step_up_unmet?: string;
};

function createSession(account: AccountInfo): Session {
  const claims = account.idTokenClaims as Claims;
  const patientId = claims.extension_PatientId;

  // HACK: Majority of the code base assumes patientId is always present,
  //       so let's throw an error so we have explicit types.
  if (!patientId) {
    throw new Error("Patient ID not found in claims");
  }

  return {
    patientId: patientId,
    isNewUser: claims.newuser === "True",
    authenticationMethods: claims.amr ?? [],
    stepUpUnmet: claims.step_up_unmet === "True",
    mfa: claims.amr?.includes("mfa") ?? false,
  };
}

enum PublicClientApplicationStatus {
  Initialized = "Initialized",
  NotInitialized = "NotInitialized",
  Broken = "Broken",
}

type PublicClientApplicationState = {
  status: PublicClientApplicationStatus;
};

const INITIAL: PublicClientApplicationState = {
  status: PublicClientApplicationStatus.NotInitialized,
};

const PublicClientApplicationStatusContext =
  createContext<PublicClientApplicationState>(INITIAL);

function isError(e: unknown): e is Error {
  return e instanceof Error;
}

export function MSALContext({ children }: PropsWithChildren<{}>) {
  const config = useConfig();
  const appInsights = useAppInsights();

  const [status, setStatus] = useState<PublicClientApplicationStatus>(
    PublicClientApplicationStatus.NotInitialized
  );

  const setAuthenticatedUserContext = useCallback(
    (account: AccountInfo | null) => {
      if (account) {
        const session = createSession(account);
        appInsights.setAuthenticatedUserContext(session.patientId);
      } else {
        appInsights.clearAuthenticatedUserContext();
      }
    },
    [appInsights]
  );

  const [instance] = useState(() => {
    const auth = config.appConfig.msal.auth;
    const result = new PublicClientApplication({ auth });

    /**
     * Event list
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/events.md
     */
    result.addEventCallback((message) => {
      const failureEvents = new Set<EventType>([EventType.LOGIN_FAILURE]);
      if (!failureEvents.has(message.eventType)) {
        return;
      }

      appInsights.trackException({
        error: message.error ?? undefined,
        exception: message.error ?? undefined,
        severityLevel: SeverityLevel.Error,
      });
    });
    result.addEventCallback((message) => {
      if (message.eventType !== EventType.LOGIN_SUCCESS) {
        return;
      }
      const payload = message.payload as AuthenticationResult;
      result.setActiveAccount(payload.account);
      setAuthenticatedUserContext(payload.account);
    });
    result.addEventCallback(({ eventType }) => {
      if (eventType !== EventType.INITIALIZE_START) {
        return;
      }
      const activeAccount = result.getActiveAccount();
      setAuthenticatedUserContext(activeAccount);
    });

    return result;
  });

  useEffect(() => {
    if (status === PublicClientApplicationStatus.Initialized) {
      return;
    }

    instance
      .initialize()
      .then(() => {
        setStatus(PublicClientApplicationStatus.Initialized);
      })
      .catch((e) => {
        setStatus(PublicClientApplicationStatus.Broken);
        const error = isError(e)
          ? e
          : new Error("Failed to initialize msal public client");

        appInsights.trackException({
          error: error,
          exception: error,
          severityLevel: SeverityLevel.Error,
        });
      });
  }, [appInsights, instance, status]);

  return (
    <PublicClientApplicationStatusContext.Provider value={{ status }}>
      <MsalProvider instance={instance}>{children}</MsalProvider>
    </PublicClientApplicationStatusContext.Provider>
  );
}

export function useMSAL(): UseMSALReturnProps {
  const publicClientApplicationState = useContext(
    PublicClientApplicationStatusContext
  );
  const { inProgress, instance } = useMsal();
  const config = useConfig();

  const account = instance.getActiveAccount();

  const session = useMemo(() => {
    if (account) {
      return createSession(account);
    }
    return null;
  }, [account]);

  const auth = useMemo(() => (account ? { account } : undefined), [account]);

  const stepUpAccess = useCallback(
    async (method: "psk" | "idv", redirectStartPage?: string) => {
      const account = instance.getActiveAccount();
      if (!account) {
        throw new Error("No active account");
      }

      return instance.acquireTokenRedirect({
        scopes: config.appConfig.msal.scopes.token,
        redirectUri: window.location.origin,
        extraQueryParameters: {
          acr_values: method,
        },
        redirectStartPage: redirectStartPage,
      });
    },
    [instance, config.appConfig.msal.scopes.token]
  );

  // to whom it may concern:
  // msal library doesn't seem to send cookies to Identity's Logout endpoint
  // so we have to do it manually in order to ensure a complete logout
  // (otherwise, the user will be logged back in automatically)
  const logout = useCallback(
    async (props: Partial<{ postLogoutRedirectUrl: string }> = {}) => {
      const account = instance.getActiveAccount();
      instance.logoutRedirect({
        postLogoutRedirectUri: props.postLogoutRedirectUrl,
        idTokenHint: account?.idToken,
      });
    },
    [instance]
  );
  const isMsalReady = inProgress === InteractionStatus.None;
  const isInstanceInitialized =
    publicClientApplicationState.status ===
    PublicClientApplicationStatus.Initialized;

  const isReady = isMsalReady && isInstanceInitialized;

  return {
    logout,
    stepUpAccess,
    instance,
    isReady,
    auth,
    session,
  };
}

export function useSession(): Session {
  const { session } = useMSAL();
  if (!session) {
    throw new Error("No Session account");
  }
  return session;
}
