import { APIFetchError, APITypesV1 } from "@cur8/api-client";
import { useCallback } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useSession } from "render/context/MSALContext";

interface PatientUpdateProps {
  email?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  preferredLang?: string;
  displayName?: string;
  sex?: APITypesV1.Sex;
}

export class PhoneNumberTakenError extends Error {}

export function usePatientMutation() {
  const { patientId } = useSession();
  const apiClient = useAPIClient().patient;
  const result = useCallback(
    (props: PatientUpdateProps) => {
      return apiClient.fetchPatient({ patientId }).result.then((patient) => {
        const update: APITypesV1.UpdatePatientDetailsRequest = {};

        if (props.email || props.phoneNumber) {
          update.contactDetails =
            patient.contactDetails ??
            ({} as APITypesV1.UpdatePatientDetailsRequest["contactDetails"]);
          if (props.email) {
            update.contactDetails.email = props.email;
          }
          if (props.phoneNumber) {
            update.contactDetails.phoneNumbers = [props.phoneNumber];
          }
        }
        if (props.firstName || props.lastName || props.displayName) {
          update.name =
            patient.name ??
            ({} as APITypesV1.UpdatePatientDetailsRequest["name"]);
          update.name.firstName = props.firstName ?? patient.name.firstName;
          update.name.lastName = props.lastName ?? patient.name.lastName;
          update.name.displayName =
            props.displayName ?? patient.name.displayName;
        }
        if (props.preferredLang) {
          update.preferredLanguage = props.preferredLang;
        }
        if (props.sex) {
          update.sex = props.sex;
        }

        return apiClient
          .updatePatient(patientId, update)
          .result.catch(async (e) => {
            if (e instanceof APIFetchError) {
              const errorBody = await e.response.json();
              if (
                e.response.status === 409 &&
                errorBody.title === "Duplicate phone number"
              ) {
                throw new PhoneNumberTakenError();
              }
            }

            throw e;
          });
      });
    },
    [apiClient, patientId]
  );

  return result;
}
