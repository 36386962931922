import { useNav } from "@pomle/react-router-paths";
import { useEffect } from "react";
import { useUpcomingVisitQuery } from "render/hooks/api/queries/useUpcomingVisitQuery";
import { useTimeOut } from "render/hooks/useTimeOut";
import { paths } from "render/routes/paths";
import { BurgerLayout } from "render/ui/layout/BurgerLayout";
import { FullScreenPageLayout } from "render/ui/layout/FullScreenPageLayout";
import { LogoHeader } from "render/ui/layout/LogoHeader";
import { Typography } from "render/ui/presentation/Typography";
import { VideoBackground } from "render/views/_shared/VideoBackground";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

export function AppointmentBeingCreatedStage() {
  const nav = {
    appointment: useNav(paths.appointment),
  };
  const upcomingPatientVisits = useUpcomingVisitQuery();

  const { startTimeout, clearTimeout } = useTimeOut(3000, () => {
    upcomingPatientVisits.refetch();
  });

  useEffect(() => clearTimeout, [clearTimeout]);

  useEffect(() => {
    if (!upcomingPatientVisits.data) {
      startTimeout();
      return;
    }
    if (!upcomingPatientVisits.data.length) {
      return startTimeout();
    }
    clearTimeout();
    nav.appointment.go({ slotId: upcomingPatientVisits.data[0].slotId });
  }, [upcomingPatientVisits.data, startTimeout, clearTimeout, nav.appointment]);

  return (
    <FullScreenPageLayout disableBackground>
      <BurgerLayout>
        <LogoHeader />
        <div className={styles.body}>
          <div className={styles.content}>
            <div className={styles.text}>
              <Typography style={{ textAlign: "center" }} variant="title-large">
                <Trans.Title />
              </Typography>
              <Typography
                style={{ textAlign: "center" }}
                variant="title-small"
                color="subtle"
              >
                <Trans.SubTitle />
              </Typography>
            </div>
          </div>
        </div>
        {null}
      </BurgerLayout>
      <VideoBackground />
    </FullScreenPageLayout>
  );
}
