import { useQuestionnaireLanguageCode } from "lib/questionnaire/locale";
import { OtherHealthConditions } from "lib/questionnaire/question-flows/onboarding/questions/otherHealthConditions";
import { Trans as TransQuestions } from "lib/questionnaire/question-flows/onboarding/questions/transV2";
import { TextInput } from "render/ui/presentation/Questionnaire/components/TextInput";
import { QuestionView } from "../../components/QuestionView";
import { Step, StepProps } from "../../step";
import { Trans } from "../../trans";

function OtherHealthConditionsStep({
  answers,
  onAnswer,
  onNext,
  onPrev,
  currentQuestion,
  totalQuestions,
  onResetAnswer,
}: StepProps) {
  const answer = answers.get(OtherHealthConditions);
  const label = TransQuestions.OtherHealthConditions.Question();
  const { englishLabel } = TransQuestions.OtherHealthConditions.Question;
  const languageCode = useQuestionnaireLanguageCode();

  return (
    <QuestionView
      currentQuestion={currentQuestion}
      totalQuestionCount={totalQuestions}
      question={OtherHealthConditions}
      onNext={onNext}
      onPrev={() => {
        onPrev();
        onResetAnswer(OtherHealthConditions);
      }}
      cta={<Trans.General.Continue />}
      explanation={{
        openText: <Trans.General.WhyAreWeAsking />,
        closeText: <Trans.General.OkIUnderstand />,
        explanationText: <Trans.Steps.OtherHealthConditionsExplanation />,
      }}
    >
      <TextInput
        placeholder={Trans.General.WriteYourAnswer()}
        answer={answer}
        onAnswer={(answer) => {
          onAnswer(OtherHealthConditions, {
            answer,
            questionAnswer: {
              questionId: "otherHealthConditions",
              englishLabel,
              label,
              languageCode,
              answer: {
                $type: "text",
                value: OtherHealthConditions.getValue(answer).trim(),
              },
            },
          });
        }}
      />
    </QuestionView>
  );
}

export function filter(steps: Step[]) {
  steps.push(OtherHealthConditionsStep);
}
