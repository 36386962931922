import { APITypesV2 } from "@cur8/api-client";
import { Answer, Answers, Question } from "@cur8/questionnaire";
import { useCallback, useMemo, useState } from "react";
import { Questionnaire } from "render/ui/presentation/Questionnaire";
import { getSteps } from "./flow";

export interface QuestionnaireAnswer {
  answer: Answer;
  questionAnswer: APITypesV2.QuestionAnswer;
}

export interface QuestionsViewProps {
  onContactUsClick: () => void;
  answers: Answers;
  onAnswer: (question: Question, answer: QuestionnaireAnswer) => void;
  onResetAnswer: (question: Question) => void;
  onComplete: () => void;
  isSubmitting: boolean;
  onNavBack: () => void;
}

export function QuestionsView({
  isSubmitting,
  answers,
  onAnswer,
  onComplete,
  onResetAnswer,
  onNavBack,
  onContactUsClick,
}: QuestionsViewProps) {
  const [activeIndex, setActiveIndex] = useState(0);

  const step = useCallback((offset: number) => {
    setActiveIndex((index) => index + offset);
  }, []);

  const onPrev = useCallback(() => {
    if (activeIndex === 0) {
      onNavBack();
    } else {
      step(-1);
    }
  }, [step, activeIndex, onNavBack]);

  const onNext = useCallback(() => {
    step(1);
  }, [step]);

  const enabledSteps = useMemo(() => {
    return getSteps(answers);
  }, [answers]);

  return (
    <Questionnaire activeIndex={activeIndex}>
      {enabledSteps.map((Step, index) => {
        return (
          <Step
            onContactUsClick={onContactUsClick}
            isSubmitting={isSubmitting}
            currentQuestion={index + 1}
            totalQuestions={enabledSteps.length - 1}
            key={index}
            answers={answers}
            onAnswer={onAnswer}
            onResetAnswer={onResetAnswer}
            onComplete={onComplete}
            onPrev={onPrev}
            onNext={onNext}
          />
        );
      })}
    </Questionnaire>
  );
}
