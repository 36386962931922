import { APITypesV1 } from "@cur8/api-client";
import { DiscountCodeAvailable } from "./components/DiscountCodeAvailable";
import { DiscountCodeExpired } from "./components/DiscountCodeExpired";
import { DiscountCodeRedeemed } from "./components/DiscountCodeRedeemed";

interface DiscountTokenProps {
  token: APITypesV1.DiscountToken;
}

export function DiscountCode({ token }: DiscountTokenProps) {
  if (token.isRedeemed) {
    return <DiscountCodeRedeemed token={token} />;
  }
  if (token.isValid === false || token.expired === true) {
    return <DiscountCodeExpired token={token} />;
  }

  return <DiscountCodeAvailable token={token} />;
}
