import { ChoiceQuestion } from "@cur8/questionnaire";
import React from "react";
import { Trans } from "./transV2";

export enum DrinkChoicesEnum {
  LessThanOne = "Less than one glass",
  OneToTwo = "One to two glasses",
  ThreeToFour = "Three to four glasses",
  FiveToSix = "Five to six glasses",
  SevenToEight = "Seven to eight glasses",
  MoreThanNine = "More than nine glasses",
}

export const DrinkChoices: [React.ReactNode, DrinkChoicesEnum][] = [
  [<Trans.Drinking.GlassesLessThanOne />, DrinkChoicesEnum.LessThanOne],
  [<Trans.Drinking.GlassesOneToTwo />, DrinkChoicesEnum.OneToTwo],
  [<Trans.Drinking.GlassesThreeToFour />, DrinkChoicesEnum.ThreeToFour],
  [<Trans.Drinking.GlassesFiveToSix />, DrinkChoicesEnum.FiveToSix],
  [<Trans.Drinking.GlassesSevenToEight />, DrinkChoicesEnum.SevenToEight],
  [<Trans.Drinking.GlassesMoreThanNine />, DrinkChoicesEnum.MoreThanNine],
];

export const DoYouDrinkAlcohol = new ChoiceQuestion(
  <Trans.Drinking.DoYouDrinkAlcohol />,
  [
    [<Trans.Drinking.No />, false],
    [<Trans.Drinking.Yes />, true],
  ]
);

export const DrinkCount = new ChoiceQuestion(
  <Trans.Drinking.Question />,
  DrinkChoices
);
